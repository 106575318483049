<template>
    <Button class='p-button-sm p-button-text' :icon='alignmentIcon' />
</template>

<script>
export default {
  name: 'MoreOptionsMenu',
  props: {
    alignment: {
      type: String,
      default: 'horizontal'
    }
  },
  computed: {
    alignmentIcon() {
      const name = 'pi pi-ellipsis-';

      return this.alignment === 'horizontal' ? `${name}h` : `${name}v`;
    }
  }
}
</script>

<style scoped>
.p-button.p-button-text {
    color: #32364E;
}

.p-button.p-button-text:enabled:hover {
  background: rgba(166, 166, 166, 0.04);
  color: #33CC99;
}

.p-button:focus {
  box-shadow: 0 0 0 0.2rem #A6A6A6;
}
</style>