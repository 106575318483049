<template>
    <Dialog class="post-locations-dialog" :modal="true" v-model:visible="displayDialog" :dismissableMask="true"
        :draggable="false">
        <template #header>
            <div class="header">
                <span class="dialog-title">{{ isRepost ? dialogHeader.repost : dialogHeader.post }}</span>
            </div>
        </template>
        <div class="locations-container">
            <div class="selection-container">
                <div class="selection-label-container">
                    <Checkbox v-model="mainFeed" name="mainFeed" value="selected" inputId="mainFeed" />
                    <label class="selection-label" for="mainFeed">Home Feed</label>
                </div>
            </div>
            <div class="groups-selection-header">
                <p>Your Groups <span>(select up to 1)</span></p>
                <Button label="Clear" class="p-button-text" @click="clearSelectedGroup()" />
            </div>
            <infinite-scroll class="members-scroll" @infinite-scroll="loadMoreGroups" message="" :noResult="noMoreGroups"
                :style="scrollStyle">
                <LoadingSpinner v-if="loading" :showLoading="loading"/>
                <div class="selection-container" v-for="group of userGroups" :key="group.groupId">
                    <div class="selection-label-container">
                        <RadioButton v-model="selectedGroup" :value="group" :name="group.name" :inputId="'' + group.groupId"
                            @click="selectGroupClick(group)" />
                        <label class="selection-label" :for="'' + group.groupId">{{ group.name }}</label>
                    </div>
                    <div class="selection-feed" v-if="selectedGroup == group">
                        <ProgressSpinner v-if="displayWaitForGroupFeeds" :showLoading="displayWaitForGroupFeeds"
                            class="group-feeds-progress-spinner" />
                        <Dropdown v-else :options="group.feedsList" optionLabel="name" optionValue="groupFeedId"
                            v-model="selectedGroupFeedId" />
                    </div>
                </div>
            </infinite-scroll>
        </div>
        <template #footer>
            <Button :label="isRepost ? dialogFooterButton.repost : dialogFooterButton.post" class="action-button" 
                :disabled="loading || displayWaitForGroupFeeds" @click="postToLocations" />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import LoadingSpinner from '../common/LoadingSpinner';

import GroupService from '../../service/GroupService';

// import EventBus from '../../event-bus';

const dialogHeader = {
    post: "Select Post Locations",
    repost: "Select Repost Locations"
};
const dialogFooterButton = {
    post: "Post",
    repost: "Repost"
};

export default {
    name: 'PostLocationsDialog',
    emits: ['post-to-locations'],
    props: {
        isRepost: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogHeader,
            dialogFooterButton,

            note: null,

            displayDialog: false,

            mainFeed: ['selected'],
            selectedGroup: null,
            selectedGroupFeedId: null,
            displayWaitForGroupFeeds: false,
            latestGroupFeed: { name: 'Latest', groupFeedId: 0 },
            userGroups: [],
            userHasGroups: false,
            excludedGroupCount: 0,
            loading: false,
            noMoreGroups: false,
            avoidGroupId: false,

            scrollStyle: 'width: 100%; max-height: 100%; height: 100%;',
        };
    },

    components: {
        Dialog,
        Checkbox,
        RadioButton,
        Dropdown,
        ProgressSpinner,
        InfiniteScroll,
        LoadingSpinner,
    },

    methods: {
        open(avoidGroupId) {
            this.avoidGroupId = avoidGroupId;
            this.startPostSequence();
            this.displayDialog = true;
        },

        resetNote() {
            this.mainFeed = ['selected'];
            this.selectedGroup = null;
        },

        clearSelectedGroup() {
            this.selectedGroup = null;
            this.selectedGroupFeedId = this.latestGroupFeed.groupFeedId;
        },

        postToLocations() {
            // console.log('postToLocations', this.selectedGroup?.groupId, this.selectedGroupFeedId, this.mainFeed.includes('selected'));
            const postToMainFeed = this.mainFeed.includes('selected');
            if ((this.selectedGroup && this.selectedGroupFeedId >= 0) || postToMainFeed) {
                this.$emit('post-to-locations', postToMainFeed, this.selectedGroup?.groupId, this.selectedGroupFeedId);
                this.displayDialog = false;
            } else {
                this.$toast.add({ severity: 'info', summary: 'Select at least one location to post.', life: 2000, group: 'center' });
            }
        },

        async startPostSequence() {
            this.noMoreGroups = false;
            this.excludedGroupCount = 0;
            this.userGroups = [];

            this.loadMoreGroups();
        },

        async loadMoreGroups() {
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreGroups) {
                    this.fetchGroups(this.userGroups.length, 25);
                } else {
                    this.loading = false;
                }
            }
        },

        fetchGroups(offset, limit) {
            this.excludedGroupCount == offset == 0 ? 0 : this.excludedGroupCount;

            GroupService.getMyGroups(offset + this.excludedGroupCount, limit).then((resp) => {
                this.loading = false;
                if (resp.data.status == 'success') {
                    // console.log('fetchGroups', resp);
                    const groupsArray = resp.data.groups.filter((group) => {
                        if (group.groupId != this.avoidGroupId && group.groupId != 1 && group.bio) {
                            return true;
                        } else {
                            this.excludedGroupCount++;
                            return false;
                        }
                    });

                    if (groupsArray.length > 0) {
                        this.userHasGroups = true;
                        this.userGroups = [...this.userGroups, ...groupsArray];

                        this.noMoreGroups = this.userGroups.length + this.excludedGroupCount == resp.data.totalCount;

                        this.updateSelectedGroupAndFeed();
                    } else {
                        this.noMoreGroups = true;

                        if(this.userGroups && this.userGroups.length == 0){
                            this.$emit('post-to-locations', true);
                            this.displayDialog = false;
                            return;
                        }
                    }
                }
            });
        },

        updateSelectedGroupAndFeed() {
            const selectedGroup = this.$store.state.selectedGroup;
            const selectedGroupConduit = this.$store.state.selectedGroupConduit;
            const groupFeedId = selectedGroupConduit ? selectedGroupConduit.obj.groupFeedId : this.latestGroupFeed.groupFeedId;

            this.userGroups.every((group) => {
                if (selectedGroup && group.groupId == selectedGroup.groupId) {
                    this.selectedGroup = group;
                    // console.log("updateSelectedGroupAndFeed", this.selectedGroup, groupFeedId);
                    this.selectGroupClick(this.selectedGroup, groupFeedId);
                    return false;
                }
                return true;
            });
        },

        selectGroupClick(group, selectedGroupFeedId) {
            this.selectedGroupFeedId = selectedGroupFeedId ? selectedGroupFeedId : this.latestGroupFeed.groupFeedId;

            if (!this.displayWaitForGroupFeeds && !group.feedsList) {
                this.displayWaitForGroupFeeds = true;
                console.log('waiting to fetch group channels for group  ' + group.groupId);
                GroupService.getGroupFeeds(group.groupId)
                    .then((resp) => {
                        try {
                            let groupFeeds = resp.data.groupFeeds;

                            group.feedsList = [this.latestGroupFeed, ...groupFeeds];
                        } finally {
                            this.displayWaitForGroupFeeds = false;
                        }
                    })
                    .catch((err) => {
                        console.log('error getting group feeds: ', err);
                        this.displayWaitForGroupFeeds = false;
                    });
            } else {
                this.displayWaitForGroupFeeds = false;
            }
        },
    },
};
</script>

<style>
.post-locations-dialog .p-dialog-header .header {
    text-align: center;
    width: 100%;
}

.post-locations-dialog .p-dialog-header .header .dialog-title {
    font-size: 24px;
    font-weight: bold;
    color: #32364e;
    margin-right: 24px;
}

.post-locations-dialog {
    font-family: 'Trebuchet MS', 'Verdana';
    max-height: calc(100vh - 100px);
    max-width: 700px;
    min-width: 500px;
    border-radius: 16px;
}

.post-locations-dialog .p-dialog-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 16px 16px;
}

.post-locations-dialog .p-dialog-content {
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    padding: 8px 0px 0px;
    scrollbar-color: #bfbfbf #f2f2f2;
    scrollbar-width: thin;
}

.post-locations-dialog .p-dialog-content::-webkit-scrollbar {
    /* width */
    width: 10px;
}

.post-locations-dialog .p-dialog-content::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #bfbfbf;
    /* color of the scroll thumb */
    border-radius: 10px;
    /* roundness of the scroll thumb */
}

.post-locations-dialog .p-dialog-footer {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 16px;
    text-align: center;
}

@media (max-width: 760px) {
    .post-locations-dialog {
        border-radius: 0px;
        width: 100%;
        height: 100%;
        min-width: 100% !important;
        max-height: 100vh !important;
    }
    .post-locations-dialog .p-dialog-header {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }  
    .post-locations-dialog .p-dialog-footer {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }  


}
</style>
<style scoped>
@media (max-width: 760px) {
    .selection-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
    }

    .selection-feed {
        margin-left: unset !important;
        width: 100%;
    }
    .selection-feed .p-dropdown {
        width: 100%;
        margin-left: 28px;
    }
}
.locations-container {
    padding: 0px 16px;
    height: 100%;
}

.selection-container {
    padding: 2px 2px 2px 2px;
    display: flex;
    align-items: center;
}

.selection-container:last-child {
    padding-bottom: 0px;
}

.selection-container .p-radiobutton,
.selection-container .p-checkbox  {
    margin: 8px 8px 8px 0px;
}

.selection-feed {
    margin-left: auto;
    display: flex;
}

.group-feeds-progress-spinner {
    height: 20px;
    min-height: 20px;
    width: 20px;
    min-width: 20px;
    margin-left: 8px;
    margin-right: 16px;
}

.groups-selection-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.groups-selection-header p {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0px;
}

.groups-selection-header button {
    color: #33cc99;
    height: 26px;
    font-size: 14px;
    font-weight: bold;
}

.groups-selection-header button:hover {
    color: white !important;
    background: #00c083 !important;
}

.groups-selection-header span {
    font-size: 14px;
    font-weight: bold;
}

.selection-label {
    font-size: 16px;
    margin-right: 8px;
    width: 100%;
}

.selection-label-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.action-button {
    color: #ffffff;
    background: #33cc99;
    border: 1px solid #33cc99;
    border-radius: 500px;
    padding: 8px 64px;
    font-size: 18px;
}

.action-button:hover {
    background: #00c083;
    border-color: #00c083;
}

.action-button:focus {
    box-shadow: none;
    border-color: #33cc99;
}
</style>