<template>
    <div class='p-grid'>
        <div class='p-sm-6'>
            <table class='quote-data-table'>
                <tr>
                    <td class='label-container'>
                        <span class='label'>Ask Price:</span>
                    </td>
                    <td class='value-container'>
                        <span :class="priceValueClass(askPrice)" v-if="askPrice">{{formatter.format(askPrice, '$0.00')}}</span>
                        <span class="value" v-else>-</span>
                    </td>
                </tr>
                <tr>
                    <td class='label-container'>
                        <span class='label'>Ask Size:</span>
                    </td>
                    <td class='value-container'>
                        <span class="value" v-if="askSize">{{askSize}}</span>
                        <span class="value" v-else>-</span>
                    </td>
                </tr>
                <tr>
                    <td class='label-container'>
                        <span class="label">Open:</span>
                    </td>
                    <td class='value-container'>
                        <span class="value" v-if="openValue">{{formatter.format(openValue, '$0.00')}}</span>
                        <span class="value" v-else>-</span>
                    </td>
                </tr>
                <tr>
                    <div class='label-container'>
                        <span class='label'>High:</span>
                    </div>
                    <td class='value-container'>
                        <span class="value" v-if="highValue">{{formatter.format(highValue, '$0.00')}}</span>
                        <span class="value" v-else>-</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class='p-sm-6'>
            <table class='quote-data-table'>
                <tr>
                    <td class='label-container'>
                        <span class='label'>Bid Price:</span>
                    </td>
                    <td class='value-container'>
                        <span :class="priceValueClass(askPrice)" v-if="bidPrice">{{formatter.format(bidPrice, '$0.00')}}</span>
                        <span class="value" v-else>-</span>
                    </td>
                </tr>
                <tr>
                    <td class='label-container'>
                        <span class='label'>Bid Size:</span>
                    </td>
                    <td class='value-container'>
                        <span class="value" v-if="bidSize">{{bidSize}}</span>
                        <span class="value" v-else>-</span>
                    </td>
                </tr>
                <tr>
                    <td class='label-container'>
                        <span class="label">Volume:</span>
                    </td>
                    <td class='value-container'>
                        <span class="value" v-if="volume">{{volume}}</span>
                        <span class="value" v-else>-</span>
                    </td>
                </tr>
                <tr>
                    <td class='label-container'>
                        <span class='label'>Low:</span>
                    </td>
                    <td class='value-container'>
                        <span class="value" v-if="lowValue">{{formatter.format(lowValue, '$0.00')}}</span>
                        <span class="value" v-else>-</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>

import FormattingUtils from '../../utilities/FormattingUtils';
import NumberUtils from '../../utilities/NumberUtils';

export default {
    name: 'QuoteFeedTable',
    props: {
        askPrice: {

            required: true
        },

        askSize: {
            required: true
        },

        openValue: {
            required: true
        },

        highValue: {
            required: true
        },

        bidPrice: {
            required: true
        },

        bidSize: {
            required: true
        },

        volume: {
            required: true
        },

        lowValue: {
            required: true
        }
    },
    data() {
        return {
            formatter: new FormattingUtils()
        }
    },

    methods: {
        priceValueClass(price) {
            return {
                'price-value': true,
                'positive': NumberUtils.isPositive(price),
                'negative': NumberUtils.isNegative(price)
            }
        },
    }

}
</script>

<style scoped>
.p-grid > div {
    padding: 0;
}
.p-grid > div:first-child {
    padding-right: 8px;
}
.p-grid > div:last-child {
    padding-left: 8px;
}
.quote-data-table {
    width: 100%;
}
.label-container {
    text-align: left;
}
.value-container {
    text-align: right;
}
.value-container > .label {
    margin-right: 8px;
}
.value-container > .value {
    font-weight: bold;
}

.price-value {
    color: black;
    font-weight: bold;
}
.positive {
    color: #33CC99;
}
.negative {
    color: #E63E3E;
}

@media (max-width: 760px) {
    .p-grid > div {
        width: 50%;
    }
}
</style>