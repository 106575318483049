<template>
    <Button v-bind="$attrs" :class="followButtonClass" @click="toggleFollow($event)" :label="isFollowing ? 'Following' : 'Follow'" />
    <UnfollowingModal :headerText="unfollowHeader" @UnfollowEntity="unfollowEntity($event)" ref='unfollowingModal' v-if='confirmUnfollow'/>
</template>

<script>
import UnfollowingModal from '../modal/UnfollowingModal.vue';

import FeedService from '../../service/FeedService';

import { mapState, mapGetters } from 'vuex';
import EventBus from '../../event-bus.js';


export default {
    name: 'FollowButton',
    emits: ['follow-success', 'unfollow-success'],
    components: {
        UnfollowingModal
    },

    props: {
        followTarget: {
            type: Object,
            required: true
        },

        confirmUnfollow: {
            type: Boolean,
            default: true
        },

        followVal: {
            type: Boolean,
            default: null
        },
    },
    data() {
        return {
            following: this.followVal
        }
    },
    computed: {
        ...mapState(['users']),
        ...mapGetters({user: 'users/getUser'}),

        followButtonClass() {
            let obj = {
                'follow-button': true,
                'following': this.isFollowing,
                'green-button__secondary': !this.isFollowing
            };

            return obj;
        },

        isEquity() {
            return this.followTarget.securityId !== undefined;
        },
        isFund() {
            return this.followTarget.fundId !== undefined;
        },
        isUser() {
            return this.followTarget.analystId !== undefined;
        },
        isTag() {
            return this.followTarget.tagId !== undefined;
        },

        unfollowHeader() {
            let header = 'Unfollow ';
            const target = this.followTarget;

            if (this.isTag) {
                header += target.name;
            } else if (this.isUser) {
                header += target.name;
            } else if (this.isEquity) {
                header += target.symbol;
            } else if (this.isFund) {
                header += target.symbol;
            }

            return header;
        },

        isFollowing() {
            if (this.following !== null) {
                return this.following
            }

            return this.isFollowingFromStore();
        }
    },

    methods: {
        isFollowingFromStore() {
            let followed = this.user?.analyst.followed;
            let isFollowed = null;
            const target = this.followTarget;

            if (this.isTag) {
                isFollowed = followed?.followedTags[target.tagId];
            } else if (this.isUser) {
                isFollowed = followed?.followedAnalysts[target.analystId];
            } else if (this.isEquity) {
                isFollowed = followed?.followedSecurities[target.securityId];
            } else if (this.isFund) {
                isFollowed = followed?.followedFunds[target.fundId];
            }

            return isFollowed !== undefined;
        },

        toggleFollow(event) {
            event.stopPropagation();

            if (this.isFollowing) {
                if (this.confirmUnfollow) {
                    if (!this.isUser || (this.isUser && this.followTarget.name !== 'INVRS_IG')) {
                        this.$refs.unfollowingModal.open();
                    }
                } else {
                    this.unfollow();
                }
            } else {
                this.follow();
            }
        },

        unfollowEntity(unfollowConfirmed) {
            if (unfollowConfirmed) { // User wants to unfollow entity - value emitted from Modal's custom event: unfollowEntity
                this.unfollow();
            } 
        },

        follow() {
            let followed = this.user.analyst.followed;
            const target = this.followTarget;

           if (this.isUser) {
                FeedService.followUser(target.name).then((resp) => {
                    if (resp.data.status == 'success') {
                        this.$toast.add({ severity: 'success', summary: 'You are now following ' + target.name, detail: '', life: 2000, group: 'tc' });
                        // followed.followedAnalysts[target.analystId] = {[target.analystId]: target.analystId};
                        followed.followedAnalysts[target.analystId] = target;

                        this.$store.dispatch('users/setFollowedAnalysts', followed.followedAnalysts);

                        this.$emit('follow-success');
                        this.following = this.following ? true : null;
                        EventBus.emit('track-follow', target);
                    }
                });
           } else if (this.isTag) {
                FeedService.followTag(target).then((resp) => {
                    if( resp.data.status == 'success' ) {
                        this.$toast.add({severity:'success', summary: 'You are now following '+target.name, detail:'', life: 2000, group: 'tc'});
                        followed.followedTags[target.tagId] = target;
                        this.$emit('follow-success');
                        this.following = this.following ? true : null;
                    }
                })
           } else if (this.isEquity) {
                FeedService.followEquity(target.securityId).then(resp => {
                    if (resp.data.status == 'success') {
                        this.$toast.add({ severity: 'success', summary: 'You are now following ' + target.symbol, detail: '', life: 2000, group: 'tc' });
                        followed.followedSecurities[target.securityId] = { securityId: target.securityId };
                        this.$emit('follow-success');
                        this.following = this.following ? true : null;
                    }
                });
           } else if (this.isFund) {
                FeedService.followFund(target.fundId).then((resp) => {
                    if( resp.data.status == 'success' ) {
                        this.$toast.add({severity:'success', summary: 'You are now following '+target.symbol, detail:'', life: 2000, group: 'tc'});
                        followed.followedFunds[target.fundId] = {fundId: target.fundId};
                        this.$emit('follow-success');
                    }
                });
           }
        },

        unfollow() {
            let followed = this.user.analyst.followed;
            const target = this.followTarget;

            if (this.isUser) {
                FeedService.unfollowUser(target.name).then((resp) => {
                    if (resp.data.status == 'success') {
                        this.$toast.add({ severity: 'success', summary: 'You are no longer following ' + target.name, detail: '', life: 2000, group: 'tc' });
                        delete followed.followedAnalysts[target.analystId];
   
                        this.$store.dispatch('users/setFollowedAnalysts', followed.followedAnalysts);

                        this.$emit('unfollow-success');
                        this.following = this.following ? false : null;
                        EventBus.emit('track-unfollow', target);
                    }
                });
            } else if (this.isTag) {
                FeedService.unfollowTag(target).then((resp) => {
                    if( resp.data.status == 'success' ) {
                        this.$toast.add({severity:'success', summary: 'You are no longer following '+target.name, detail:'', life: 2000, group: 'tc'});
                        delete followed.followedTags[target.tagId];
                        this.$emit('unfollow-success');
                        this.following = this.following ? false : null;
                    }

                });
            } else if (this.isEquity) {
                FeedService.unfollowEquity(target.securityId).then(resp => {
                    if (resp.data.status == 'success') {
                        this.$toast.add({ severity: 'success', summary: 'You are no longer following ' + target.symbol, detail: '', life: 2000, group: 'tc' });
                        delete followed.followedSecurities[target.securityId];
                        this.$emit('unfollow-success');
                        this.following = this.following ? false : null;
                    }
                });
            } else if (this.isFund) {
                FeedService.unfollowFund(target.fundId).then((resp) => {
                    if( resp.data.status == 'success' ) {
                        this.$toast.add({severity:'success', summary: 'You are no longer following '+target.symbol, detail:'', life: 2000, group: 'tc'});
                        delete followed.followedFunds[target.fundId];
                        this.$emit('unfollow-success');
                        this.following = this.following ? false : null;
                    }

                });
            }
        },
    }
}
</script>

<style scoped>
.follow-button {
    padding: 8px 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    justify-content: center;
}

.following,
.following:enabled:hover {
    background: #33CC99;
    border-color: #33CC99;
    color: #FFFFFF;
}
.following:focus {
    border-color: #33CC99;
    box-shadow: none;
}
</style>