<template>
	<Dialog ref='confirmModal' v-model:visible="display" :modal="true" :closable="false" class='confirm-modal' :appendTo="appendToString" @show="onShown">
		<template #header>
			<span class="p-dialog-title">{{header}}</span>
			<slot name="custom-header"></slot>
		</template>
		<slot name='content'></slot>
        <div class="conformation-container" v-if="isConfirmTextRequired">
            <span style="min-width: fit-content;">{{ `Type "${confirmText}":` }}</span>
            <InputText style="width: 100%;" type="text" v-model='confirmTextInput' />
        </div>
		<template #footer>
			<Button :label="cancelButtonLabel" class='cancel p-button-raised p-button-rounded' @click='closeAndCallback(false)'/>
			<Button :label="confirmButtonLabel" class="p-button-raised p-button-rounded" :class='confirmButtonGreen ? " accept-green" : " accept"' 
				@click='closeAndCallback(true)' :disabled="disableConfirmButtonMain"/>
		</template>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
    name: 'ConfirmModal',
	emits: ['confirmed', 'cancelled'],
	props: {
		headerText: {
			type: String,
			required: true
		},

		confirmButtonLabel: {
			type: String,
			required: false,
			default: "OK"
		},
		cancelButtonLabel: {
			type:String,
			required: false,
			default: "Cancel"
		},

		appendToString: {
			type: String,
			default: 'body'
		},

		disableConfirmButton: {
			type: Boolean
		},
		
		confirmButtonGreen: {
			type: Boolean
		},

		confirmText: {
			type: String
		}

	},
    data() {
		return {
			
			display: false,
			confirmTextInput: '',
			callbackState: null,

		}
    },

	components: {
		Dialog
	},

	computed: {
		header() {
			return this.headerText;
		},

        isConfirmTextRequired() {
            return this.confirmText;
        },

        disableConfirmButtonMain() {
            return this.disableConfirmButton 
                || (this.isConfirmTextRequired && this.confirmText !== this.confirmTextInput);
        }
	},

	methods: {
		onShown() {
			if (this.appendToString !== 'body') {
				this.$refs.confirmModal.mask.style.position = 'absolute';
			}
		},
		open(callbackState) {
			this.display = true;
			this.confirmTextInput = '';
			this.callbackState = callbackState;
		},
		closeAndCallback(value) {
			if (value) { // If user clicked on 'confirm' button
				this.$emit('confirmed', this.callbackState)
			} else { // If user clicked on 'Cancel' button
				this.$emit('cancelled')
			}
			this.display = false
		}
	}

}
</script>

<style>
.confirm-modal {
	font-family: "Trebuchet MS", "Verdana";
	box-shadow:  0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.confirm-modal .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.confirm-modal .p-dialog-header .p-dialog-title {
	font-weight: bold;
    font-size: 24px;
}

.confirm-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 0;
}

.confirm-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.conformation-container {
    margin: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
}

@media (max-width: 760px) {
    .confirm-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .confirm-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .confirm-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
 .confirm-modal .p-dialog-footer .p-button {
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	font-weight: 600;
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .confirm-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 1rem;
 }

 .confirm-modal .p-dialog-footer .accept {
	border-color: #E63E3E;
 }

 .confirm-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #E63E3E;
	border: 2px solid #E63E3E;
 }

 .confirm-modal .p-dialog-footer .accept-green {
	border-color: #33CC99;
 }

 .confirm-modal .p-dialog-footer .accept-green:hover {
	color: #FFFFFF;
	background: #33CC99;
	border: 2px solid #33CC99;
 }

 .confirm-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
	border: 2px solid #32364E;
 } 
</style>
