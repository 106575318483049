<template>
    <input ref="fileInput" type="file" :accept='this.acceptImageType' style='display: none' @change='onFileSelect' />
</template>

<script>

import EventBus from '../../event-bus';

export default {
    name: 'ImageFileInput',

    emits: ['selectedImage', 'openErrorModal'],

    props: {
        srcImageId: {
            type: String,
            required: true
        },
        maxWidth: {
            type: Number,
            required: false,
            default: 200,
        },
         maxHeight: {
            type: Number,
            required: false,
            default: 200,
        },
        resize:{
            type: Boolean,
            required: false,
            default: true,
        }
    },
    data() {
        return {
            imageId: this.srcImageId,
            acceptImageType: 'image/*',
            errorMessage: null,
            imageFile: null
        }
    },

    created() {
        EventBus.on('open-file-dialog', this.openFileDialog);
    },

    methods: {
        openFileDialog() {
            this.$nextTick(() => {
                if( this.$refs.fileInput ){
                    this.$refs.fileInput.value = ""; // Allows user to import the same photo twice in a row
                    this.$refs.fileInput.click();
                }
            });
           
        },
         getTypeClass(fileType) {
            return fileType.substring(0, fileType.indexOf('/'));
        },
        isImageFile(file) {
            return this.getTypeClass(this.acceptImageType) === this.getTypeClass(file.type);
        },
       
        resizeImage(img) {
            img.addEventListener('load', () => {
                let canvas = document.createElement('canvas'); // Used to draw graphics on a web page

                let ctx = canvas.getContext('2d');

                /* This is to ensure that the selected photo will be centered and clear as much as possible
                when uploaded into the rounded avatar */
                if( this.resize ){
                    if( this.maxWidth > 0 ) {
                        if (img.width > this.maxWidth) {
                            img.width = this.maxWidth;
                        } 
                    }
                    if( this.maxHeight > 0 ){
                        if (img.height > this.maxHeight) {
                            img.height = this.maxHeight;
                        }
                    }
                }

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, img.width, img.height); // Lays out where the new image will be placed on with it's new dimensions
                
                let dataUrl = canvas.toDataURL(this.imageFile.type) // Returns a data URI containing a representation of the image in the format specified by the type (img.type)
                document.getElementById(this.imageId).src = dataUrl; // Loads the new image source url to image element where it will be uploaded within the rounded avatar
                this.$emit('selectedImage', {name: this.imageFile.name, url: dataUrl});
            })
        },
        onFileSelect(event) {

            this.errorMessage = null;
            this.imageFile = event.target.files[0] ? event.target.files[0] : null;

            if (this.imageFile) {
                if (this.isImageFile(this.imageFile)) {
                    let img = document.createElement('img'); // New image for updated sizing of selected image
                    let reader = new FileReader(); // Object that can access the contents of files that user selected

                    reader.addEventListener('load', (event) => {
                        img.src = event.target.result; // Load the selected image source to the new image's source url
                       
                        this.resizeImage(img);
                        
                    })

                    reader.readAsDataURL(this.imageFile) // Reads the contents of the image file, which finishes selecting the image

                } else {
                    this.errorMessage = "We don't support this file type. Please upload an image file.";
                    this.$emit('openErrorModal', this.errorMessage);
                }
            } 
        }
    }
}
</script>