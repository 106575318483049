<template>
    <LoadingSpinner :showLoading="loading" />
    <div class='layout-content' v-show="!loading">
        <div class='tab-div'>
            <ProfileCard :headerText="`#${name}`" :bioText="description" :showAvatar="false" :notOwnProfile="true">
                   <template #actions>
                       <Button :class="{'toggle-notes-only-button': true, 'selected': toggleNotesButtonSelected}" class="p-d-md-flex p-d-none" v-tooltip.bottom="'Notes Only'" icon='pi pi-pencil' @click="toggleNotesOnly"/>
                       <Button :label='followButtonLabel' :class='followButtonClass' @click='toggleFollowing'/>
                    </template>
                    <template #content>
                        <div class='num-stats-row'>
                            <div class='num-notes-posted-block'>
                                <div class='icon-amount'>
                                    <i class='pi pi-pencil'></i>
                                    <span class='amount'>{{numNotes}}</span>
                                </div>
                                <span class='label'>Notes</span>
                            </div>
                        
                            <div class='num-followers-block'>
                                <div class='icon-amount'>
                                    <i class='pi pi-users'></i>
                                    <span class='amount'>{{numFollowers}}</span>
                                </div>
                                <span class='label'>Followers</span>
                            </div>
                        </div>
                    </template>
                </ProfileCard>
            <PillMenuBar :navigationItems="profileMenuItems" headerType="sub" v-if="!isMobile()"></PillMenuBar>
            <SubTabMenu ref="mobileMenu" :tabMenuItems="mobileProfileMenuItems" :minScrollLength="2" v-else />
            <SectorSecurities :selectedTag="selectedTag" v-if="isSectorOrIndustry && viewingSecurities"/>
            <FeedActivityList :loader="loadPosts" ref="activityList" templateForComponent="User Notes" :loadOnMount="false" v-show="!viewingSecurities">
               
               
            </FeedActivityList>
        </div>
    </div>

    
    <UnfollowingModal :headerText="unFollowHeader" @UnfollowEntity="unfollowTag($event)" ref='unfollowTagModal'></UnfollowingModal>
</template>

<script>
import ProfileCard from '../components/profile/ProfileCard.vue';
import UnfollowingModal from '../components/modal/UnfollowingModal';
import FeedActivityList from '../components/feed/FeedActivityList.vue';

import LoadingSpinner from '../components/common/LoadingSpinner';

import FeedService from '../service/FeedService';

import BrowserUtils from '../utilities/BrowserUtils';

import PillMenuBar from '../components/menu/PillMenuBar.vue';
import SubTabMenu from '../components/menu/mobile/SubTabMenu.vue';
import SectorSecurities from '../components/profile/SectorSecurities.vue';
import EventBus from '../event-bus';

export default {
    name: 'Profile',
    data() {
        return {
           selectedTag: null,
            loading: true,
            notesOnly: false,
         
          
            containerWidth: null,

            // For displaying 
            
            viewingOtherUserProfile: true,
            toggleNotesButtonSelected: false,

            

             profileMenuItems: [
                    {label: 'Activity', action: this.viewActivity, selected: true, hidden: false},
                   
                    {label: 'Securities', action: this.viewTagSecurities, selected: false, hidden: true}
            ],
            mobileProfileMenuItems: [
                {
                    label: 'Activity',
                    action: this.viewActivity,
                   
                    visible: true

                },
               
                {
                    label: 'Securities',
                    action: this.viewTagSecurities,
                    
                    visible: true
                }
            ],
            viewSecuritiesSelected: false,
        }
    },
    components: {
     UnfollowingModal, FeedActivityList,  LoadingSpinner, ProfileCard,
     PillMenuBar,SubTabMenu, SectorSecurities,
    },
    watch: {
        $route(newRoute, oldRoute) { //eslint-disable-line
            
            if( newRoute.fullPath.startsWith('/tag/profile/') ) {
                this.loadTagProfile();
            }
        },


        
        notesOnly(newVal, oldVal) {
            if( newVal != oldVal) {
                this.$refs.activityList.refreshFeed();
            }
        }
    },

    created() {
        //this.EventBus.on('profile-updated', this.loadProfile);
        EventBus.on('toggle-notes-only-button', () => {
            this.notesOnly = !this.notesOnly;
      });
        
    },

    mounted() {
       console.log("mounting tag");
        this.loadTagProfile();

      
        
    },
    deactivated() {
       
        this.containerWidth = null;
    },

    
    computed: {
        viewingSecurities() {
            return this.viewSecuritiesSelected
        },
        isSectorOrIndustry() {
            return this.selectedTag && this.selectedTag.sectorId;
        },
        name() {
           if( this.selectedTag != null ) {
               return this.selectedTag.name;
           }
           else {
               return "";
           }
        },

        description() {
            if( this.selectedTag != null ) {
               return this.selectedTag.description;
            }
            else {
               return "";
            }
        },
        numFollowers() {
            if( this.selectedTag != null ) {
               return this.selectedTag.numFollowers;
            }
            else {
               return "";
            }
        },
        numNotes() {
            if( this.selectedTag != null ) {
               return this.selectedTag.numNotes;
            }
            else {
               return "";
            }
        },

        isFollowing() {
            if( this.selectedTag ) {
                return this.$store.state.users.user.analyst.followed.followedTags[this.selectedTag.tagId] !== undefined;
            }
            else {
                return false;
            }
        },
        followButtonLabel() {
            return this.isFollowing ? 'Following' : 'Follow';
        },
        followButtonClass() {
			return {
				'user-action-button': true,
				'secondary-button': true,
                'follow-button': true,
				'following': this.isFollowing
			}
		},

        showFollow() {
            return this.viewingOtherUserProfile;
        },

        unFollowHeader() {
            if( this.selectedTag != null ) {
                return 'Unfollow '+this.selectedTag.name;
            }
            else {
                return "";
            }
        },

        
        
    },
    methods: {
        
       viewTagSecurities() {
            this.profileMenuItems[1].selected = true;
           
            this.profileMenuItems[0].selected = false;
            
            if( this.isMobile() ) {
                this.$refs.mobileMenu.setSelectedItem(1);
            }
            this.viewSecuritiesSelected = true;
       },


        isMobile() {
            return BrowserUtils.isMobile();
        },
        

        toggleNotesOnly() {
			this.toggleNotesButtonSelected = !this.toggleNotesButtonSelected;

			EventBus.emit('toggle-notes-only-button');
		},

        async hasPosts() {
            let results = [];
            if( this.selectedTag ) {
                    
                results = await FeedService.getTagPosts(this.selectedTag, 1, 0, this.notesOnly);
                //console.log("results : "+JSON.stringify(results));
            }
            
            return ( results.length > 0 );
        },

        async loadPosts(currentFeedItems) {
            
            if( !this.loading ) {
                //console.log("loading more profile posts")
                let results = [];
                if( this.selectedTag ) {
                    
                    results = await FeedService.getTagPosts(this.selectedTag, 15, currentFeedItems.length, this.notesOnly);
                }
               
                return results;
                
            }
            else {
                return [];
            }
          
            
        },

       

      
        async loadTagProfile() {
            
            this.loading = true;

            FeedService.getTag(this.$route.params.tagId).then( async resp => {
                if( resp.data.status == 'success'){
                    this.selectedTag = resp.data.tag;
                    if( !this.selectedTag.sectorId) {
                        this.profileMenuItems[1].hidden = true;
                        this.mobileProfileMenuItems[1].hidden = true;
                        this.profileMenuItems[0].selected = true;
                        this.mobileProfileMenuItems[0].selected = true;
                        this.profileMenuItems[1].selected = false;
                        this.mobileProfileMenuItems[1].selected = false;
                    }
                    else {
                        this.profileMenuItems[1].hidden = false;
                        this.mobileProfileMenuItems[1].hidden = false;
                        this.profileMenuItems[0].selected = true;
                        this.mobileProfileMenuItems[0].selected = true;
                        this.profileMenuItems[1].selected = false;
                        this.mobileProfileMenuItems[1].selected = false;
                    }
                    this.loading = false;
                    let posts = await this.hasPosts();
                    if( posts ) {
                        console.log("has posts was true");
                        this.viewActivity();
                    }
                    else {
                        
                        this.viewTagSecurities();
                    }
                    // this.$refs.activityList.refreshFeed();
                   
                }
                else {
                    console.log("error getting tag with id "+this.$route.params.tagId)
                }

                 
            })
            

           
        }, 
        

        

        

        // FOLLOWING USER PROFILE METHODS
        toggleFollowing() {
			if (!this.isFollowing) {
				this.follow();
			}
			else {
                this.$refs.unfollowTagModal.open();
                
			}
		},
		unfollowTag(unfollowConfirmed) {
			if (unfollowConfirmed) { // User wants to unfollow analyst - value emitted from Modal's custom event: unfollowEntity
				this.unfollow();
			} 
		},
		unfollow() {
            FeedService.unfollowTag(this.selectedTag).then((resp) => {
				if( resp.data.status == 'success' ) {
					this.$toast.add({severity:'success', summary: 'You are no longer following '+this.selectedTag.name, detail:'', life: 2000, group: 'tc'});
					delete this.$store.state.users.user.analyst.followed.followedTags[this.selectedTag.tagId];
				}

			});
		},
		follow(){
			FeedService.followTag(this.selectedTag).then((resp) => {
				if( resp.data.status == 'success' ) {
					this.$toast.add({severity:'success', summary: 'You are now following '+this.selectedTag.name, detail:'', life: 2000, group: 'tc'});
					this.$store.state.users.user.analyst.followed.followedTags[this.selectedTag.tagId] = this.selectedTag;
                }
			})
		},

        // SUB PILL MENU BAR METHODS
        viewNotes() {

        },
        viewActivity() {
            this.viewSecuritiesSelected = false;
            this.profileMenuItems[0].selected = true;
            this.mobileProfileMenuItems[0].selected = true;
            this.profileMenuItems[1].selected = false;
            this.mobileProfileMenuItems[1].selected = false;
            this.$refs.activityList.refreshFeed();

            if( this.isMobile() ) {
                this.$refs.mobileMenu.setSelectedItem(0);
            }
        },
        viewFollowedAndFollowers() {

        }
    }
}
</script>

<style scoped>

*:not(.pi), ::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

.layout-content {
    padding-top: 8px;
}

.user-action-button {
    height: 25px;
    padding: 16px;
    border-radius: 10px;
}
.secondary-button {
    color: #32364e;
    border: 1px solid #32364e;
    background: #FFFFFF
}
.secondary-button:hover {
    border-color: #32364e;
    background: #32364e;
    color: #FFFFFF;
}
.secondary-button:focus {
    box-shadow: none;
    border-color: #32364e;
}

.top-row .row .follow-button {
    color: #32364e;
    border: 1px solid #33CC99;
    background: #FFFFFF;
    /* margin-left: auto; */
    margin-right: 10px;
}
.top-row .row .follow-button.following,
.top-row .row .follow-button:hover {
    border-color: #33CC99;
    background: #33CC99;
    color: #FFFFFF;
}
.top-row  .row .follow-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.num-stats-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 0px;
}

.num-stats-row > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 65px;
}
.num-stats-row > div .icon-amount {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.num-stats-row > div .icon-amount .amount {
    font-size: 20px;
    color: black;
}
.num-stats-row > div .label {
    width: 100%;
    font-size: 16px;
    text-align: left;
    color: #a6a6a6;
}
::v-deep(.num-stats-row > div .icon-amount .pi) {
    color: #33CC99;
}
::v-deep(.num-stats-row > div .icon-amount .pi-pencil),
::v-deep(.num-stats-row > div .icon-amount .pi-thumbs-up) {
    font-size: 25px;
}
::v-deep(.num-stats-row > div .icon-amount .pi-users) {
    font-size: 30px;
}

::v-deep(.toggle-notes-only-button) {
	border-radius: 16px;
	padding: 6px 16px;
	border: 1px solid #32364e;
	background: #FFFFFF;
	color: #32364e;
    margin-right: 8px;
}
::v-deep(.toggle-notes-only-button:enabled:hover),
::v-deep(.toggle-notes-only-button.selected) {
	background: #32364e;
	color: #FFFFFF;
	border: 2px solid #32364e;
 }
 ::v-deep(.toggle-notes-only-button:focus) {
	border-color: #32364e;
	box-shadow: none;
 }

@media (max-width: 760px) {
    h1 {
        font-size: 1.25rem !important;
    }

    .num-stats-row {
        padding-top: 10px;
        padding-left: 0;
        padding-right: 0;
    }
    .username {
        color: #BFBFBF;
    }
    .user-action-button {
        height: 25px;
        padding: 8px;
        border-radius: 6px;
        font-size: 0.8rem;
    }
    .icon-amount {
        justify-content: flex-start !important;
    }
    .icon-amount i {
        font-size: 20px !important;
    }
    .icon-amount .amount {
        margin-left: 8px;
        font-size: 18px !important;
    } 
}



</style>