<template>
	<Dialog :header="header" v-model:visible="display" :modal="true" :closable="false" class='unfollowing-modal'>
		<template #footer>
			<Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='closeAndUnfollow(false)'/>
			<Button label="Unfollow" class='accept p-button-raised p-button-rounded' @click='closeAndUnfollow(true)'/>
		</template>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
    name: 'UnfollowingModal',
	props: {
		headerText: {
			type: String,
			required: true
		},
	},
    data() {
		return {
			
			display: false
		}
    },

	components: {
		Dialog
	},

	computed: {
		header() {
			return this.headerText;
		}
		
	},

	methods: {
		open() {
			this.display = true;
		},
		closeAndUnfollow(value) {
			if (value) { // If user clicked on 'Unfollow' button
				this.$emit('unfollowEntity', true)
			} else { // If user clicked on 'Cancel' button
				this.$emit('unfollowEntity', false)
			}
			this.display = false
		}
	}

}
</script>

<style>
.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.unfollowing-modal {
	box-shadow: none;
}

.unfollowing-modal .p-dialog-header {
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
}

.unfollowing-modal .p-dialog-header .p-dialog-title {
	font-family: "Trebuchet MS", "Verdana";
	font-weight: 600;
}

.unfollowing-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 0;
}

.unfollowing-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
</style>
<style scoped>
 .unfollowing-modal .p-dialog-footer .p-button {
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	font-weight: 600;
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .unfollowing-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 1rem;
 }

 .unfollowing-modal .p-dialog-footer .accept {
	border-color: #E63E3E;
 }

 .unfollowing-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #E63E3E;
	border: 2px solid #E63E3E;
 }

 .unfollowing-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
	border: 2px solid #32364E;
 } 
</style>
