import { CANADIAN_CURRENCY_TYPE, US_CURRENCY_TYPE } from "../../common/constants";

export function alreadyAnsweredPoll(activity) {
    let poll = activity.poll;

    if (poll) {
        if (poll.pollType == "YesNo") {
            return activity?.own_reactions?.yesVote || activity?.own_reactions?.noVote;
        }

        for (var i = 0; i < poll.pollOptions.length; i++) {
            if (activity.own_reactions[poll.pollOptions[i].code]?.length > 0) {
                return true;
            }
        }
    }

    return false;
}

export const correctAnswerYesNoOptionIds = {
    notApplicable: "notApplicable",
    yes: "yes",
    no: "no"
};

export const currencyOptions = [
    { label: CANADIAN_CURRENCY_TYPE.SYMBOL, value: CANADIAN_CURRENCY_TYPE.ID },
    { label: US_CURRENCY_TYPE.SYMBOL, value: US_CURRENCY_TYPE.ID }
];

export const correctAnswerYesNoOptions = [
    { label: "Not applicable", id: correctAnswerYesNoOptionIds.notApplicable },
    { label: "Yes", id: correctAnswerYesNoOptionIds.yes },
    { label: "No", id: correctAnswerYesNoOptionIds.no }
];

export const difficultyLevelOptionIds = {

    easy: 1,
    medium: 2,
    hard: 3
};

export const difficultyLevelOptions = [
    { label: "Easy", id: difficultyLevelOptionIds.easy },
    { label: "Medium", id: difficultyLevelOptionIds.medium },
    { label: "Hard", id: difficultyLevelOptionIds.hard }
];

export const pollCategoryOptionIds = {
    history: 1,
    ratios: 2,
    concepts: 3,
    analysis: 4,
    trading: 5,
    calculations: 6,
    fraud: 7,
};

export const pollCategoryOptions = [
    { label: "History", id: pollCategoryOptionIds.history },
    { label: "Ratios", id: pollCategoryOptionIds.ratios },
    { label: "Concepts", id: pollCategoryOptionIds.concepts },
    { label: "Analysis", id: pollCategoryOptionIds.analysis },
    { label: "Trading", id: pollCategoryOptionIds.trading },
    { label: "Calculations", id: pollCategoryOptionIds.calculations },
    { label: "Fraud", id: pollCategoryOptionIds.fraud }
];

export const pollChoicesCodes = {
    choiceA: "A",
    choiceB: "B",
    choiceC: "C",
    choiceD: "D",
    choiceE: "E"
};