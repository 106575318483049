<template>
    <Dialog ref='imageDialog' :class="{'tall-image': hasTallImage, 'image-pop-up-dialog': true}" v-model:visible="display" :modal="true" :draggable="false" :dismissableMask='true' :appendTo='appendToString' @hide='close' @show='onShown'>
        <div class='image-container'>
            <img :src='imageUrl' />
        </div>
        <template #footer>

        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
    name: 'ImageDialog',
    props: {
        appendToString: {
            type: String,
            default: 'body'
        }
    },
    data() {
        return {
            imageUrl: '',
            display: false,
            hasTallImage: false
        }
    },

    components: {
        Dialog
    },

    methods: {
        onShown() {
            if (this.appendToString !== 'body') {
                this.$refs.imageDialog.mask.style.position = 'absolute';
            }
        },
        open(url, isImageTaller) {
            this.display = true;
            this.imageUrl = url;
            this.hasTallImage = isImageTaller;
        },
        close() {
            this.display = false;
            this.imageUrl = ''
        },
    }
}
</script>

<style>
.image-pop-up-dialog {
	box-shadow: none;
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    max-height: unset;
}

.image-pop-up-dialog .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}
.image-pop-up-dialog .p-dialog-header-icons {
    margin-left: auto;
}

.image-pop-up-dialog .p-dialog-content {
    overflow-y: hidden;
    padding: 0px 16px;
}

.image-pop-up-dialog .p-dialog-footer {
    border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
    padding: 16px;
}

</style>

<style scoped>
.image-pop-up-dialog .image-container {
    max-width: calc(100vw - 100px);
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}
.image-pop-up-dialog.tall-image .image-container {
    max-width: 700px;
}

.image-pop-up-dialog .image-container::-webkit-scrollbar {
	/* width */
	width: 10px;
    
}
.image-pop-up-dialog .image-container::-webkit-scrollbar-track {
	margin-right: 10px;
}
.image-pop-up-dialog .image-container::-webkit-scrollbar-thumb {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}

img {
    max-width: 100%;
    max-height: 100%;
}
</style>