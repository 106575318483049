import APIService from '@/common/APIService';
import store from '../store/index';
import format from 'date-fns/format';
import add from 'date-fns/add';

class PortfolioServiceImpl {

    getConnectionParams( provider = 'snaptrade') {
        return APIService.get(`/portfolios/connection/params?provider=${provider}`);
    }

    getReconnectionParams( institutionId, provider = 'snaptrade') {
        return APIService.get(`/portfolios/reconnection/params?provider=${provider}&institutionId=${institutionId}`);
    }
  

    getListOfAccounts(accountTypes) {
        return APIService.get('/portfolios/accounts?limit=20&offset=0&accountTypes=' + (accountTypes ? accountTypes : ''));
    }

    getInvestmentAccount(investmentAccountId) {
        return APIService.get(`/portfolios/account/${investmentAccountId}`);
    }

    getGroupPortfolioInvestmentAccount(groupPortfolioId, investmentAccountId) {
        return APIService.get(`portfolios/group/account/${investmentAccountId}?groupPortfolioId=`+groupPortfolioId);
    }

    getCashAccounts() {
        return APIService.get('/portfolios/cash');
    }

    getPortfolioAllocationData(accountId) {
        return APIService.get(`/portfolios/account/${accountId}/breakdown`);
    }

    getPortfolioHoldingsData(accountId, aggregateCurrencyId) {
        return APIService.get(`/portfolios/account/${accountId}/holdings?aggregateCurrencyId=`+aggregateCurrencyId);
    }

    getPortfolioPerformance(accountId) {
        // hack: to change
        //return APIService.get(`/portfolios/account/${accountId}/returns`);
        let endDate = new Date();
        
        let startDate = add(endDate,{years:-1});
        return APIService.get(`/portfolios/account/${accountId}/returns?startDate=`+format(startDate, 'yyyy-MM-dd')+"&endDate="+format(endDate, 'yyyy-MM-dd'));
    }

    updatePortfolios(provider = 'snaptrade') {
        return APIService.post(`/portfolios/accounts/update?provider=${provider}`, {});
    }

    getImportJobs() {
        return APIService.get(`/portfolios/import/jobs?limit=5&offset=0`);
    }

    cancelImportJob(importId) {
        return APIService.post(`portfolios/import/jobs/${importId}/cancel`);
    }

    portfoliosSettingsEnabled() {
        return store.state.featureSettings?.portfoliosEnabled;
    }

    portfoliosFeatureEnabled() {
        return store.state.users?.user?.features.includes("Portfolios");
    }

    cashflowSettingsEnabled() {
        return store.getters['featureSettings'].cashflowEnabled;
    }

    cashflowFeatureEnabled() {
        return store.state.users?.user?.features.includes("Cashflow");
    }

    getTransactions(accountId, limit, offset) {
        return APIService.get(`portfolios/account/${accountId}/txns?limit=${limit}&offset=${offset}`);
    }

    deletePortfolio(accountId) {
        return APIService.delete(`portfolios/accounts/${accountId}`);
    }

    deleteAccount(provider = 'snaptrade') {
        return APIService.delete(`portfolios/${provider}/account`);
    }

    deletePaperAccount(investmentAccountId) {
        return APIService.delete('portfolios/paper/account/'+investmentAccountId);
    }

    transferFunds(transfer) {
       
        return APIService.post('portfolios/account/transfer', transfer);
    }

    recordGroupAccountTransfer(transfer) {
       
        return APIService.post('portfolios/group/account/transfer', transfer);
    }

    deleteBrokerageConnection(institutionId, provider = 'snaptrade') {
        return APIService.delete(`portfolios/${provider}/brokerage/${institutionId}`);
    }

    retryImport(institutionId, extAccountId) {
        return APIService.post(`portfolios/import`, {
          provider: "snaptrade",
          institutionId: institutionId,
          extAccountId: extAccountId
        });
    }

    hideAccount(accountId) {
        return APIService.put(`portfolios/accounts/hide/${accountId}`, {});
    }

    showAccount(accountId) {
        return APIService.put(`portfolios/accounts/show/${accountId}`, {});
    }
    
    // SHARING ACCOUNT METHODS
    shareAccount(accountId, sharingLevel) {
        return APIService.put(`portfolios/accounts/sharing/${accountId}/${sharingLevel}`, {})
    }

    getSharedAccounts(analystId) {
        return APIService.get(`portfolios/shared/accounts/${analystId}?limit=10&offset=0&query=&orderBy=`);
    }

    getSharedAccountHoldings(accountId) {
        return APIService.get(`portfolios/shared/account/${accountId}/holdings`);
    }

    getSharedAccountBreakdown(accountId) {
        return APIService.get(`portfolios/shared/account/${accountId}/breakdown`);
    }

    getSharedAccountPerformanceData(accountId) {
        return APIService.get(`portfolios/shared/account/${accountId}/returns`);
    }

    // Cash flow stuff

    getCashFlowData(accountId) {
        return APIService.get(`portfolios/account/${accountId}/dividend/forecast?numYears=1`)
    }

    getTopPerformers() {
        return APIService.get(`portfolios/community/performers`);
    }

    // Group stuff

    getGroupPortfolioBreakdown(portfolioId) {
        return APIService.get('portfolios/group/breakdown?groupPortfolioId='+portfolioId);
    }

    getGroupPortfolioHoldings(portfolioId, aggregateCurrencyId) {
        return APIService.get('portfolios/group/holdings?groupPortfolioId='+portfolioId+'&aggregateCurrencyId='+aggregateCurrencyId);
    }

    getGroupPortfolioReturns(portfolioId) {
        return APIService.get('portfolios/group/returns?groupPortfolioId='+portfolioId);
    }

    getGroupPortfolioDividendForecast(portfolioId) {
        return APIService.get('portfolios/group/dividend/forecast?groupPortfolioId='+portfolioId);
    }

    getGroupPortfolioMemberBreakdown(portfolioId, investmentAccountId) {
        return APIService.get('portfolios/group/member/breakdown?groupPortfolioId='+portfolioId+'&investmentAccountId='+investmentAccountId);
    }

    getPublicGroupPortfolioMemberBreakdown(portfolioId, investmentAccountId) {
        return APIService.get('portfolios/public/group/member/breakdown?groupPortfolioId='+portfolioId+'&investmentAccountId='+investmentAccountId);
    }

    getGroupPortfolioMemberHoldings(portfolioId, investmentAccountId) {
        return APIService.get('portfolios/group/member/holdings?groupPortfolioId='+portfolioId+'&investmentAccountId='+investmentAccountId);
    }

    getPublicGroupPortfolioMemberHoldings(portfolioId, investmentAccountId) {
        return APIService.get('portfolios/public/group/member/holdings?groupPortfolioId='+portfolioId+'&investmentAccountId='+investmentAccountId);
    }

    getGroupPortfolioMemberReturns(portfolioId, investmentAccountId) {
        return APIService.get('portfolios/group/member/returns?groupPortfolioId='+portfolioId+'&investmentAccountId='+investmentAccountId);
    }

    // Plaid stuff

    // createLinkToken() {
    //   return APIService.get('/portfolios/link/token');
    // }

    // exchangePublicToken(publicToken) {
    //   return APIService.post(`/portfolios/import/${publicToken}`, {
    //     publicToken
    //   });
    // }

    /* PAPER PORTFOLIO METHODS */

    createPaperAnalysisAccount(name, currencyId) {
        return APIService.post(`portfolios/create/paper/analysis`, {
          name: name,
          currencyId: currencyId
        });
    }

    createPaperTradingAccount(name, currencyId) {
        return APIService.post(`portfolios/create/paper/trading`, {
          name: name,
          currencyId: currencyId
        });
    }

    getPosition(investmentAccountId, assetTypeId, assetId) {
        return APIService.get(`portfolios/account/${investmentAccountId}/position?assetTypeId=${assetTypeId}&assetId=${assetId}`);
    }

    recordCashDeposit(userId, currencyId, amount) {
        return APIService.post('portfolios/account/deposit/cash', {
            userId: userId,
            currencyTypeId: currencyId,
            amount: amount
        });
    }


    recordDeposit(investmentAccountId, amount, transactionSubTypeId) {
        return APIService.post('portfolios/account/deposit', {
            investmentAccountId: investmentAccountId,
            amount: amount,
            accountTransactionSubTypeId: transactionSubTypeId
        });
    }

    recordWithdrawal(investmentAccountId, amount, transactionSubTypeId) {
        return APIService.post('portfolios/account/withdrawal', {
            investmentAccountId: investmentAccountId,
            amount: amount,
            accountTransactionSubTypeId: transactionSubTypeId
        });
    }

    addPosition(investmentAccountId, avgCost, assetId, assetTypeId, quantity) {
        return APIService.post('portfolios/account/position', {
            investmentAccountId: investmentAccountId,
            avgCostPerUnit: avgCost,
            assetId: assetId,
            assetTypeId: assetTypeId,
            quantity: quantity
        });
    }

    updatePosition(investmentAccountId, price, assetId, assetTypeId, quantity, tradeTypeId) {
        return APIService.put('portfolios/account/position', {
            investmentAccountId: investmentAccountId,
            price: price,
            assetId: assetId,
            assetTypeId: assetTypeId,
            quantity: quantity,
            tradeTypeId: tradeTypeId
        });
    }

    recordCashPosition(investmentAccountId, amount) {
        return APIService.post('portfolios/account/position/cash', {investmentAccountId: investmentAccountId, amount: amount});
    }

    getLatestForexRates() {
        return APIService.get('portfolios/forex/latest');
    }

    getCompetitionPortfolioHoldings(contestId, userId) {
        return APIService.get(`/portfolios/competition/${contestId}/${userId}/holdings`);
    }

    getCompetitionPortfolioBreakdown(contestId, userId) {
        return APIService.get(`/portfolios/competition/${contestId}/${userId}/breakdown`);
    }
    
}

const PortfolioService = new PortfolioServiceImpl();

export default PortfolioService;