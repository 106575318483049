<template>
    <div class="impression-list" style="max-width:80rem;">
    <template v-if="templateForComponent === 'Popular Notes'">
        <LoadingSpinner :loading='loading' v-if='loading'/>
        <ActivityCard v-for="activity in feed" :key="activity.id" :ref="activity.id" :activity="activity" :selectedGroupFeed="selectedGroupFeed"
            @on-view-details="onViewDetails" @on-edit-details="onEditDetails" @post-liked="onPostLiked" :component='templateForComponent' 
            @post-deleted="onPostDeleted" @post-reacted="onPostReacted" @post-unreacted="onPostUnreacted"></ActivityCard>
    </template>
    <template v-else-if="templateForComponent === 'Security Mentioned Notes' || templateForComponent === 'User Notes'">
        <infinite-scroll @infinite-scroll="loadMore" :message="message" :noResult="noResult" :style="scrollStyle" :autoNavigate="true">
            <slot></slot>
            <LoadingSpinner :loading='loading' v-if='loading'/>
            <div class='p-grid' v-if='feed.length > 0'>
                <div class="left-col p-col-12 p-md-6">
                    <ActivityCard v-for="activity in feed.filter((f,i) => numberUtils.isEven(i) && f.verb !== 'follow')" :key="activity.id" :ref="activity.id" :activity="activity" 
                        :selectedGroupFeed="selectedGroupFeed" @on-view-details="onViewDetails" @on-edit-details="onEditDetails" 
                        @post-liked="onPostLiked" @post-deleted="onPostDeleted" @post-reacted="onPostReacted" @post-unreacted="onPostUnreacted"/>
                </div>
                <div class='right-col p-col-12 p-md-6'>
                    <ActivityCard v-for="activity in feed.filter((f,i) => numberUtils.isOdd(i) && f.verb !== 'follow')" :key="activity.id" :ref="activity.id" :activity="activity" 
                        :selectedGroupFeed="selectedGroupFeed" @on-view-details="onViewDetails" @on-edit-details="onEditDetails"
                        @post-liked="onPostLiked" @post-deleted="onPostDeleted"
                        @post-reacted="onPostReacted" @post-unreacted="onPostUnreacted"/>
                </div>
            </div>
        </infinite-scroll>
    </template>
    <template v-else>
        <infinite-scroll @infinite-scroll="loadMore" :message="message" :noResult="noResult" :style="scrollStyle" :autoNavigate="true">
            <slot></slot>
            <LoadingSpinner :loading='loading' v-if='loading'/>
            <div class='activity-cards-container'>
                <ActivityCard v-for="activity in feed.filter(f => f.verb !== 'follow')" :key="activity.id" :ref="activity.id" :activity="activity" 
                    :selectedGroupFeed="selectedGroupFeed" :selectedFeedType="selectedFeedType" @on-view-details="onViewDetails" @on-edit-details="onEditDetails" 
                    @post-liked="onPostLiked" @post-unliked="onPostUnliked" @post-deleted="onPostDeleted"
                    @post-reacted="onPostReacted" @post-unreacted="onPostUnreacted" @poll-closed="onPollClosed" @poll-opened="onPollOpened" :context="context"></ActivityCard>
            </div>
        </infinite-scroll>
    </template>
    <PostDetailsDialog ref="detailsDialog" :post="selectedPost" :startInEditMode="startInEditMode" :viewingFrom="templateForComponent" @comment-posted="onCommentPosted" 
    @post-read="onPostRead" @post-liked="onPostLiked" @post-unliked="onPostUnliked" @post-deleted="onPostDeleted"
    @post-reacted="onPostReacted" @post-unreacted="onPostUnreacted" :allowComments="allowComments"></PostDetailsDialog>
    </div>
</template>

<script>
import ActivityCard from './ActivityCard';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import LoadingSpinner from '../common/LoadingSpinner';
import PostDetailsDialog from './PostDetailsDialog.vue';

import FeedService from '../../service/FeedService';
import ResearchService from '../../service/ResearchService';

import NumberUtils from '../../utilities/NumberUtils';

import EventBus from '../../event-bus';
import {mapState} from 'vuex';
import SessionStorageUtils from '../../utilities/SessionStorageUtils';
import CookieUtils from '../../utilities/CookieUtils';
import { COOKIE_SHARED_FEED_POST_ID } from '../../common/constants';

export default {
    name: 'FeedActivityList',
    components: {
    ActivityCard,
    InfiniteScroll,
    LoadingSpinner,
    PostDetailsDialog,
},
    props: {
        selectedEquity: {
            type: Object,
            required: false,
        },
        selectedFund: {
            type: Object,
            required: false,
        },
        notesOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        loader: {
            type: Function,
            required: false
        },
        templateForComponent: {
            type: String,
        },
        loadOnMount: {
            type: Boolean,
            required: false,
            default: true,
        },
        scrollStyle: {
            type: String,
            default: 'width: 100%; height: 100vh;'
        },
        selectedGroupFeed: {
            type: Object,
            required: false
        },
        allowComments: {
            type: Boolean,
            required: false,
            default: true
        },
        selectedContestData: {
            type: Object,
            required: false
        },
        context:{
            type:String,
            required: false
        }
    },
    data() {
        return {
            loading: false,
            message: '',
            noResult: false,
            displayPost: false,
            postContent: '',
            selectedPost: { activity: null, noteVersion: null },
            postMenuItems: [],
            comments: [],
            feed: [],

            numberUtils: null,
            startInEditMode: false,
            
            justMounted: false,

        };
    },
    computed: {
        ...mapState(['selectedFeedType','registered']),
    },
    methods: {

        onPostDeleted(post) {//eslint-disable-line
            
            this.refreshFeed("FeedActivityList.onPostDeleted");
        },

        onPollOpened(activity) {
            //console.log("poll opened current state is "+JSON.stringify(activity));
             activity.poll['closedDate'] = null;
            //console.log("poll opened after state is "+JSON.stringify(activity));
        },

        onPollClosed(activity) {
            //console.log("poll closed current state is "+JSON.stringify(activity));
           
            activity.poll['closedDate'] = (new Date()).getTime();
             //console.log("poll closed after state is "+JSON.stringify(activity));
        },

        onPostReacted(activity, reaction) {
            //console.log("on post liked called");
            if (activity.reaction_counts) {
                if (activity.reaction_counts[reaction.kind]) {
                    activity.reaction_counts[reaction.kind] = activity.reaction_counts[reaction.kind] + 1;
                } else {
                    activity.reaction_counts[reaction.kind] = 1;
                }
            } else {
                activity['reaction_counts'] = { [reaction.kind]: 1 };
            }

            if( activity.own_reactions ) {
                activity.own_reactions[reaction.kind] = [reaction];
            }
            else {
                activity['own_reactions'] = {
                    [reaction.kind]: [reaction], // just a place holder until we reload the activity
                };
            }
        },

        onPostUnreacted(activity, reaction) {
            //console.log("on post unliked called");
            if (activity.reaction_counts) {
                if (activity.reaction_counts[reaction]  && activity.reaction_counts[reaction] > 0) {
                    activity.reaction_counts[reaction] = activity.reaction_counts[reaction] - 1;
                } else {
                    activity.reaction_counts[reaction] = 0;
                }
            } else {
                activity['reaction_counts'] = { reaction: 0 };
            }

            delete activity.own_reactions[reaction];
        },

        onPostLiked(event) {
            let activity = event.activity;
            //console.log("on post liked called");
            if (activity.reaction_counts) {
                if (activity.reaction_counts.like) {
                    activity.reaction_counts.like = activity.reaction_counts.like + 1;
                } else {
                    activity.reaction_counts['like'] = 1;
                }
            } else {
                activity['reaction_counts'] = { like: 1 };
            }

            //Need to add to own reactions so that other checks see the post has already been liked
            // this is just a placeholder so the own_reactions.like array is not empty
            // activity.own_reactions = {
            //     like: [{ user_id: this.$store.state.users.user.analyst.analystId }], // just a place holder until we reload the activity
            // };
            if( activity.own_reactions ) {
                activity.own_reactions['like'] = [event.reaction];
            }
            else {
                activity['own_reactions'] = {
                    like: [event.reaction], // just a place holder until we reload the activity
                };
            }
        },

        onPostRead(event) {
            let activity = event.activity;

            if( activity.own_reactions ) {
                activity.own_reactions['read'] = [event.reaction];
            }
            else {
                activity['own_reactions'] = {
                    read: [event.reaction], // just a place holder until we reload the activity
                };
            }
        },

        onPostUnliked(activity) {
            //console.log("on post unliked called");
            if (activity.reaction_counts) {
                if (activity.reaction_counts.like  && activity.reaction_counts.like > 0) {
                    activity.reaction_counts.like = activity.reaction_counts.like - 1;
                } else {
                    activity.reaction_counts['like'] = 0;
                }
            } else {
                activity['reaction_counts'] = { like: 0 };
            }

            //Need to add to own reactions so that other checks see the post has already been liked
            // this is just a placeholder so the own_reactions.like array is not empty
            // activity.own_reactions = {
            //     like: [{ user_id: this.$store.state.users.user.analyst.analystId }], // just a place holder until we reload the activity
            // };

            activity.own_reactions.like = [];
        },

        async onCommentPosted(post) {
            let activity = post.activity === undefined ? post : post.activity;
            await this.refreshPostCommentsCount(activity);
        },

        async onCommentDeleted() {
            await this.refreshPostCommentsCount(this.selectedPost.activity);
        },

        async onNestedCommentPosted() {
            if (this.selectedPost )  {
                await this.refreshPostCommentsCount(this.selectedPost.activity);
            }
        },

        // eslint-disable-next-line no-unused-vars
        // async onCommentPosted(evt) {
        //     await this.refreshPostCommentsCount(evt.activity);
        // },

        async refreshPostCommentsCount( activity ) {
            if(activity){
                FeedService.getSingleActivity(activity.id).then((resp) => {
                    if(resp[0]!=undefined){
                        activity.num_comments = resp[0].num_comments;
                        activity.reaction_counts.comment = resp[0].num_comments;
                        activity.latest_reactions.comment = resp[0].latest_reactions.comment;
                    }
                });
            }
        }, 

        onEditDetails( activity ) {
            this.startInEditMode = true;
            this.displayPostDetails(activity);
        }, 

        onViewDetails(activity) {
            this.startInEditMode = false;
            
           this.displayPostDetails(activity);
        },

        displayPostDetails(activity) {
            if(this.$refs?.detailsDialog && !this.$refs.detailsDialog.displayPost) {
                // Mention type notifications have the actual activity data (summary for notes or content for normal posts) in it's 'object' field
                const activityData = (activity.summary || activity.content || activity.title) ? activity : activity.object;

                if (this.isNote(activityData)) {
                    ResearchService.getNoteVersion(activityData.note_version_uuid).then((resp) => {
                        this.selectedPost = { activity: activityData, noteVersion: resp.data.noteVersion };

                        this.$refs.detailsDialog.displayPost = true;
                    });
                } else {
                    this.selectedPost = { activity: activityData, noteVersion: null };
                    this.$refs.detailsDialog.displayPost = true;
                }
               // console.log("checking following activity", activity);
                if( activity?.groupFeed?.courseId) {
                    // module lesson so store it for later
                    this.$store.commit("SET_LAST_VIEWED_MODULE_LESSON", {groupFeedId:activity.groupFeed.groupFeedId, activity: activity});
                }
            }
        },

        onPostUpdated(postUpdate) {
            try  {
                let d = new Date(postUpdate.updatedActivity.extra.revision_date);
                this.selectedPost.activity['revision_date'] = d.getTime();
                

            
                this.selectedPost.activity.thumbnails = postUpdate.updatedActivity.extra.thumbnails;
                this.selectedPost.activity.mentioned_exchanges = postUpdate.updatedActivity.extra.mentioned_exchanges;
                this.selectedPost.activity.mentioned_funds = postUpdate.updatedActivity.extra.mentioned_funds;
                this.selectedPost.activity.mentioned_indices = postUpdate.updatedActivity.extra.mentioned_indices;
                this.selectedPost.activity.mentioned_industries = postUpdate.updatedActivity.extra.mentioned_industries;
                this.selectedPost.activity.mentioned_people = postUpdate.updatedActivity.extra.mentioned_people;
                this.selectedPost.activity.mentioned_sectors = postUpdate.updatedActivity.extra.mentioned_sectors;
                this.selectedPost.activity.mentioned_securities = postUpdate.updatedActivity.extra.mentioned_securities;
                this.selectedPost.activity.mentioned_tags = postUpdate.updatedActivity.extra.mentioned_tags;

                if(this.isNote(this.selectedPost.activity)){
                    
                    this.selectedPost.noteVersion.content = postUpdate.updatedContent;
                    this.selectedPost.activity.title = postUpdate.updatedActivity.extra.title;
                    this.selectedPost.activity.summary = postUpdate.updatedActivity.extra.summary;
                    this.selectedPost.noteVersion.title = postUpdate.updatedActivity.extra.title;
                    this.selectedPost.noteVersion.summary = postUpdate.updatedActivity.extra.summary;
                
                } else if (this.selectedPost.activity.type === 'question') {
                    this.selectedPost.activity.content = postUpdate.updatedContent;
                    this.selectedPost.activity.title = postUpdate.updatedActivity.extra.title;

                    if (postUpdate.updatedActivity.extra.poll) {
                        this.selectedPost.activity.poll = postUpdate.updatedActivity.extra.poll;
                    }
                } else {
                    this.selectedPost.activity.content = postUpdate.updatedContent;
                    
                }
            }catch( error) {
                console.log("unexpected error handling post update: " + error);
            }
           
        },

        isNote(activity) {
            return activity?.has_note == 1;
        },

        async loadMore() {
            
            if (!this.loading) {
                this.loading = true;
                if( this.loader ){
                    let olderActivities = await this.loader(this.feed)
                   
                    if (olderActivities.length <= 0) {
                        this.noResult = true;
                    } else {
                        this.feed = this.feed.concat(olderActivities);
                        //console.log("checking feed with loader")
                         this.checkGoToQAFeedParam();
                        this.noResult = false;
                    }
                    this.loading = false;
                    this.$nextTick(()=>{
                        if( this.selectedGroupFeed?.courseId ) {
                            this.scrollToLastViewedLesson(this.selectedGroupFeed);
                        }
                    })
                    
                }
                else {
                  
                    let olderActivities = await this.loadActivities(this.feed.length, this.notesOnly)
                    
                    if (olderActivities.length <= 0) {
                        this.noResult = true;
                    } else {
                        const shouldCheckGoToQAFeedParam = this.feed?.length == 0 && this.selectedFeedType == 'questions';

                        this.feed = this.feed.concat(olderActivities);
                       // console.log("checking feed without loader", shouldCheckGoToQAFeedParam)
                        if(shouldCheckGoToQAFeedParam){
                            this.checkGoToQAFeedParam();
                        }
                        this.noResult = false;
                    }
                    this.loading = false;
                    
                }
                
                
            }
        },

        checkGoToQAFeedParam() {
            const goToQAFeedPostId = SessionStorageUtils.getParsedValue('goToQAFeedPostId');
            //console.log("goToQAFeedPostId ", goToQAFeedPostId)
            if(goToQAFeedPostId){
                sessionStorage.removeItem('goToQAFeed');
                sessionStorage.removeItem('goToQAFeedPostId');
                this.openPostDetailsDialogByPostId(goToQAFeedPostId);
            }
        },

        openPostDetailsDialogByPostId(postId) {
            const activity = this.findActivityThroughFeedByPostId(postId);

            if(activity) {
                this.displayPostDetails(activity);
            } else {
                FeedService.getInAppSharedPost(postId).then((activity) => {
                    if(activity){   
                        this.displayPostDetails(activity);
                    }
                });
            }
        },

        openFeedPost(activity) {
           
           
            try{
                
                this.$refs[activity.id][0].$el.nextElementSibling.scrollIntoView({ behavior: "smooth" });
            } catch(e) {
                console.error("unable to find post", e);
            }
           

            this.displayPostDetails(activity);
        },

        scrollToLastViewedLesson(feed) {
            let activity = this.$store.state.lastViewedModuleLessons[feed.groupFeedId];
            if( activity ) {
                try{
                
                    this.$refs[activity.id][0].$el.nextElementSibling.scrollIntoView({ behavior: "smooth" });
                } catch(e) {
                    console.log("unable to scroll to post", e);
                }
            }
        },

        

        findActivityThroughFeedByPostId(postId) {
            return this.feed.find(a => a.foreign_id == postId);
        },

        loadActivities(offset, notesOnly) {
            if (this.selectedEquity) {
                return FeedService.getEquityActivities(offset, notesOnly);
            } else if (this.selectedFund) {
                return FeedService.getFundActivities(offset, notesOnly);
            } else if(this.selectedContestData){
                return FeedService.getGroupContestPosts(offset, this.selectedContestData.groupId, this.selectedContestData.contestId, this.selectedContestData.cycleStartDate);
            } else if (this.selectedFeedType == 'latest'){
                return FeedService.getLatestPosts(offset, notesOnly);
            } else if (this.selectedFeedType == 'questions'){
                return FeedService.getQuestions( offset, notesOnly);
            }else if(this.selectedFeedType == 'featuredFeed'){
                return FeedService.getFeaturedFeedActivities(offset);
            }else if(this.selectedFeedType == 'motions'){
                return FeedService.getMotions(offset); 
            } else {
              return FeedService.getActivities(offset, notesOnly);
            }
        },

        // eslint-disable-next-line no-unused-vars
        refreshFeed(context) {
            // console.log("Called FeedActivityList.refreshFeed() context: ", context);
            this.noResult = false;
            this.feed = [];
            this.loadMore();
            FeedService.startStatusImpressionDetector("FeedActivityList.refreshFeed");
        },

        openSharedPostDetailsDialog() {
            const feedPostId = CookieUtils.getCookie(COOKIE_SHARED_FEED_POST_ID);
            //console.log("open-shared-post-details-dialog", feedPostId);
            if(feedPostId){
                this.openPostDetailsDialogByPostId(feedPostId);
                CookieUtils.deleteCookie(COOKIE_SHARED_FEED_POST_ID);
            }
        },

        // eslint-disable-next-line no-unused-vars
        subscribeToEventBus(context){
            // console.log("subscribeToEventBus: ", context);
            EventBus.off('latest-feed');
            EventBus.on('latest-feed', () => {
                FeedService.getLatestPosts(this.feed.length, this.notesOnly);
            });
            EventBus.off('followed-changed');
            EventBus.on('followed-changed', () => {
                this.refreshFeed("FeedActivityList.followed-changed");
            });
            EventBus.off('refresh-feed', this.onRefreshFeedEvent);
            EventBus.on('refresh-feed',this.onRefreshFeedEvent);
            EventBus.off('reposted');
            EventBus.on('reposted', () => {
                this.refreshFeed("FeedActivityList.reposted");
            });
            EventBus.off('trigger-dialog-for-single-activity');
            EventBus.on('trigger-dialog-for-single-activity', (activity) => {
                this.onViewDetails(activity)
            })
            EventBus.off('post-updated');
            EventBus.on('post-updated', (postUpdate)=> {
                this.onPostUpdated(postUpdate);
            })
            EventBus.off('post-promoted');
            EventBus.on('post-promoted', () => {
                this.refreshFeed("FeedActivityList.post-promoted");
            });
            EventBus.off('post-demoted');
            EventBus.on('post-demoted', () => {
                this.refreshFeed("FeedActivityList.post-demoted");
            });
            EventBus.off('nested-comment-posted');
            EventBus.on('nested-comment-posted', () => {
                this.onNestedCommentPosted();
            });
            EventBus.off('comment-deleted');
            EventBus.on('comment-deleted', () => {
                this.onCommentDeleted();
            });
            EventBus.off('open-feed-post');
            EventBus.on('open-feed-post', this.openFeedPost);

            EventBus.off('scroll-to-last-viewed-lesson');
            EventBus.on('scroll-to-last-viewed-lesson', this.scrollToLastViewedLesson);
        },

        onRefreshFeedEvent() {
            this.refreshFeed("FeedActivityList.refresh-feed");
        }
    },

    watch: {
        registered(val, oldVal) {
            if(!oldVal && val){
                this.openSharedPostDetailsDialog();
            }
        },
        notesOnly() {
            
            this.refreshFeed();
        },
        selectedFeedType(val, oldVal){
            if(val != oldVal){
                this.refreshFeed("FeedActivityList.selectedFeedType");
            }
        },
        selectedContestData() {
            this.refreshFeed("selectedContestData");
        }
    },

    mounted() {
        if( this.loadOnMount ){
            this.refreshFeed("FeedActivityList.mounted");
        }
        
        this.justMounted = true;

        this.subscribeToEventBus("mounted");

        if(this.registered){
            this.openSharedPostDetailsDialog();
        }
    },

    activated() {
       // console.log("activating feed activity list")
        if( !this.justMounted) {
            FeedService.startStatusImpressionDetector("FeedActivityList.activated");

            this.subscribeToEventBus("activated");
        }

        this.justMounted = false;
    },

    deactivated() {
        //console.log("deactivating feed activity list")
        FeedService.stopStatusImpressionDetector("FeedActivityList.deactivated");
        this.justMounted = false;
    },

    unmounted() {
        //console.log("umounting feed activity list")
        FeedService.stopStatusImpressionDetector("FeedActivityList.unmounted");
        this.justMounted = false;
    },

    created() {
        this.numberUtils = NumberUtils;
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
::v-deep(figure > img) {
    max-width: 100%;
    min-width: 100%;
}

::v-deep(a.mention) {
    cursor: pointer;
    color: rgb(51, 204, 153);
}

.more-options {
    float: right;
}

.p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
}

::v-deep(.p-grid .left-col) {
    padding: 24px 24px 0px;
}
::v-deep(.p-grid .right-col) {
    padding: 24px 24px 0px 0px;
}

::v-deep(.scroll-to-top-button) {
    position: fixed;
    right: 42%;
    bottom: 10px;
}

@media (max-width: 760px) {
    ::v-deep(.p-grid) {
        margin: 0;
    }
    ::v-deep(.p-grid .right-col) {
    padding: 0px;
    }

    ::v-deep(.scroll-to-top-button) {
        position: fixed;
        right: 40%;
        bottom: 10%;
    }
}
</style>