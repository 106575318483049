import { AGGREGATE_PORTFOLIO_TYPE, COLLABORATIVE_PORTFOLIO_TYPE } from '../common/constants';
import store from '../store/index';
import APIService from '@/common/APIService'

class GroupServiceImpl {

    createGroup(name, groupUserName, type, isCommunityPrivate, visible, invited, inviteExpiryDate, avatarDataUrl) {
        let createGroupRequest = { //eslint-disable-line
            name: name,
            groupUserName: groupUserName,
            type: type,
            isCommunityPrivate: isCommunityPrivate,
            ownerId : store.state.users.user.userId,
            visible: visible,
            invited: invited,
            inviteExpiryDate: inviteExpiryDate,
            avatarDataUrl:avatarDataUrl
        };

        return APIService.post('/groups/group', createGroupRequest);
    }

    createGroupPortfolio(groupId, name, description, locked, showDollarAmounts, mandatory, defaultCurrencyId, 
        allowPaperTradingAccounts, allowPaperAnalysisAccounts, allowBrokerageAccounts, collaborationSettings){
        let createGroupPortfolioRequest = {
            name: name,
            description : description,

            locked: locked,
            showDollarAmounts: showDollarAmounts,
            mandatory: mandatory,
            defaultCurrencyId: defaultCurrencyId,

            allowPaperTradingAccounts: allowPaperTradingAccounts,
            allowPaperAnalysisAccounts: allowPaperAnalysisAccounts,
            allowBrokerageAccounts: allowBrokerageAccounts,
            
            groupPortfolioTypeId: collaborationSettings ? COLLABORATIVE_PORTFOLIO_TYPE.ID : AGGREGATE_PORTFOLIO_TYPE.ID,

            collaborationSettings: collaborationSettings ? {
                quorum: collaborationSettings.quorum,
                percentageToPass: collaborationSettings.percentageToPass,
                tradingStartTime: collaborationSettings.tradingStartTime,
                tradingStopTime: collaborationSettings.tradingStopTime,
                defaultDeadline: collaborationSettings.defaultDeadline,
                automaticExec: collaborationSettings.automaticExec,
                groupFeedId: collaborationSettings.groupFeedId,
                suspendTradingFloor: collaborationSettings.suspendTradingFloor,
                tradeCriteria: collaborationSettings.tradeCriteria,
                motionHints: collaborationSettings.motionHints,
            } : undefined
        };
        return APIService.post('/groups/'+groupId+'/portfolio', createGroupPortfolioRequest );
    }

    createGroupFeed(groupId, name, description, isPrivate, allowMemberPosts, allowMemberComments, allowMemberLikes, inviteOnly, showFullContent, courseId){
        let createGroupFeedRequest = {
            name: name,
            description : description,
            isPrivate: isPrivate,
            allowMemberPosts: allowMemberPosts,
            allowMemberComments: allowMemberComments,
            allowMemberLikes: allowMemberLikes,
            inviteOnly: inviteOnly,
            showFullContent: showFullContent,
            recordStats: true,
            courseId: courseId
        };
        return APIService.post('/groups/'+groupId+'/feed', createGroupFeedRequest );
    }

    createGroupCourse(groupId, req){
       
        return APIService.post('/groups/'+groupId+'/course', req );
    }

    deleteGroupFeed(groupId, feedId){
        return APIService.delete('/groups/' + groupId + '/feeds/' + feedId);
    }

    deleteGroupCourse(groupId, courseId){
        return APIService.delete('/groups/' + groupId + '/courses/' + courseId);
    }

    groupsEnabled() {
        return ( store.state.featureSettings && store.state.featureSettings.groupsEnabled )
    }

    getMyGroups(offset, limit, query) {
        return APIService.get('/groups/mygroups?offset='+offset+'&limit='+limit +(query ? "&query="+query : "")); 
    }

    getPopularGroups(offset, limit, query) {
        return APIService.get('/groups/popular?offset='+offset+'&limit='+limit +(query ? "&query="+query : "")); 
    }

    searchGroups(offset, limit, query) {
        return APIService.get('/groups/search?offset='+offset+'&limit='+limit +(query ? "&query="+query : "")); 
    }

    getEducationCourses(limit, offset, query) {
        return APIService.get('/groups/education?offset='+offset+'&limit='+limit +(query ? "&query="+query : "")); 
    }

    getEducationalGroups(limit, offset, query) {
        return APIService.get('/groups/educational?offset='+offset+'&limit='+limit +(query ? "&query="+query : "")); 
    }
    
    generateGroupInviteLink(groupId, expiryDate){
        return APIService.post('/groups/invitelink', {groupId: groupId, expiryDate: expiryDate});
    }

    getGroupCashAccounts(groupId) {
        return APIService.get('/groups/'+groupId+'/cash'); 
    }

    getGroupInviteLinks(groupId, limit, offset, excludeExpired) {
        return APIService.get('/groups/'+groupId+'/invitelinks?limit='+limit+'&offset='+offset+'&excludeExpired='+(excludeExpired? excludeExpired : "false")); 
    }

    getGroupInvitePreview(inviteCode) {
        return APIService.get('/groups/invite/preview?inviteCode='+inviteCode); 
    }

    getSentGroupInvites(groupId, limit, offset, excludeAccepted) {
        return APIService.get('/groups/'+groupId+'/invites/sent?limit='+limit+'&offset='+offset+'&excludeAccepted='+(excludeAccepted? excludeAccepted : "false")); 
    }
    getReceivedGroupInvites(limit, offset, excludeAccepted) {
        return APIService.get('/groups/invites/received?limit='+limit+'&offset='+offset+'&excludeAccepted='+(excludeAccepted? "true" : "false")); 
    }

    updateInviteLinkExpiry( inviteCode, expiryDate) {
        return APIService.put('/groups/invitelink', {inviteCode: inviteCode, expiryDate: expiryDate});
    
    }

    addGroupPortfolioAccount(groupId, portfolioId, investmentAccountId) {
        return APIService.post('/groups/'+groupId+'/portfolio/'+portfolioId+'/account/'+investmentAccountId, {});
    }

    addGroupCollaborativePortfolioAccount(groupId, portfolioId, name, currencyId) {
        return APIService.post('/groups/'+groupId+'/portfolio/'+portfolioId+'/collaborative/account/', {name: name, currencyId: currencyId});
    }

    joinGroupByInvite(inviteCode) {
        return APIService.post('/groups/join/'+inviteCode,{});
    }

    joinGroup(group) {
        return APIService.post('/groups/'+group.groupId+'/join', {});
    }

    joinPrivateOrFreeEducationGroup(groupId, courseId, cohortId, accessCode){
        return APIService.post(`/groups/${groupId}/course/${courseId}/cohort/${cohortId}/join`, {accessCode: (accessCode? accessCode: null)});
    }

    acceptGroupInvite(groupInviteId) {
        return APIService.post('/groups/accept/invite/'+groupInviteId, {});
    }

    declineGroupInvite(groupInviteId) {
        return APIService.post('/groups/decline/invite/'+groupInviteId, {});
    }

    leaveGroup(groupId) {
        return APIService.put("/groups/"+groupId+"/members/leave", {});
    }

    banFromGroup(groupId, bannedUserId, banStartDate, banStopDate) {
        return APIService.post("/groups/"+groupId+"/members/ban", {bannedUserId: bannedUserId, banStartDate: banStartDate, banStopDate: banStopDate});
    }

    unBanFromGroup(groupId, bannedUserId) {
        return APIService.post("/groups/"+groupId+"/members/unban ", {bannedUserId: bannedUserId});
    }

    inviteToGroup(groupId, invited, expiryDate){
        let inviteRequest = { 
           invited: invited,
           expiryDate: expiryDate
        };

        return APIService.post('/groups/'+groupId+"/invite", inviteRequest);
    }

    searchGroupMembers(groupId, offset, limit, query, includeBanned) {
        return APIService.get('/groups/'+groupId+'/members/search?offset='+offset+'&limit='+limit +"&query="+query +"&includeBanned="+includeBanned); 
    }

    getMembersToFollow(groupId, offset, limit) {
        return APIService.get('/groups/'+groupId+'/members/tofollow?offset='+offset+'&limit='+limit ); 
    }

    removeMember(groupId, userId) {
        return APIService.put('/groups/'+groupId+'/members/remove', {userId: userId});
    }

    deleteGroup(groupId) {
        return APIService.delete('/groups/'+groupId);
    }

    deleteGroupPortfolio(groupId, portfolioId) {
        return APIService.delete('/groups/'+groupId+'/portfolio/'+portfolioId);
    }

    deleteGroupPortfolioAccount(groupId, portfolioId, investmentAccountId) {
        return APIService.delete('/groups/'+groupId+'/portfolio/'+portfolioId+'/account/'+investmentAccountId);
    }

    updateGroup(groupId, name, groupUserName, linkList, type, isCommunityPrivate, visible, bio, avatar,allowMemberPosts, allowMemberComments, allowMemberLikes ) {
        return APIService.put('/groups/'+groupId, {
            name: name,
            groupUserName: groupUserName,
            linkList: linkList,
            type: type,
            isCommunityPrivate: isCommunityPrivate,
            ownerId : store.state.users.user.userId, // TODO check
            visible: visible,
            bio: bio,
            avatar: avatar,
            allowMemberPosts: allowMemberPosts, 
            allowMemberComments: allowMemberComments, 
            allowMemberLikes: allowMemberLikes
        });
    }

    updateGroupSettings(groupId, defaultGroupPortfolioId, showFullContent, allowMemberPosts, allowMemberComments, allowMemberLikes, 
        groupPortfoliosEnabled, aggregatePortfoliosEnabled, collaborativePortfoliosEnabled,
        acceptingMembers, resourcesEnabled, position, coursesEnabled) {
        return APIService.put('/groups/'+groupId+"/settings", {
            allowMemberPosts: typeof allowMemberPosts == "boolean" ? allowMemberPosts : null,
            allowMemberComments: typeof allowMemberComments == "boolean" ? allowMemberComments : null,
            allowMemberLikes: typeof allowMemberLikes == "boolean" ? allowMemberLikes : null,

            groupPortfoliosEnabled: typeof groupPortfoliosEnabled == "boolean" ? groupPortfoliosEnabled : null,
            aggregatePortfoliosEnabled: typeof aggregatePortfoliosEnabled == "boolean" ? aggregatePortfoliosEnabled : null,
            collaborativePortfoliosEnabled: typeof collaborativePortfoliosEnabled == "boolean" ? collaborativePortfoliosEnabled : null,
            defaultGroupPortfolioId: defaultGroupPortfolioId,
            
            showFullContent: typeof showFullContent == "boolean" ? showFullContent : null,
            acceptingMembers: acceptingMembers,
            resourcesEnabled: resourcesEnabled,
            position: position,
            coursesEnabled: coursesEnabled
        });
    }

    updateGroupPortfolio(groupId, portfolioId, name, description, locked, showDollarAmounts, mandatory, defaultCurrencyId, 
        allowPaperTradingAccounts, allowPaperAnalysisAccounts, allowBrokerageAccounts, collaborationSettings){
        const updateGroupPortfolioRequest = {
            name: name,
            description : description,
            
            locked: locked,
            showDollarAmounts: showDollarAmounts,
            mandatory: mandatory,
            defaultCurrencyId: defaultCurrencyId,

            allowPaperTradingAccounts: allowPaperTradingAccounts,
            allowPaperAnalysisAccounts: allowPaperAnalysisAccounts,
            allowBrokerageAccounts: allowBrokerageAccounts,

            groupPortfolioTypeId: collaborationSettings ? COLLABORATIVE_PORTFOLIO_TYPE.ID : AGGREGATE_PORTFOLIO_TYPE.ID,
        
            collaborationSettings: collaborationSettings ? {
                quorum: collaborationSettings.quorum,
                percentageToPass: collaborationSettings.percentageToPass,
                tradingStartTime: collaborationSettings.tradingStartTime,
                tradingStopTime: collaborationSettings.tradingStopTime,
                defaultDeadline: collaborationSettings.defaultDeadline,
                automaticExec: collaborationSettings.automaticExec,
                groupFeedId: collaborationSettings.groupFeedId,
                suspendTradingFloor: collaborationSettings.suspendTradingFloor,
                tradeCriteria: collaborationSettings.tradeCriteria,
                motionHints: collaborationSettings.motionHints,
            } : undefined
        };
        return APIService.put('/groups/'+groupId+'/portfolio/'+portfolioId, updateGroupPortfolioRequest );
    }

    updateGroupFeed(groupId, feedId, name, description, isPrivate, allowMemberPosts, allowMemberComments, allowMemberLikes, inviteOnly, showFullContent, courseId) {
        let updateGroupFeedRequest = {
            name: name,
            description : description,
            isPrivate: isPrivate,
            allowMemberPosts: allowMemberPosts,
            allowMemberComments: allowMemberComments,
            allowMemberLikes: allowMemberLikes,
            inviteOnly: inviteOnly,
            showFullContent: showFullContent,
            courseId: courseId
        };
        return APIService.put('/groups/' + groupId + '/feeds/' + feedId, updateGroupFeedRequest);
    }

    updateGroupCourse(groupId, courseId, req){
       
        return APIService.put('/groups/'+groupId+'/courses/' + courseId, req );
    }

    updateGroupMemberRole(userId, groupId, roleId) {
        return APIService.put(`/groups/${groupId}/update/${userId}/role`, {roleId: roleId})
    }

    addAdminRole(userId, groupId) {
        return APIService.post(`/groups/${groupId}/add/${userId}/admin`, {});
    }

    removeAdminRole(userId, groupId) {
        return APIService.delete(`/groups/${groupId}/remove/${userId}/admin`);
    }

    addModRole(userId, groupId) {
        return APIService.post(`/groups/${groupId}/add/${userId}/mod`, {});
    }

    removeModRole(userId, groupId) {
        return APIService.delete(`/groups/${groupId}/remove/${userId}/mod`);
    }

    requestToJoin(groupId) {
        return APIService.post('/groups/'+groupId+"/join/requests", {} );
    }

   

    cancelJoinRequest(groupId, requestId) {
        return APIService.delete('/groups/'+groupId+"/join/requests/"+requestId, );
    }

    rejectJoinRequest(groupId, requestId) {
        return APIService.delete('/groups/'+groupId+"/join/requests/"+requestId, );
    }

    acceptJoinRequest(groupId, requestId) {
        return APIService.put('/groups/'+groupId+"/join/requests/"+requestId, );
    }

    getJoinRequests(groupId, limit, offset, query, excludeAccepted) {
        return APIService.get('/groups/'+groupId+'/join/requests?limit='+limit+'&offset='+offset+(query ? '&query='+query : '')+'&excludeAccepted='+(excludeAccepted? excludeAccepted : "true")); 
    }

    getGroup(groupId) {
        return APIService.get('/groups/'+groupId);
    }

    getGroupByGroupUserId(groupUserId) {
        return APIService.get('/groups/'+groupUserId+'/userId');
    }

    /** to get any group join request made by current user to group */
    getGroupJoinRequest(groupId) {
        return APIService.get('/groups/'+groupId+'/join/request'); 
    }

    generateGroupAvatar(groupId) {
        return APIService.get('/groups/'+groupId+'/avatar/generate');
    }

    getGroupFeeds(groupId) {
        return APIService.get('/groups/'+groupId+'/feeds');
    }

    getAllGroupFeeds(groupId) {
        return APIService.get('/groups/'+groupId+'/feeds/all');
    }

    moveGroupPost(groupId, feedPostId, groupFeedId, repostId) { ///{groupId}/post/{feedPostId}/move/{groupFeedId}
        //console.log("move group post with repost id "+ repostId);
        return APIService.post('/groups/'+groupId+'/post/'+feedPostId+'/move/'+groupFeedId, {repostId: (repostId ? repostId : null)});
    
    }

    getGroupCourses(groupId) {
        return APIService.get('/groups/'+groupId+'/courses');
    }

    getGroupCourse(groupId, courseId) {
        return APIService.get('/groups/'+groupId+'/courses/'+courseId);
    }

    getOpenGroupCourses(groupId) {
        return APIService.get('/groups/'+groupId+'/courses/open');
    }

    getCourseCohorts(course){
        return APIService.get('/groups/'+course.groupId+'/course/'+course.courseId+"/cohorts/open");
    }

    getCourseCohort(groupId, courseId, cohortId){
        return APIService.get('/groups/'+groupId+'/course/'+courseId+"/cohorts/"+cohortId);
    }

    updateCohort(course, cohort) {
        return APIService.put('/groups/'+course.groupId+'/course/'+course.courseId+"/cohort/"+cohort.cohortId, cohort);
    }

    saveCohort(course, cohort) {
        return APIService.post('/groups/'+course.groupId+'/course/'+course.courseId+"/cohort", cohort);
    }

    deleteCohort(course, cohort) {
       return  APIService.delete('/groups/'+course.groupId+'/course/'+course.courseId+"/cohort/"+cohort.cohortId);
    }

    getAllCourseCohorts(course, limit, offset){
        let params = "";
        if( limit ) {
            params = "?limit="+limit+"&offset="+offset;
        }
        return APIService.get('/groups/'+course.groupId+'/course/'+course.courseId+"/cohorts"+params);
    }

    getUserCourseCohorts(course){
        
        return APIService.get('/groups/'+course.groupId+'/course/'+course.courseId+"/cohorts/user");
    }


    getGroupCourseFeeds(groupId, courseId) {
        return APIService.get('/groups/'+groupId+'/course/'+courseId+'/feeds/');
    }

    getGroupPortfolio(groupId, groupPortfolioId) {
        return APIService.get('/groups/'+groupId+'/portfolios/'+groupPortfolioId);
    }

    getGroupPortfolios(groupId) {
        return APIService.get('/groups/'+groupId+'/portfolios');
    }

    getCollaborativeGroupPortfolios(groupId) {
        return APIService.get('/groups/'+groupId+'/collaborative/portfolios');
    }

    getGroupPortfolioAccounts(groupId, portfolioId) {
        return APIService.get('/groups/'+groupId+'/portfolio/'+portfolioId+'/accounts');
    }

    getGroupMemberRoles(groupId, userId) {
        return APIService.get(`/groups/${groupId}/member/${userId}/roles`);
    }

    sendEmailToGroupMembers(groupId, subject, message, testEmails, selectedCohort) {
        const sendEmailToGroupMembersRequest = {
            subject: subject,
            message: message,
            testEmails: testEmails,
            cohortId: (selectedCohort? selectedCohort.cohortId : null)
        };

        return APIService.post(`/groups/${groupId}/email`, sendEmailToGroupMembersRequest);
    }

    getContestTradeOrders(contestId, startDate, endDate, limit, offset, userId, sortBy, sortDir) {
        return APIService.get(`groups/contest/${contestId}/orders?startDate=${startDate}&endDate=${endDate}&limit=${limit}&offset=${offset}`
            + (userId ? `&userId=${userId}` : "")
            + (sortBy ? `&sortBy=${sortBy}` : "")
            + (sortDir ? `&sortDir=${sortDir}` : "")
            );
    }

    getGroupContest(contestId) {
        return APIService.get(`/groups/contests/`+contestId);
    }

    getSystemContests() {
        return APIService.get(`/groups/system/contests`);
    }

    getGroupsHostedContests(limit, offset) {
        return APIService.get(`/groups/hosted/contests?limit=${limit}&offset=${offset}`);
    }

    getGroupContests(groupId) {
        return APIService.get(`/groups/${groupId}/contests`);
    }

    generateGroupContestRegisterParam(groupId, contestId) {
        return APIService.post(`/groups/${groupId}/contests/${contestId}/register/param`, {});
    }

    generateGroupCourseRegisterParam(groupId, courseId) {
        return APIService.post(`/groups/${groupId}/course/${courseId}/register/param`, {});
    }

    generateGroupCourseCohortRegisterParam(groupId, courseId, cohortId) {
        return APIService.post(`/groups/${groupId}/course/${courseId}/cohort/${cohortId}/register/param`, {});
    }

    decodeGroupCourseRegCode(courseRegCode) {
        return APIService.get(`/groups/course/register/params/decode/${courseRegCode}`);
    }


    awardContestant(groupId, contestId, awardId, userId, reason) {
        return APIService.post(`/groups/${groupId}/contests/${contestId}/award`, { awardId: awardId, userId: userId, awardReason: reason});
    }

    stripAward(groupId, contestId, userId) {
        return APIService.delete(`/groups/${groupId}/contests/${contestId}/award/${userId}`);
    }

    issueParticipationAwards(groupId, contestId) {
        return APIService.post(`/groups/${groupId}/contests/${contestId}/award/participation`, {});
    }

    generateGroup(groupId, expiryDate){
        return APIService.post('/groups/invitelink', {groupId: groupId, expiryDate: expiryDate});
    }

    getGroupFeedContest(groupFeedId) {
        return APIService.get(`/groups/feed/${groupFeedId}/contest`);
    }

    getGroupCourseContests(groupId, groupCourseId, cohortId) {
        if( cohortId) {
            return APIService.get(`/groups/${groupId}/course/${groupCourseId}/cohort/${cohortId}/contests`);
        }
        else {
            return APIService.get(`/groups/${groupId}/course/${groupCourseId}/contests`);
        }
    }

    getContestStats(contestId, cycleStartDate, cycleEndDate, limit, offset, sortStatId, groupId) {
        return APIService.get(`/groups/contest/${contestId}/stats?limit=${limit}&offset=${offset}`
            + `&cycleStartDate=${cycleStartDate}&cycleEndDate=${cycleEndDate}&sortStatId=${sortStatId}`
            +(groupId ? `&groupId=${groupId}`: ''));
    }

    getGroupContestStats(groupId, cycleStartDate, cycleEndDate, limit, offset, sortStatId) {
        return APIService.get(`/groups/${groupId}/contest/stats?limit=${limit}&offset=${offset}`
            + `&cycleStartDate=${cycleStartDate}&cycleEndDate=${cycleEndDate}&sortStatId=${sortStatId}`);
    }
    getGroupCourseStats(groupId, courseId, cycleStartDate, cycleEndDate, limit, offset, sortStatId) {
        return APIService.get(`/groups/${groupId}/course/${courseId}/stats?limit=${limit}&offset=${offset}`
            + `&cycleStartDate=${cycleStartDate}&cycleEndDate=${cycleEndDate}&sortStatId=${sortStatId}`);
    }

    getCourseCohortStats(groupId, courseId, cohortId, limit, offset, sortStatId) {
        return APIService.get(`/groups/${groupId}/course/${courseId}/cohort/${cohortId}/stats?limit=${limit}&offset=${offset}`
            + `&sortStatId=${sortStatId}`);
    }

    getCourseCohortModuleStats(groupId, courseId, cohortId, groupFeedId, limit, offset, sortStatId) {
        return APIService.get(`/groups/${groupId}/course/${courseId}/cohort/${cohortId}/module/${groupFeedId}/stats?limit=${limit}&offset=${offset}`
            + `&sortStatId=${sortStatId}`);
    }

    updateGroupFeedOrdering(groupId, groupFeedIds) {
        return APIService.put(`/groups/${groupId}/feeds/ordering`, {groupFeedIds: groupFeedIds});
    }

    updateGroupCourseOrdering(groupId, groupCourseIds) {
        return APIService.put(`/groups/${groupId}/courses/ordering`, {groupCourseIds: groupCourseIds});
    }

    isGroupCourseCohortMember(groupId, courseId, cohortId) {
        return APIService.get(`/groups/${groupId}/course/${courseId}/cohort/${cohortId}/member`);

    }

    searchCohortMembers(course, cohort, limit, offset, query) {
        return APIService.get(`/groups/${course.groupId}/course/${course.courseId}/cohort/${cohort.cohortId}/members/search?limit=${limit}&offset=${offset}&query=${(query? query: '')}`);
    }

    getGroupCourseResources(groupId, courseId) {
        return APIService.get(`/groups/${groupId}/course/${courseId}/resources?limit=100&offset=0`);
    }

    getGroupCourseResource(groupId, courseId, resourceId){
      
        return APIService.get(`/groups/${groupId}/course/${courseId}/resources/${resourceId}`);
    }

    getGroupCourseFeedResources(groupId, courseId, feedId) {
        return APIService.get(`/groups/${groupId}/course/${courseId}/feed/${feedId}/resources?limit=100&offset=0`);
    }

    uploadGroupCourseResource(groupId, courseId, file) {
        return APIService.postFile(`/groups/${groupId}/course/${courseId}/resource`, file);
    }

    updateGroupCourseResourceThumbnail(groupId, courseId, resourceId, req){
        return APIService.put(`/groups/${groupId}/course/${courseId}/resources/${resourceId}/thumbnail`, req);
    }

    uploadGroupCourseFeedResource(groupId, courseId, feedId, file) {
        return APIService.postFile(`/groups/${groupId}/course/${courseId}/feed/${feedId}/resource`, file);
    }


    deleteGroupResource(groupId, resourceId) {
        return APIService.delete(`groups/${groupId}/resource/${resourceId}`);
    }

    updateGroupResourceOrdering(groupId, resourceIds) {
        return APIService.put(`/groups/${groupId}/resource/ordering`, {resourceIds: resourceIds});
    }

    
    hasGroupSubscription(groupId, subscriptionId) {
        return APIService.get(`/groups/${groupId}/subscription/${subscriptionId}/active`);

    }

    cloneGroupCourse(srcCourse, targetGroup) {
        return APIService.post('/groups/'+srcCourse.groupId+'/courses/'+srcCourse.courseId+'/clone-to/'+targetGroup.groupId, {});
    }
    canJoinGroup(groupId){
        return APIService.get(`/groups/${groupId}/membership/open`);
    }

    hasGroupMembership(groupId){
        return  APIService.get(`/groups/${groupId}/membership/status`);
    }

    getHostedCompetitions(groupId, limit, offset){
        return  APIService.get(`/groups/${groupId}/competitions?limit=${limit}&offset=${offset}`);
    }

    saveCompetition(groupId, req){
        return APIService.post('/groups/'+groupId+'/competition', req);
    }
    cancelCompetition(groupContest) {
        return APIService.delete(`/groups/${groupContest.groupId}/competition/${groupContest.contestId}`);
    }

    isGroupContestLive(contestId){
        return  APIService.get(`/groups/competition/${contestId}/live`);
    }

    getGroupCourseAccess(groupId, courseId){
        return APIService.get(`/groups/${groupId}/courses/${courseId}/access`);
    }

    getGroupLandingPage(groupId) {
        return APIService.get(`/groups/${groupId}/landing`);
    }

    saveGroupLandingPage(groupId, landingPage){

        return APIService.post(`/groups/${groupId}/landing`, landingPage);

    }

    removeFromCohort(selectedCourse, selectedCohort, selectedMember) {
        return APIService.delete(`/groups/${selectedCourse.groupId}/course/${selectedCourse.courseId}/cohort/${selectedCohort.cohortId}/user/${selectedMember.userId}`);
    }
}


const GroupService = new GroupServiceImpl();

export default GroupService;