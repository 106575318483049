import { GROUP_ADMIN_ROLE_TYPE, GROUP_MOD_ROLE_TYPE } from '../common/constants';
import store from '../store/index';

const FEED_MOD = "FEED_MODERATOR";
const CHAT_MOD = "CHAT_MODERATOR";
const ADMIN = "ADMIN";
const ADVERTISING = "ADVERTISING";
const PREMIUM_SUBSCRIBER = "PREMIUM_SUBSCRIBER";
const PREMIUM_PORTFOLIO_SUBSCRIBER = "PREMIUM_PORTFOLIO_SUBSCRIBER";
const BETA_PREMIUM_TESTER = "BETA_PREMIUM_TESTER";

class UserUtilsImpl {


    userIsAdmin() {
        let user = store.state.users.user;
        if( user.roles ) {
            for( const role of user.roles ) {
                if( role.name == ADMIN){
                    return true;
                }
            }
        }
        return false;
    }

    userIsAdvertising() {
        let user = store.state.users.user;
        if( user.roles ) {
            for( const role of user.roles ) {
                if( role.name == ADVERTISING){
                    return true;
                }
            }
        }
        return false;
    }

    userIsFeedModerator() {
        let user = store.state.users.user;
        if( user.roles ) {
            for( const role of user.roles ) {
                if( role.name == FEED_MOD){
                    return true;
                }
            }
        }
        return false;


    }

    userIsChatModerator() {
        let user = store.state.users.user;
        if( user.roles ) {
            for( const role of user.roles ) {
                if( role.name == CHAT_MOD){
                    return true;
                }
            }
        }
        return false;


    }

    isGroupOwnerAdminOrMod(groupOwnerId, roles) {
        return this.isUser(groupOwnerId) || this.isGroupAdmin(roles) || this.isGroupMod(roles);
    }   

    isGroupAdmin(roles) {
        return roles && roles.some((role) => role.roleId === GROUP_ADMIN_ROLE_TYPE['ID']);
    }

    isGroupMod(roles) {
        return roles && roles.some((role) => role.roleId === GROUP_MOD_ROLE_TYPE['ID']);
    }

    isPremiumSubscriber() {
        return store.getters['users/roleList'].some(role => role.name === PREMIUM_SUBSCRIBER);
    }

    isBetaPremiumTester() {
        return store.getters['users/roleList'].some(role => role.name === BETA_PREMIUM_TESTER);
    }

    isPremiumPortfolioSubscriber() {
        return store.getters['users/roleList'].some(role => role.name === PREMIUM_PORTFOLIO_SUBSCRIBER);
    }

    isFeatureEnabled(featureNameEnabled) {
        return (store.state.featureSettings && store.state.featureSettings[featureNameEnabled]);
    }

    isUser(id) {
        const user = store.state.users.user;

        return Number(id) === user.userId || Number(id) === user.analyst.analystId;
    }

   

}

const UserUtils = new UserUtilsImpl();
export default UserUtils;