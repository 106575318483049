<template>
    <div>
    <CommentCard v-for="comment in commentList" :groupOwnerId="groupOwnerId" :key="comment.id" :comment="comment" @comment-posted="onCommentOnCommentPosted" @comment-liked="onCommentLiked" 
        @comment-unliked="onCommentUnliked" :commentLikes="commentLikes" :feedPostId="activityForeignId" :depthLevel="commentLevel" :origActivity="origActivity"/>
    </div>
</template>

<script>

import FeedService from '../../service/FeedService';

const LIKE = 1;

import {defineAsyncComponent} from 'vue';

export default {
    name: 'CommentList',
    props: {
        groupOwnerId: {
            type: Number
        },
        activityForeignId: {
            type: String,
            required: true
        },
        commentList: {
            type: Array,
            required: true
        },
        commentLevel:  {
            type: Number,
            required: true
        },
        origActivity: {
           
            required: false,
        }

        
    },
    data() {
        return {
            commentLikes: []
        }
    },

    components: {
        CommentCard: defineAsyncComponent(() => import('./CommentCard.vue'))
    },

    mounted() {
        this.getCommentLikes();
    },

    methods: {
        async getCommentLikes() {
            await FeedService.getCommentLikes(this.activityForeignId).then(resp=> {
                if( resp.data.status == 'success') {
                    this.commentLikes = resp.data.commentLikes
                }
                else {
                    console.log("error getting comment likes ", resp.data.message);
                }
            });
        },

        onCommentOnCommentPosted(event) {//eslint-disable-line
            let origComment = event.origComment;

            if( !origComment.children_counts) {
                origComment['children_counts'] = {like:0, comment:0}
            }

            if (origComment.children_counts.comment) {
                origComment.children_counts.comment = origComment.children_counts.comment + 1;
            } else {
                origComment.children_counts['comment'] = 1;
            }
        },

        onCommentLiked(event) {
            let comment = event.comment;
            if (comment.children_counts.like) {
                comment.children_counts.like = comment.children_counts.like + 1;
            } else {
                comment.children_counts['like'] = 1;
            }
            let feedCommentReaction = {
                feedReactionTypeId : LIKE,
                analystId : this.$store.state.users.user.analyst.analystId,
                relatedPostId : comment.data.related_post_id,
                relatedActivityId : comment.data.related_activity_id,
                relatedReactionId : comment.id,
                relatedFeedCommentId: comment.data.feed_comment_id,
                relatedAnalystId : comment.user_id,
                reactionId: event.reaction.id
                
            };

            console.log(this.commentLikes)
            this.commentLikes.push(feedCommentReaction);
            console.log(this.commentLikes)

        },

        onCommentUnliked(comment) {
            
            if (comment.children_counts.like && comment.children_counts.like > 0 ) {
                comment.children_counts.like = comment.children_counts.like - 1;
            } else {
                comment.children_counts['like'] = 0;
            }
            

            this.commentLikes =this.commentLikes.filter(val => val.relatedFeedCommentId !== comment.data.feed_comment_id);
        },
    }
}
</script>

<style scoped>
.comment-card.level-1:last-child {
    margin-bottom: 0px;
}

.comment-card ::v-deep(.child-comments) {
    margin-top: 8px;
}

</style>