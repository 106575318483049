import axios from 'axios'
import { API_URL, QM_WM_ID, QM_API_URL } from "@/common/config";
import store from '../store/index';

import EventBus from '../event-bus';


const UNAUTHORIZED = 401;

const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000
});


apiClient.interceptors.request.use((config) => {
  // We are importing store before it is populated.
  // We intercept the request and use the current apiKey
  
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  return config;
});

 apiClient.interceptors.response.use(function (response) {
  
  let renewRequired = response.headers['renew-token'];
  //console.debug(JSON.stringify(response.headers));
  if( renewRequired ) {
   
      EventBus.emit('qm-reauthorization-required');
    
  }

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if( error.response.status == UNAUTHORIZED) {
    
    EventBus.emit('qm-authorization-required');
  }
  return Promise.reject(error);
});

const QMAPIService = {
    
  
    
    getExternal(url) {
      let externalApiClient = axios.create();
      return externalApiClient.get(url);
      
    },
    get(resource) {
      if( resource.endsWith('?')) {
        return apiClient.get(QM_API_URL+resource+"webmasterId="+QM_WM_ID+"&sid="+store.state.sid);
      }
      else {
        return apiClient.get(QM_API_URL+resource+"&webmasterId="+QM_WM_ID+"&sid="+store.state.sid);
      }
    },
   
    // post(resource, params) {
    //   return apiClient.post(`${resource}`, params);
    // },
  
    // update(resource, params) {
    //   return apiClient.put(resource, params);
    // },
  
    // put(resource, params) {
    //   return apiClient.put(resource, params);
    // },
  
    // delete(resource) {
    //   return apiClient.delete(resource);
    // }
  };
  
  export default QMAPIService;