<template>
    <div class='sub-tab-menu-container'>
        <TabMenu :model="items" :activeIndex="activeIndex" v-observe-visibility="visibilityChanged">
            <template #item="{item}">
                <button @click="callAction(item)">
                    {{ item.label }}
                </button>
            </template>
        </TabMenu>
    </div>
</template>

<script>
import TabMenu from 'primevue/tabmenu';

import {keepMenuItemVisible} from '../';

export default {
    name: 'SubTabMenu',
    components: {
        TabMenu
    },

    props: {
        tabMenuItems: {
            type: Array,
            required: true
        },

        minScrollLength: {
            type: Number,
            default: 3
        }
    },
    data() {
        return {
            activeIndex: 0,
            items: []
        }
    },

    mounted() {
        this.items = this.tabMenuItems.filter(keepMenuItemVisible).map((item, index) => {
            let obj = item;

            obj['index'] = index;

            return obj;
        });
    },

    methods: {
        keepMenuItemVisible,

        visibilityChanged (isVisible, entry) { // eslint-disable-line
            if(isVisible ) {
                const length = this.items.length;

            
                if (length > this.minScrollLength) {
                    const tabMenuContainer = document.getElementsByClassName('sub-tab-menu-container')[0];

                    const tabMenuWidth = `${(tabMenuContainer.offsetWidth / 2) * length}px`;

                    tabMenuContainer.childNodes[0].style.width = tabMenuWidth;

                    document.getElementsByClassName('sub-tab-menu-container')[0].childNodes[0].childNodes[0].childNodes.forEach((node) => {
                        if (node.tagName === 'LI' && node.className.split(' ').includes('p-tabmenuitem')) {
                            console.log(node.offsetWidth)
                        }
                    })

                }
            }
            
            
        },

        callAction(item) {
            this.activeIndex = item.index;

            item.action();
        },

        setSelectedItem(index) {
            this.activeIndex = index;
        }
    }
}
</script>

<style scoped>
.sub-tab-menu-container {
    width: 100%;
    overflow-x: auto;
}

::v-deep(.p-tabmenu) {
    position: relative;
    height: 35px;
    width: 100%;
    border: none;
    overflow: hidden;
    margin-bottom: 8px;
}

::v-deep(.p-tabmenu-nav) {
    height: 100%;
    display: flex;
    border: none;
}

::v-deep(.p-tabmenuitem) {
    width: 50%;
    border: none;
    padding: 0;
}

::v-deep(.p-tabmenuitem > button) {
    display: flex;
    height: 100%;
    width: 100%;
    border: none;
    font-size: 16px;
    color: #32364E;
    font-family: 'Trebuchet MS', 'Verdana';
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

::v-deep(.p-highlight > button) {
    background: #32364E;
    color: #fff !important;
}
</style>