<template>
    <div class='user-list-item-container'>
        <UserAvatar :imageSrc='user.avatar' size='md' @click='userAvatarClick($event)'/>
        <div class='text-container'>
            <span class='header-text' @click='headerTextClick($event)'>
                {{stringUtils.fullName(user)}} <slot name="afterFullName"></slot>
            </span>
            <span class='sub-text' @click='subTextClick($event)'>
                @{{user.name}}
            </span>
            <span class='sub-text' @click='subText2Click($event)' v-if="showStats">
               {{user.activityStats.numFollowers}} Followers | {{user.activityStats.numPosts}} {{postsText}}
            </span>
        </div>
        <div class='buttons-container' v-if="$slots.actions">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
import UserAvatar from '../profile/UserAvatar.vue';

import PostUtils from '../../utilities/PostUtils';
import StringUtils from '../../utilities/StringUtils';
import UserUtils from '../../utilities/UserUtils';


export default {
    name: 'GroupListItem',
    props: {
        user: {
            type: Object,
            required: true
        },
        showStats: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        UserAvatar
    },
    data() {
        return {
            stringUtils: StringUtils,
            userUtils: UserUtils
        }
    },
    computed: {
        postsText() {
            return this.user.activityStats.numPosts > 1 ? 'Posts' : 'Post';
        }
    },

    methods: {
        headerTextClick(event) {
            if(this.user.userTypeId !== 2) {
                PostUtils.goToAnalystProfile(this.user.name, event);
            }
        },
        subTextClick(event) {
            if(this.user.userTypeId !== 2) {
                PostUtils.goToAnalystProfile(this.user.name, event);
            }
        },
        subText2Click(event) {
            if(this.user.userTypeId !== 2) {
                PostUtils.goToAnalystProfile(this.user.name, event);
            }
        },
        userAvatarClick(event) {
            if(this.user.userTypeId !== 2) {
                PostUtils.goToAnalystProfile(this.user.name, event);
            }
        },
    }
}
</script>

<style scoped>
.user-list-item-container {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #BFBFBF;
}
.user-list-item-container:last-child {
    border-bottom: none;
}

::v-deep(.p-avatar) {
    margin-right: 16px;
}
::v-deep(.p-avatar:hover) {
    cursor: pointer;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.header-text {
    font-size: 16px;
    color: #32364e;
    font-weight: bold;
    margin-bottom: 4px;
}
.header-text:hover {
    cursor: pointer;
}

.sub-text {
    font-size: 12px;
    color: #BFBFBF;
}
.sub-text:hover {
    cursor: pointer;
}

.buttons-container {
    margin-left: auto;
}
.buttons-container > ::v-deep(*) {
    margin-right: 8px;
}
.buttons-container > ::v-deep(*:last-child) {
    margin-right: 0px;
}
</style>