<template>
    <Dialog header="Move Post" v-model:visible="display" :modal="true" :closable="true" class='move-post-modal'>
        
        <div v-show="!posting">
            <div class="groups-selection-header">
                <p>Choose destination:</p>
            </div>
            <div>
                <ProgressSpinner v-if="loading" :showLoading="loading"
                                class="group-feeds-progress-spinner" />
                <Dropdown v-else :options="groupFeeds" optionLabel="name" placeholder="Select a feed/module" v-model="selectedGroupFeed" >
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                            <div>{{ (slotProps.value.courseId ? slotProps.value.courseName+" - "+slotProps.value.name :  "Community - "+slotProps.value.name)}}</div>
                        </div>
                        <span v-else>
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div class="flex align-items-center">
                            <div>{{ (slotProps.option.courseId ? slotProps.option.courseName+" - "+slotProps.option.name :  "Community - "+slotProps.option.name)}}</div>
                        </div>
                    </template>
                </Dropdown>
            </div>
           
        </div>
        <template #footer>
            <div class='button-container'>
                <Button label="Move" class='p-button-raised p-button-rounded' @click='move()' />
                <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close()' />
            </div>
        </template>
    </Dialog>
     <Dialog v-model:visible="posting" :modal="true" :closable="false" class='moving-posting-progress-modal'  id="moving-progress-modal">
        <template #header>
            <div style="font-size: 2rem;margin:auto;">Moving post...</div>
        </template>
        <LoadingSpinner :showLoading="posting" style="height: 65px;min-height:65px;"/>
    </Dialog>
</template>

<script>

import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';

import GroupService from '../../service/GroupService';
import LoadingSpinner from '../common/LoadingSpinner';

export default {
    name: 'MovePostDialog',

    data() {
        return {
            display: false,
            group: null,
            postId: null,
            repostId: null,
            groupFeeds: [],
            selectedGroupFeed: null,
            loading: false,
            posting: false,
            callback: null,
        }
    },

    components: {
        Dialog,
        Dropdown,
        ProgressSpinner,
        LoadingSpinner
    },

    methods: {
        open(activity, callback) {
            this.loading = true;
            this.callback = callback;
            this.posting = false;
            this.groupFeeds = [];
            this.selectedGroupFeed = null;
            this.display = true;
            this.group = activity.group;
            this.postId = activity.foreign_id;
            this.repostId = null;
            if( this.postId && this.postId.lastIndexOf(":") > 0 ) {// repost
                this.postId = this.postId.substring(this.postId.lastIndexOf(":")+1);
                this.repostId = activity.id;
            }
           // console.log(activity)
            //console.log("repost id = "+ this.repostId)
            GroupService.getAllGroupFeeds(this.group.groupId).then(resp => {
                if (resp.data.status == 'success') {
                    this.groupFeeds = resp.data.groupFeeds;
                }
                this.loading = false;
            });
        },

        move() {
            //console.log("selectedGroupFeed : ", this.selectedGroupFeed);
            this.posting = true;
            let dest = (this.selectedGroupFeed.courseId ? this.selectedGroupFeed.courseName+" - "+this.selectedGroupFeed.name :  "Community - "+this.selectedGroupFeed.name);
            GroupService.moveGroupPost(this.group.groupId, this.postId, this.selectedGroupFeed.groupFeedId, this.repostId).then( resp=> {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'Post was moved to '+dest, life: 3500, group: 'center' });
                    this.callback();
                    this.close();

                }
                else {
                    //console.log(resp.data.message);
                    this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3500, group: 'center' });
                }
                this.posting = false;
            });
        },

        close() {
            this.display = false;
            this.groupFeeds = [];
            this.selectedGroupFeed = null;
            this.group = null;
            this.postId = null;
            this.loading = false;
            this.posting = false;
            this.callback = null;
            this.repostId = null;
        },
    }
}
</script>

<style>
#moving-progress-modal.moving-posting-progress-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 350px;
    height: 150px;
	box-shadow: none;
}

#moving-progress-modal.moving-posting-progress-modal .p-dialog-header {
    padding: 20px 10px 10px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
}

#moving-progress-modal.moving-posting-progress-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

#moving-progress-modal.moving-posting-progress-modal .logo-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
}

#moving-progress-modal.moving-posting-progress-modal .p-dialog-content {
	padding: 10px 25px !important;
    height: 100% !important;
    border-bottom-left-radius: 30px !important;
	border-bottom-right-radius: 30px !important;
    text-align: center;
}

#moving-progress-modal.moving-posting-progress-modal .button-container {
    display: flex;
    justify-content: center;
}


.p-dialog-mask.p-component-overlay {
    background-color: rgba(50, 54, 78, 0.7);
}

.move-post-modal {
    font-family: "Trebuchet MS", "Verdana";
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    min-width: 450px;
}

.move-post-modal .p-dialog-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.move-post-modal .p-dialog-header .p-dialog-title {
    font-weight: bold;
    font-size: 24px;
}

.move-post-modal .p-dialog-content {
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
}

.move-post-modal .body-text {
    font-size: 16px;
    margin-bottom: 1rem;
}

.move-post-modal .p-dialog-footer {
    padding-top: 1.5rem;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.move-post-modal .button-container {
    display: flex;
    justify-content: center;
}
</style>
<style scoped>
.group-feeds-progress-spinner {
    height: 20px;
    min-height: 20px;
    width: 20px;
    min-width: 20px;
    margin-left: 8px;
    margin-right: 16px;
}

</style>