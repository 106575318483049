class NumberUtilsImpl {

    isEven(num) {
        return num % 2 === 0;
    }

    isOdd(num) {
        return num % 2 === 1;
    }

    isNegative(num) {
        return num < 0;
    }

    isPositive(num) {
        return num >= 0;
    }
}

const NumberUtils = new NumberUtilsImpl();
export default NumberUtils;