<template>
    <LoadingSpinner v-if="!poll" :showLoading="!poll" style="height: 65px;min-height:65px;"/>
    <template v-else-if="poll">
        <template v-if="!showAnswerDistrubution">
            <template v-if="isPollTypeYesNo()">
                <span class="poll-label" v-if="(isPollClosed() || poll.showAnswerOnSubmit) && yesNoCorrectAnswer !== null">Correct answer was: {{yesNoCorrectAnswer}}</span>
            </template>
            <template v-else>
                 <span class="poll-label" v-if="(isPollClosed() || poll.showAnswerOnSubmit) && isPollTypeMultipleChoice() && this.pollMultipleChoiceCorrectAnswer?.code">

                    Correct answer was: {{this.pollMultipleChoiceCorrectAnswer?.code}}
                </span>
            </template>
        </template>
        <template v-else>
            <span class='poll-label'>Current results are:</span>
            
            <template v-if="isPollTypeYesNo()">
               
                <div class="poll-option">Yes: {{poll.yesVotes}}</div> 
                <div class="poll-option">No: {{poll.noVotes}}</div>
               
                <span class="poll-label" v-if="(isPollClosed() || poll.showAnswerOnSubmit) && poll.yesIsCorrect !== null">Correct answer was: {{poll.yesIsCorrect ? 'Yes' : 'No'}}</span>

                <div class="poll-label">Total Users Polled: {{poll.noVotes + poll.yesVotes}}</div>
            </template>
            <template v-else>
               
                <div v-for="option in poll.pollOptions" :key="option.pollOptionId" class="poll-option">
                    {{option.votes}}&nbsp;&nbsp;<b>{{option.code}}.</b>&nbsp;{{option.title}}:&nbsp;
                </div>
                

                <span class="poll-label" v-if="(isPollClosed() || poll.showAnswerOnSubmit) && isPollTypeMultipleChoice() && this.pollMultipleChoiceCorrectAnswer?.code">

                    <div class="poll-label">Total User's Polled: {{ poll.yesVotes}}</div>
                        Correct answer was: {{this.pollMultipleChoiceCorrectAnswer?.code}}
                </span>
            </template>
        </template>

        <LoadingSpinner v-if="pollWinnersInitLoading" :showLoading="pollWinnersInitLoading" style="height: 65px;min-height:65px;"/>
        <template v-if="showAnswerDistrubution">
            <div class="winners-container" v-if="pollWinners && pollWinners?.length > 0 && (isPollClosed() || poll.showAnswerOnSubmit) && (isPollTypeMultipleChoice() || isPollTypeYesNo())">
                <span class="poll-winners-label">Winners:</span>
                <infinite-scroll v-if="pollWinners && pollWinners.length > 0" @infinite-scroll="loadMoreWinners" message="" :noResult="noMoreWinnersToLoad" :style='scrollStyle' class="winners-scrollpanel">
                    <template v-for="pollWinner in pollWinners" :key="pollWinner.userId">
                        <div class="user-item" @click="goToAnalystProfile(pollWinner.name)">
                            <UserAvatar class="user-avatar" :imageSrc="pollWinner.avatar" size="md" />
                            <div class="user-details-container">
                                <span>{{ getFullName(pollWinner) }}</span>
                                <span>@{{ pollWinner.name }}</span>
                            </div>
                        </div>
                    </template>
                    <LoadingSpinner :showLoading="pollWinnersLoading" v-if="pollWinnersLoading" style="height: 65px;min-height:65px;"/>
                </infinite-scroll>
            </div>
        </template>
    </template>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner.vue';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import UserAvatar from '../profile/UserAvatar.vue';

import PollingService from '../../service/PollingService';

import router from '../../router';

export default {
    name: 'ViewPollingResults',
    emits: ['close-modal'],
    props: {
        poll: {
            type: Object,
            required: false
        },
        showAnswerDistrubution: {
            type: Boolean,
            required: false,
            default: true,
        }
    },

    data() {
		return {
			beforeUnmount: false,

			pollMultipleChoiceCorrectAnswer: null,
            yesNoCorrectAnswer: null,
			pollWinners: [],
            pollWinnersInitLoading: true,
            pollWinnersLoading: false,
            noMoreWinnersToLoad: false,
			
            scrollStyle: "width: 100%; height: 200px;",
		}
    },

	components: {
        UserAvatar,
        LoadingSpinner,
        InfiniteScroll
	},

    watch: {
        poll(newPoll, oldPoll){
            
           
            if(newPoll && newPoll?.pollId != oldPoll?.pollId && this.pollWinners?.length == 0){
                //console.log("ViewPollingResults watch newPoll", newPoll);
                this.init();
            }
            else if (newPoll?.pollId == oldPoll?.pollId ) {
               
               this.initCorrectAnswer();
            
            }
        }
    },

    mounted() {
        this.beforeUnmount = false;
        //console.log("poll in card is ", this.poll)
        if(this.poll){
            this.init();
        }
    },
    
    beforeUnmount() {
        this.beforeUnmount = true;
    },

	methods: {
        initCorrectAnswer() {
            this.pollMultipleChoiceCorrectAnswer = null;
            this.yesNoCorrectAnswer = null;
            if(this.isPollClosed() || this.poll.showAnswerOnSubmit ){
                if( this.isPollTypeMultipleChoice() ) {
                    this.pollMultipleChoiceCorrectAnswer = this.poll.pollOptions.find(option => option.answerIsCorrect === true);
                }
                else if( this.isPollTypeYesNo() ){
                    this.yesNoCorrectAnswer = (this.poll.yesIsCorrect ? 'Yes' : 'No');
                }
                
            }
        },
        init(){
            this.initCorrectAnswer();

            if(this.isPollClosed() || this.poll.showAnswerOnSubmit){
                this.pollWinnersInitLoading = true;
                this.refreshPollWinners();
            } else {
                this.pollWinnersInitLoading = false;
            }
        },

        refreshPollWinners() {
            this.pollWinners = [];
            this.pollWinnersLoading = false;
            this.noMoreWinnersToLoad = false;

            this.fetchPollWinners(10, 0);
        },

        async loadMoreWinners() {
            // console.log('loadMoreWinners', this.pollWinnersLoading);
            if (!this.pollWinnersLoading) {
                this.pollWinnersLoading = true;

                if (!this.noMoreWinnersToLoad) {
                    this.fetchPollWinners(10, this.pollWinners.length);
                } else {
                    this.pollWinnersLoading = false;
                }
            }
        },

        fetchPollWinners(limit, offset) {
            PollingService.getPollWinners(this.poll.pollId, limit, offset).then(resp => {
                if(!this.beforeUnmount){
                    this.pollWinnersInitLoading = false;
                    this.pollWinnersLoading = false;

                    if( resp.data.status == 'success') {
                        // console.log("getPollWinners", resp.data);
                        const winners = resp.data.pollWinners;

                        if (winners.length > 0) {
                            const results = this.pollWinners.concat(winners);

                            this.pollWinners = results;

                            this.noMoreWinnersToLoad = this.pollWinners.length >= resp.data.total;
                            // console.log("this.noMoreWinnersToLoad", this.noMoreWinnersToLoad);
                        } else {
                            this.noMoreWinnersToLoad = true;
                        }
                    } else {
                        console.log("An error occurred trying to getPollWinners()");
                        // this.$toast.add({ severity: 'error', summary: "An error occurred trying to getPollWinners()", detail: '', life: 3000, group: 'tc' });
                    }
                }
            });
        },

        getFullName(user) {
            if (user) {
                let name = '';
                name += user?.firstName ? user.firstName : '';
                name += user?.middleName ? user.middleName : '';
                name += user?.lastName ? user.lastName : '';
                return name;
            } else {
                return '';
            }
        },

        goToAnalystProfile(username) {
            this.$emit('close-modal');
            router.push('/analysts/profile/' + username);
        },

        isPollClosed() {
            return this.poll?.closedDate != null;
        },

        isPollTypeYesNo() {
            return this.poll.pollTypeId == 1;
        },

        isPollTypeMultipleChoice() {
            return this.poll.pollTypeId == 2;
        },
	}

}
</script>

<style scoped>
.p-float-label {
    margin: 15px 0px 25px;
}

.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.poll-label {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
}

.poll-winners-label {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.winners-scrollpanel {
    max-height: 150px !important;
    overflow-y: auto;
}

.user-item {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.user-avatar {
    min-width: 48px;
    min-height: 48px;
}
.user-details-container {
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
</style>