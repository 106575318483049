<template>
<div :style="(isMobile() ? 'text-align: center;margin-bottom:3px;' : 'margin-bottom:5px;')">
  <Dropdown class="exchange-selector" v-model="selectedExchange" optionLabel="label" :options="exchangeOptions" />
  <Dropdown class="stat-selector" v-model="selectedStat" optionLabel="label" :options="statOptions" />
</div>
<!-- <DataTable  id="sectorSecuritiesTable" :value="securities" scrollHeight="500px" :rowClass="portfolioRowClass" :resizableColumns="true" columnResizeMode="expand" 
 :lazy="true" :loading="loading" responsiveLayout="stack" breakpoint="760px"> -->
 <DataTable  id="sectorSecuritiesTable" :value="securities" :scrollable="true" scrollHeight="500px" :rowClass="portfolioRowClass" :resizableColumns="true" columnResizeMode="expand"  :lazy="true" :loading="loading">
 
    <template > 
        <Column header="Actions" headerClass="holdings-header" class="action-column" v-if="!isMobile()" :frozen="true">
        <template #body="slotProps"> 
        
            <div @click="goToOverview(slotProps.data)"  class="p-button p-component p-button-icon-only view-sec-button" v-tooltip.top="'Go to company overview'" >
                <span class="pi pi-chart-line p-button-icon"></span><span class="p-button-label">&nbsp;</span>
            </div>
            <div @click="goToPosts(slotProps.data)"  class="p-button p-component p-button-icon-only view-sec-button " v-tooltip.top="'Go to Posts'" >				
                <span class="material-symbols-outlined p-button-icon " >speaker_notes</span>
            </div>
            
        </template>
        </Column>
    </template>
    <Column field="key.symbol" header="Symbol" headerClass="holdings-header" class="symbol-column" :frozen="true" >
        <template #body="slotProps">
            <div class="ticker" v-if="!isMobile()">
                {{formatSymbol(slotProps.data.key.symbol) }}
            </div>
            <div class="ticker p-button view-item-button" @click="goToOverview(slotProps.data)"  v-else>
            {{formatSymbol(slotProps.data.key.symbol)}}
            </div>
        </template>
    </Column>
    <Column field="equityinfo.longname" header="Name" headerClass="holdings-header" class="name-column">
        <template #body="slotProps">
            <div class="company" v-tooltip.top="{value: slotProps.data.equityinfo.longname, class:'company-name-tooltip'}">
            {{slotProps.data.equityinfo.longname}}
            </div>
        </template>
    </Column>
    <Column field="pricedata.fundamental.marketcap" header="Market Cap" headerClass="holdings-header" class="market-cap-column" >
        <template #body="slotProps">
        <div class="market-value" v-if="slotProps.data.fundamental?.marketcap">
            {{ formatMarketCap(slotProps.data.fundamental.marketcap) }}
        </div>
        <div class="no-value" v-else>
            -
        </div>
        </template>
    </Column>
     
    <Column field="pricedata.prevclose" header="Prev. Close" headerClass="holdings-header" class="market-column" >
        <template #body="slotProps">
        <div class="market-value" v-if="slotProps.data.pricedata.prevclose">
            {{ valueFormatter(slotProps.data.pricedata.prevclose, 'currency') }}
        </div>
        <div class="no-value" v-else>
            -
        </div>
        </template>
    </Column>
    <Column field="pricedata.open" header="Open" headerClass="holdings-header" class="market-column" >
        <template #body="slotProps">
        <div class="market-value" v-if="slotProps.data.pricedata.open">
            {{ valueFormatter(slotProps.data.pricedata.open, 'currency') }}
        </div>
        <div class="no-value" v-else>
            -
        </div>
        </template>
    </Column>
        <Column field="pricedata.last" header="Last" headerClass="holdings-header" class="market-column" >
        <template #body="slotProps">
        <div :class="'market-value ' + getTickColor(slotProps.data.changepercent)" v-if="slotProps.data.pricedata.last">
            {{ valueFormatter(slotProps.data.pricedata.last, 'currency') }}
        </div>
        <div class="no-value" v-else>
            -
        </div>
        </template>
    </Column>
        <Column field="pricedata.change" header="%Change" headerClass="holdings-header" class="market-column" >
        <template #body="slotProps">
        <div :class="'market-value ' + getTickColor(slotProps.data.changepercent)" v-if="slotProps.data.pricedata.changepercent">
            {{ valueFormatter(slotProps.data.pricedata.changepercent, 'percentage') }}
            
        </div>
        <div class="no-value" v-else>
            -
        </div>
        </template>
    </Column>
        <Column field="pricedata.bid" header="Bid" headerClass="holdings-header" class="market-column" >
        <template #body="slotProps">
        <div class="market-value" v-if="slotProps.data.pricedata.bid">
            {{ valueFormatter(slotProps.data.pricedata.bid, 'currency') }}
        </div>
        <div class="no-value" v-else>
            -
        </div>
        </template>
    </Column>
        <Column field="pricedata.ask" header="Ask" headerClass="holdings-header" class="market-column" >
        <template #body="slotProps">
        <div class="market-value" v-if="slotProps.data.pricedata.ask">
            {{ valueFormatter(slotProps.data.pricedata.ask, 'currency') }}
        </div>
        <div class="no-value" v-else>
            -
        </div>
        </template>
    </Column>
        <Column field="pricedata.tradevolume" header="Trade Vol." headerClass="holdings-header" class="market-column" >
        <template #body="slotProps">
        <div class="market-value" v-if="slotProps.data.pricedata.tradevolume">
            {{ slotProps.data.pricedata.tradevolume}}
        </div>
        <div class="no-value" v-else>
            -
        </div>
        </template>
    </Column>
    <Column field="pricedata.close" header="Close" headerClass="holdings-header" class="market-column" >
        <template #body="slotProps">
        <div class="market-value" v-if="slotProps.data.pricedata.close">
            {{ valueFormatter(slotProps.data.pricedata.close, 'currency') }}
        </div>
        <div class="no-value" v-else>
            -
        </div>
        </template>
    </Column>
        
      
      
    </DataTable>

</template>

<script>
import DataService from '../../service/DataService';
import Dropdown from 'primevue/dropdown';
import BrowserUtils from '../../utilities/BrowserUtils';
import ResearchService from '../../service/ResearchService';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import SecurityFormatter from '../../common/SecurityFormatter';
import FormattingUtils from '../../utilities/FormattingUtils';

const LIMIT = 10;
const stats = [ //eslint-disable-line
{ label:'Top 10 Volume Actives', code: 'va'},
{ label:'Top 10 Dollar Gainers', code: 'dg'},
{ label:'Top 10 Dollar Losers', code: 'dl'},
{ label:'Top 10 Dollar Volume', code: 'dv'},
{ label:'Top 10 Percent Gainers', code: 'pg'},
{ label:'Top 10 Percent Losers', code: 'pl'},
{ label:'Top 10 Annual High', code: 'ah'},
{ label:'Top 10 Annual Low', code: 'al'},
{ label:'Top 10 Percent Largest Volume Change', code: 'plvc'},
{ label:'Top 10 Range Advances', code: 'rup'},
{ label:'Top 10 Range Declines', code: 'rdp'},
{ label:'Top 10 Gap Up', code: 'gup'},
{ label:'Top 10 Gap Down', code: 'gdp'},
{ label:'Top 10 Most Volatile', code: 'mv'},
{ label:'Top 10 Largest Trades', code: 'lt'},
{ label:'Top 10 Largest Range', code: 'lr'},
{ label:'Top 10 Weekly Percent Gainers', code: 'wpg'},
{ label:'Top 10 Weekly Percent Losers', code: 'wpl'},
{ label:'Top 10 Monthly Percent Gainers', code: 'mpg'},
{ label:'Top 10 Monthly Percent Losers', code: 'mpl'},
{ label:'Top 10 Quarterly Percent Gainers', code: 'qpg'},
{ label:'Top 10 Quarterly Percent Losers', code: 'qpl'},
];  
export default {


    name: 'SectorSecurities',
    props: {
        selectedTag: {
            type: Object,
            required: true
        },
    },
    components: {
        Dropdown,DataTable,Column,
    },

    data() {
        return {
            
            exchangeOptions: [],//[US, CANADA],
            statOptions: [],//stats,
            selectedExchange : null,
            selectedStat : null,

            mounting: false,
            securities: [],
            loading: false,
            justMounted : false,
            marketCapFormatter: new FormattingUtils(),
        }
    },

    mounted() {
        console.log("mounting sector securities");
        this.mounting = true;
        this.exchangeOptions = [{label:'NYSE', code:'nye', xcode:'XNYS'}, {label:'NASDAQ', code:'nsd', xcode:'XNAS'},  {label:'TSX', code:'tsx', xcode:'XTSE'}, 
            {label:'TSV', code:'tsv', xcode:'XTSX'},{label:'CSE', code:'cnq', xcode:'XCNQ'},
            {label:'NYSE American', code:'amx',xcode:'ARCX' },{label:'OTC', code:'oto', xcode:'OOTC'}];
        this.statOptions = stats,
        this.selectedExchange = this.exchangeOptions[0];
        this.selectedStat = this.statOptions[16];
        this.mounting = false;
        this.reloadSecurities();
        this.justMounted  = true;

        if (this.isMobile()) {
            this.$nextTick( () => {
                this.scrollToWeightColumn();
            });
        
        }
       
        
    },
    activated() {
        console.log("activating sector securities")
        if( !this.justMounted ) {
            this.reloadSecurities();
        }
        else {
            this.justMounted = false;
        }
         
    },

    watch: {
        selectedExchange() {
            this.reloadSecurities();
        },

        selectedStat(){
            this.reloadSecurities();
        }
    },

    methods: {
        formatMarketCap(value) {
            return this.marketCapFormatter.format(value, '$0.0a');
        },
        scrollToWeightColumn() {
      
            document.querySelector('#sectorSecuritiesTable .p-datatable-wrapper').scrollLeft = 200;
        },

        formatSymbol(symbol) {
            return SecurityFormatter.getSymbolWithoutQMExchange(symbol);
        },
        isMobile() {
            return BrowserUtils.isMobile();
        },
    
        reloadSecurities() {
            if( !this.mounting ) {
                this.loading = true;
                this.securities = [];
               
                let exchangeCode = this.selectedExchange.code;
               
                let sectorIndustryCode= (this.selectedTag.industryCode ? this.selectedTag.industryCode: this.selectedTag.sectorCode);

                DataService.getSectorSecurities(exchangeCode, sectorIndustryCode, this.selectedStat.code, LIMIT).then(resp => {
                    try{
                        //console.log(JSON.stringify(resp))
                        this.securities = resp.data.results.quote;
                        this.loading = false;
                    }catch(error) {
                        this.loading = false;
                        console.log("error getting sector securities.");
                    }

                });
            }
        },

        
        getTickColor(changepercent) {
            if( changepercent < 0 ) {
                return 'red-tick';
            }
            else {
                return 'green-tick';
            }
        },

        goToOverview( sec ) {
            let router = this.$router;
            let symbol = this.formatSymbol(sec.key.symbol);
            let exSymbol = this.selectedExchange.xcode;
           if( sec.datatype == 'equity') {
                ResearchService.setSelectedEquityFromXignite(exSymbol, symbol, (resp) => {
                    if (resp == 'success') {
                        router.push({ path: '/equity' });
                    }
                });
                
            }else  {
                

                ResearchService.setSelectedFund(exSymbol, symbol, (resp) => {
                    if (resp == 'success') {
                        router.push({ path: '/fund' });
                    }
                });
                
            }

        },
        goToPosts( sec ) {
            let router = this.$router;
            let symbol = this.formatSymbol(sec.key.symbol);
            let exSymbol = this.selectedExchange.xcode;
           if( sec.datatype == 'equity') {
                ResearchService.setSelectedEquityFromXignite(exSymbol, symbol, (resp) => {
                    if (resp == 'success') {
                        router.push({ path: '/equity/notes' });
                    }
                });
                
            }else  {
                

                ResearchService.setSelectedFund(exSymbol, symbol, (resp) => {
                    if (resp == 'success') {
                        router.push({ path: '/fund/notes' });
                    }
                });
                
            }
        },
        
        valueClass(data) {
            return [
                {
                'positive': data.marketValue > data.bookValue,
                'negative': data.marketValue < data.bookValue,
                'even': data.marketValue === data.bookValue,
                }
            ];
        }, 

        portfolioRowClass() {
            return 'portfolio-row';
        },

        valueFormatter(value, type) {
            if (type === 'currency') {
                const formattedValue = '$' + value.toLocaleString('en-US', {
                minimumFractionDigits: 2, 
                maximumFractionDigits: 2
                });
                return formattedValue;
            } else if (type === 'percentage') {
                const formattedValue = value.toLocaleString('en-US', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
                }) + '%';
                return formattedValue;
            }
        }
    
    }

}
</script>
<style>
.company-name-tooltip {
  max-width: 300px;
}
</style>
<style scoped>
.stat-selector {
    margin-left:5px;
}

.p-dropdown {
    border: 2px solid #32364e;
    border-radius: 50px;
    padding: 1px 4px 1px 8px;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
}

.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus {
    border-color: #32364e;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
}

::v-deep(span.p-dropdown-label.p-inputtext) {
    color: #32364e;
    font-size: .8rem;
}


.p-dropdown-trigger-icon {
    color: #32364e;
    font-size: 0.9rem;
}

.p-dropdown-item {
    color: #32364e;
    border-bottom: 1px solid #333;
}

li.p-dropdown-item {
   
    font-size: .8rem;
}



::v-deep(*:not(.pi, .material-icons-outlined, .material-symbols-outlined)), ::v-deep(.p-component){
    font-family: 'Trebuchet MS', 'Verdana';
}



.no-holdings-text {
  font-family: "Trebuchet MS";
  font-size: 12px;
  color: #999;
}

.negative, .positive, .ticker, .company, .weight, .market-value, .book-value {
  font-size: 12px;
}

.negative, .positive, .even, .ticker {
  font-weight: bold;
}

.negative {
  color: #e63e3e;
}

.positive {
  color: #3c9;
}

.ticker {
  color: #32364e;
}

.even, .company, .weight, .market-value, .book-value, .no-value {
  color: black;
}

::v-deep(.portfolio-row) {
  font-family: 'Trebuchet MS';
}

::v-deep(.holdings-header), ::v-deep(.p-column-title) {
  font-family: 'Trebuchet MS';
  font-weight: 400;
  font-size: 12px;
  background: #fff !important;
  color: #999;
}

::v-deep(.p-datatable .p-datatable-thead > tr > th) {
  background: #fff;
}

.company {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

::v-deep(.weight-column){
  min-width: 70px;
  max-width: 70px;
  
}

::v-deep(.symbol-column) {
   min-width: reset;
    max-width: 98px;
   /* flex: none !important; */
}

::v-deep(.action-column) {
  min-width: 98px;
  max-width: 98px;
}



 @media (max-width: 760px) {
    ::v-deep(.symbol-column) {
      min-width: 98px;
      max-width: none; /*98px;*/
      flex: none !important;
      
    }

    /* ::v-deep(.name-column) {
        max-width: none;
    } */

    ::v-deep(.view-item-button) {
        
      background: #FFFFFF;
      border: 1px solid #33CC99;
      box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
      border-radius: 8px;
      color: #32364e;
      width: 100px;
  }

  #sectorSecuritiesTable {
    margin-bottom: 4rem;
  }
  /* .company {
    text-overflow:unset;
    overflow:unset;
    white-space: break-spaces;
    text-align: right;
  } */
} 

::v-deep(.name-column) {
  min-width: 200px;
}

::v-deep(.market-column), ::v-deep(.book-column), ::v-deep(.gain-loss-column) {
  min-width: 55px;
}

::v-deep(.market-cap-column) {
     min-width: 80px;

}

::v-deep(.view-item-button) {
        /* margin-left: auto; */
    background: #FFFFFF;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
    border-radius: 8px;
    color: #32364e;
    /* padding: 8px 12px; */
    /* width: 4rem; */
    justify-content: center;

}

::v-deep(.view-item-button:hover) {
    border-color:#32364e;
    background-color: #33cc99;
    color: #FFFFFF;
}
::v-deep(.view-item-button:focus) {
    box-shadow: none;
    border-color: #33cc99;
}

::v-deep(.view-sec-button) {
   border: 1px solid #32364e;
    background-color: #FFFFFF;
    color: #32364e;
    padding: 0px;
    height: 31px;
    border-radius: 30px;
    /* flex: 0 1 47px; */
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-left:3px;
    

}

::v-deep(.view-sec-button:hover) {
    border-color:#32364e;
    background-color: #33cc99;
    color: #FFFFFF;
}
::v-deep(.view-sec-button:focus) {
    box-shadow: none;
    border-color: #33cc99;
}

.green-tick {
  color: #33CC99;
}

.red-tick {
  color: #e63e3e;
}


::v-deep(.portfolio-row > td) {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
} 
</style>
