<template>
    <div :class="pillMenuBarClassObject" ref="pillMenuBar">
        <div class='left-aligned-buttons-container' v-if="$slots.left || headerType === 'sub'">
            <slot name="left"></slot>
        </div>

        <Carousel v-if="enableCarousel" :key="navigationCarouselKey" :value="navigationItems.filter(keepMenuItemVisible)" :numVisible="11" :numScroll="1" 
            class='navigationCarousel' ref='navigationCarousel' :circular="false"  orientation="horizontal" 
            :showIndicators="false" :showNavigators="true" :responsiveOptions="navigationCarouselResponsive">
            <template #item="item">
                <div>
                    <Button v-if="item.data.to" :class="{active: $route.path === item.data.to, 'p-button-raised': true, 'p-button-rounded': true, 'disabled-pill' :  item.data.disabled, [ item.data.className]:  item.data.className}" >
                        <router-link :to="item.data.to" class='pill-menuitem-text'>
                            {{item.data.label}}
                        </router-link>
                    
                    </Button>
                    <Button v-if="item.data.action" :class="{active: item.data.selected, 'p-button-raised': true, 'p-button-rounded': true}"  @click="callAction(item.data)" >
                    
                        <span v-if="item.data.action"  class='pill-menuitem-text'>{{item.data.label}}</span>

                    </Button>
                </div>
            </template>
        </Carousel>

        <div v-else class='navigation' ref='navigationContainer'>
            <template v-for="item in navigationItems.filter(keepMenuItemVisible)" :key="item.label">
                <Button v-if="item.to" :class="{active: $route.path === item.to, 'p-button-raised': true, 'p-button-rounded': true, 'disabled-pill' : item.disabled, [item.className]: item.className}" >
                    <router-link :to="item.to">
                        <span v-if="item.materialIcon" class='material-symbols-outlined'>{{item.materialIcon}}</span>
                        <span :class='item.materialIcon ? "pill-menuitem-icon-text" : "pill-menuitem-text"'>{{item.label}}</span>
                    </router-link>
                    <Badge v-if="item.badge" :value="item.badge" />
                   
                </Button>
                 <Button v-if="item.action" :class="{active: item.selected, 'p-button-raised': true, 'p-button-rounded': true}"  @click="callAction(item)" >
                    
                    <span v-if="item.materialIcon" class='material-symbols-outlined'>{{item.materialIcon}}</span>
                    <span v-if="item.action" :class='item.materialIcon ? "pill-menuitem-icon-text" : "pill-menuitem-text"'>{{item.label}}</span>
                    <Badge v-if="item.badge" :value="item.badge" />
                </Button>
            </template>
            
        </div>
        
        <div class='optional-buttons-container' ref='optionalButtonsContainer' v-if="$slots.custom || headerType === 'sub'">
            <slot name="custom"></slot>
        </div>

    </div>
</template>

<script>
/*
    How this works:
        You can specify regular menu items by specifying an array of navigationItems.
        The navigation items can have a 'to' or an 'action' property but not both.
        Navigation items with a 'to' will trigger navigating to a new route.
        Navigation items with an 'action' will trigger the specified action.
        Currently all items must either have a 'to' or an 'action' you can't mix and match.
        Finally there is a 'custom' slot where you can add arbitrary components and  the only styling is that they are right aligned and the exact
        same thing with a 'left' slot but they are left aligned instead.

*/

    import Badge from 'primevue/badge';
    import Carousel from 'primevue/carousel';

    import {keepMenuItemVisible} from './';

    export default {
        name: 'PillMenuBar',
        props: {
            navigationItems: {
                type: Array,
                required: true
            },

            headerType: { 
                type: String,
                required: true
            },

            autoScrolling: {
                type: Boolean,
                default: false
            },

            enableCarousel: {
                type: Boolean,
                default: false
            }
        },
        components: {
            Carousel,
            Badge
        },
        methods: {
            keepMenuItemVisible,

            callAction(menuItem) {
               
                this.navigationItems.forEach((item) => {
                   
                    if( item === menuItem ) {
                        item.selected = true;
                    }
                    else {
                        item.selected = false;
                    }
                });
                menuItem.action();
            },

            onResize() {

                if(this.enableCarousel){
                    this.navigationCarouselKey +=1;
                    this.isScrolling = false;
                } else {
                    /* We want to reset the menu bar to it's base state (Not scrolling and it's navigation container to have it's original width), because
                    the navigation container's right property will take the value of the total width of all it's buttons plus 300 - Hence, will always 
                    be too big to be under 300px as the optional buttons container's left property will remain the same as it hasn't moved. */
                    this.$refs.navigationContainer.style.minWidth = 'unset';
                    this.$refs.navigationContainer.style.width = 'unset';
                    this.isScrolling = false;

                    this.$nextTick(() => {
                        // Measuring the distance between the navigation button container and optional buttons container
                        if (this.$refs.optionalButtonsContainer.getBoundingClientRect().left - this.$refs.navigationContainer.getBoundingClientRect().right < 300) {
                            this.$refs.navigationContainer.style.minWidth = `${this.initialWidth + 300}px`;
                            this.$refs.navigationContainer.style.width = `${this.initialWidth + 300}px`;
                            this.isScrolling = true;
                        }
                    });
                }

            }
        },

        data() {
            return {
                pmbro: null,
                obcro: null,
                isScrolling: false,
                initialWidth: null,
                /* navigationCarousel data */
                navigationCarouselKey: 0,
                navigationCarouselResponsive: [
                    {
                        breakpoint: '1500px',
                        numVisible: 9,
                        numScroll: 4
                    },
                    {
                        breakpoint: '1400px',
                        numVisible: 8,
                        numScroll: 4
                    },
                    {
                        breakpoint: '1200px',
                        numVisible: 7,
                        numScroll: 3
                    },
                    {
                        breakpoint: '1024px',
                        numVisible: 5,
                        numScroll: 3
                    },
                    {
                        breakpoint: '800px',
                        numVisible: 3,
                        numScroll: 1
                    },
                    {
                        breakpoint: '600px',
                        numVisible: 2,
                        numScroll: 1
                    },
                    {
                        breakpoint: '480px',
                        numVisible: 1,
                        numScroll: 1
                    },
                ],
            }
        },
        computed: {
            pillMenuBarClassObject() {
                return {
                    'pill-menu-bar': true,
                    'main-header': this.headerType === 'main',
                    'sub-header': this.headerType === 'sub',
                    'scrollable': this.isScrolling
                }
            }
        },

        mounted() {
            this.$nextTick(() => {
                if(!this.enableCarousel){
                    Array.from(this.$refs.navigationContainer.children).forEach((child) => {
                        this.initialWidth += child.offsetWidth;
                    })
                }

                if (!this.pmbro && this.headerType === 'main' && this.autoScrolling) {
                    this.pmbro = new ResizeObserver(this.onResize).observe(this.$refs.pillMenuBar);
                }

                if (!this.obcro && this.headerType === 'main' && this.autoScrolling) {
                    this.obcro = new ResizeObserver(this.onResize).observe(this.$refs.optionalButtonsContainer);
                }
            });
        },
        unmounted() {
            if (this.pmbro) {
                this.pmbro.unobserve(this.$refs.pillMenuBar);
            }

            if (this.obcro) {
                this.obcro.unobserve(this.$refs.optionalButtonsContainer);
            }
        },

    }
</script>

<style scoped>
    ::v-deep(*):not(.pi, .material-icons-outlined, .material-symbols-outlined) {
        font-family: "Trebuchet MS", "Verdana";
    }

    .material-symbols-outlined {
        font-size: 20px !important;
        margin-left: 16px;
        color: #32364E;
        pointer-events: none;
    }

    ::v-deep(.p-badge) {
        position: absolute;
        right: -12px;
        top: -6px;
        padding: 6px 6px;
        font-size: 8px;
        line-height: 0.6;
        height: 16px;
        background: #33CC99;
        color: #FFFFFF;
    }

    ::v-deep(.navigation .p-button.active .material-symbols-outlined),
    ::v-deep(.navigation .p-button:enabled:hover .material-symbols-outlined) {
        color: #fff;
    }

    .pill-menu-bar {
        display: flex;
        align-items: center;
    }

    .pill-menu-bar.main-header {
        padding: 16px 32px 24px;
    }

    .pill-menu-bar.sub-header {
        margin-bottom: 16px;
    }

   .navigation {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;
    }

    ::v-deep(.navigation .p-button) {
        background: #FFFFFF;
        border: 0;
        padding: 0;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        overflow: visible;
        position: relative;
    }
    ::v-deep(.navigation .p-button:last-child) {
        margin-right: 0px;
    }

    ::v-deep(.navigation .p-button a) {
        align-items: center;
        display: flex;
        padding: 0;
    }

    ::v-deep(.navigationCarousel .p-button.active),
    ::v-deep(.navigation .p-button.active) {
        background: #32364E;
        pointer-events: none;
    }

    ::v-deep(.navigationCarousel .p-button:enabled:hover),
    ::v-deep(.navigation .p-button:enabled:hover) {
        background: #32364E;
        border: 0;
    }

    ::v-deep(.navigationCarousel .p-button.purple-button__secondary),
    ::v-deep(.navigation .p-button.purple-button__secondary) {
        border: 1px solid #693BF5;
    }
    ::v-deep(.navigationCarousel .p-button.purple-button__secondary.active),
    ::v-deep(.navigationCarousel .p-button.purple-button__secondary:enabled:hover),
    ::v-deep(.navigation .p-button.purple-button__secondary.active),
    ::v-deep(.navigation .p-button.purple-button__secondary:enabled:hover) {
        background: #693BF5;
       border: 1px solid #693BF5;
    }
    ::v-deep(.navigationCarousel .p-button.purple-button__secondary:focus),
    ::v-deep(.navigation .p-button.purple-button__secondary:focus) {
        border: 1px solid #693BF5;
        box-shadow: none;
    }

    ::v-deep(.navigationCarousel .p-button .pill-menuitem-text),
    ::v-deep(.navigation .p-button .pill-menuitem-text) {
        color: #32364E;
        padding: 8px 16px;
        white-space: nowrap;
    }
    
    ::v-deep(.navigation .p-button .pill-menuitem-icon-text) {
        color: #32364E;
        padding: 8px 16px 8px 4px;
    }
    ::v-deep(.navigation .p-button.active .pill-menuitem-icon-text),
    ::v-deep(.navigation .p-button:enabled:hover .pill-menuitem-icon-text) {
        color: #FFFFFF !important;
    }

    ::v-deep(.navigationCarousel .p-button:enabled:hover .pill-menuitem-text),
    ::v-deep(.navigationCarousel .p-button.active .pill-menuitem-text),
    ::v-deep(.navigationCarousel .p-button .pill-menuitem-text:hover) ,
    ::v-deep(.navigation .p-button:enabled:hover .pill-menuitem-text),
    ::v-deep(.navigation .p-button.active .pill-menuitem-text),
    ::v-deep(.navigation .p-button .pill-menuitem-text:hover) {
        color: #FFFFFF;
    }

    .pill-menu-bar.main-header .left-aligned-buttons-container {
        display: flex;
        margin-right: auto;
        align-items: center;
    }

    ::v-deep(.left-aligned-buttons-container > *) {
        margin-right: 8px;
    }

    ::v-deep(.left-aligned-buttons-container > *:last-child) {
        margin-right: 0;
    }

    .pill-menu-bar.main-header .optional-buttons-container {
        display: flex;
        margin-left: auto;
        align-items: center;
        flex-shrink: 0;
    }

    /* ::v-deep(.action) {
        margin-left: auto;
    } */

    /* ::v-deep(.custom) {
        margin-left: auto;
    } */

   
    ::v-deep(.optional-buttons-container .p-button) {
        padding: 0.5rem 1rem;
    }

    ::v-deep(.optional-buttons-container > *) {
        margin-right: 8px;
    }

    ::v-deep(.optional-buttons-container > *:last-child) {
        margin-right: 0;
    }

   ::v-deep(.disabled-pill) {
         pointer-events: none;
         opacity: 0.3;
   }

   .pill-menu-bar.main-header.scrollable {
        overflow-x: auto;
        padding-bottom: 18px;
   }
   .pill-menu-bar.main-header.scrollable::-webkit-scrollbar {
        height: 6px;
    }
    .pill-menu-bar.main-header.scrollable::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background: #BFBFBF;    /* color of the scroll thumb */
        border-radius: 10px;       /* roundness of the scroll thumb */
    }

    .pill-menu-bar.sub-header .optional-buttons-container {
        flex: 1;
        text-align: right;
    }
    .pill-menu-bar.sub-header .left-aligned-buttons-container {
        flex: 1;
        text-align: left;
    }

    /* navigationCarousel */

    .navigationCarousel {
        overflow: hidden;
        flex-shrink: 1;
        width: 100%;
        max-width: 1200px;
    }

    ::v-deep(.navigationCarousel .p-carousel-item) {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    ::v-deep(.navigationCarousel .p-link:disabled) {
        display: none;
    }

    ::v-deep(.navigationCarousel .p-button) {
        background: #FFFFFF;
        border: 0;
        padding: 0;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }

</style>