<template>
<Dialog header="Oops!" v-model:visible="display" :modal="true" :closable="false" class='import-image-error-modal'>
    <div class='error-message'>{{message}}</div>
    <template #footer>
        <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close'/>
        <Button label="Try Again" class='accept p-button-raised p-button-rounded' @click='closeAndReupload'/>
    </template>
</Dialog>


</template>

<script>
import Dialog from 'primevue/dialog';

import EventBus from '../../event-bus';

export default {
    name: 'ImportImageErrorModal',
    emits: ['open-file-dialog'],
   
    components : {
        Dialog,
       
    },

    data() {
      return {
        display : false,
        message: null
      }
    },
    
    methods: {
        open(errorMessage) {
            this.message = errorMessage;
            this.display = true;
        },
        close() {
            this.display = false;
        },
        closeAndReupload() {
            EventBus.emit('open-file-dialog');
            this.close();
        }
    }
   
}
</script>

<style>
.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.import-image-error-modal {
    width: 340px;
    font-family: "Trebuchet MS", "Verdana";
	box-shadow: none;
}

.import-image-error-modal .p-dialog-header {
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    padding-bottom: 10px;
    padding-left: 25px;
}

.import-image-error-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

.import-image-error-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 10px 30px;
}

.import-image-error-modal .error-message {
    font-size: 16px;
    color: #a6a6a6;
}

.import-image-error-modal .p-dialog-footer {
    display: flex;
    justify-content: center;
	padding-top: 1.5rem;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
</style>
<style scoped>
.import-image-error-modal .p-dialog-footer .p-button {
    font-size: 16px;
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .import-image-error-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 10px;
 }

 .import-image-error-modal .p-dialog-footer .accept {
    border-color: #33CC99;
    padding: 10px 30px;
 }

.import-image-error-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33CC99;
 }
  .import-image-error-modal .p-dialog-footer .accept:focus {
     box-shadow: none;
     border-color: #33CC99;
 }

.import-image-error-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
 } 
 .import-image-error-modal .p-dialog-footer .cancel:focus {
     box-shadow: none;
     border-color: #32364E;
 }

</style>