<template>
    <Dialog class='snapshot-dialog' v-model:visible="display" :style="{ width: '1000px', height: 'auto' }" @show="takeSnapshot" @hide="onHide" :modal='true' :draggable='false' :dismissableMask='true'>
        <template #header>
            <div class='header'>
                <span class='dialog-title'>Snapshot</span>
            </div>
        </template>
        <LoadingSpinner :showLoading="loadingImage" text="Creating Snapshot..." textPosition="margin-top:195px;margin-left:420px;" minHeight="350px;" />
        <div contenteditable="true" style="text-align:center;" @contextmenu="onRightClick">
            <!-- <img :src="snapshotUrl" style="max-width:900px;" :onload="imgLoaded" @contextmenu="onImageRightClick" aria-haspopup="true" ref="snapshotImg" /> -->
            <Image :src="snapshotUrl" imageStyle="max-width:900px;" :onload="imgLoaded" preview />
        </div>
        <ContextMenu ref="imgContextMenu" :model="contextMenuitems" />

        <template #footer>
            <div class='snapshot-menubar'>
                <a :class="{'snapshot-menu-button': true, 'p-disabled': menuDisabled}" @click='toggleShareButtonMenu'>
                    <span class="pi pi-share-alt"></span>
                    <span class="button-label">Share</span>
                    <span class="pi pi-angle-down"></span>
                </a>
                <Menu class='filter-dropdown' ref="shareButtonMenu" :model="shareButtonItems" :popup="true" >
                    <template #item="{ item }">
                        <a class="p-menuitem-link" @click="item.command">
                            <div class='menuitem-row'>
                                <span :class="{[item.icon]: true}"></span>
                                <span class="p-menuitem-text">{{ item.label }}</span>
                            </div>
                        </a>
                    </template>
                </Menu>
                <a :class="{'snapshot-menu-button': true, 'p-disabled': menuDisabled}" @click='download'>
                    <span class="pi pi-download"></span>
                    <span class="button-label">Download</span>
                </a>
                <a :class="{'snapshot-menu-button': true, 'p-disabled': menuDisabled}" @click='toggleCopyButtonMenu'>
                    <span class="pi pi-copy"></span>
                    <span class="button-label">Copy</span>
                    <span class="pi pi-angle-down"></span>
                </a>
                <Menu class='filter-dropdown' ref="copyButtonMenu" :model="copyButtonItems" :popup="true" >
                    <template #item="{ item }">
                        <a class="p-menuitem-link">
                            <div class='menuitem-row'>
                                <span :class="{[item.icon]: true}"></span>
                                <span class="p-menuitem-text">{{ item.label }}</span>
                            </div>
                        </a>
                    </template>
                </Menu>
            </div>
        </template>
    </Dialog>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner.vue';
import html2canvas from 'html2canvas';
import Dialog from 'primevue/dialog';
import ResearchService from '../../service/ResearchService';
import Image from 'primevue/image';
import { APP_PROTOCOL } from '@/common/config';
import axios from 'axios';

import ContextMenu from 'primevue/contextmenu';

import BrowserUtils from '../../utilities/BrowserUtils';

export default {
    name: 'SnapshotDialog',
    emits:['update:displaySnapShot'],
    props: {
        displaySnapShot: {
            type: Boolean,
            required: true,
        },

        target: {
            type: Element,
            required: false,
        },
        cropWidthAmount: {
            type: Number,
            required: false,
            default: 40,
        }
    },
    components: {
        LoadingSpinner,
        Dialog,
        ContextMenu,
        Image,
    },

    data() {
        return {
            display: this.displaySnapShot,
            loadingImage: false,
            snapshotUrl: '',
            snapShotTarget: this.target,
            snapshotBlob: null,
            contextMenuitems: [
                {
                    label: 'Copy Image',
                    icon: 'pi pi-copy',
                    command: () => {
                        this.copyImageToClipboard();
                    },
                    disabled: this.menuDisabled || BrowserUtils.isFirefox(),
                },
               
                {
                    label: 'Copy Image to Note',
                    icon: 'pi pi-copy',
                    command: () => {
                        this.copyImageLinkToNote();
                    },
                    disabled: this.menuDisabled,
                },
                 {
                    label: 'Copy Image Address',
                    icon: 'pi pi-copy',
                    command: () => {
                        this.copyImageAddressToClipboard();
                    },
                    disabled: this.menuDisabled,
                },
            ],

            shareButtonItems: [
                {
                    label: 'Twitter',
                    icon: 'pi pi-twitter',
                    command: () => {
                        this.tweet();
                    },  
                },
                {
                    label: 'Facebook',
                    icon: 'pi pi-facebook',
                    command: () => {
                        this.shareOnFacebook();
                    },
                },
            ],

            copyButtonItems: [
                {
                    label: 'Image',
                    icon: 'pi pi-copy',
                    command: () => {
                        this.copyImageToClipboard();
                    },
                    disabled: BrowserUtils.isFirefox()
                    
                },
               
                {
                    label: 'Image To Note',
                    icon: 'pi pi-copy',
                    command: () => {
                        this.copyImageLinkToNote();
                    },
                    
                },
                 {
                    label: 'Image Address',
                    icon: 'pi pi-copy',
                    command: () => {
                        this.copyImageAddressToClipboard();
                    },
                    
                },
            ]
        };
    },

     updated() {
        if (this.displaySnapShot) {
            this.display = this.displaySnapShot;
        }
    },

    watch: {
        displaySnapShot(newVal, oldVal) { // eslint-disable-line
            
            this.display = newVal;
        },

        target(){
            this.snapShotTarget = this.target;
        }
    },
    computed: {
        menuDisabled() {
            return this.loadingImage;
        }
    },

    methods: {
        onRightClick(event) {
            this.$refs.imgContextMenu.show(event);
        },

        toggleShareButtonMenu(event) {
            this.$refs.shareButtonMenu.toggle(event);
        },
        toggleCopyButtonMenu(event) {
            this.$refs.copyButtonMenu.toggle(event);
        },
        onHide() {
            this.display = false;
            this.snapshotUrl = '';
            this.snapshotBlob = null;
            this.loadingImage = false;
            this.$emit('update:displaySnapShot', false);
        },

        imgLoaded() {
            this.loadingImage = false;
        },

        takeSnapshot() {

            
            if (this.target && this.display) {
               
                this.snapshotUrl = '';

                this.loadingImage = true;
                setTimeout(() => {
                    // {useCORS: true, allowTaint: true}
                    html2canvas(this.snapShotTarget).then((canvas) => {
                        //this.snapshotUrl = canvas.toDataURL();
                        canvas.toBlob((blob) => {
                            this.snapshotBlob = blob;
                        });
                        ResearchService.getSnapshotUrl(this.$store.state.users.user.userId, this.cropWidthAmount, canvas.toDataURL()).then((response) => {
                            this.snapshotUrl = APP_PROTOCOL + response.data.invrsUrl;
                        });
                    });
                }, 1000);

               
            }
        },
        copyImgUrlToClipboard() {
            var tempInput = document.createElement('input');
            tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
            tempInput.value = this.snapshotUrl;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
        },

        download() {
            this.downloadWithAxios(this.snapshotUrl, 'my-chart.png');
        },

        async copyImageToClipboard() {
            if (navigator.clipboard) {
                 const data = [new ClipboardItem({ [this.snapshotBlob.type]: this.snapshotBlob })]//eslint-disable-line
                await navigator.clipboard.write(data);
            
                this.$toast.add({ severity: 'success', summary: 'Image copied to Clipboard', life: 2000, group: 'center' });
            }
        },

        async copyImageAddressToClipboard() {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(this.snapshotUrl);
            } else {
                this.copyImgUrlToClipboard();
            }
            this.$toast.add({ severity: 'success', summary: 'Image copied to Clipboard', life: 2000, group: 'center' });
        },

        copyImageLinkToNote() {
            this.$store.commit('ADD_IMAGE_TO_NOTE', this.snapshotUrl);
            this.$toast.add({ severity: 'success', summary: 'Image copied to Note', life: 2000, group: 'center' });
        },

        onImageRightClick(event) {
            this.$refs.imgContextMenu.show(event);
        },

        downloadWithAxios(url, title) {
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    this.forceFileDownload(response, title);
                })
                .catch(() => console.debug('error occured'));
        },
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', title);
            document.body.appendChild(link);
            link.click();
        },

        tweet() {
            let url = 'https://twitter.com/intent/tweet?&text=My Portfolio ' + this.snapshotUrl + ' created on https://www.invrs.com';
            window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
        },
        shareOnFacebook() {
            let url = 'https://www.facebook.com/sharer.php?u=' + this.snapshotUrl;

            window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
        },
    },
};
</script>

<style>
.snapshot-dialog {
    font-family: 'Trebuchet MS', 'Verdana';
    border-radius: 16px;
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
}
.snapshot-dialog .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
    padding: 16px;
}
.snapshot-dialog .p-dialog-content {
    border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
    padding: 0px 16px 0px;
    overflow-y: hidden;
}
.snapshot-dialog .p-dialog-footer {
    border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
    padding: 16px;
}
</style>
<style scoped>
*:not(.pi), .p-component {
    font-family: 'Trebuchet MS', 'Verdana';
}

.snapshot-dialog .p-dialog-header .header {
    width: 100%;
    text-align: center;
}
.snapshot-dialog .p-dialog-header .dialog-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #32364e;
}

.snapshot-menubar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.snapshot-menubar .snapshot-menu-button {
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding: 8px;
    background: #FFFFFF;
    border: 1px solid #32364e;
    color: #32364e;
    margin-right: 8px;
}
.snapshot-menubar .snapshot-menu-button:last-child {
    margin-right: 0px;
}
.snapshot-menubar .snapshot-menu-button:hover {
    background: #32364e;
    color: #FFFFFF;
    cursor: pointer;
}
.snapshot-menubar .snapshot-menu-button > span {
    margin-right: 8px;
}
.snapshot-menubar .snapshot-menu-button > span:last-child {
    margin-right: 0px;
}
</style>