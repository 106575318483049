class SessionStorageUtilsImpl {

    getParsedValue(key) {
        return JSON.parse(sessionStorage.getItem(key));
    }

    getRawValue(key){
        return sessionStorage.getItem(key);
    }

}

const SessionStorageUtils = new SessionStorageUtilsImpl();
export default SessionStorageUtils;