import APIService from '@/common/APIService';

class PollingServiceImpl {

    answerPoll(pollId, answer) {
        return APIService.post(`/polls/${pollId}/vote`, answer);
    }

    closePoll(pollId) {
        return APIService.put(`/polls/${pollId}/close`);
    }

    openPoll(pollId) {
        return APIService.put(`/polls/${pollId}/open`);
    }

    getPoll(pollId) {
        return APIService.get(`/polls/${pollId}`);
    }

    getPollWinners(pollId, limit, offset) {
        return APIService.get(`/polls/${pollId}/winners?limit=${limit}&offset=${offset}`);
    }

}

const PollingService = new PollingServiceImpl();
export default PollingService;