<template>
    <div class='card'>
        <div class='top-row'>
            <div>
                <template v-if='showAvatar'>
                    <UserAvatar :imageSrc='avatar' size='md' shape="circle" 
                        :customStyle="isMobile() ? 'width: 40px; height: 40px;min-width: 40px; min-height: 40px;' : ''"/>
                        
                </template>
                
                <div class='names'>
                    <h1 class='header-text'>{{headerText}}</h1>
                    <div class="second-row">
                        <span class='sub-text'>{{subText}}</span>
                        <span class='signup-date' v-if="signupDate"> Member Since: {{signupDate}}</span>
                    </div>
                    <div class="custom-row" v-if="!isTagOrGroupPreview && !isGroup">
                        <slot name="custom"></slot>
                        
                    </div>
                    <div class="user-link" v-if="notOwnProfile && userLink">
                        <i class="pi pi-link link-icon"></i>
                        <span v-if="userLink" @click="openLinkTab">{{userLink}}</span>
                    </div>
                    <div class="user-link" v-if="!notOwnProfile && !isTagOrGroupPreview">
                        <i class="pi pi-link link-icon"></i>
                        <span v-if="userLink" @click="openLinkTab">{{userLink}}</span>
                        <span v-else @click="sendEditProfileEvent">Add a link +</span>
                    </div>
                   
                </div>
                
            </div>
            
            <div class='user-actions-container' v-if="$slots.actions">
                <slot name="actions"></slot>
                
            </div>
            
        </div>
        <template v-if="!isTagOrGroupPreview && !isGroup && groupContestAwards">
            <div class="award-row">
                    <span v-for="award in groupContestAwards" :key="award.awardId">
                        <img :src="getAwardIcon(award)" class="award-img" v-tooltip.bottom="award.contestName+': '+award.awardReason"/>
                    </span>
            </div>
        </template>
        <div class='bio-row' v-if="bioText && showBio">
            <div :class='"bio-text" + (!showFullBio && isMobile() ? " hide-text" : "")' v-html="bioText"></div>
            <a v-if="isMobile()" class="expand-link-button" @click="showHideFullBio">{{!showFullBio ? "...See more" : " See Less"}}</a>
        </div>
        
        <div v-if="$slots.content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
import UserAvatar from './UserAvatar.vue';
import BrowserUtils from '../../utilities/BrowserUtils';
import {AWARDS} from '../contests/index';

import EventBus from '../../event-bus';

export default {
    name: 'ProfileCard',
    props: {
        headerText: {
            type: String,
            required: true
        },
        subText: {
            type: String,
            default: ''
        },
        bioText: {
            type: String,
            default: ''
        },
        avatar: {
            type: String,
            default: ''
        },
        showAvatar: {
            type: Boolean,
            default: true
        },
        signupDate:{
            type: String,
            default: ''
        },
        userLink: {
            type: String,
            default: null,
        },
        notOwnProfile: {
            type: Boolean,
            required: false
        },
        showBio: {
            type: Boolean,
            default: true,
            required: false
        },
        groupContestAwards: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            showFullBio: false
        }
    },

    components: {
    UserAvatar
},
    
    computed: {
        isTagOrGroupPreview() {
            return this.$route.path.includes('/tag/') || this.$route.path.includes('/groupPreview');
        },
        isGroup() {
            return this.$route.path.includes('/groups');
        }
    },
    methods: {
        getAwardIcon(award) {
           for( const a of AWARDS ) {
                if( a.awardId == award.awardId){
                    return a.icon;
                }
            }
            return ""; 
        },
        isMobile() {
            return BrowserUtils.isMobile();
        },

        sendEditProfileEvent() {
            if(this.isGroup){
                EventBus.emit('edit-group-profile');
            } else {
                EventBus.emit('edit-profile');
            }
        },

        openLinkTab() {
            window.open(this.userLink, '_blank').focus();
        },

        showHideFullBio() {
            this.showFullBio = !this.showFullBio;
        }
    },
}
</script>

<style scoped>
*:not(.pi), ::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

.award-img {
    max-width:2em;
    max-height:2em;
    margin-right:3px;
}

.card {
    padding: 16px;
    margin-bottom: 20px; 
    border-radius: 16px;
    width: 100%;
}

.bio-row {
    display: flex;
    flex-direction: row;
    align-items:flex-end;
    margin: 4px 0px;
}

.bio-text {
    font-size: 15px;
    color: black;
    margin-right: 2px !important;
}

.hide-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    margin-right: 0px !important;
}

.expand-link-button {
    color: rgb(51, 204, 153);
    font-size: 13px;
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;
}

.second-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.custom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.user-link, .user-link a, .user-link a:hover, .user-link a:active, .user-link a:visited {
    color: #3c9;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.link-icon {
    margin-right: 4px;
}

.top-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.top-row > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 0;
}
.top-row div .names {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    min-width: 0;
    margin-left: 8px;
}

.top-row .row {
    display: flex;
}
.top-row .row:last-child {
    margin-top: 10px;
}

.header-text {
    display: block;
    font-size: 24px;
    color: #32364e;
    margin: 0;
    line-break: anywhere;
}

.signup-date {
     display: block;
    font-size: 12px;
    color: #a6a6a6;
}
.sub-text {
    display: block;
    font-size: 16px;
    color: #32364e;
    margin-right: 8px;
    line-break: anywhere;
}
.user-actions-container {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.user-actions-container > ::v-deep(*) {
    margin-right: 8px;
}
.user-actions-container > ::v-deep(*):last-child {
    margin-right: 0px;
}

@media (max-width: 760px) {
    h1{
        font-size: 1.25rem !important;
    }

    .card {
        border-radius: 0;
        padding: 16px;
        margin-bottom: 8px; 
        box-shadow: none;
    }

    .second-row {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 4px;
    }

    .top-row > div {
        align-items: flex-start;
    }

    .award-img {
        max-width:1.5em;
        max-height:1.5em;
       
    }
}
</style>