<template>
    <div class="p-avatar" :class='classWithCustomShapeObj' v-html="imageSrc" v-if='imgSrc.startsWith("<svg")'/>
    <Avatar :image="imgSrc" :class='size' :style='customStyle' :shape="shape" v-else-if='isPredefinedShape'/>  
    <Avatar :image="imgSrc" :class='classWithCustomShapeObj' :style='customStyle' v-else/>  
</template>

<script> 
import Avatar from 'primevue/avatar';

const PREDEFINED_SHAPES = ['circle', 'square'];

export default {
    name: 'UserAvatar',
    props: {
        imageSrc: {
            required: true,
            validtor: prop => typeof prop === 'string' || prop === null
        },
        size: {
            type: String
        },
        shape: {
            type: String,
            default: 'circle'
        },
        customStyle: {
            type: String
        }
    },
    components: {
        Avatar
    },

    computed: {
        imgSrc() {
            return this.imageSrc ? this.imageSrc : '/Default-Avatar.jpeg';
        },

        isPredefinedShape() { // The Avatar component itself already has built in values for shapes such as square and circle
            return PREDEFINED_SHAPES.includes(this.shape);
        },

        classWithCustomShapeObj() {
            const classObj = {
                [this.size]: true,
                'rounded-square': this.shape === 'roundedSquare',
                'circle': this.shape === 'circle'
            }

            return classObj;
        }
    },
    
}
</script>

<style scoped>
.p-avatar {
    border: 2px solid #32364e;
}
::v-deep(img) {
    background: white !important;
}
/* .p-avatar-circle {
    margin-right: 8px;
} */
.rounded-square {
    overflow: hidden;
    border-radius: 16px;
}
.circle {
    overflow: hidden;
    border-radius: 50%;
}

.xs {
    min-width: 12px;
    min-height: 12px;
}
.sm {
    min-width: 24px;
    min-height: 24px;
}
.sm-md {
    min-width: 40px;
    min-height: 40px;
}
.md {
    min-width: 48px;
    min-height: 48px;
}
.lg {
    min-width: 60px;
    min-height: 60px;
}
.xl {
    min-width: 80px;
    min-height: 80px;
}

</style>