import { ref } from "vue";

export default function infiniteScroll(noResult, emit, scroller, direction) {
    const scrollRef = ref(null);
    
    let loading = ref(false);

    const handleScroll = () => {
      let element = scrollRef.value;
      
      if (direction.value === 'down') {

        let pos = element.getBoundingClientRect().bottom;
      
        if(  pos < scroller.value.$el.getBoundingClientRect().bottom+20 ){
        
          
          if(noResult.value) {
            loading.value = false
          } else {
            //console.log("emiting infinite scroll")
            emit('infinite-scroll');
            loading.value = true;
          }
        }
      } else {
        let pos = element.getBoundingClientRect().top;
      
        if(  pos > scroller.value.$el.getBoundingClientRect().top - 20 ){

          if(noResult.value) {
            loading.value = false
          } else {
            emit('infinite-scroll');
            loading.value = true;
          }
        }
      }
    }

    return {
        handleScroll,
        scrollRef,
        loading,
        scroller,
    }
  
}