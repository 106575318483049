<template>
    
    <span class="poll-reward-label" v-if="activity.poll?.maxNumberOfWinners > 0 && activity.poll?.correctAnswerReward > 0">
        First {{ activity.poll?.maxNumberOfWinners }} {{ activity.poll?.maxNumberOfWinners == 1 ? 'user' : 'users' }} to
        answer
        correctly will be paid ${{ activity.poll?.correctAnswerReward }} in {{ activity.poll?.rewardCurrencyId ==
            CANADIAN_CURRENCY_TYPE.ID ? 'CAD' : 'USD' }} Playdough.
    </span>
    <span class="poll-reward-label" v-else-if="activity.poll?.correctAnswerReward > 0">
        Users to answer
        correctly will be paid ${{ activity.poll?.correctAnswerReward }} in {{ activity.poll?.rewardCurrencyId ==
            CANADIAN_CURRENCY_TYPE.ID ? 'CAD' : 'USD' }} Playdough.
    </span>
    <template v-if="activity.poll?.pollTypeId == YESNO_POLL.pollTypeId">
        <div class="voting-container" :style="getVotingContainerStyle(disableVotingContainer())">
            <Button class="yes-vote-button" @click="answerYesNoPoll($event, 'yes')" :loading="castingYesVote"
                :disabled="castingNoVote">
                <span class="vote-label">YES</span>
                <span class="material-symbols-outlined vote-icon">check_circle</span>
            </Button>
            <Button class="no-vote-button" @click="answerYesNoPoll($event, 'no')" :loading="castingNoVote"
                :disabled="castingYesVote">
                <span class="vote-label">NO</span>
                <span class="material-symbols-outlined vote-icon">cancel</span>
            </Button>
        </div>
        <div class="motion-status-text">
            <template v-if="(alreadyReacted(activity, 'yesVote') || alreadyReacted(activity, 'noVote'))">
                <span class='voted' v-if="alreadyReacted(activity, 'yesVote')">You answered Yes.</span>
                <span class='voted' v-else-if="alreadyReacted(activity, 'noVote')">You answered No.</span>
                <a v-if="isEducationalGroupQuestion() && showAnswerDetailsLink" class='view-results-text' @click.stop="viewAnswerDetails($event)">View Answer Details</a>
            </template>
            <template v-else-if="pollClosed">
                <span class='voted'>This poll is now closed.</span>
            </template>
            <template v-if="!postDetailsDialogSection && (pollClosed || alreadyReacted(activity, 'yesVote') || alreadyReacted(activity, 'noVote')) && !isEducationalGroupQuestion()">
                <a class='view-results-text' @click.stop="viewPollingResults()">View results</a>
            </template>
        </div>
    </template>
    <template v-else-if="activity.poll?.pollTypeId == MULTIPLE_CHOICE_POLL.pollTypeId">
        <div class="polling-container" :style="getVotingContainerStyle(disablePollingContainer())">
            <div class="poll-options">
                <div v-for="option in activity.poll.pollOptions" :key="option.pollOptionId" @click.stop="scrollToSubmitPollBtn" class="poll-option">
                    <label :for="option.pollOptionId" class="ml-2">{{ option.code }}.</label>&nbsp;
                    <RadioButton v-model="selectedOption" :inputId="option.code + option.pollOptionId" name="pizza"
                        :value="option.pollOptionId" />&nbsp;
                    <span>{{ option.title }}</span>
                </div>

            </div>
            <Button class="yes-vote-button" @click="answerMultipleChoicePoll($event)" :loading="castingPoll" ref="submitPollBtnRef"
                :disabled="castingPoll">
                <span class="vote-label">Submit</span>
                <span class="material-symbols-outlined vote-icon">check_circle</span>
            </Button>

        </div>
        <div class="motion-status-text">
            <template v-if="alreadyAnsweredPoll(activity)">
                <span class='voted'>You chose: {{ getPollAnswers(activity) }} </span>
                <a v-if="isEducationalGroupQuestion() && showAnswerDetailsLink" class='view-results-text' @click.stop="viewAnswerDetails($event)">View Answer Details</a>
            </template>
            <template v-else-if="pollClosed">
                <span class='voted'>This poll is now closed.</span>
            </template>
            <template v-if="!postDetailsDialogSection && (pollClosed || alreadyAnsweredPoll(activity)) &&  !isEducationalGroupQuestion()">
                <a class='view-results-text' @click.stop="viewPollingResults()">View results</a>
            </template>
        </div>
    </template>
    <template v-else-if="activity.poll?.pollTypeId == MULTIPLE_SELECT_POLL.pollTypeId">
        <div class="polling-container" :style="getVotingContainerStyle(disablePollingContainer())">
            <div class="poll-options">
                <div v-for="option in activity.poll.pollOptions" :key="option.pollOptionId" @click.stop="scrollToSubmitPollBtn" class="poll-option">
                    <label :for="option.pollOptionId" class="ml-2">{{ option.code }}.</label>&nbsp;
                    <Checkbox v-model="selectedOptions" :inputId="option.code + option.pollOptionId" name="pizza"
                        :value="option.pollOptionId" @click="$event.stopPropagation();" />&nbsp;
                    <label :for="option.pollOptionId" class="ml-2">{{ option.title }}</label>
                </div>

            </div>
            <Button class="yes-vote-button" @click="answerMultipleSelectionPoll($event)" :loading="castingPoll" ref="submitPollBtnRef"
                :disabled="castingPoll">
                <span class="vote-label">Submit</span>
                <span class="material-symbols-outlined vote-icon">check_circle</span>
            </Button>
        </div>
        
        <div class="motion-status-text">
            <template v-if="alreadyAnsweredPoll(activity)">
                <span class='voted'>You chose: {{ getPollAnswers(activity) }} </span>

            </template>
            <template v-else-if="pollClosed">
                <span class='voted'>This poll is now closed.</span>
            </template>
            <template v-if="!postDetailsDialogSection && (pollClosed || alreadyAnsweredPoll(activity))">
                <a class='view-results-text' @click.stop="viewPollingResults()">View results</a>
            </template>
        </div>
        
    </template>
    <div class="motion-status-text" style="font-size:9px;float:right;" v-if="hasCategoryOrDifficulty">
            <Tag severity="info" :value="pollCategory" style="margin-right:5px;font-size:9px;" v-if="hasCategory"></Tag>
            
        
            <Tag :severity="difficultyLevel.severity" :value="difficultyLevel.label"  style="margin-right:5px;font-size:9px;" v-if="hasDifficulty"></Tag>
            
    </div>
</template>

<script>
import { YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL, CANADIAN_CURRENCY_TYPE } from '../../common/constants';

import BrowserUtils from '../../utilities/BrowserUtils';
import PollingService from '../../service/PollingService';

import TimeUtils from '../../utilities/TimeUtils';
import PostUtils from '../../utilities/PostUtils';
import UserUtils from '../../utilities/UserUtils';

import EventBus from '../../event-bus';

import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import Tag from 'primevue/tag';

const difficultyLevels = [
    { label: "Easy", severity: "success" },
    { label: "Medium", severity: "warning" },
    { label: "Hard", severity: "danger"}
];

const pollCategories = [
   
    "History",
    "Ratios",
    "Concepts",
    "Analysis",
    "Trading",
    "Calculations",
    "Fraud",
];

export default {
    emits: ['on-view-details', 'post-reacted', 'open-polling-results-modal', 'on-view-answer-details'],
    props: {
        activity: {
            type: Object,
            required: true
        },
        postDetailsDialogSection: {
            type: Boolean,
            required: false
        },
        showAnswerDetailsLink: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            // CONSTANTS
            YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL,
            CANADIAN_CURRENCY_TYPE,


            likedClicked: false,
            images: [],
            postUtils: null,
            timeUtils: null,
            userUtils: UserUtils,
            textLength: 0,
            deletingAsSpam: false,
            postReactions: [],
            reactionCounts: {},
            castingYesVote: false,
            castingNoVote: false,
            selectedOption: null,
            selectedOptions: [],
            castingPoll: false,
        };
    },


    computed: {
        isPollClosed() {
            return this.activity.poll?.closedDate != null;
        },

        pollClosed() {
            if (this.activity.poll?.closedDate) {
                return true;
            }
            else {
                //return TimeUtils.isSameOrBeforeNow(this.activity.poll?.closedDate);
                return false;
            }
        },

        hasCategoryOrDifficulty() {
           
            return this.hasCategory || this.hasDifficulty;
        },

        hasCategory() {
            
            return this.activity.poll?.categoryId != null;
        },
        
        hasDifficulty() {
           
            return this.activity.poll?.difficultyId != null;
        },

        pollCategory() {
            
            return pollCategories[this.activity.poll?.categoryId-1];
        },

        difficultyLevel() {
            //console.log("diff "+this.activity.poll?.difficultyId, difficultyLevels[this.activity.poll?.difficultyId-1])
            return difficultyLevels[this.activity.poll?.difficultyId-1];
        }




    },

    name: 'QuestionVoting',
    components: {
    RadioButton,
    Checkbox,
    Tag,
},

    created() {
        this.postUtils = PostUtils;
        this.timeUtils = TimeUtils;
        // console.log(this.postDetailsDialogSection);
    },

    methods: {
        isEducationalGroupQuestion() {
             return this.activity.group?.educational;
        },
        isForceRead(){
            return !this.postDetailsDialogSection && this.activity.poll.forceRead;
        },

        disableVotingContainer(){
            return this.alreadyReacted(this.activity, 'yesVote') || this.alreadyReacted(this.activity, 'noVote') 
                || this.pollClosed;
        },

        disablePollingContainer(){
            return this.alreadyAnsweredPoll(this.activity) 
                || this.pollClosed;
        },

        getVotingContainerStyle(disableContainer){
            if(disableContainer){
                return 'pointer-events:none; opacity:.3;';
            } else {
                return this.isForceRead() ? 'pointer-events:none;' : '';
            }
        },

        getCodeForOptionId(optionId) {
            let poll = this.activity.poll;
            for (var i = 0; i < poll.pollOptions.length; ++i) {
                if (poll.pollOptions[i].pollOptionId == optionId) {
                    return poll.pollOptions[i].code;
                }
            }
            return null;
        },

        showRegularConfetti(){
            this.$confetti.start({
                particlesPerFrame: 5,
                windSpeedMax: 0
            });

            setTimeout(() => {
                this.$confetti.stop();
            }, 2500);
        },

        showDollarSignConfetti(){
            this.$confetti.start({
                particles: [
                    {
                        type: 'image',
                        size: 15,
                        url: 'assets/images/dollar-sign.png',
                    },
                ],
                particlesPerFrame: 5,
                windSpeedMax: 0
            });

            setTimeout(() => {
                this.$confetti.stop();
            }, 2500);
        },

        scrollToSubmitPollBtn() {
            if(this.postDetailsDialogSection && this.$refs.submitPollBtnRef) {
                this.$refs.submitPollBtnRef.$el.scrollIntoView({ behavior: 'smooth' });
            }
        },

        answerMultipleSelectionPoll(evt) {
            evt.stopPropagation();
            evt.preventDefault();
            //console.log('The selected options were ' + JSON.stringify(this.selectedOptions))
            if (this.selectedOptions != null && this.selectedOptions.length > 0) {
                this.castingPoll = true;

                PollingService.answerPoll(this.activity.poll.pollId, { selectedOptionIds: this.selectedOptions }).then(resp => {
                    if (resp.data.status === 'success') {
                        this.selectedOptions.forEach(option => {
                            const reaction = {
                                kind: this.getCodeForOptionId(option),
                                extra: {
                                    created_at: TimeUtils.getFormattedTime(null, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
                                }
                            };
                            this.$emit('post-reacted', this.activity, reaction);
                        })
                        this.viewPollingResults();
                    } else {
                        this.$toast.add({ severity: 'error', summary: resp.data.message, life: 4000, group: 'center' });
                        this.castingPoll = false;
                    }
                });
            }
        },

        answerMultipleChoicePoll(evt) {
            evt.stopPropagation();
            evt.preventDefault();
            //console.log('The selected option was ' + JSON.stringify(this.selectedOption))
            if (this.selectedOption != null) {
                this.castingPoll = true;
                //console.log('The selected option was ' + JSON.stringify(this.selectedOption))
                PollingService.answerPoll(this.activity.poll.pollId, { selectedOptionIds: [this.selectedOption] }).then(resp => {
                    if (resp.data.status === 'success') {
                        // console.log("answerPoll", resp);
                        
                        const poll = resp.data?.poll;

                        if(poll && poll.showAnswerOnSubmit){
                            const multipleChoiceCorrectAnswer = poll.pollOptions.find(option => option.answerIsCorrect === true);

                            if(this.selectedOption === multipleChoiceCorrectAnswer.pollOptionId){
                                if(poll.correctAnswerReward 
                                    && (poll.maxNumberOfWinners == 0 || (this.selectedOption.votes <= poll.maxNumberOfWinners))){
                                    this.showDollarSignConfetti();
                                } else {
                                    this.showRegularConfetti();
                                }
                                this.$toast.add({ severity: 'success', summary: "You got the correct answer!", life: 5000, group: 'center' });
                            }
                            else {
                                if( this.isEducationalGroupQuestion() && this.showAnswerDetailsLink ){
                                    this.$toast.add({ severity: 'info', summary: "The correct answer was "+multipleChoiceCorrectAnswer.code+".  Check the answer details to understand why.", life: 5000, group: 'center' }); 
                                }
                            }
                        }

                        const reaction = {
                            kind: this.getCodeForOptionId(this.selectedOption),
                            extra: {
                                created_at: TimeUtils.getFormattedTime(null, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
                            }
                        };
                        this.$emit('post-reacted', this.activity, reaction);
                        this.viewPollingResults();
                        EventBus.emit('post-answered', this.activity);
                    } else {
                        this.$toast.add({ severity: 'error', summary: resp.data.message, life: 4000, group: 'center' });
                        this.castingPoll = false;
                    }
                });
            }
        },

        answerYesNoPoll(evt, vote) {
            evt.stopPropagation();
            evt.preventDefault();
            const castingVote = (value) => {
                if (vote === 'yes') {
                    this.castingYesVote = value;
                } else {
                    this.castingNoVote = value;
                }
            }


            castingVote(true);

            PollingService.answerPoll(this.activity.poll.pollId, { yesNo: (vote === 'yes' ? true : false) }).then(resp => {
                if (resp.data.status === 'success') {
                    // console.log("answerPoll", resp);

                    const poll = resp.data?.poll;
                   
                    if( poll && poll.showAnswerOnSubmit){
                        if(poll && poll.showAnswerOnSubmit && poll.yesIsCorrect === (vote === 'yes')){
                            if(poll.correctAnswerReward 
                                && (poll.maxNumberOfWinners == 0 
                                || ((vote === 'yes' && poll.yesVotes <= poll.maxNumberOfWinners) || (vote === 'no' && poll.noVotes <= poll.maxNumberOfWinners)))){
                                this.showDollarSignConfetti();
                            } else {
                                this.showRegularConfetti();
                            }
                            this.$toast.add({ severity: 'success', summary: "You got the correct answer!", life: 5000, group: 'center' });
                        }
                        else {
                            if( this.isEducationalGroupQuestion() && this.showAnswerDetailsLink){
                                const correctAnswer = (poll.yesIsCorrect ? 'Yes' : 'No');
                                this.$toast.add({ severity: 'info', summary: "The correct answer was "+correctAnswer+".  Check the answer details to understand why.", life: 5000, group: 'center' }); 
                            }
                        }
                    }

                    const reaction = {
                        kind: vote === 'yes' ? 'yesVote' : 'noVote',
                        extra: {
                            created_at: TimeUtils.getFormattedTime(null, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
                            poll: poll
                        }
                    };
                    this.$emit('post-reacted', this.activity, reaction);
                    this.viewPollingResults();
                    EventBus.emit('post-answered', this.activity);
                } else {
                    this.$toast.add({ severity: 'error', summary: resp.data.message, life: 4000, group: 'center' });
                }
            });

        },

        isOwnPost() {

            if (this.activity.verb == 'repost') {
                return this.postUtils.isOwnPost(this.activity.object.actor.id);

            }
            else {
                return this.postUtils.isOwnPost(this.activity.actor.id)
            }
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        onClick(e) {
            if (!this.isInAppLink(e.target) && !this.isFeaturedGroupFeed(this.activity)) {
                if (this.activity.verb == 'repost') {
                    this.$emit('on-view-details', this.activity.object);
                } else {
                    this.$emit('on-view-details', this.activity);
                }
            }
        },

        viewAnswerDetails(e) {
            this.$emit('on-view-answer-details', e);
        },

        isQuestion() {
            return this.activity.verb.startsWith("question");
        },

        isPoll() {
            return this.activity.poll;
        },

        viewPollingResults() {
            this.$emit('open-polling-results-modal');
        },

        alreadyReacted(activity, key) {
            if(activity.own_reactions) {

            
                if (activity.own_reactions[key] === undefined) {
                    return false;
                } else {
                    return activity.own_reactions[key].length > 0;
                }
            }
            else {
                return false;
            }
        },

        alreadyAnsweredPoll(activity) {
            let poll = activity.poll;

            if (poll?.pollType == "YesNo") {
                return activity?.own_reactions?.yesVote || activity?.own_reactions?.noVote;
            }

            if( activity.own_reactions){
                for (var i = 0; i < poll.pollOptions.length; i++) {
                    let optionCode = poll.pollOptions[i].code
                    //console.log("checking for pollOption "+optionCode);
                    
                    if (activity.own_reactions[optionCode] ) {
                        let selectedOptions = activity?.own_reactions[optionCode];
                        if( selectedOptions && selectedOptions.length > 0) {
                            return true;
                        }
                        
                    }
                    
                }
            }

            

            return false;
        },

        getPollAnswers(activity) {
            let answers = '';
            let poll = activity.poll;
            for (var i = 0; i < poll.pollOptions.length; i++) {
                if (activity.own_reactions[poll.pollOptions[i].code]?.length > 0) {
                    answers = answers + poll.pollOptions[i].code + " ";
                }
            }

            return answers;
        }

    }
};
</script>
<style>
.p-component {
    font-family: 'Trebuchet MS', 'Verdana';
}
</style>
<style scoped>
::v-deep(*:not(.pi, .material-symbols-outlined)),
::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.p-tag-success {
    background-color:#33CC99;
}

.poll-reward-label {
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 10px;
}

.poll-options {
    padding-bottom: 15px;
}

.poll-option {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.poll-option:last-child {
    margin-bottom: 0;
}

.polling-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
}

.voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 8px;
}

.voting-container button,
.polling-container button {
    display: flex;
    align-items: center;
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 0px 8px;
    background: none;
}

.voting-container button:hover,
.polling-container button:hover {
    transform: rotate(0.2deg);
    transition: transform 0.25s cubic-bezier(0.5, 400, 0.5, -400);
    animation-iteration-count: infinite;
    background: none;
    border-color: none;
    box-shadow: none;
}

.voting-container .yes-vote-button,
.polling-container .yes-vote-button {
    margin-right: 12px;
}

.voting-container .yes-vote-button:hover,
.polling-container .yes-vote-button:hover {
    border-color: #33CC99;
}

.voting-container .no-vote-button:hover {
    border-color: #E63E3E;
}

.voting-container .vote-label,
.polling-container .vote-label {
    font-weight: bold;
    color: #32364e;
    font-size: 14px;
}

.voting-container .yes-vote-button .vote-icon,
.polling-container .yes-vote-button .vote-icon {
    color: #33CC99;
}

.voting-container .no-vote-button .vote-icon {
    color: #E63E3E;
}

.voting-container .vote-icon,
.polling-container .vote-icon {
    font-size: 24px;

}

v::deep(.disabled-menu) {
    pointer-events: none;
}

.question-post {
    color: #32364E;
    /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}

.poll {
    font-size: 18px;
    left: 12px;
    top: -4px;
}

.motion-post {
    color: #32364E;
    /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}

.looks-container {
    margin-left: auto;
}

.motion-status-text .view-results-text {
    color: #33CC99;
    margin-left: 4px;
}

.motion-status-text .view-results-text:hover {
    text-decoration: underline;
    color: #33CC99;
}

/* STYLING FOR MOTION ACTIVITY CARDS */
.motion-status-text {
    font-size: 11px;
}

.motion-status-text .passed {
    color: #33CC99;
    font-weight: bold;
}

.motion-status-text .failed {
    color: #E63E3E;
    font-weight: bold;
}

.motion-status-text .deadline-passed,
.motion-status-text .other {
    color: #32364E;
}

.motion-status-text .voted {
    color: #32364E;
}

.motion-status-text .material-symbols-outlined {
    color: #32364E;
    font-size: 16px;
}

.motion-status-text .voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.motion-status-text .voting-container .other {
    margin-right: 6px;
}

.motion-status-text .voting-container .vote-button {
    display: flex;
    align-items: center;
    border: 1px solid #32364E;
    border-radius: 50px;
    padding: 4px;
    background: none;
}

.motion-status-text .voting-container .vote-button:hover {
    transform: rotate(0.2deg);
    transition: transform 0.25s cubic-bezier(0.5, 400, 0.5, -400);
    animation-iteration-count: infinite;
    background: none;
}

.motion-status-text .voting-container .vote-button:focus {
    box-shadow: none;
}

.motion-status-text .voting-container .vote-button ::v-deep(.p-button-label) {
    font-weight: bold;
    color: #32364e;
    font-size: 12px;
}

</style>