import APIService from '@/common/APIService';

class MotionServiceImpl {

    submitMotion(motionId) {
        return APIService.post(`/motions/${motionId}/submit/`, {});
    }

    deleteMotion(motionId) {
        return APIService.delete(`/motions/${motionId}/delete/`);
    }

    updateMotion(motionId, motion) {
        return APIService.put(`/motions/update/${motionId}/`, motion);
    }

    saveAndSubmitMotion(req) {
        return APIService.post('/motions/submit', req);
    }

    saveAsDraft(req) {
        return APIService.post('/motions/draft', req);
    }

    executeMotion(motionId) {
        return APIService.post('/motions/'+motionId+'/execute', {});
    }

    withdrawMotion(motionId) {
        return APIService.put('/motions/withdraw/'+motionId, {});
    }

    vetoMotion(motionId, vetoNote) {
        return APIService.put('/motions/veto/'+motionId, {vetoNote:vetoNote});
    }

    getMotion(motionId) {
        return APIService.get('/motions/'+motionId);
    }

    getMotions(groupPortfolioId, offset, limit) {
        return APIService.get('/motions/portfolio/'+groupPortfolioId+'?offset='+offset+'&limit='+limit);
    }
    
    getUserMotions(offset, limit) {
        return APIService.get('/motions/user?offset='+offset+'&limit='+limit);
    }
    
    getUserVotes(offset, limit) {
        return APIService.get('/motions/user/voted?offset='+offset+'&limit='+limit);
    }
    
    castVote(motionId, vote) {
        return APIService.post(`/motions/${motionId}/vote/${vote}`, {});
    }

    rescheduleMotions() {
        return APIService.post(`/motions/reschedule`, {});
    }
}

const MotionService = new MotionServiceImpl();

export default MotionService;