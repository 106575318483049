<template>
  <div class="card allocation-card">
    <div class="header-container">
      <div class="chart-header-container">
        <h2 class="chart-header">Allocation</h2>
      </div>
      <div class="top-right-container">
        <Dropdown class="dropdown" v-model="dropdownOption" :options="allocationChartViewOptions" />
        <template v-if="viewingOwnPortfolio && moreMenuItems.length > 0">
          <MoreOptionsMenu class='more-options' @click="toggleMenu"/>
          <Menu ref="menu" :model="moreMenuItems" :popup="true" />
        </template>
      </div>
    </div>
    <div class="chart-container" id='allocation-chart-container'>
      <Chart v-if="!noAllocationData" type="doughnut" :data="chartData" :options="options" />
      <p class="no-allocation-text" v-else>No holdings yet.</p>
    </div>
  </div>

  <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" v-if="viewingOwnPortfolio"/>
</template>

<script>
import Chart from 'primevue/chart';
import Dropdown from 'primevue/dropdown';

import MoreOptionsMenu from '../../menu/MoreOptionsMenu.vue';
import SnapshotDialog from '../../modal/SnapshotDialog.vue';

export default {
  name: 'AllocationChart',
  components: {
    Chart,
    MoreOptionsMenu,
    SnapshotDialog,
    Dropdown
  },
  props: {
    sectorLabels: {
      required: true,
    },
    sectorValues: {
      required: true,
    },
    assetLabels: {
      required: true,
    },
    assetValues: {
      required: true,
    },
    showInfoIcon: {
      default: true
    },
    viewingOwnPortfolio: {
      type: Boolean,
      required: true
    },
    holdingLabels: {
      required: true,
    },
    holdingValues: {
      required: true,
    },
    allowSnapshots:{
      required: false,
      default: true
    }

  },

  data() {
    return {
      allocationChartViewOptions: ['Holdings', 'Sector', 'Asset'],
      dropdownOption: 'Holdings',
      chartData: {
        labels: this.holdingLabels,
        datasets: [
          {
            data: this.holdingValues,
            backgroundColor: ["#32364E", "#3C9", "#693BF5", "#e63e3e", "rgb(83, 103, 255)", "rgb(255, 189, 83)", "#f2f4fa", "#000", "brown", "orange" ],
            hoverBackgroundColor: [],
          }
        ]
      },
      options: {
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                font: {
                  family: 'Trebuchet MS',
                  size: 12,
                }
              }
            },
          }
        },

      

      snapshotTarget: null,
      displaySnapshot: false
    }
  },

  computed: {

    noAllocationData() {
      return this.sectorValues.length === 0 && this.assetValues.length === 0;
    },
    moreMenuItems() {
      if( this.allowSnapshots) {
        return [
          {
              label: 'Take Snapshot',
              icon: 'pi pi-camera',
              command: () => {
                this.openSnapshotDialog();
              }
          }
        ];
      }
      else {
        return [];
      }
    }
  },

  watch: {
    dropdownOption(val) {
      if (val === 'Sector') {
        this.chartData.labels = this.sectorLabels;
        this.chartData.datasets[0].data = this.sectorValues;
      } else if (val === 'Asset') {
        this.chartData.labels = this.assetLabels;
        this.chartData.datasets[0].data = this.assetValues;
      } else if (val === 'Holdings') {
        this.chartData.labels = this.holdingLabels;
        this.chartData.datasets[0].data = this.holdingValues;
      }
    }
  },

  methods: {
    chartOptionClickHandler() {
      if (this.sectorOrAsset === "Sector") {
        this.chartData.labels = this.sectorLabels;
        this.chartData.datasets[0].data = this.sectorValues;
      } else if (this.sectorOrAsset === "Asset") {
        this.chartData.labels = this.assetLabels;
        this.chartData.datasets[0].data = this.assetValues;
      }

      this.chartKey++;
    },

    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    openSnapshotDialog() {
      this.snapshotTarget = document.getElementById('allocation-chart-container');
      this.displaySnapshot = true;
    },
  },
}
</script>

<style scoped>
.chart-header-container {
  display: flex;
  align-items: center;
}

.chart-header {
  font-family: 'Trebuchet MS';
	font-weight: bold;
	color: #32364e;
	font-size: 24px;
  margin-bottom: 0;
  margin-right: 4px;
}

.chart-container {
  width: 100%;
  margin: auto auto;
  margin-bottom: 8px;
}

.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
}

.top-right-container {
  display: flex;
  align-items: flex-start;
}

.no-allocation-text {
  font-family: "Trebuchet MS";
  font-size: 16px;
  color: #999;
}

.dropdown {
  box-shadow: none !important;
  max-width: 103px;
  padding: 0 2px !important;
  margin-right: 4px;
}

::v-deep(.p-dropdown-label) {
  font-size: 12px !important;
}

.card {
  margin-bottom: 24px;
}

::v-deep(.allocation-toggle .p-highlight),
::v-deep(.allocation-toggle .p-button:hover), 
::v-deep(.allocation-toggle .p-button:focus) {
  background: #32364E;
  border-color: #32364E;
  color: #FFFFFF;
  box-shadow: none;
}

::v-deep(.allocation-toggle.p-selectbutton .p-button.p-highlight) {
  background: #32364E;
  color: #FFFFFF; 
  pointer-events: none;
  border-radius: 2rem;
  z-index: 2;
}

::v-deep(.allocation-toggle.p-selectbutton .p-button ) {
  border-color: #32364E;
  /* padding-left: 15px; */
  /* padding-right: 15px; */
}

::v-deep(.allocation-toggle.p-selectbutton .p-button:first-of-type ) {
  left: 13px;
}

::v-deep(.allocation-toggle.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
  border-radius: 2rem 0 0 2rem;
}

  ::v-deep(.allocation-toggle.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
  border-radius: 0 2rem 2rem 0;
}

::v-deep(.allocation-toggle.p-selectbutton .p-button:not(.p-highlight):hover) {
  background: #32364E;
  border-color: #32364E;
  color: #FFFFFF; 
}

::v-deep(.allocation-toggle .p-selectbutton .p-button-label) {
  padding: 0.1rem .5rem;
}

::v-deep(.allocation-toggle span) {
  font-size: 12px;
}
</style>
