<template>
    <div :class="commentCardClass">
        <template v-if='!summary'>
            <div class='comment-row'>
                <UserAvatar :imageSrc="comment.user.data.avatar" size="sm" customStyle='flex: 0 1 auto;margin-right:3px;' @click='postUtils.goToAnalystProfile(comment.user.data.name, $event), closePostDialog()'/>
                <div class='comment-content'>
                    <div class='names-bar'>
                        <span class='full-name' @click='postUtils.goToAnalystProfile(comment.user.data.name, $event), closePostDialog()'>{{stringUtils.fullName(comment.user.data)}}</span>
                        <span class='username' @click='postUtils.goToAnalystProfile(comment.user.data.name, $event), closePostDialog()'>@{{comment.user.data.name}}</span>
                        <span class='time-posted'> | {{timeUtils.getTimeFromNow(commentCreationDate)}}</span>
                        <MoreOptionsMenu class="more-options"  @click='toggleFollowMenu'/>
                        <Menu ref="menu" :model="commentMenuItems" :popup="true" />
                    </div>
                    <div :id="'comment-text-' + comment.id" class='comment-text' v-html="comment.data.content ? comment.data.content : ''"></div>
                    <div class='comment-activity-bar'>
                        <div class='likes-container'>
                            <i :class="{'pi pi-thumbs-up':true, 'liked': isAlreadyLiked(), 'own-comment': postUtils.isOwnPost(comment.user_id)}" @click="onLikeClicked"/> {{ postUtils.hasLikeCount(childrenCounts) ? childrenCounts.like : '' }}
                        </div>
                        <div class='comments-container' v-if='level < 3'>
                            <i class="pi pi-comment" @click="onCommentClicked"/> {{ postUtils.hasCommentCount(childrenCounts) ? childrenCounts.comment : '' }}
                        </div>
                    </div>
                    <div class='sub-comment-bar' v-if='displayCommentEditor'>
                        <UserAvatar :imageSrc="users.user.analyst.avatar" @click='postUtils.goToAnalystProfile(users.user.analyst.avatar, $event), closePostDialog()'/>
                        <CommentEditor @on-post="onPost" @cancel-post="onCancel" ref="commentEditor" :cancelable="true"/>
                    </div>
                </div>
            </div>
            <div class='child-comments' :style="childCommentsStyleObject">
              <LoadingSpinner :loading='childCommentsLoading' v-if='childCommentsLoading'/>
                <div v-show='showChildComments && childComments && childComments.length > 0'>
                    <CommentList :origActivty="origActivity" :commentList="childComments" :activityForeignId="feedPostId" v-if='childComments.length > 0 && !childCommentsLoading' :commentLevel='level + 1' :groupOwnerId="groupOwnerId"/>
                </div>
            </div>
            <span class='reply-thread-toggle-text show'  @click='viewNextComments()' v-if='offsetLevel <= childrenCounts.comment && childrenCounts.comment - offsetLevel !== 0'>{{viewMoreCommentsLabel}}</span>
            <span :class="{'reply-thread-toggle-text': true, 'collapse': showChildComments, 'show': !showChildComments}"  @click='toggleCommentsDisplay()' v-else-if='childrenCounts.comment - offsetLevel === 0'>{{entireReplyThreadLabel}}</span>
        </template>
        <template v-else>
            <span class='full-name' @click='postUtils.goToAnalystProfile(comment.user.data.name, $event), closePostDialog()'>{{stringUtils.fullName(comment.user.data)}}</span> <span class='username' @click='postUtils.goToAnalystProfile(comment.user.data.name, $event), closePostDialog()'>@{{comment.user.data.name}}</span><span class='time-posted'> | {{timeUtils.getTimeFromNow(commentCreationDate)}}</span>
            <span class='reply-count' v-if="hasReplies"> | {{ replyLabel}}</span>
            <div class='comment-text' v-html="comment.data.content ? comment.data.content : ''"></div>
        </template>
    </div>
    <ImageDialog ref='imageDialog'/>
    <ConfirmModal  headerText='Delete this comment?' confirmButtonLabel='Delete' @confirmed="deleteCommentConfirmed" @cancelled="deleteCommentCancelled" ref='confirmDeleteModal' />
</template>

<script>
import CommentEditor from '../feed/CommentEditor';
import CommentList from './CommentList';
import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../../components/menu/MoreOptionsMenu';
import UserAvatar from '../../components/profile/UserAvatar';

import FeedService from '../../service/FeedService';
import StringUtils from '../../utilities/StringUtils';
import PostUtils from '../../utilities/PostUtils';
import TimeUtils from '../../utilities/TimeUtils';

import { mapState } from 'vuex';
import EventBus from '../../event-bus';
import ConfirmModal from '../modal/ConfirmModal.vue';
import UserUtils from '../../utilities/UserUtils';
import ImageDialog from '../feed/ImageDialog';

export default {
    name: 'CommentCard',
    props: {
        groupOwnerId: {
            type: Number
        },
        feedPostId: {
            type: String
        },
        comment: {
            type: Object,
            required: true,
        },
        summary: {
            type: Boolean,
            default: false,
        },
        commentLikes: {
            type: Array,
            required: false,
            default: new Array(),
        },
        depthLevel: {
            type: Number,
            default: 1
        },
        origActivity:{
            required:false
        }
    },
    emits: ['comment-liked', 'comment-unliked', 'comment-posted', 'cancel-other-pending-comments', 'close-post-dialog'],
    data() {
        return {
            likedClicked: false,
            // comment: this.commentData,
            displayCommentEditor: false,
            childComments: [],
            level: this.depthLevel,
            childCommentsLoading: false,
            offsetLevel: 0,
            showChildComments: true,

            postUtils: null,
            stringUtils: null,
            timeUtils: null
        };
    },

    components: {
        CommentEditor,
        CommentList,
        LoadingSpinner,
        MoreOptionsMenu,
        ConfirmModal,
        ImageDialog,
        UserAvatar
    },

    created() {
        this.postUtils = PostUtils;
        this.stringUtils = StringUtils;
        this.timeUtils = TimeUtils;

        if (this.level <= 1) {
             this.childCommentsLoading = true;
            this.getMax10ChildComments(this.offsetChild);
        }
        
    },

    computed: {
        ...mapState(['users']),
        commentCardClass() {
            const levelClassName = `level-${this.level}`;

            return {
                'comment-card': true,
                [levelClassName]: true
            }
        },

        childCommentsStyleObject() {
            return {
                'marginLeft': `${(this.level + 1) * 16}px`
            }
        },

        childrenCounts() {
            if (this.comment.children_counts) {
                return this.comment.children_counts;
            } else {
                return this.comment.childrenCounts;
            }
        },

        viewMoreCommentsLabel() {
            if ((this.offsetLevel + 10) < this.childrenCounts.comment ) {
                return 'View 10 more comments';
            }   
            return `View ${this.childrenCounts.comment - this.offsetLevel} more comments`;
        },
        entireReplyThreadLabel() {
            return this.showChildComments ? 'Collapse entire reply thread' : 'Show entire reply thread';
        },
        offsetChild() {
            return (this.childComments.length === 0) ? 0 : this.childComments[this.childComments.length - 1].id;
        },

        commentMenuItems() {
            return this.getCommentMenuItems();
        },

        commentCreationDate() {
            return this.comment.created_at ? this.comment.created_at : this.comment.extra.created_at
        },

        hasReplies() {
            const replies = this.childrenCounts.comment;
            return replies && replies > 0;
        },

        replyLabel() {
            return this.childrenCounts.comment=== 1 ? '1 reply' : `${this.childrenCounts.comment} replies`;
        }
    },
    mounted() {
        
        
        this.$nextTick( () => {
            let commentsSection = document.getElementById(`comment-text-`+this.comment.id);
            if (commentsSection) {
                for(let i = 0; i < commentsSection.childNodes.length; i++) {
                    let children = commentsSection.childNodes[i];

                    Array.from(children.childNodes).forEach((node) => {
                       
                        if (node.tagName === 'IMG') {
                            
                            node.addEventListener('click', () => {
                                this.$refs.imageDialog.open(node.src, node.height > node.width);
                            });
                        }
                    });
                }
            }
        });
        
    },
    methods: {
        closePostDialog() {
            EventBus.emit('cancel-post-dialog')
        },
        toggleFollowMenu(event) {
            this.$refs.menu.toggle(event);
        },
        toggleCommentsDisplay() {
            this.showChildComments = !this.showChildComments;
        },

        onCancel() {
            this.displayCommentEditor = false;
            this.$refs.commentEditor.resetEditor();
        },

        deleteComment() {
            this.$refs.confirmDeleteModal.open();
        },

        deleteCommentConfirmed() {
           
            FeedService.deleteComment(this.comment).then( resp => {
               
                if( resp.data.status == "success"){
                   
                  EventBus.emit('comment-deleted');
                    
                }
                else {
                    //
                }
            })
        },

        deleteCommentCancelled() {

        },

        isGroupOwner(groupOwnerId) {
            return UserUtils.isUser(groupOwnerId);
        },

        canDelete(user_id) {
            if( this.postUtils.isOwnPost(user_id) ) {
                return true;
            }
            else if (UserUtils.userIsFeedModerator() || UserUtils.userIsAdmin() ) {
                return true;
            }
            else {
                return (this.origActivity && this.origActivity.group && UserUtils.isGroupOwnerAdminOrMod(this.origActivity.group.groupOwnerId,this.$store.getters['users/groupRoleList'])) ;
            }
            
        },

        getCommentMenuItems() {
            const user_id = this.comment.user_id;

            //console.log("getting follow menu item  and already follows = " + this.alreadyFollows)
            let menuItems = [];
            
            if (this.postUtils.alreadyFollowsUser(user_id)) {
                menuItems.push( {
                    label: 'Unfollow',
                    icon: 'pi pi-users',
                    command: () => {
                        this.unfollow();
                    },
                });
            } else if(!this.postUtils.isOwnPost(user_id) ){
                menuItems.push( {
                    label: 'Follow',
                    icon: 'pi pi-users',
                    command: () => {
                        this.follow();
                    },
                });
            }

            if (this.canDelete(user_id)) {
                menuItems.push( {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.deleteComment();
                    },
                });
            }  
            return menuItems;
        },

        async unfollow() {
            await FeedService.unfollowUser(this.comment.user.data.name).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'You are no longer following ' + this.comment.user.data.name, detail: '', life: 2000, group: 'tc' });
                    delete this.$store.state.users.user.analyst.followed.followedAnalysts[this.comment.user_id];

                    EventBus.emit('followed-changed');
                }
            });
        },

        async follow() {
            await FeedService.followUser(this.comment.user.data.name).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'You are now following ' + this.comment.user.data.name, detail: '', life: 2000, group: 'tc' });
                    this.$store.state.users.user.analyst.followed.followedAnalysts[this.comment.user_id] = { analystId: this.comment.user_id };

                    EventBus.emit('followed-changed');
                }
            });
        },

        async onPost(childComment) {
            await FeedService.postCommentOnComment(this.comment, childComment).then( resp => {
                if( resp.data.status == "success") {
                    this.$emit('comment-posted', { origComment: this.comment, reaction: resp.data.reaction});
                    EventBus.emit('nested-comment-posted');
                    this.onCancel();

                    if ((this.offsetLevel + 1) === this.childrenCounts.comment) {
                        this.childCommentsLoading = true;
                        this.getAddedChildComment(this.offsetChild);
                    }
                }
                else if( resp.data.message == 'Comments disabled'){
                    this.$toast.add({ severity: 'error', summary: 'Comments are currently disabled for this group feed.', life: 3000, group: 'center' });
                }
                else {
                    console.log("error posting comment on comment:"+resp.data.message);
                }
            });
        },

        viewNextComments() {
            this.getMax10ChildComments(this.offsetChild);
        },

        onLikeClicked() {
            if (!this.likedClicked) {
                this.likedClicked = true;
                if (!this.postUtils.isOwnPost(this.comment.user_id)) {
                    
                    if( !this.isAlreadyLiked() ) {
                        FeedService.likeComment(this.comment).then((resp) => {
                            if (resp.data.status == 'success') {
                                // will not be success if user already liked comment
                                this.$emit('comment-liked', { comment:this.comment, reaction: resp.data.reaction});
                                this.likedClicked = false;
                                EventBus.emit('track-like-comment', this.comment);
                            }
                        });
                    }
                    else {
                        
                        FeedService.unlikeComment(this.getExistingLike().reactionId).then((resp) => {
                            if (resp.data.status == 'success') {
                                // will not be success if user already liked comment
                                this.$emit('comment-unliked', this.comment);
                                this.likedClicked = false;
                                 EventBus.emit('track-unlike-comment', this.comment);
                            }
                        });
                    }
                }
            }
        },

        getExistingLike() {
            let existing = this.commentLikes.filter(e => e.relatedReactionId === this.comment.id);
            if( existing.length > 0 )  {
                return existing[0];
            }
            else {
                return null;
            }
        }, 

        isAlreadyLiked() {
            return this.commentLikes.some(e => e.relatedReactionId === this.comment.id);
           
        },

        onCommentClicked() {
            if (!this.displayCommentEditor) {
                this.displayCommentEditor = true;
            }
        },
        
        async getMax10ChildComments(childId) {
            await FeedService.getChildComments(this.comment, childId).then(resp => {
                const comments = resp.results;
                this.offsetLevel += comments.length;
                
                this.childComments = this.childComments.concat(comments)
                this.childCommentsLoading = false;
            });
        },
        async getAddedChildComment(childId) {
            await FeedService.getChildComments(this.comment, childId).then(resp => {
                let comments = resp.results;
                this.offsetLevel++;

                this.childComments = this.childComments.concat(comments[comments.length - 1])
                this.childCommentsLoading = false;
            });
        },
    },
};
</script>

<style  scoped>
::v-deep(*:not(.pi)), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}

/* ::v-deep(figure > img) {
    max-width: 100%;
    min-width: 100%;
} */

.full-name {
    font-weight: bold;
    color: #32364e;
}
.reply-count {
    font-size: 12px;
    color: #33CC99;
}
.time-posted ,
.username {
    font-size: 12px;
    color: #a6a6a6;
}
.comment-text {
    color: black;
}

::v-deep(.comment-text > p > img) {
    max-width: 10rem;
}

::v-deep(.comment-text a) {
    font-weight: bold;
}
::v-deep(.comment-text .analyst-mention) {
    color: #693BF5;
}
::v-deep(.comment-text .security-mention) {
    color: #33CC99;
}

::v-deep(a.mention),
.username:hover,
.full-name:hover,
.p-avatar:hover {
    cursor: pointer;
}

/* POST DETAILS DIALOG STYLING */
.comment-card.level-1 {
    margin-bottom: 16px;
}
/* .comment-card.level-2,
.comment-card.level-3{
    margin-bottom: 12px;
} */
.comment-card {
    padding-bottom: 10px;
}

.reply-thread-toggle-text.collapse {
    color: #E63E3E;
}
.reply-thread-toggle-text.show {
    color: #33CC99;
}
.reply-thread-toggle-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.comment-row {
    display: flex;
    width: 100%;
    
}

/* .comment-card.level-1 > .comment-row {
    margin-bottom:12px;
} */

.comment-row .comment-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom:12px;
}

.comment-row .comment-content .names-bar {
    display: flex;
    width: 100%;
}

.comment-row .comment-content .names-bar .more-options {
    align-self: flex-start;
    margin-left: auto;
    padding: 0px;
}

.comment-row .comment-content .names-bar .full-name {
    margin-right: 8px;
}

.comment-row .comment-content .comment-activity-bar {
    display: flex;
    margin-top: 8px;
    align-items: center;
}
.comment-row .comment-content .comment-activity-bar [class*='container']  {
    display: flex;
    color: #32364e;
    align-items: center;
}
.comment-row .comment-content .comment-activity-bar .likes-container {
    margin-right: 16px;
}
.comment-row .comment-content .comment-activity-bar [class*='container'] i {
    font-size: 16px;
    margin-right: 8px;
}
.comment-row .comment-content .comment-activity-bar [class*='container'] i.liked,
.comment-row .comment-content .comment-activity-bar [class*='container'] i:not(.own-comment):hover {
    cursor: pointer;
    color: #33CC99;
}

.comment-row .comment-content .comment-text {
    margin-top: 4px;
}

.comment-row .comment-content .sub-comment-bar {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

::v-deep(.comment-row .comment-content .sub-comment-bar .comment-editor-bar .comment-editor-container) {
    margin-right: 8px;
}
.comment-card.level-1 ::v-deep(.comment-row) .comment-content .sub-comment-bar .comment-editor-bar .comment-editor-container {
    width: 445px;
}
.comment-card.level-2 ::v-deep(.comment-row) .comment-content .sub-comment-bar .comment-editor-bar .comment-editor-container {
    width: 415px;
}

.comment-card.level-1 ::v-deep(.comment-row) .comment-content .sub-comment-bar .comment-editor-bar div[role='application'] {

    width: 100%;/*445px;*/
}
.comment-card.level-2 ::v-deep(.comment-row) .comment-content .sub-comment-bar .comment-editor-bar div[role='application'] {

    width: 100%;/*415px;*/
}
::v-deep(.comment-row .comment-content .sub-comment-bar .comment-editor-bar div[role='textbox']) {
    min-height: 30px;
}
::v-deep(.comment-row .comment-content .sub-comment-bar .comment-editor-bar .ck.ck-editor__editable_inline) {
    font-size: 14px;
}
::v-deep(.comment-row .comment-content .sub-comment-bar .comment-editor-bar .ck-placeholder) {
    line-height: 1.8;
}

::v-deep(.comment-row .comment-content .sub-comment-bar .comment-editor-bar .ck.ck-dropdown .ck-dropdown__panel[class*=ck-dropdown__panel]) {
    left: -100px;
}

::v-deep(.comment-text img:hover){
    cursor: pointer;
}

/* ::v-deep(.sub-comment-bar .post-comment-button ) {
    font-size: .7rem;
}
::v-deep(.sub-comment-bar  .cancel-comment-button) {
    font-size: .7rem;
} */

/* .like--float {
    opacity: 0;
    margin-left: -10px;
}
.up {
    margin-left: -10px;
    -webkit-animation: slide-out-top-card 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-top-card 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-out-top-card {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    99% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-out-top-card {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
} */

@media(max-width: 760px) {
    .sub-comment-bar .p-avatar{
        align-self: flex-start;
        /* margin-right: rem; */
        margin-top: 0.5rem;
    }
}
</style>
