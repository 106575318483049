<template>
    <Dialog class='post-likes-list-modal' v-model:visible="display" :modal="true" :draggable="false" :dismissableMask='true' header="Liked By:" @show="onShown">
        <LoadingSpinner :showLoading="initLoading"/>
        <infinite-scroll @infinite-scroll="loadMoreUsers" message="" :noResult="noMoreUsers" :style='scrollStyle' v-if='!initLoading'>
            <UserListItem v-for="user in users" :key="user.analystId" :user='user'>
                <template #actions>
                    <FollowButton :followTarget="user" :confirmUnfollow="false" :followVal="user.activityStats.following" v-if='!userUtils.isUser(user.userId)'/>
                </template>
            </UserListItem>
            <LoadingSpinner :showLoading="loading" minHeight='100px'/>
        </infinite-scroll>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import FollowButton from '../button/FollowButton.vue';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import UserListItem from '../list/UserListItem.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';

import FeedService from '../../service/FeedService';
import UserUtils from '../../utilities/UserUtils';

export default {
    name: 'PostLikesListDialog',
    components: {
        Dialog, InfiniteScroll, UserListItem, LoadingSpinner, FollowButton
    },

    data() {
        return {
            display: false,
            initLoading: false,
            loading: false,
            noMoreUsers: false,
            users: [],
            scrollStyle: "width: 100%; max-height: 100%; height: calc(80vh - 60px);",
            feedPostId: null,
            userUtils: UserUtils
        }
    },

    methods: {
        open(activity) {
            
            if( activity.verb == 'repost') {
                this.feedPostId = activity.object.foreign_id;
            }
            else {
                this.feedPostId = activity.foreign_id;
            }
            this.display = true;
        },

        onShown() {
            this.initLoading = true;
            this.loading = false;
            this.users = [];
            this.fetchUsers(null, 10, 0);
        },

        fetchUsers(query, limit, offset) {
            FeedService.queryPostLikers(this.feedPostId, query, limit, offset).then((res) => {
                this.loading = false;
                this.initLoading = false;
                const analysts = res.data.analysts;

                if (analysts.length > 0) {
                    const results = this.users.concat(analysts);

                    this.users = results;

                    this.noMoreUsers = false;
                } else {
                    this.noMoreUsers = true;
                }
            })
        },

        async loadMoreUsers() {
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreUsers) {
                    this.fetchUsers(null, 10, this.users.length);
                } else {
                    this.loading = false;
                }
            }
        },
    }
}
</script>

<style>

.post-likes-list-modal {
    font-family: "Trebuchet MS", "Verdana";
	box-shadow: none;
    max-height: 100vh;
    width: 700px;
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

.post-likes-list-modal .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
    border-bottom: 1px solid #F2F2F2;
    padding: 16px;
}

.post-likes-list-modal .p-dialog-content {
    overflow: hidden;
    padding: 0px;
    border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

@media (max-width: 760px) {
    .post-likes-list-modal {
        width: 100% !important;
        height: 80vh;
        border-radius: 0px !important;
    }
    .post-likes-list-modal .p-dialog-header {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }    
    .post-likes-list-modal .p-dialog-content {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .post-likes-list-modal .p-dialog-footer {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 16px;
        width: 100% !important;
    }

}
</style>

<style scoped>
.post-likes-list-modal .p-dialog-content .p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    overflow: auto;
    width: 100%;
    height: 100%;
}

.post-likes-list-modal .p-dialog-content .p-scrollpanel ::v-deep(.p-scrollpanel-content::-webkit-scrollbar) {
    display: none;
}
</style>
