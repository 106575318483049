<template>
    <div class='comment-editor-bar'>
       
            <vue-editor id="commentEditor" ref="commentEditor" v-model="comment" :editorOptions="createFeedCommentEditorSettings('#postDetailsDialog .status-container')"  :customModules="editorModules" 
                                 placeholder="Add a comment..."/>
        
        <div :class="(enabled? 'button-container' : 'button-container button-container-disabled')" >
            <Button icon="pi pi-times" class='p-button-raised cancel-comment-button' @click='cancelComment' v-if='cancelable'></Button>
            <Button class="p-button-raised post-comment-button" @click="postComment" :disabled="comment?.length == 0">Post</Button>
        </div>
    </div>
    
</template>

<script>



import { VueEditor, Quill } from "vue3-editor";
import { createFeedCommentEditorSettings, feedCommentEditorModules,  gatherMentionsAndMedia} from "../editor/config.js";
//import EventBus from '../../event-bus.js';

export default {
    emits: ['on-post', 'cancel-post'],

    props: {
        cancelable: {
            type: Boolean,
            default: false
        },
        enabled: {
            type: Boolean,
            default: true
        }
    },
    components: {
        VueEditor,
        Quill, //eslint-disable-line
    },

    data() {
        return {
            comment: '',

          

            isActive: false,
            renderedEditor: null,
            createFeedCommentEditorSettings: createFeedCommentEditorSettings,
            editorModules: feedCommentEditorModules,
            
        };
    },
   

    methods: {
        postComment() {
          
            let mentionsAndMedia = gatherMentionsAndMedia(this.$refs.commentEditor);
            this.$emit('on-post', { comment: { content:this.comment}, mentions: mentionsAndMedia.mentions, mediaUrls: mentionsAndMedia.mediaUrls });
            
            
        },
        cancelComment() {
            this.resetEditor();
            this.$emit('cancel-post');
        },
       

        resetEditor() {
            this.isActive = false;
            this.comment = '';
        },
    },
};
</script>

<style scoped>
::v-deep(.ql-toolbar) {
  /* position: absolute;
  bottom: 0;
  width: 100%; */
  /* transform: translateY(100%); */
  float:right;
  /* border-left-width: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 16px; */
  /* height: 80px; */
  border: none !important;

}

.comment-editor-bar {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 0 1 auto;
}

::v-deep(.ql-editor) {
    /* padding: 2px; */
    /* border-bottom-right-radius: 0 !important; */
    /* overflow:  hidden !important; */
    /* max-height: 79px; */
    padding: 5px 8px;
    min-height: 35px;
    max-height: 80px;
    overflow: auto;
}
::v-deep(.ql-editor::-webkit-scrollbar-track)
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	/* border-radius: 10px; */
	/* background-color: #F5F5F5; */
     cursor: pointer;
    
}

::v-deep(.ql-editor::-webkit-scrollbar)
{
	width: 12px;
    cursor: pointer;
	/* background-color: #F5F5F5; */
}

::v-deep(.ql-editor::-webkit-scrollbar-thumb)
{
	/* border-radius: 10px; */
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
	/* background-color: #D62929; */
      cursor: pointer;
}


::v-deep(#emoji-palette){
    top: unset !important;
    position: fixed !important;
    bottom: 30px;
}

::v-deep(.ql-container) {
    margin-right: 16px; 
    padding: 0px 8px; 
    border: 1px solid #BFBFBF !important; 
    /* border-right: none; */
    /* background: #F2F4FA; */
    border-radius: 16px;
    /* border-bottom-right-radius: 0px !important; */
    /* border-right-width: 0px !important; */
    /* border-bottom-left-radius: 0px; */
    width: 85%;
    /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);  */
    /* position: relative; */
   
   
   /* max-height:80px; */
    overflow: hidden;
}
::v-deep(.quillWrapper) {
    width:100%;
    margin-left: 3px;
    
}
::v-deep(.ql-container:focus-within) {
    /* border-color:#33CC99; */
}

/* .comment-editor-container {
    margin-right: 16px; 
    padding: 0px 8px; 
    border: 2px solid #BFBFBF; 
    background: #F2F4FA;
    border-radius: 16px;
    width: 100%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); 
    position: relative;
}
.comment-editor-container:focus-within {
    border-color:#33CC99;
} */
::v-deep(.p-button) {
    padding: 4px 8px;
    background: #FFFFFF;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    color: #32364e;
}
::v-deep(.post-comment-button) {
    border: 1px solid #33CC99;
    
}
::v-deep(.post-comment-button:hover) {
    border-color: #33CC99;
    color: #FFFFFF;
    background: #33CC99;
}

::v-deep(.cancel-comment-button) {
    border: 1px solid #E63E3E;
    margin-right: 8px;
}
::v-deep(.cancel-comment-button:hover) {
    border-color: #E63E3E;
    color: #FFFFFF;
    background: #E63E3E;
}

::v-deep(div[role='toolbar']) {
    /* display: none !important; */
    /* width: 20px !important; */
    float: right;
    border: none;
}

::v-deep(div[role='application']) {
    /* width: 0px;
    flex: 1 1 460px; */
    width: 520px;
    margin-right: 0px;
}

::v-deep(.ck.ck-editor__main > .ck-editor__editable) {
    /* background: #F2F4FA; */
    background: none;
}
::v-deep(div[role='textbox']) {
    min-height: 40px;
    max-height: 80px;
    /* border: 2px solid #BFBFBF; */
    /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); */
    box-shadow: none;
    border: none;
}
::v-deep(.ck.ck-editor__editable_inline) {
    font-size: 16px;
}
::v-deep(.ck.ck-editor__editable_inline>:last-child),
::v-deep(.ck.ck-editor__editable_inline>:first-child) {
    margin: 0px;
}

::v-deep(.ck .ck-placeholder:before),
::v-deep(.ck.ck-placeholder:before) {
    color: #BFBFBF;
}

::v-deep(.mention) {
    color: rgb(51, 204, 153);
}

::v-deep(.ck.ck-content) {
    /* border-radius: 16px !important;
    padding: 8px 16px; */
    border-radius: 0px !important;
    padding: 8px 16px;
}
::v-deep(.ck-editor__editable_inline::-webkit-scrollbar) {
	/* width */
	width: 10px;
    
}
::v-deep(.ck-editor__editable_inline::-webkit-scrollbar-track) {
	margin-right: 10px;
}
::v-deep(.ck-editor__editable_inline::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}
::v-deep(.ck-editor__editable_inline.ck-focused) {
    /* border: 2px solid #33CC99 !important; */
    border: none !important;
    box-shadow: none !important;
}

::v-deep(.ck-content .image img) {
    /* display: block;
    margin: 0 auto; */
    max-width: 10rem;
    min-width: 5rem;
    
}

::v-deep(.ck-content .image-inline img) {
    /* display: block;
    margin: 0 auto; */
    max-width: 10rem;
    min-width: 5rem;
    


}

::v-deep(div.comment-text > figure > img) {
    /* display: block;
    margin: 0 auto; */
    max-width: 10rem;
    min-width: 5rem;
}

.button-container {
    display:contents;
}

@media (max-width: 760px) {
    ::v-deep(#commentEditor) {
        width: 100%;
    }
    .button-container {
        align-self: flex-end;
        margin-top: 1rem;
        margin-right: 8px;
        display:block !important;
    }
    .comment-editor-container {
        width: 100% !important;
    }
    .comment-editor-bar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    ::v-deep(div[role='application']) {
        width: auto;
    }
}

.button-container-disabled {
    pointer-events: none;
    opacity: .3;
}



</style>

<style >
.ck.ck-block-toolbar-button {
    z-index: 10000;
     transform: translateY( -10px );
}

div.comment-text > figure > img {
    /* display: block;
    margin: 0 auto; */
    max-width: 10rem;
    min-width: 5rem;
}
</style>