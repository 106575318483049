<template>
  <div class="loader-container" v-show="showLoading"  :style="loaderStyle" > 
        
        <div class="loader">
             
            <div class="bottom-section">
                <div class="bottom"></div>
                <div class="bottom"></div>
                <div class="bottom"></div>
                <div class="bottom"></div>
            </div>
            <div class="top-section">
                <div class="bottom"></div>
                <div class="bottom"></div>
                <div class="bottom"></div>
                <div class="bottom"></div>
            </div>
            <div class="middle"></div>
           
        </div>
        <div class="loading-text">{{text}}</div>
        
        
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
     props: {
        showLoading: {
            type: Boolean,
            default: true,
        },
        minHeight: {
            type: String,
            default: "400px;",
        },
        text: {
            type: String,
            default: "",
        },
        textPosition: {

        }
    },
    computed: {
        loaderStyle() {
            return "border-radius:30px;min-height:" + this.$props.minHeight;
        },
        textStyle() {
            return "z-index:999;position:absolute;"+this.$props.textPosition;
        }
    }
    

}
</script>

<style scoped>
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader-container .loader .middle,
.loader-container .loader .top-section .bottom,
.loader-container .loader .bottom-section .bottom {
    background: #32364e;
}

.loading-text {
    text-align: center;
    font-size: 16px;
    transform: translateY(-150px);
}
</style>
