<template>
    <Dialog header="Polling Results" v-model:visible="display" :modal="true" :closable="true" class='polling-results-modal'>
        <ViewPollingResults v-if="poll" :poll="poll" @close-modal="close" :showAnswerDistrubution="true"/>

        <template #footer>
            <div class='button-container'>
                <Button label="Close" class='cancel p-button-raised p-button-rounded' @click='close()' />
            </div>
        </template>
    </Dialog>
</template>

<script>

import Dialog from 'primevue/dialog';
import ViewPollingResults from './ViewPollingResults.vue';


import PollingService from '../../service/PollingService';

export default {
    name: 'ViewPollingResultsModal',

    data() {
        return {
            display: false,
            poll: null,
        }
    },

    components: {
        Dialog,
        ViewPollingResults
    },

    methods: {
        open(pollId) {
            this.display = true;
            PollingService.getPoll(pollId).then(resp => {
                if (resp.data.status == 'success') {
                    this.poll = resp.data.poll;
                }
            });
        },

        close() {
            this.display = false;
            this.pollId = null;
        },
    }
}
</script>

<style>
.p-dialog-mask.p-component-overlay {
    background-color: rgba(50, 54, 78, 0.7);
}

.polling-results-modal {
    font-family: "Trebuchet MS", "Verdana";
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    min-width: 250px;
}

.polling-results-modal .p-dialog-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.polling-results-modal .p-dialog-header .p-dialog-title {
    font-weight: bold;
    font-size: 24px;
}

.polling-results-modal .p-dialog-content {
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
}

.polling-results-modal .body-text {
    font-size: 16px;
    margin-bottom: 1rem;
}

.polling-results-modal .p-dialog-footer {
    padding-top: 1.5rem;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.polling-results-modal .button-container {
    display: flex;
    justify-content: center;
}
</style>
<style scoped>

</style>