import APIService from '@/common/APIService'

class AdServiceImpl {

    getAdPartners() {
        return APIService.get(`/ads/partners`);
    }

    getAdCampaigns(partnerId) {
        return APIService.get(`/ads/partner/${partnerId}/campaigns`);
    }

    getCampaignAds(campaignId) {
        return APIService.get(`/ads/campaign/${campaignId}/ads`);
    }

    getCampaignAdLinks(campaignId, adId) {
        return APIService.get(`/ads/campaign/${campaignId}/ad/${adId}/links`);
    }

    createAdPartner(name) {
        const campaignAdDetailsReq = {
            name: name,
        };
        return APIService.post(`/ads/partner`, campaignAdDetailsReq);
    }

    createAdPartnerCampaign(partnerId, name, startDate, endDate) {
        const campaignAdDetailsReq = {
            name: name,
            startDate: startDate,
            endDate: endDate
        };
        return APIService.post(`/ads/partner/${partnerId}/campaign`, campaignAdDetailsReq);
    }

    createAdForCampaign(campaignId, name, baseUrl, startDate, endDate) {
        const campaignAdDetailsReq = {
            name: name,
            baseUrl: baseUrl,
            startDate: startDate,
            endDate: endDate
        };
        return APIService.post(`/ads/campaign/${campaignId}/ad`, campaignAdDetailsReq);
    }

    addAdToCampaign(campaignId, adId, baseUrl, startDate, endDate) {
        const campaignAdDetailsReq = {
            baseUrl: baseUrl,
            startDate: startDate,
            endDate: endDate
        };
        return APIService.post(`/ads/campaign/${campaignId}/ads/${adId}`, campaignAdDetailsReq);
    }

    trackInAppAdLink(adLink) {
       
        return APIService.post(`/ads/track-in-app-ad-link`, adLink);
    }

    endCampaignAd(campaignId, adId){
        return APIService.put(`/ads/campaign/${campaignId}/ad/${adId}`, {});
    }

    getRandomChanceBannerAd() {
        return APIService.get('/ads/banner/chance');
    }

}

const AdService = new AdServiceImpl();

export default AdService;