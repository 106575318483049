<template>
    <Dialog id="postDetailsDialog" :class="postDetailsModalClass" v-model:visible="displayPost" @show="onShown" @hide="close" :modal="true" :draggable="false" :dismissableMask="mode !== 'edit'">
        <template #header>
            <template v-if="!isEducationalPost()">
                <div class='status-post-owner-info'>
                    <UserAvatar :imageSrc='post.activity.actor.data.avatar' customStyle='width: 36px; height: 36px;'  @click='postUtils.goToAnalystProfile(post.activity.actor.data.name, $event), close()' />
                    <div class="names-container">
                        <span class='full-name' @click='postUtils.goToAnalystProfile(post.activity.actor.data.name, $event), close()'>{{stringUtils.fullName(post.activity.actor.data)}}</span>
                        <span class='username'><span @click='postUtils.goToAnalystProfile(post.activity.actor.data.name, $event), close()'>@{{post.activity.actor.data.name}}</span>
                        | {{timeUtils.getTimeFromNow(post.activity.time)}}{{ (post.activity.revision_date ? ' | Edited '+timeUtils.getTimeFromNow(post.activity.revision_date) : '')}}
                        </span>
                        <span class="posted-in-span" @click.stop='goToGroupProfile(post.activity.group, post.activity.groupFeed)'>
                            {{ post.activity.group ? ' Posted in ' + post.activity.group.name : ''}}
                            {{ post.activity.groupFeed ? '- ' + post.activity.groupFeed.name : ''}}
                        </span>
                    </div>
                    <template v-if="mode == 'edit'">
                        <Button type="button" v-if="mode == 'edit'" label="Update" icon="pi pi-check" class="update-post-button" @click="updatePost" :disabled="disableUpdateButton" :loading="updating"/>
                        <Button type="button" v-if="mode == 'edit'" label="Cancel" icon="pi pi-times" class="cancel-post-button" @click="exitEditMode" />
                        <MoreOptionsMenu class="more-options-edit-mode"  @click='togglePostMenu'/>
                    </template>
                    <template v-else>
                        <div class='actions-container'>
                            <FollowButton :followTarget="userObjectForFollowButton" :confirmUnfollow="false" v-if='!userUtils.isUser(post.activity.actor.id) && !postUtils.alreadyFollowsUser(post.activity.actor.id)'/>
                            <Badge class='following-badge' value="Following" v-else-if="!userUtils.isUser(post.activity.actor.id) && postUtils.alreadyFollowsUser(post.activity.actor.id)"></Badge>
                            <MoreOptionsMenu class="more-options"  @click='togglePostMenu'/>
                        </div>
                    </template>
                    <Menu ref="menu" :model="postMenuItems" :popup="true" />
                </div>
            </template>
            <template v-else>

                <!--================== educational post header =========================== -->
                <template v-if="mode == 'edit'">
                    <div class='status-post-owner-info'>
                        <UserAvatar :imageSrc='post.activity.actor.data.avatar' customStyle='width: 36px; height: 36px;'  @click='postUtils.goToAnalystProfile(post.activity.actor.data.name, $event), close()' />
                        <div class="names-container">
                            <span class='full-name' @click='postUtils.goToAnalystProfile(post.activity.actor.data.name, $event), close()'>{{stringUtils.fullName(post.activity.actor.data)}}</span>
                            <span class='username'><span @click='postUtils.goToAnalystProfile(post.activity.actor.data.name, $event), close()'>@{{post.activity.actor.data.name}}</span>
                            | {{timeUtils.getTimeFromNow(post.activity.time)}}{{ (post.activity.revision_date ? ' | Edited '+timeUtils.getTimeFromNow(post.activity.revision_date) : '')}}
                            </span>
                            <span class="posted-in-span" @click.stop='goToGroupProfile(post.activity.group, post.activity.groupFeed)'>
                                {{ post.activity.group ? ' Posted in ' + post.activity.group.name : ''}}
                                {{ post.activity.groupFeed ? '- ' + post.activity.groupFeed.name : ''}}
                            </span>
                        </div>
                        <Button type="button" v-if="mode == 'edit'" label="Update" icon="pi pi-check" class="update-post-button" @click="updatePost" :disabled="disableUpdateButton" :loading="updating"/>
                        <Button type="button" v-if="mode == 'edit'" label="Cancel" icon="pi pi-times" class="cancel-post-button" @click="exitEditMode" />
                        <MoreOptionsMenu class="more-options-edit-mode"  @click='togglePostMenu'/>
                        <Menu ref="menu" :model="postMenuItems" :popup="true" />
                    </div>
                </template>
                <template v-else>
                    <template v-if="post.activity.title">
                        <div class='post-educational-title' >
                        
                            <template v-if="post.activity.verb.startsWith('question')">
                                <template v-if="post.activity.poll?.pollTypeId == MULTIPLE_CHOICE_POLL.pollTypeId || post.activity.poll?.pollTypeId == MULTIPLE_SELECT_POLL.pollTypeId">
                                    <img src="/assets/images/mc.png" class="title-icon" />
                                
                                </template>
        
                                <template v-else-if="post.activity.poll?.pollTypeId == YESNO_POLL.pollTypeId">
                                    <img src="/assets/images/poll.png" class="title-icon" />
                                
                                </template>

                                <template v-else>
                                    <img src="/assets/images/gq.png" class="title-icon" />
                                    
                                </template>
                            </template>
                            <template v-else-if="post.activity.verb.startsWith('motion')" >
                                <img class="title-icon" src="/assets/images/motion_icon.png" />
                            </template>
                            <template v-else-if="post.activity.task == true">
                                <img src="/assets/images/task.png" class="title-icon" style="margin-right:10px;"/>
                            
                            </template>
                            <template v-else>
                                <img src="/assets/images/lesson.png" class="title-icon" style="scale:0.7;"/>
                            
                            </template>

                            {{post.activity.title}}
                        </div>
                    </template>
                    <template v-else>
                        <img src="/assets/images/lesson.png" class="title-icon" style="scale:0.7;"/>
                    </template>
                    <div class='actions-container' style="float:right;">
                        <MoreOptionsMenu class="more-options"  @click='togglePostMenu'  v-if="postMenuItems && postMenuItems.length > 0"/>
                        <Menu ref="menu" :model="postMenuItems" :popup="true" />
                    </div>
                    
                </template>
                
            </template>
        </template>
        <template  v-if="mode =='read'">
            <infinite-scroll id='infiniteScrollForDialog' @infinite-scroll="loadMoreComments" :message="message" :noResult="noMoreResults" :style="scrollStyle" :autoNavigate="true">
                <div class='post-body-content'>
                    <div v-if="post.activity.title && !isEducationalPost()" class='post-content-title' >
                        
                        <template v-if="post.activity.verb.startsWith('question')">
                            <template v-if="post.activity.poll?.pollTypeId == MULTIPLE_CHOICE_POLL.pollTypeId || post.activity.poll?.pollTypeId == MULTIPLE_SELECT_POLL.pollTypeId">
                                <img src="/assets/images/mc.png" class="title-icon" />
                                
                            </template>
    
                            <template v-else-if="post.activity.poll?.pollTypeId == YESNO_POLL.pollTypeId">
                                <img src="/assets/images/poll.png" class="title-icon" />
                               
                            </template>

                            <template v-else>
                                <img src="/assets/images/gq.png" class="title-icon" />
                                
                            </template>
                        </template>
                        <template v-else-if="post.activity.verb.startsWith('motion')" >
                            <img class="title-icon" src="/assets/images/motion_icon.png" />
                        </template>
                        <template v-else-if="post.activity.task == true">
                            <img src="/assets/images/task.png" class="title-icon" style="margin-right:10px;"/>
                           
                        </template>

                        {{post.activity.title}}
                    </div>
                    <template v-if="post.activity.verb.startsWith('motion')">
                        <div class="trade-details-section">
                            <div class="trade-details-header">Trade Details</div>
                            <div class="trade-details-content">
                                Place <b v-if="mainTradeOrderObject.tradeOrderType === 'Market'">{{ mainTradeOrderObject.tradeType === 'Buy' ? 'Buy' : 'Sell'}}</b>
                                    <b v-else>{{mainTradeOrderObject.tradeType}}</b> order for <b>{{ mainTradeOrderObject.quantity }}</b> units of <b>{{ mainTradeOrderObject.symbol }}</b> <span v-html="mainTradeOrderText"></span>
                                    <p>
                                   
                                    <b>Portfolio: </b><span class="trade-details-portfolio-name" @click="goToPortfolio()">{{post.activity.motion.portfolioName}}</span><br>
                                    <b>Account: {{post.activity.motion.accountName}}</b> </p>

                                    <div class="allocation-chart-section" v-if='allocationLoaded && holdingsLoaded'>
                                        <b>Current Holdings: </b>
                                        <AllocationChart :sectorLabels="sectorLabels" :sectorValues="sectorValues" :assetLabels="assetLabels" :assetValues="assetValues" :showInfoIcon="false" 
                    :viewingOwnPortfolio="true" :holdingLabels="holdingLabels" :holdingValues="holdingValues" />
                                    </div>

                            </div>
                        </div>
                        
                        <div class="motion-details-header">Motion Details</div>

                        <div class="voting-deadline-section">
                            <span class="voting-deadline-header">Voting Deadline:</span> 
                            {{ post.activity.motion.deadline  + (post.activity.motion.deadline == 1 ? ' hour' : ' hours')}} ( <b>{{ deadlineDate }}</b> Approx.)
                        </div>

                        <div class="summary-section">
                            <span class="summary-section-header">Summary:</span>
                            <ul>
                                <template v-for="point of [post.activity.motion.summaryPoint1,post.activity.motion.summaryPoint2,post.activity.motion.summaryPoint3]">
                                    <li v-if="point" :key="point" v-html="point">
                                    </li>
                                </template>
                            </ul>
                        </div>


                        <div class="rationale-header">Detailed Rationale:</div>
                    </template>
                    <div :id="'postActualContent-' + post.activity.id" class='post-actual-content' v-html="getPostContent()"></div>
                    
                    <div class="motion-section" v-if="post.activity.verb.startsWith('motion')">
                        <span class="motion-status-text" v-if="[PASSED_MOTION_STATUS, VETOED_MOTION_STATUS, WITHDRAWN_MOTION_STATUS, FAILED_MOTION_STATUS].includes(post.activity.motion.motionStatus)">{{ motionStatusText }}</span>
                        <span class="motion-status-text" v-else-if="post.activity.motion.motionStatus === SUBMITTED_MOTION_STATUS && deadlineHasPassed">
                        Deadline has passed, voting is over.
                        </span>
                        <template v-if="(!alreadyReacted(post.activity, 'yesVote') && !alreadyReacted(post.activity, 'noVote')) && !deadlineHasPassed && post.activity.motion.motionStatus === SUBMITTED_MOTION_STATUS">
                            <div class="motion-security-section">
                                <div class="security-exchange-text">{{ mainTradeOrderObject.symbol }} on {{ mainTradeOrderObject.exchangeSymbol }}</div>
                                <QuoteFeedTable :askPrice="askPrice" :askSize="askSize" :bidPrice="bidPrice" :bidSize="bidSize"
                                            :highValue="highValue" :lowValue="lowValue" :openValue="openValue" :volume="volume" />
                            </div>
                            <div class="voting-container" >
                                <Button class="yes-vote-button" @click="castVote('yes')" :loading="castingYesVote" :disabled="castingNoVote">
                                    <span class="vote-label">Vote YES</span>
                                    <span class="material-symbols-outlined vote-icon">check_circle</span>
                                </Button>
                                <Button class="no-vote-button" @click="castVote('no')" :loading="castingNoVote" :disabled="castingYesVote">
                                    <span class="vote-label">Vote NO</span>
                                    <span class="material-symbols-outlined vote-icon">cancel</span>
                                </Button>
                            </div>
                        </template>
                        <div class="voted-text" v-else-if="(alreadyReacted(post.activity, 'yesVote') || alreadyReacted(post.activity, 'noVote'))" v-html="votedText"></div>
                    </div>
                    
                    <div v-if="post.activity.mentioned_tags" style="margin-top:10px;">
                        <template v-for="feedTag in post.activity.mentioned_tags" :key="feedTag">
                        <a class="mention tag-mention"  data-mention-type="tag" :href="'/index.html#/tag/profile/'+feedTag.tagId"  >{{(feedTag.tag.startsWith("#") ? feedTag.tag : "#"+feedTag.tag)}}</a>
                        &nbsp;
                        </template>
                        <template v-if="post.activity.motion">
                            <template v-for="motionTradeOrder in post.activity.motion.motionTradeOrders" :key="motionTradeOrder">
                                <template v-if="motionTradeOrder.tradeOrder && motionTradeOrder.tradeOrder.assetType== 'equity'">
                                    <a data-mention-type="security" :data-mention="'$'+motionTradeOrder.tradeOrder.symbol" class="mention security-mention" onclick="window.CKEditorPluginService.onMentionClicked(event)" href="#" data-sec-type="equity" :data-exchange="motionTradeOrder.tradeOrder.exchangeSymbol">${{motionTradeOrder.tradeOrder.symbol}}</a>
                                </template>
                                <template v-else-if="motionTradeOrder.tradeOrder">
                                    <a data-mention-type="security" :data-mention="'$'+motionTradeOrder.tradeOrder.symbol" class="mention security-mention" onclick="window.CKEditorPluginService.onMentionClicked(event)" href="#" data-sec-type="fund" :data-exchange="motionTradeOrder.tradeOrder.exchangeSymbol">${{motionTradeOrder.tradeOrder.symbol}}</a>
                                </template>
                            
                            </template>
                        </template>
                    </div>
                     <template v-if="!isEducationalPost() ">
                        <div class='activity-stats-bar'>
                            <span class='likes-container'>
                                <i :class="{'pi pi-thumbs-up':true, 'liked': postUtils.isAlreadyLiked(post.activity), 'own-post': postUtils.isOwnPost(post.activity.actor.id)}" @click="onLikeClicked" />
                                <span class='likes-amount'  v-tooltip.bottom="'View Likes'" @click='openPostLikesListDialog()'>{{ postUtils.hasLikeCount(post.activity.reaction_counts) ? post.activity.reaction_counts.like : '' }}</span>
                            </span>
                            <span class='comments-container'>
                                <i class="pi pi-comment" @click="onClick" />
                                {{ post.activity.num_comments ? '' + post.activity.num_comments : '' }}
                            </span>
                            <div class="reaction-container" v-if="!postUtils.isOwnPost(post.activity.actor.id)">
                                <div class="reaction-icon-container" @click="openReactionMenu">
                                    <span class="material-symbols-outlined reaction-icon">sentiment_satisfied</span>
                                </div>
                                <ReactionMenu @reaction-selected="addOrRemoveReaction(post.activity, $event)" :reactions="postReactions" ref="reactionMenu"/>
                            </div>
                            <span class='looks-container' v-if="postUtils.hasLookCount(post.activity.reaction_counts)" v-tooltip.bottom="post.activity.reaction_counts.look+' views'">
                                👀 {{post.activity.reaction_counts.look}}
                            </span>
                        </div>
                        <div class="reaction-bar-container" v-if="reactionCounts">
                            <template v-for='key in Object.keys(reactionCounts)' :key='key'>
                                <div :class="{'reaction-container': true, 'own-post': postUtils.isOwnPost(post.activity.actor.id), 'reacted': alreadyReacted(post.activity, key)}" 
                                v-if="!['comment', 'like', 'look', 'yesVote', 'noVote', 'A', 'B', 'C', 'D', 'E', 'read'].includes(key) && formatReactionCount(key)['number'] > 0" @click="addOrRemoveReaction(post.activity, key)">
                                    <span class='num'>{{formatReactionCount(key)['number']}}</span>
                                    <span class='icon'>{{formatReactionCount(key)['icon']}}</span>
                                </div>
                            </template>
                        </div>
                     </template>
                    

                    <QuestionVoting postDetailsDialogSection :activity="post.activity" @post-reacted="postReactedByVoting" />
                    
                    <div class="view-polling-results-section" v-if="showPollingResultsSection()">
                        <ViewPollingResults :poll="poll" @close-modal="close" :showAnswerDistrubution="!isEducationalPost()"/>
                    </div>

                    <Accordion class="post-actual-content" v-if="showPollAnswerDetailsSection()"  :activeIndex="0">
                        <AccordionTab header="Answer Details">
                            <div :id="'postAnswerActualContent-' + post.activity.id" v-html="poll?.answerDetails"></div>
                        </AccordionTab>
                    </Accordion>

                </div>
                <template v-if="!isEducationalPost() ">
                    <div class='comments-section' data-role="comments">
                       
                        <CommentList :origActivity="post.activity" :commentList="comments" :activityForeignId="post.activity.foreign_id" v-if='post.activity && comments.length > 0' :commentLevel='1'
                        :groupOwnerId="post.activity.group ? post.activity.group.groupOwnerId : null" />
                    </div>
                </template>
            </infinite-scroll>
        </template>
        <div v-else-if="mode =='edit'" class='edit-form-container' >
           
            <template  v-if="isNote() || isQuestion">
                <div class="status-form-top-row" style=" width: 100%;">
                    <InputText type="text" v-model="editMode.title" placeholder="Add a title" ref="titleInput" maxlength="100" class="title-input"/>
                </div>
            </template>
            <template v-if="isNote()">
                <div :class="{ 'status-container':true, 'input-error': v$.currentStatusLength.$error}" aria:haspopup="true" aria-controls="overlay_panel" :style="editorStyle"  id='noteEditorDiv' >
                    
                    <vue-editor id="notePostEditor" ref="notePostEditor" v-model="editMode.content" :editorOptions="editMode.createFeedEditorSettings('#postDetailsDialog .status-container')"  :customModules="editMode.editorModules" 
                                    :disabled="updating" />
                    
                </div>
            </template>
           
            <template v-else-if="isQuestion">
                <div class="status-container" aria:haspopup="true" aria-controls="overlay_panel"  id='statusEditorDiv'>
                    
                    <vue-editor id="questionPostEditor" ref="questionPostEditor" v-model="editMode.content" :editorOptions="editMode.createFeedEditorSettings('#postDetailsDialog .status-container')"  :customModules="editMode.editorModules"   
                             :disabled="updating" />
                </div>

                <LoadingSpinner :showLoading="loadingPollData" minHeight="200px"/>
                <template v-if="!loadingPollData">
                    <template v-if="isQuestionPostedInGroup">
                        <div class="choices-section">
                            <div class="choices-label">Difficulty level (optional):</div>
                            <div class="flex flex-wrap gap-2">
                                <Dropdown v-model="editMode.difficultyLevelOptionId" :options="difficultyLevelOptions" placeholder="Select a Difficulty" showClear optionLabel="label" optionValue="id" />
                            </div>
                        </div>

                        <div class="choices-section">
                            <div class="choices-label">Category (optional):</div>
                            <div class="flex flex-wrap gap-2">
                                <Dropdown v-model="editMode.pollCategoryOptionId" :options="pollCategoryOptions" placeholder="Select a Category" showClear optionLabel="label" optionValue="id" />
                            </div>
                        </div>
                    </template>

                    <div class="choices-section" v-if="this.isYesNoQuestion || this.isPollQuestion">
                        <div class="choices-label">Force users to view poll details before answering:</div>
                        <div class="input-section">
                            <InputSwitch class="input-switch" v-model="editMode.forceRead"/>
                        </div>
                    </div>
                
                    <div class="choices-section" v-if="isYesNoQuestion">
                        <div class="choices-label">Correct answer:</div>
                        <div class="flex flex-wrap gap-2">
                            <div class="flex align-items-center" v-for="option in correctAnswerYesNoOptions" :key="option.id">
                                <RadioButton v-model="editMode.correctAnswerYesNoOptionId" :inputId="'correctAnswerYesNo'+option.id" :name="option.id" :value="option.id" />
                                <label :for="'correctAnswerYesNo'+option.id" class="ml-2">{{ option.label }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="choices-section" v-else-if="isPollQuestion">
                        <div class="choices-labels">
                            <span class="choices-label">Choices (min 2, max 5):</span>
                            <span class="choices-label" v-if="isMultipleChoicePoll">Correct answer (optional):</span>
                        </div>

                        <div class="input-section">
                            <div class="choices-item-container">
                                A&nbsp;
                                <InputText type="text" class="choice-text-input" v-model="editMode.choiceA" placeholder="Enter choice A" :maxlength="editMode.choiceMaxLength"/>
                                <span class='required'>*</span>
                            </div>
                            <Checkbox v-if="isMultipleChoicePoll" v-model="correctAnswerPoll" name="choice1" :value="pollChoicesCodes.choiceA" />
                        </div>
                        <div class="input-section">
                            <div class="choices-item-container">
                                B&nbsp;
                                <InputText type="text" class="choice-text-input" v-model="editMode.choiceB" placeholder="Enter choice B" :maxlength="editMode.choiceMaxLength"/>
                                <span class='required'>*</span>
                            </div>
                            <Checkbox v-if="isMultipleChoicePoll" v-model="correctAnswerPoll" name="choice2" :value="pollChoicesCodes.choiceB" />
                        </div>
                        <div class="input-section">
                            <div class="choices-item-container">
                                C&nbsp;
                                <InputText type="text" class="choice-text-input" v-model="editMode.choiceC" placeholder="Enter choice C (optional)" :maxlength="editMode.choiceMaxLength"/>
                            </div>
                            <Checkbox v-if="isMultipleChoicePoll" v-model="correctAnswerPoll" name="choice3" :value="pollChoicesCodes.choiceC" />
                        </div>
                        <div class="input-section">
                            <div class="choices-item-container">
                                D&nbsp;
                                <InputText type="text" class="choice-text-input" v-model="editMode.choiceD" placeholder="Enter choice D (optional)" :maxlength="editMode.choiceMaxLength"/>
                            </div>
                            <Checkbox v-if="isMultipleChoicePoll" v-model="correctAnswerPoll" name="choice4" :value="pollChoicesCodes.choiceD" />
                        </div>
                        <div class="input-section">
                            <div class="choices-item-container">
                                E&nbsp;
                                <InputText type="text" class="choice-text-input" v-model="editMode.choiceE" placeholder="Enter choice E (optional)" :maxlength="editMode.choiceMaxLength"/>
                            </div>
                            <Checkbox v-if="isMultipleChoicePoll" v-model="correctAnswerPoll" name="choice5" :value="pollChoicesCodes.choiceE" />
                        </div>
                    </div>

                <div v-if="hasReward" class="choices-section" >
                    <div class="choices-label">Answer explanation (optional):</div>
                    <div class="status-container" aria:haspopup="true" aria-controls="overlay_panel"  id='answerDetialsEditorDiv'>
                        
                        <vue-editor id="AnswerDetailsPostEditor" ref="AnswerDetailsPostEditor" v-model="editMode.answerDetails" :editorOptions="editMode.createFeedEditorSettings('#postDetailsDialog .status-container')"  :customModules="editMode.editorModules"   
                                 />
                    
                    </div>

                   
                    <div class="choices-label">Show the correct answer to users after they answer.</div>
                    <div class="input-section">
                        <InputSwitch class="input-switch" v-model="editMode.showAnswerOnSubmit"/>
                    </div>

                    <div class="choices-labels">
                        <span class="choices-label">Reward amount:</span>
                        <span class="choices-label">Reward currency:</span>
                    </div>
                    <div class="input-section">
                        <div class="choices-item-container">
                            <InputNumber id='rewardAmount' class="reward-amount-input" v-model="editMode.correctAnswerReward" mode="currency" currency="USD" locale="en-US" placeholder="Enter reward amount" />
                        </div>
                        <SelectButton class="reward-currency-select-button" :unselectable="false" v-model="editMode.rewardCurrencyId"
                                :options="currencyOptions" optionLabel="label" optionValue="value" />
                    </div>
                    <div class="choices-labels">
                        <span class="choices-label">Max number of winners (Set to 0 for no limit):</span>
                    </div>
                    <div class="input-section">
                        <div class="choices-item-container">
                            <InputNumber class="reward-amount-input" v-model="editMode.maxNumberOfWinners" placeholder="Enter max number of winners" />
                        </div>
                    </div>
                    <span class="choices-label" v-if="editMode.correctAnswerReward > 0">{{ pollRewardLabel }}</span>
                    
                </div>
                </template>
            </template>
            <template v-else >
                <div :class="{ 'status-container':true, 'input-error': v$.currentStatusLength.$error}" aria:haspopup="true" aria-controls="overlay_panel" :style="editorStyle"  id='statusEditorDiv' >
                    
                    <vue-editor id="statusPostEditor" ref="statusPostEditor" v-model="editMode.content" :editorOptions="editMode.createFeedEditorSettings('#postDetailsDialog .status-container')"  :customModules="editMode.editorModules" 
                                :disabled="updating" @text-change="watchStatusLength"/>
                
                    <span :style="{marginRight: 'auto', color: v$.currentStatusLength.$error ? '#E63E3E': null}">{{currentStatusLength}} / {{editMode.statusCharLimit}}</span>
                </div>
            </template>

             <template v-if="!isQuestion">
                <Accordion style="margin-top:5px;">
                    <AccordionTab header="Thumbnails:">
                <!-- <div class="choices-label">Thumbnails:</div> -->
                        <div class="input-section">
                                <InputSwitch class="input-switch" v-model="thumbnailsEnabled"/><span v-show="thumbnailsEnabled" style="margin-right:auto;margin-left:5px;">Enabled</span><span v-show="!thumbnailsEnabled" style="margin-right:auto;margin-left:5px;">Disabled</span>
                        </div>
                        <div v-show="thumbnailsEnabled">
                        
                                <div class='image-preview-thumbnail'  v-show="thumbnailUrl">
                            
                                <img :id='thumbnailElementId' :src='thumbnailUrl' v-show="thumbnailUrl" />
                                    
                                </div>
                                
                                <div class='image-preview-thumbnail' v-show="post.activity.thumbnails?.length == 1 && !thumbnailUrl ">
                                
                                        <img id='existingSingleThumbnail' :src="post.activity.thumbnails[0]?.url"/>
                                        <span v-show="thumbnailRemoved" style="padding:10px;line-height:200px;">No thumbnail provided.  Will autogenerate based on images in post.</span>
                                        
                                
                                </div>
                                <div class='image-preview-thumbnails' v-show="post.activity.thumbnails?.length > 1 && !thumbnailUrl">
                                
                                        <img :src="post.activity.thumbnails[0]?.url"/>
                                        <img :src="post.activity.thumbnails[1]?.url"/>
                                
                                </div>
                                
                            
                            
                        </div>
                        <div v-show="thumbnailsEnabled">
                            <span class='p-button p-component action-button'  @mouseup='openFileDialogForImportingImage' style="margin-top: 25px;" v-show='!thumbnailUrl'>
                                    <span class="p-button-label">Change Thumbnail</span>
                                    <ImageFileInput :srcImageId='thumbnailElementId' @selectedImage='selectedImage($event)' @openErrorModal='openErrorModal($event)' :maxHeight="0" :maxWidth="0"/>
                            </span>
                            <Button label='Remove Thumbnail' class='delete action-button' v-show='thumbnailUrl || (post.activity.thumbnails?.length == 1 && !thumbnailRemoved)' @click='removeThumbnail' style="margin-top: 5px;margin-left:5px;"/>
                        </div>
                        <div v-show="!thumbnailsEnabled"  class='image-preview-thumbnail' style="border:none;">
                            <span>No Thumbnail will be shown</span>
                        </div>
                    </AccordionTab>
                </Accordion>
                
            </template>
            
        </div>
        <template #footer> 
            <template v-if="!isEducationalPost() ">
                <div class='comment-footer' :style=" (mode== 'edit' ? 'pointer-events:none;opacity:0.2;' : '')">
                    <UserAvatar :imageSrc='$store.state.users.user.analyst.avatar' @click='postUtils.goToAnalystProfile($store.state.users.user.analyst.name, $event), close()' />
                    <CommentEditor @on-post="onPostComment" ref="commentEditor" :enabled="allowComments"/>
                </div>
            </template>
        </template>
    </Dialog>

    <ImageDialog ref='imageDialog'/>
    <ConfirmModal  headerText='Delete this post?' confirmButtonLabel='Delete' @confirmed="deletePostConfirmed" @cancelled="deletePostCancelled" ref='confirmDeleteModal' />
    <SharePostModal ref='sharePostModal'/>
    <PostLikesListDialog ref='postLikesListModal'/>
    <PostLocationsDialog ref="postLocationsDialog" :isRepost="true" @post-to-locations="doRepost"/>
    <ImportImageErrorModal ref='importImageErrorModal'/>
</template>

<script>
import {SUBMITTED_MOTION_STATUS, WITHDRAWN_MOTION_STATUS, PASSED_MOTION_STATUS, FAILED_MOTION_STATUS, VETOED_MOTION_STATUS,YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL, CANADIAN_CURRENCY_TYPE } from '../../common/constants';

import {SHARE_URL} from '../../common/config';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import AllocationChart from '../portfolio/overview/AllocationChart.vue';
import Dialog from 'primevue/dialog';
import CommentEditor from './CommentEditor';
import UserAvatar from '../profile/UserAvatar';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import CommentList from './CommentList';
import QuestionVoting from './QuestionVoting';
import ViewPollingResults from './ViewPollingResults';
import ImageDialog from '../feed/ImageDialog';
import ConfirmModal from '../modal/ConfirmModal.vue';
import SharePostModal from '../modal/SharePostModal.vue';
import ReactionMenu from '../menu/ReactionMenu';
import PostLikesListDialog from './PostLikesListDialog.vue';
import PostLocationsDialog from './PostLocationsDialog.vue';
import FollowButton from '../button/FollowButton.vue';
import QuoteFeedTable from '../QuoteMedia/QuoteFeedTable.vue';
import Badge from 'primevue/badge';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import InputSwitch from 'primevue/inputswitch';
// import CommentCard from './CommentCard';

import GroupService from '../../service/GroupService';
import SecurityFormatter from '../../common/SecurityFormatter';
import DataService from '../../service/DataService';
import FeedService from '../../service/FeedService';
import MotionService from '../../service/MotionService';
import PortfolioService from '../../service/PortfolioService';
import TimeUtils from '../../utilities/TimeUtils';
import PostUtils from '../../utilities/PostUtils';
import StringUtils from '../../utilities/StringUtils';
import UserUtils from '../../utilities/UserUtils';
import BrowserUtils from '../../utilities/BrowserUtils';

//quill
import { VueEditor, Quill } from "vue3-editor";
import { createFeedEditorSettings, feedEditorModules, gatherMentionsAndMedia} from "../editor/config.js";







import EventBus from '../../event-bus';
import useVuelidate from '@vuelidate/core'
import { maxValue } from '@vuelidate/validators';

import FormattingUtils from '../../utilities/FormattingUtils';
import PollingService from '../../service/PollingService';
import { correctAnswerYesNoOptionIds, currencyOptions, correctAnswerYesNoOptions, difficultyLevelOptions, pollCategoryOptions, pollChoicesCodes } from './';


import ImageFileInput from '../fileInput/ImageFileInput';
import ImportImageErrorModal from '../errors/ImportImageErrorModal';


const LIKE = 1;
const domParser = new DOMParser();
const MARKET_ORDER_TYPES = [BUY_MARKET_ORDER_NAME, SELL_MARKET_ORDER_NAME];

const BUY_MARKET_ORDER_NAME = 'Buy Market';
const SELL_MARKET_ORDER_NAME = 'Sell Market';

const POLLING_PRICE_INTERVAL = 10000;

export default {
    components: {
        InfiniteScroll,
        AllocationChart,
        Dialog,
        CommentEditor,
        UserAvatar,
        ImageDialog,
        MoreOptionsMenu,
        CommentList,
        ConfirmModal,
        //GiphyDialog,
        SharePostModal,
        PostLikesListDialog,
        PostLocationsDialog,
        FollowButton,
        QuoteFeedTable,
        Badge,
        ReactionMenu,
        LoadingSpinner,
       QuestionVoting,
        ViewPollingResults,
        Accordion,
        AccordionTab,
        SelectButton,
        RadioButton,
        InputNumber,
        Checkbox,
        Dropdown,
        InputSwitch,
        VueEditor, 
        Quill,//eslint-disable-line
        // CommentCard
        ImageFileInput,
        ImportImageErrorModal,
       

    },
    emits: ['comment-posted', 'post-liked', 'post-unliked', 'post-deleted', 'post-reacted', 'post-unreacted', 'post-read'],
    props: {
        post: {
            type: Object,
            required: true,
        },
        startInEditMode: {
            type: Boolean,
            required: true,
            default: false,
        },
        viewingFrom: {
            type: String,
            default: null
        },
        allowComments:{
            type: Boolean,
            required: false,
            default: true
        }
    },
     setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            //CONSTANTS
            MARKET_ORDER_TYPES,
            SUBMITTED_MOTION_STATUS,
            WITHDRAWN_MOTION_STATUS,
            FAILED_MOTION_STATUS,
            VETOED_MOTION_STATUS,
            PASSED_MOTION_STATUS,
            YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL,
            correctAnswerYesNoOptionIds,
            currencyOptions,
            correctAnswerYesNoOptions,
            difficultyLevelOptions, 
            pollCategoryOptions,
            pollChoicesCodes,

            loading: false,
            message: '',
            noMoreResults: false,
            // change from 100vh to 80vh to fit mobile comments coverflow
            //scrollStyle: 'width: 100%; height: calc(80vh - 154px) ;',
            displayPost: false,
            postContent: '',
            loadingPollData: false,

            comments: [],
            likeClicked: false,
            displayWait: false,
            commentLikes: [],

            timeUtils: null,
            postUtils: null,
            StringUtils: null,
            userUtils: null,
            currencyFormatter: new FormattingUtils(),
            mode: "read",
            
            editMode: {
                //editor: ClassicEditor, //eslint-disable-line
                //renderedEditor: null,
                //answerDetailsRenderedEditor: null,
                //editorConfig: null,
                //answerDetailsEditorConfig: null,
                
                editorModules: feedEditorModules,
                
                createFeedEditorSettings: createFeedEditorSettings,

                title: null,
                content: null,

                forceRead: false,
                maxNumberOfWinners: 0,

                showAnswerOnSubmit: false,
                answerDetails: null,
                correctAnswerYesNoOptionId: null,
                correctAnswerReward: 0,
                rewardCurrencyId: CANADIAN_CURRENCY_TYPE['ID'],

                difficultyLevelOptionId: null,
                pollCategoryOptionId: null,

                pollOptions: [],
                choiceA: "",
                choiceB: "",
                choiceC: "",
                choiceD: "",
                choiceE: "",
              
                statusCharLimit: 500,
                choiceMaxLength: 200,
                
            },

            lastSelectedRenderedEditor: null,
            correctAnswerPoll: [],

             updating: false,
            currentStatusLength: 0,
            readingStartTime: null,
            readingStopTime: null,
            postReactions: [],
            reactionCounts: {},

            castingYesVote: false,
            castingNoVote: false,
            refetcher: null,
            askPrice: null,
            bidPrice: null,
            openValue: null,
            highValue: null,
            lowValue: null,
            volume: null,
            askSize: null,
            bidSize: null,
            sectorLabels: [],
            sectorValues: [],
            assetLabels: [],
            assetValues: [],
            holdingLabels: [],
            holdingValues: [],
            allocationLoaded: false,
            holdingsLoaded: false,

            poll: null,

            thumbnailUrl: null,
            thumbnailFile: null,
            thumbnailElementId: 'editPostThumbnailId',
            thumbnailsEnabled: true,
            thumbnailRemoved : false,
        };
    },

    created() {
        this.timeUtils = TimeUtils;
        this.postUtils = PostUtils;
        this.stringUtils = StringUtils;
        this.userUtils = UserUtils;

        EventBus.off('cancel-post-dialog');

        EventBus.on('cancel-post-dialog', () => {
            this.close();
        });
         
        EventBus.on('comment-deleted', () => {
            this.onCommentDeleted();
        });
    },
    mounted() {
        for(const key in FeedService.reactionMap) {
            this.postReactions.push({
                'text': key,
                'icon': FeedService.reactionMap[key]
            });
        }
    },

    computed: {

        postDetailsModalClass() {
            return {
                'post-details-modal': true,
                'motion-post-details-modal': this.post?.activity?.verb.startsWith('motion'),
                'question-post-details-modal': this.post?.activity?.verb.startsWith('question'),
                'editing-modal': this.mode === 'edit'
            }
        },

        scrollStyle() {
            if( this.isMobile()){
                return 'width: 100%; height: 100%;';
            }
            else {
                return 'width: 100%; height: calc(80vh - 154px) ;';
            }
        },
        postMenuItems() {
            return this.getPostMenuItems();
        },
        disableUpdateButton() {
            let disabled = false;

            if (this.isQuestion) {
                if (this.isPollQuestion) {
                    const requiredAnswersEmpty = this.editMode.choiceA === "" || this.editMode.choiceB === "";

                    disabled = requiredAnswersEmpty;

                    if (this.isMultipleChoicePoll && this.correctAnswerPoll.length > 0) {
                        let optionalCorrectAnswerEmpty = false;
                        if (this.correctAnswerPoll[0] === pollChoicesCodes.choiceC) {
                            optionalCorrectAnswerEmpty = this.editMode.choiceC === "";
                        } else if(this.correctAnswerPoll[0] === pollChoicesCodes.choiceD) {
                            optionalCorrectAnswerEmpty = this.editMode.choiceD === "";
                        } else if (this.correctAnswerPoll[0] === pollChoicesCodes.choiceE) {
                            optionalCorrectAnswerEmpty = this.editMode.choiceE === "";
                        }

                        disabled = disabled || optionalCorrectAnswerEmpty;
                    }
                }

                if (this.hasReward) {
                    disabled = disabled || this.editMode.correctAnswerReward === 0 ? 'disabled' : null;
                }
            } else {
                disabled = this.v$.errors?.length > 0 || (!this.editMode.content || this.editMode.content === "") ? 'disabled' : null;
            }

            return disabled;
        },
        editorStyle() {
            // if( this.isNote() ) {
            //     return 'width: 100%; height: calc(100vh - 15px);';
            // }
            // else {
            //     return 'width: 100%; height: calc(100vh - 154px)';
            // }
            return '';
        },

        mainTradeOrderObject() {
            return this.post.activity.verb.startsWith('motion') ? this.post.activity.motion.motionTradeOrders[0].tradeOrder : null;
        },

        mainTradeOrderText() {
            let text = '';

            if (this.post.activity.verb.startsWith('motion')) {

                if (this.mainTradeOrderObject.tradeOrderType === 'Market') {
                    text = 'at <b>market price' + this.getLastAskOrBid() + '</b>.';
                } else if (this.mainTradeOrderObject.tradeOrderType === 'Limit') {
                    text = `with a <b>limit price of ${this.currencyFormatter.format(this.mainTradeOrderObject.limitPrice,'$0.00')}</b>.`;
                } else if (this.mainTradeOrderObject.tradeOrderType === 'StopLimit') {
                    text = `with a <b>limit price of ${this.currencyFormatter.format(this.mainTradeOrderObject.limitPrice,'$0.00')}</b> and <b>stop price of ${this.currencyFormatter.format(this.mainTradeOrderObject.stopPrice,'$0.00')}</b>.`;
                } else if (['StopLoss', 'StopEntry'].includes(this.mainTradeOrderObject.tradeOrderType)) {
                    text = `with a <b>stop price of ${this.currencyFormatter.format(this.mainTradeOrderObject.stopPrice,'$0.00')}</b>.`;
                }
            }

            return text;
        },


        motionStatusText() {

            const MOTION_STATUS_MAP = {
                [PASSED_MOTION_STATUS]: 'Motion Passed',
                [WITHDRAWN_MOTION_STATUS]: 'Motion Withdrawn',
                [FAILED_MOTION_STATUS]: 'Motion Failed',
                [VETOED_MOTION_STATUS]: 'Motion Vetoed'
            }

            return MOTION_STATUS_MAP[this.post.activity.motion.motionStatus];
        },

        deadlineHasPassed() {
            return TimeUtils.isSameOrBeforeNow(this.post.activity.motion.deadlineDate);
        },

        deadlineDate() {
            return TimeUtils.getFormattedTime(this.post.activity.motion.deadlineDate, 'YYYY-MM-DD HH:mm');
        },

        votedText() {
            let text = null;
           
            if (this.alreadyReacted(this.post.activity, 'yesVote')) {
                 let time = TimeUtils.getFormattedTime(this.post.activity.own_reactions.yesVote[0].extra.created_at, 'HH:mm');
                let date = TimeUtils.getFormattedTime(this.post.activity.own_reactions.yesVote[0].extra.created_at, 'MMM DD YYYY')
                text = `At ${time} on ${date} you voted <span class='yes'>"Yes"</span>`;
            } else {
                 let time = TimeUtils.getFormattedTime(this.post.activity.own_reactions.noVote[0].extra.created_at, 'HH:mm');
                let date = TimeUtils.getFormattedTime(this.post.activity.own_reactions.noVote[0].extra.created_at, 'MMM DD YYYY')
                text = `At ${time} on ${date} you voted <span class='no'>"No"</span>`;
            }

            return text;
        },

        userObjectForFollowButton() {
            return {
                analystId: Number(this.post.activity.actor.id),
                name: this.post.activity.actor.data.name
            }
        },

        isQuestion(){
            return this.post.activity.verb.startsWith("question");
        },

        isYesNoQuestion(){
            return this.isQuestion && this.post.activity?.poll?.pollTypeId === YESNO_POLL.pollTypeId;    
        },

        isPollQuestion(){
            return this.isQuestion && [MULTIPLE_CHOICE_POLL.pollTypeId, MULTIPLE_SELECT_POLL.pollTypeId].includes(this.post.activity?.poll?.pollTypeId);    
        },

        isMultipleChoicePoll(){
            return this.isPollQuestion && this.post.activity.poll.pollTypeId === MULTIPLE_CHOICE_POLL.pollTypeId;    
        },

        isQuestionPostedInGroup(){
            return this.isQuestion && !this.post.activity.poll 
                && (UserUtils.isUser(this.post.activity?.group?.groupOwnerId) || UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']) || UserUtils.isGroupMod(this.$store.getters['users/groupRoleList']));
        },

        pollRewardLabel(){
            if(this.editMode.maxNumberOfWinners == 0){
                return `Users to answer correctly will be paid 
                    ${this.currencyFormatter.format(this.editMode.correctAnswerReward, '$0.00')} in 
                    ${this.editMode.rewardCurrencyId == CANADIAN_CURRENCY_TYPE.ID ? 'CAD' : 'USD'} Playdough.`;
            } 
            
            return `First ${this.editMode.maxNumberOfWinners} ${this.editMode.maxNumberOfWinners == 1 ? 'user' : 'users'} to answer correctly will be paid 
                ${this.currencyFormatter.format(this.editMode.correctAnswerReward, '$0.00')} in 
                ${this.editMode.rewardCurrencyId == CANADIAN_CURRENCY_TYPE.ID ? 'CAD' : 'USD'} Playdough. 
                (Total amount: ${this.currencyFormatter.format(this.editMode.maxNumberOfWinners*this.editMode.correctAnswerReward, '$0.00')} 
                ${this.editMode.rewardCurrencyId == CANADIAN_CURRENCY_TYPE.ID ? 'CAD' : 'USD'} Playdough)`;
        },

        hasReward(){
            return (this.isMultipleChoicePoll && this.correctAnswerPoll?.length > 0) 
            || (this.isYesNoQuestion && this.editMode.correctAnswerYesNoOptionId != correctAnswerYesNoOptionIds.notApplicable);
        },

        hasBeenAnswered() {
            return (this.isYesNoQuestion || this.isPollQuestion) && this?.poll?.yesVotes + this?.poll?.noVotes > 0;
        }
    },

    watch: {

        post: {
            handler(updatedActivity, oldActvity) {//eslint-disable-line
                this.reactionCounts = this.post.activity.reaction_counts;

                if (this.post.activity.verb.startsWith('motion')) {
                    this.allocationLoaded = false;
                    this.holdingsLoaded = false;

                    this.getGroupPortfolioMemberBreakdown();
                    this.getGroupPortfolioMemberHoldings();
                }

                if (this.post.activity.verb.startsWith('question')) {
                    this.refreshPoll();
                }
            },
            deep: true
        },

        correctAnswerPoll(newValue, oldValue){
            if(oldValue && oldValue[0]){
                let index = this.correctAnswerPoll.indexOf(oldValue[0]);
                if (index !== -1) {
                    this.correctAnswerPoll.splice(index, 1);
                }
            }
        }
    },

    validations() {
      return {
        currentStatusLength: {
          maxValue: maxValue(this.statusCharLimit)
        }
      }
    },

    methods: {
         isEducationalPost() {
            return (this.post.activity?.group?.educational || this.post.activity?.group?.coursesEnabled) && this.post.activity?.groupFeed?.courseId;
        },

        openFileDialogForImportingImage() {
            EventBus.emit('open-file-dialog');
        },
        selectedImage(importedImage) {
            this.thumbnailFile = importedImage;
            this.thumbnailUrl = importedImage.url;
            this.thumbnailRemoved = false;
        },
        openErrorModal(errorMessage) {
            this.$refs.importImageErrorModal.open(errorMessage)
        },
        removeThumbnail() {
            let customThumb = document.getElementById(this.thumbnailElementId);
            let existingThumb = document.getElementById('existingSingleThumbnail');
            if(  customThumb) {
                customThumb.src = ""; // Prevents former image from rendering when uploading new image
            }
            if( existingThumb ) {
                existingThumb.src = "";
            }
            this.thumbnailFile = null;
            this.thumbnailUrl = null;
            this.thumbnailRemoved = true;
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        refreshPoll(){
            if (this.post?.activity?.poll) {
                this.loadingPollData = true;
                
                PollingService.getPoll(this.post.activity.poll.pollId).then(resp => {
                    if( resp.data.status == 'success') {
                        this.poll = resp.data.poll;

                        this.$nextTick(() => {
                            const postAnswerActualContent = document.getElementById(`postAnswerActualContent-${this.post.activity.id}`);
                            this.addEventListenersToContentElement(postAnswerActualContent);

                            this.populateNonGeneralQuestionEditForm();
                        });
                    }

                    this.loadingPollData = false;
                });
            }
        },

        showPollingResultsSection(){
            return this.hasAnsweredOrClosedPoll() ;
        },

        hasAnsweredOrClosedPoll() {
            return  this.poll?.pollId && (this.poll?.closedDate || this.alreadyAnsweredPoll()) ;
        },

        isEducationalGroupPoll() {
             return this.post?.activity?.group?.educational
        },

        showPollAnswerDetailsSection(){
            return this.hasAnsweredOrClosedPoll() && this.poll?.answerDetails;
        },

        alreadyAnsweredPoll() {
            const activity = this.post.activity;
            const poll = activity.poll;

            if (poll?.pollType == "YesNo") {
                return activity?.own_reactions?.yesVote || activity?.own_reactions?.noVote;
            }

            if( activity.own_reactions){
                for (var i = 0; i < poll.pollOptions.length; i++) {
                    let optionCode = poll.pollOptions[i].code
                    //console.log("checking for pollOption "+optionCode);
                    
                    if (activity.own_reactions[optionCode] ) {
                        let selectedOptions = activity?.own_reactions[optionCode];
                        if( selectedOptions && selectedOptions.length > 0) {
                            return true;
                        }
                        
                    }
                    
                }
            }

            return false;
        },

        postReactedByVoting(a, r){
            this.refreshPoll();
            this.$emit('post-reacted', a, r);
        },

        getLastAskOrBid(){
            if(this.askPrice || this.bidPrice){
                return this.mainTradeOrderObject.tradeType === 'Buy' 
                    ? ` (last ask ${this.currencyFormatter.format(this.askPrice,'$0.00')})` 
                    : ` (last bid ${this.currencyFormatter.format(this.bidPrice,'$0.00')})`;
            } else {
                return '';
            }
        },

        castVote(vote) {
            const castingVote = (value) => {
                if (vote === 'yes') {
                this.castingYesVote = value;
                } else {
                    this.castingNoVote = value;
                }  
            }

            castingVote(true);

            MotionService.castVote(this.post.activity.motion.motionId, vote).then((resp) => {
                if (resp.data.status === 'success') {
                    const reaction = {
                        kind: vote === 'yes' ? 'yesVote' : 'noVote',
                        extra: {
                            created_at: TimeUtils.getFormattedTime(null, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
                        } 
                    };
                    this.$emit('post-reacted', this.post.activity, reaction); 
                } else {
                    if (resp.data.message === 'Not Authorized') {
                        const message = `You must be a member of ${this.post.activity.group.name} to vote on this motion`;

                        this.$toast.add({ severity: 'error', summary: resp.data.message, detail: message, life: 4000, group: 'center' });
                    } else {
                        this.$toast.add({ severity: 'error', summary: resp.data.message, life: 4000, group: 'center' });
                    }
                }

                castingVote(false);
            })
            .catch(err => {
                castingVote(false);
                this.$toast.add({ severity: 'error', summary: err, life: 4000, group: 'center' });
                console.err(err);
            }) 
        },

        resetQuoteDataValues() {
            this.askPrice = null;
            this.bidPrice = null;
            this.openValue = null;
            this.highValue = null;
            this.lowValue = null;
            this.volume = null;
            this.askSize = null;
            this.bidSize = null;
        },

        startPollingPrices() {
            if (this.refetcher) {
                this.resetQuoteDataValues();
                clearInterval(this.refetcher);
            }
            this.fetchPrices();

            this.refetcher = setInterval(() => {
                this.fetchPrices();
            }, POLLING_PRICE_INTERVAL);
        },

        stopPollingPrices(reset = true) {
            if (this.refetcher) {
                clearInterval(this.refetcher);

                if (reset) {
                    this.resetQuoteDataValues();
                }
                this.refetcher = null;
            }
        },

        fetchPrices() {
            DataService.getSnapQuotes(SecurityFormatter.getSymbolViaCdnExchanges(this.mainTradeOrderObject)).then((resp) => {
                const quoteData = resp.data.results.quote[0];
                if( quoteData && quoteData.pricedata) {
                    this.askPrice = quoteData.pricedata.ask;
                    this.bidPrice = quoteData.pricedata.bid;
                    this.openValue = quoteData.pricedata.open;
                    this.highValue = quoteData.pricedata.high;
                    this.lowValue = quoteData.pricedata.low;
                    this.volume = quoteData.pricedata.tradevolume;
                    this.askSize = quoteData.pricedata.asksize;
                    this.bidSize = quoteData.pricedata.bidsize;
                }
            });
        },

        openPostLikesListDialog() {
            this.$refs.postLikesListModal.open(this.post.activity);
        },

         

        createSummary(content) {
           let doc = new DOMParser().parseFromString(content, 'text/html');
            
            let summary = "";
            let lineCount = 0;
            for( let child of doc.body.children) {
                if( child.outerHTML.length + summary.length > 3000 ) {
                    break;
                }
                else {
                    summary += child.outerHTML;
                }
                
                if ( lineCount > 10 ) {
                    break;
                }
                ++lineCount;
            }
           
            return summary;

            
        },

        populateNonGeneralQuestionEditForm() {
            if (this.isYesNoQuestion || this.isPollQuestion) {
                this.editMode.forceRead = this.post.activity.poll.forceRead;

                if (this.isQuestionPostedInGroup) {
                    this.editMode.difficultyLevelOptionId = this.post.activity.poll?.difficultyId;
                    this.editMode.pollCategoryOptionId = this.post.activity.poll?.categoryId;
                }

                if (this.isYesNoQuestion) {
                    if (!this.poll.yesIsCorrect) {
                        this.editMode.correctAnswerYesNoOptionId = this.poll.yesIsCorrect ? correctAnswerYesNoOptionIds.yes : correctAnswerYesNoOptionIds.no;
                    } else {
                        this.editMode.correctAnswerYesNoOptionId = correctAnswerYesNoOptionIds.notApplicable;
                    }
                } else if (this.isPollQuestion) {
                    this.editMode.answerDetails = this.poll?.answerDetails;
                    
                    this.poll.pollOptions.forEach(option => {
                        if (option.code === pollChoicesCodes.choiceA) {
                            this.editMode.choiceA = option.title;
                        } else if (option.code === pollChoicesCodes.choiceB) {
                            this.editMode.choiceB = option.title;
                        } else if (option.code === pollChoicesCodes.choiceC) {
                            this.editMode.choiceC = option.title;
                        } else if (option.code === pollChoicesCodes.choiceD) {
                            this.editMode.choiceD = option.title;
                        } else if (option.code === pollChoicesCodes.choiceE) {
                            this.editMode.choiceE = option.title;
                        } 
                    });

                    const findCorrectAnswer = this.poll.pollOptions.filter(option => option.answerIsCorrect)[0];
                    if (this.isMultipleChoicePoll && findCorrectAnswer && findCorrectAnswer.code !== this.correctAnswerPoll[0]) {
                        this.correctAnswerPoll = [findCorrectAnswer.code];
                    }
                }

                if (this.hasReward) {

                    this.editMode.showAnswerOnSubmit = this.post.activity.poll.showAnswerOnSubmit;
                    this.editMode.answerDetails = this?.poll?.answerDetails;
                    this.editMode.correctAnswerReward = this.post.activity.poll.correctAnswerReward;
                    this.editMode.rewardCurrencyId = this.post.activity.poll.rewardCurrencyId;
                    this.editMode.maxNumberOfWinners = this.post.activity.poll.maxNumberOfWinners;
                }
                
            }
            
        },

        async updatePost() {
            this.updating = true;

            if( this.thumbnailUrl == null && this.post.activity.thumbnails?.length == 1 && this.thumbnailsEnabled && !this.thumbnailRemoved) {
                this.thumbnailUrl = this.post.activity.thumbnails[0]?.url;
            }

            if( this.isNote() ) {
                let mentionsAndMedia = gatherMentionsAndMedia(this.$refs.notePostEditor);
                
                let notePostUpdate = { 
                        noteVersionId: this.post.noteVersion.noteVersionId, 
                        postId: this.post.activity.foreign_id,
                        activityId: this.post.activity.id,
                        title: this.editMode.title,
                        summary: this.createSummary(this.editMode.content),
                        content: this.editMode.content,
                        mentions: mentionsAndMedia.mentions, 
                        mediaUrls: mentionsAndMedia.mediaUrls ,
                        thumbnailsEnabled:  this.thumbnailsEnabled,
                        thumbnailUrl: this.thumbnailUrl,
                        };
                 FeedService.updateNotePost(notePostUpdate).then( (resp) => {
                      
                     try {
                        if( resp.data.status == "success") {
                            
                            EventBus.emit('post-updated', {updatedContent : this.editMode.content, updatedActivity: resp.data.updatedActivity});
                            
                        }
                        else {
                            console.log("error updating note post " + JSON.stringify(resp.data));
                            
                            this.$toast.add({ severity: 'error', summary: "Sorry there was an error updating the post.  Please try again.  If the problem persists contact us on the #support channel.", detail: '', life: 4000, group: 'tc' });
                    
                        }
                     }catch(error ) {
                         console.log("Unexpected error updating note post " + error);
                     }
                     this.exitEditMode();
                     this.updating = false;
                 });
                 
            } else if (this.isQuestion) {
                let mentionsAndMedia = gatherMentionsAndMedia(this.$refs.questionPostEditor);
                
                let questionPostUpdate = { 
                      
                    postId: this.post.activity.foreign_id,
                    activityId: this.post.activity.id,
                    title: this.editMode.title,
                    content: this.editMode.content,
                    mentions: mentionsAndMedia.mentions, 
                    mediaUrls: mentionsAndMedia.mediaUrls ,
                    optionsToAdd: [],
                    optionsToUpdate: [],
                    optionIdsToRemove: [],
                    yesIsCorrect: null,
                    correctAnswerReward: null,
                    maxNumberOfWinners: null,
                    rewardCurrencyId: null,
                    showAnswerOnSubmit: null,
                    forceRead: null,
                    answerDetails: null,
                    categoryId: null,
                    difficultyId: null,
                };

               // if (!this.hasBeenAnswered) {
                    questionPostUpdate.forceRead = this.editMode.forceRead;
                    questionPostUpdate.categoryId = this.editMode.pollCategoryOptionId;
                    questionPostUpdate.difficultyId = this.editMode.difficultyLevelOptionId;

                    let correctAnswerExists = false;

                    if (this.isYesNoQuestion) {
                        let yesIsCorrect = this.editMode.correctAnswerYesNoOptionId !== correctAnswerYesNoOptionIds.notApplicable
                            ? this.editMode.correctAnswerYesNoOptionId === correctAnswerYesNoOptionIds.yes : null;
                        correctAnswerExists = correctAnswerExists ? correctAnswerExists : yesIsCorrect != null;

                        questionPostUpdate.yesIsCorrect = yesIsCorrect;
                    } else if (this.isPollQuestion) {

                        if (this.isMultipleChoicePoll && this.correctAnswerPoll.length > 0) {
                            correctAnswerExists = true;
                        }

                        let optionsToUpdate = [];
                        let optionsToAdd = [];
                        let optionIdsToRemove = [];

                        const isAnswerCorrect = (code) => {
                            const answer = this.correctAnswerPoll[0];

                            if (this.isMultipleChoicePoll && answer ) {
                                return this.correctAnswerPoll[0] === code;
                            } else if (!this.isMultipleChoicePoll) {
                                return null;
                            }

                            return false;
                        }

                        // The actual state of the choices when editing the poll form
                        const choices = [
                            {
                                code: pollChoicesCodes.choiceA,
                                title: this.editMode.choiceA?.trim(),
                                answerIsCorrect: isAnswerCorrect(pollChoicesCodes.choiceA)
                            },
                            {
                                code: pollChoicesCodes.choiceB,
                                title: this.editMode.choiceB?.trim(),
                                answerIsCorrect: isAnswerCorrect(pollChoicesCodes.choiceB)
                            },
                            {
                                code: pollChoicesCodes.choiceC,
                                title: this.editMode.choiceC?.trim(),
                                answerIsCorrect: isAnswerCorrect(pollChoicesCodes.choiceC)
                            },
                            {
                                code: pollChoicesCodes.choiceD,
                                title: this.editMode.choiceD?.trim(),
                                answerIsCorrect: isAnswerCorrect(pollChoicesCodes.choiceD)
                            },
                            {
                                code: pollChoicesCodes.choiceE,
                                title: this.editMode.choiceE?.trim(),
                                answerIsCorrect: isAnswerCorrect(pollChoicesCodes.choiceE)
                            }
                        ];

                        choices.forEach(choice => {
                            let currentChoice = this.poll.pollOptions.filter(option => option.code === choice.code)[0];

                            if (currentChoice) {
                                if (choice.title !== "") { // If answer we edited or selected/deselected to be correct is not empty, then we update it
                                    if (this.isMultipleChoicePoll) {
                                        if (currentChoice.title !== choice.title || currentChoice?.answerIsCorrect !== choice.answerIsCorrect) { // If we deselected/selected the answer to be the correct one or changed it's answer value, then we're updating the poll option
                                            let updatedChoice = {
                                                ...currentChoice,
                                                title: choice.title,
                                                answerIsCorrect: choice.answerIsCorrect
                                            };    
                                        
                                            optionsToUpdate.push(updatedChoice);
                                        }
                                    } else {
                                        if (currentChoice.title !== choice.title) { // If we changed the answer value from a multi select poll, then we're updating the poll option
                                            let updatedChoice = {
                                                ...currentChoice,
                                                title: choice.title,
                                                answerIsCorrect: choice.answerIsCorrect
                                            };    
                                        
                                            optionsToUpdate.push(updatedChoice);    
                                        }
                                    }
                                } else { // If answer we edited is empty, then we remove it
                                    optionIdsToRemove.push(currentChoice.pollOptionId);
                                }
                            } else { // If addin option
                                if (choice.title !== "") { // If current poll doesn't have option whatever, we're adding it
                                    let newChoice = {
                                        ...choice,
                                        pollId: this.poll.pollId
                                    }
                                
                                    optionsToAdd.push(newChoice);
                                }
                            }
                        })

                        questionPostUpdate.optionsToAdd = optionsToAdd;
                        questionPostUpdate.optionIdsToRemove = optionIdsToRemove;
                        questionPostUpdate.optionsToUpdate = optionsToUpdate;
                    }

                    if (correctAnswerExists) {
                        questionPostUpdate.showAnswerOnSubmit = this.editMode.showAnswerOnSubmit;
                        questionPostUpdate.answerDetails = this.editMode.answerDetails;
                        questionPostUpdate.maxNumberOfWinners = this.editMode.maxNumberOfWinners;

                        if (this.editMode.correctAnswerReward > 0) {
                            questionPostUpdate.correctAnswerReward = this.editMode.correctAnswerReward;
                            questionPostUpdate.rewardCurrencyId = this.editMode.rewardCurrencyId;
                        }
                    }
                //}

                FeedService.updateQuestionPost(questionPostUpdate).then( (resp) => {
                    
                    try{
                        if( resp.data.status === "success") {
                            
                            EventBus.emit('post-updated', {updatedContent : this.editMode.content, updatedActivity: resp.data.updatedActivity});
                            this.$toast.add({ severity: 'success', summary: 'Question post update successfull!', life: 4000, group: 'tc' });
                        }
                        else {
                            console.log("error updating question post " + JSON.stringify(resp.data));
                            this.$toast.add({ severity: 'error', summary: 'Error updating post', detail: resp.data.message, life: 4000, group: 'tc' });
                        }
                    }catch( error) {
                        this.$toast.add({ severity: 'error', summary: 'Error updating post', detail: error, life: 4000, group: 'tc' });
                        console.log("Unexpected error updating question post " + error);
                    }
                    this.exitEditMode();
                    this.updating = false;
                 });

            } else {
                let mentionsAndMedia = gatherMentionsAndMedia(this.$refs.statusPostEditor);
                
                 let statusPostUpdate = { 
                       
                        postId: this.post.activity.foreign_id,
                        activityId: this.post.activity.id,
                        content: this.editMode.content,
                        mentions: mentionsAndMedia.mentions, 
                        mediaUrls: mentionsAndMedia.mediaUrls ,
                        thumbnailsEnabled:  this.thumbnailsEnabled,
                        thumbnailUrl: this.thumbnailUrl,
                        };
                 FeedService.updateStatusPost(statusPostUpdate).then( (resp) => {
                    
                    try{
                        if( resp.data.status == "success") {
                            
                            EventBus.emit('post-updated', {updatedContent : this.editMode.content, updatedActivity: resp.data.updatedActivity});
                        }
                        else {
                            console.log("error updating note post " + JSON.stringify(resp.data));
                            this.$toast.add({ severity: 'error', summary: "Sorry there was an error updating the post.  Please try again.  If the problem persists contact us on the #support channel.", detail: '', life: 4000, group: 'tc' });
                        }
                    }catch( error) {
                        console.log("Unexpected error updating status post " + error);
                    }
                    this.exitEditMode();
                    this.updating = false;
                 });
            }
           
        },


        watchStatusLength() {
            
            let currentText = this.editMode.content;
            currentText = currentText.replace(/&nbsp;/g, " "); // Looks for all non-breaking space codes (Especially when you press enter), replaces them with actual single spaces
            // for future reference the reg expression below doesn't work in safar and breaks everything as safari doesn't yet support 
            // lookahead and lookbehind regular expressions.
            //const matches  = currentText.match(?:<a .+>)([^<>]+)(?=<\/a>)/g); // For mentions or links, gets the raw text in those 'a' tags

            let dom = domParser.parseFromString(currentText,"text/xml");
            const matches = dom.getElementsByTagName("a");

            this.currentStatusLength = StringUtils.stripHtml(currentText).length; // Strip entire html from 'status'

            if (matches && matches.length > 0) {
                // Substract the length of each raw text in a mention or link (In html form)
                for(let i = 0; i<matches.length; i++){
                    this.currentStatusLength -= matches[i].textContent.length//match.length;
                }

                // We want to treat mentions or links as 1 char, so we simply add each match we find
                this.currentStatusLength += matches.length;
            }

            
            if (this.currentStatusLength > this.editMode.statusCharLimit) {
                
                this.v$.currentStatusLength.$touch();
            } else {
                
                this.v$.currentStatusLength.$reset();
            }
        },

        isNote() {
            if( this.post && this.post.activity ) {
                return this.post.activity.has_note == 1;
            }
            else {
                return false;
            }
        },

        goToGroupProfile(group, groupFeed) {

            this.displayPost = false;
           
            this.loading = false;

            const latest =  {groupId: group.groupId, groupFeedId: 0, name:"Latest"};
            
            this.$store.commit("SET_GROUP_ACCESS_HIST", 
                { 
                    groupId: group.groupId, 
                    conduit: {
                        groupId: group.groupId,
                        type: "feed", 
                        obj: groupFeed && groupFeed.groupFeedId ? groupFeed : latest
                    }
                });

            if(groupFeed && groupFeed.groupFeedId && this.$route.params.groupId == group.groupId){
                EventBus.emit('select-group-feed', groupFeed);
            }

            this.$router.push('/groups/profile/' + group.groupId);
            
        },

        deletePostConfirmed() {
            const deletingAsSpam = false;

            if( this.post.activity.verb == "repost") {
                FeedService.deleteRepost(this.post, deletingAsSpam).then( resp => {
                
                    if( resp.data.status == "success"){
                        this.close();
                       
                        this.$emit('post-deleted', this.post);
                        
                        
                    }
                    else {
                        //
                    }
                })
            }
            else {
                FeedService.deletePost(this.post, deletingAsSpam).then( resp => {
                
                    if( resp.data.status == "success"){
                        this.close();
                         if( this.isEducationalPost() ){
                            EventBus.emit('refresh-current-module');
                        }
                        else {
                            this.$emit('post-deleted', this.post);
                        }
                    
                        
                    }
                    else {
                        //
                    }
                })
            }
        },

        deletePostCancelled() {

        },

        togglePostMenu(event) {
            this.$refs.menu.toggle(event)
        },

        close() {
            this.displayPost = false;
            this.currentStatusLength = 0;
            this.exitEditMode();
            this.trackReadingTime();
            this.castingYesVote = false;
            this.castingNoVote = false;
            this.poll = null;

            if (this.post.activity.verb.startsWith('motion')) {
                this.stopPollingPrices();
            } else if (this.isQuestion) {

               this.editMode.title = null;
               this.editMode.content = null;

               this.editMode.forceRead = false;
               this.editMode.maxNumberOfWinners = 0;

               this.editMode.showAnswerOnSubmit = false;
               this.editMode.answerDetails = null;
               this.editMode.correctAnswerYesNoOptionId = null;
               this.editMode.correctAnswerReward = 0;
               this.editMode.rewardCurrencyId = CANADIAN_CURRENCY_TYPE['ID'];

               this.editMode.difficultyLevelOptionId = null;
               this.editMode.pollCategoryOptionId = null;

               this.editMode.pollOptions = [];
               this.editMode.choiceA = "";
               this.editMode.choiceB = "";
               this.editMode.choiceC = "";
               this.editMode.choiceD = "";
               this.editMode.choiceE = "";
     
               this.correctAnswerPoll = [];
            }
            this.thumbnailRemoved = false;
            this.thumbnailUrl = null;
            this.thumbnailsEnabled = true;
        },

        goToPortfolio() {
            this.close();

            const groupId = this.post.activity.motion.groupId;
            const groupPortfolioId = this.post.activity.motion.groupPortfolioId;
            const isOnSameGroupProfile = this.$route.path.startsWith("/groups/profile/" + groupId);

            if (groupId && groupPortfolioId) {
                GroupService.getGroupPortfolio(groupId, groupPortfolioId).then(resp => {
                    if (resp.data.status === 'success') {

                        const portfolio = resp.data.groupPortfolio;

                        if (isOnSameGroupProfile) {
                            EventBus.emit("select-group-portfolio", {portfolio: portfolio});
                        } else {
                            this.$store.commit("SET_GROUP_ACCESS_HIST", 
                            { 
                                groupId: groupId, 
                                conduit: {
                                    groupId: groupId,
                                    type: "portfolio",
                                    obj: portfolio
                                }
                            });

                            this.$router.push('/groups/profile/' + groupId);
                        }
                    }
                });
            }
        },

        getPostContent() {
          const noteVersion = this.post.noteVersion;
          let content = null;

          if (noteVersion) {
            content = this.post.noteVersion.content;
          } else {
            if (this.post.activity.verb.startsWith('motion')) {
                content = this.post.activity.motion.details;
            } else {
                content = this.post.activity.content;
            }
          }

          if( noteVersion  && noteVersion.noteVersionTags.length ) {
             
              let tagSection = "<div><strong>Tags </strong>";
              noteVersion.noteVersionTags.forEach( tag => {
                  tagSection = tagSection + this.buildTag(tag) +"&nbsp;";
              });
              tagSection = tagSection+ "</div>"
              content = content + tagSection;
          }

          return content;
        },

        buildTag( tag ) {
            return '<a class="mention" href="/tag/profile/'+tag.tagId+'">#'+tag.name+'</a>';
        },

     
        isSharingEnabled() {
            return ( this.$store.state.featureSettings && this.$store.state.featureSettings.postSharingEnabled );
        },

        isGroupOwner(groupOwnerId) {
            return UserUtils.isUser(groupOwnerId);
        },

        isGroupOwnerAdminOrMod() {
            return UserUtils.isGroupOwnerAdminOrMod(this.post?.activity?.group?.groupOwnerId,this.$store.getters['users/groupRoleList']) 
        },

        isMotion() {
            return this.post.activity.verb.startsWith("motion");
        },

        getPostMenuItems() {
            const user_id = this.post.activity.actor.id;
            let menuItems = [];
            if( this.isSharingEnabled() && !this.post?.activity?.group?.isPrivate &&  !this.post?.activity?.group?.billingPlanId ) {
                  menuItems.push( {
                    label: 'Share',
                    icon: 'pi pi-share-alt',
                    command: () => {
                        this.showShareLink();
                    },
                });
                //console.log( 'postactivity = ', this.post.activity);
                //if( this.post?.activity?.groupFeed && !this.post.activity?.group?.educational && !this.post.activity?.group?.billingPlanId) {
                    menuItems.push( {
                        label: 'Get In-App Link',
                        icon: 'pi pi-link',
                        command: () => {
                            this.showInAppShareLink(this.post.activity.foreign_id, this.post.activity.groupFeed );
                        },
                    });
              // }
            }
            //console.log("getting follow menu item  and already follows = " + this.alreadyFollows)
            if( !this.isEducationalPost() ) {
                if (this.postUtils.alreadyFollowsUser(user_id)) {
                    menuItems.push( {
                        label: 'Unfollow',
                        icon: 'pi pi-users',
                        command: () => {
                            this.unfollow();
                        },
                    });
                } else if( !this.postUtils.isOwnPost(user_id)){
                    menuItems.push( {
                        label: 'Follow',
                        icon: 'pi pi-users',
                        command: () => {
                            this.follow();
                        },
                    });
                    
                }
            }
            
            
            if( !this.postUtils.isOwnPost(user_id)) {
                if (this.post.activity.group ){
                    if(  !this.post.activity.group.isPrivate && !this.isMotion() && !this.post.activity?.group?.educational && !this.post.activity?.group?.billingPlanId){
                        menuItems.push( {
                        label: 'Repost',
                        icon: 'pi pi-replay',
                        command: () => {
                                this.showRepostLocationsDialog();
                            },
                        });
                    }
                }
                else {
                   if( !this.isMotion() ) {
                    menuItems.push( {
                            label: 'Repost',
                            icon: 'pi pi-replay',
                            command: () => {
                                this.showRepostLocationsDialog();
                            },
                        });
                   }
                }
                if( !this.isEducationalPost() ) {
                 menuItems.push({
                    label: 'Report',
                        icon: 'pi pi-exclamation-triangle',
                        command: () => {
                            this.report();
                        },
                    });
                }
               
            }else {
                if (this.post.activity.group ){
                    if(  !this.post.activity.group.isPrivate && !this.isMotion() && !this.post.activity?.group?.educational && !this.post.activity?.group?.billingPlanId){
                        menuItems.push( {
                        label: 'Repost',
                        icon: 'pi pi-replay',
                        command: () => {
                                this.showRepostLocationsDialog();
                            },
                        });
                    }
                }
                if( !this.isMotion() ){
                    /* 
                    1. If status post or note
                    2. If general question
                    3. If Yes/No question and it hasn't been answered yet
                    4. If poll question and it hasn't been answered yet
                    */
                    if (!this.isQuestion || ( this.isQuestion && !this.post.activity.poll) || ((this.isYesNoQuestion || this.isPollQuestion) && !this.hasBeenAnswered) ) {
                        menuItems.push( {
                            label: 'Edit',
                            icon: 'pi pi-pencil',
                            command: () => {
                                this.enterEditMode();
                            },
                        });
                    }
                }
            }

            if (this.postUtils.isOwnPost(user_id) || UserUtils.userIsFeedModerator() || UserUtils.userIsAdmin()
              || (this.post.activity.group && this.isGroupOwnerAdminOrMod())) {
                if( !this.isMotion() ) {
                     menuItems.push( {
                        label: 'Edit',
                        icon: 'pi pi-pencil',
                        command: () => {
                            this.enterEditMode();
                        },
                    });
                    menuItems.push( {
                        label: 'Delete',
                        icon: 'pi pi-trash',
                        command: () => {
                            this.deletePost();
                        },
                    });
                }
            } 

             menuItems = menuItems.filter((value, index, self) => {

                /* We use 'self' since it returns the array of the current element (value),
                    we then use that array to loop through each element and compare it to 'value'.
                    If they match, we return the index found in 'self' and compare it to 'index' to determine
                    it's duplicate
                */
                return index === self.findIndex((item) => {
                    return item.label === value.label
                })
            })
            return menuItems;
        },

        showShareLink() {
            
            this.$refs.sharePostModal.open(SHARE_URL +"/"+this.post.activity.foreign_id+".xhtml");

        },

         showInAppShareLink(postId, groupFeed) {
            let url = '/index.html#/groups/profile/'+groupFeed.groupId+'?postId='+postId;
            this.$refs.sharePostModal.open(url);

        },

        enterEditMode() {
            this.mode = 'edit';
            this.thumbnailUrl = null;
            this.thumbnailsEnabled = true;
            this.thumbnailFile = null;
           
            this.editMode.content = this.getPostContent();
            if( this.isNote() ) {
                this.editMode.title = this.post.noteVersion.title;
            } else if (this.isQuestion) {
              
                this.editMode.title = this.post.activity.title;

            } else {
                this.watchStatusLength();
            }

            
        },

        exitEditMode() {
            this.mode = 'read';
           
            this.editMode.content = null;
            this.thumbnailRemoved = false;
            this.thumbnailUrl = null;
            this.thumbnailsEnabled = true;
        },

        onCommentLiked(event) {
            let comment = event.comment;
            if (comment.children_counts.like) {
                comment.children_counts.like = comment.children_counts.like + 1;
            } else {
                comment.children_counts['like'] = 1;
            }
            let feedCommentReaction = {
                feedReactionTypeId : LIKE,
                analystId : this.$store.state.users.user.analyst.analystId,
                relatedPostId : comment.data.related_post_id,
                relatedActivityId : comment.data.related_activity_id,
                relatedReactionId : comment.id,
                relatedFeedCommentId: comment.data.feed_comment_id,
                relatedAnalystId : comment.user_id,
                reactionId: event.reaction.id
                
            };

            this.commentLikes.push(feedCommentReaction);

        },

        onCommentUnliked(comment) {
            
            if (comment.children_counts.like && comment.children_counts.like > 0 ) {
                comment.children_counts.like = comment.children_counts.like - 1;
            } else {
                comment.children_counts['like'] = 0;
            }
            

            this.commentLikes =this.commentLikes.filter(val => val.relatedFeedCommentId !== comment.data.feed_comment_id);
        },

        onLikeClicked() {
            if (!this.likeClicked) {
                this.likedClicked = true;
                

                if (!this.postUtils.isOwnPost(this.post.activity.actor.id) && !this.postUtils.isAlreadyLiked(this.post.activity)) {
                    
                    FeedService.likePost(this.post.activity).then((resp) => {
                        
                        if (resp.data.status == 'success') {
                            
                            this.$emit('post-liked', {activity:this.post.activity, reaction: resp.data.reaction});
                            this.likedClicked = false;
                            EventBus.emit('track-like-post', this.post);
                        }
                        else if(resp.data.message == 'Not Member'){
                            this.$toast.add({ severity: 'error', summary: `You must be a member of the group "${this.post.activity.group.name}" to like this post.`, detail: '', life: 3000, group: 'center' });
                        }
                        else if( resp.data.message == 'Likes disabled'){
                            this.$toast.add({ severity: 'error', summary: 'Likes and reactions are currently disabled for this group feed.', life: 3000, group: 'center' });
                        }
                    });
                }
                else if( !this.postUtils.isOwnPost(this.post.activity.actor.id) && this.postUtils.isAlreadyLiked(this.post.activity) ) {
                    // we are unliking here
                
                    FeedService.unlikePost(this.post.activity.own_reactions.like[0].id).then((resp) => {
                    
                        if (resp.data.status == 'success') {
                            this.$emit('post-unliked', this.post.activity);
                            EventBus.emit('track-unlike-post', this.post);
                        }
                        else{
                            console.log("error unliking "+ JSON.stringify(resp.data));
                        }
                        this.likedClicked = false;
                    });

                } 
                
            }

        },
        report() {
            let postUrl = null;
            let postId = null;
           
            postUrl = SHARE_URL +"/"+this.post.activity.foreign_id+".xhtml"
            postId = this.post.activity.foreign_id;
            


            let type ="Post";

            let report = {
                type: type,
                postUrl: postUrl,
                postId: postId,

            }
            EventBus.emit('post-reported', report);
           
        },

        showRepostLocationsDialog() {
            const activityGroupId = this.post.activity.verb == "repost" ? this.post.activity?.object?.group?.groupId : this.post.activity?.group?.groupId;
            // console.log("showRepostLocationsDialog", activityGroupId, this.post.activity?.object?.group?.groupId, this.post.activity?.group?.groupId);
            this.$refs.postLocationsDialog.open(activityGroupId);
        },

        doRepost(postToMainFeed, groupId, groupSubFeedId) {
            FeedService.repost(this.post.activity, null,postToMainFeed, groupId ? groupId : null, groupSubFeedId ? groupSubFeedId : null).then( (resp) => {
                 if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: "You've reposted "+ this.post.activity.actor.data.name+"'s post.", detail: '', life: 3000, group: 'tc' });
                    
                    EventBus.emit('reposted');
                }
                else {
                    if( resp.data.message == 'Not Authorized'){
                        this.$toast.add({ severity: 'error', summary: "You must be a group member to repost.", detail: '', life: 3000, group: 'tc' });
                    }
                    else {
                        console.log(JSON.stringify(resp.data));
                    }
                }
            });
        },

        deletePost() {
            this.$refs.confirmDeleteModal.open();
        },
        
        async unfollow() {
            await FeedService.unfollowUser(this.post.activity.actor.data.name).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'You are no longer following ' + this.post.activity.actor.data.name, detail: '', life: 2000, group: 'tc' });
                    delete this.$store.state.users.user.analyst.followed.followedAnalysts[this.post.activity.actor.id];

                    EventBus.emit('followed-changed');
                }
            });
        },

        async follow() {
            await FeedService.followUser(this.post.activity.actor.data.name).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'You are now following ' + this.post.activity.actor.data.name, detail: '', life: 2000, group: 'tc' });
                    this.$store.state.users.user.analyst.followed.followedAnalysts[this.post.activity.actor.id] = { analystId: this.post.activity.actor.id };

                    EventBus.emit('followed-changed');
                }
            });
        },

        async onPostComment(comment) {
            if (comment && comment !== '') {
                this.$toast.add({ severity: 'success', summary: 'Posting comment...', life: 500, group: 'center' });
                await FeedService.postCommentOnPost(this.post.activity, comment).then((resp) => {
                    if ('success' == resp.data.status) {
                        this.$refs.commentEditor.resetEditor();
                        this.$toast.add({ severity: 'success', summary: 'Comment posted', life: 1500, group: 'center' });

                        this.noMoreResults = false;
                        this.loadMoreComments();

                        /* When we post a comment, we want to update other feeds with the same activity with the new comment count that are rendered elsewhere 
                        on the same page */
                        if (this.viewingFrom === 'Popular Notes') {
                            EventBus.emit('update-comments-from-home-activity', { activity: this.post.activity, reaction: resp.data.reaction });
                        } else if (this.viewingFrom === 'Home') {
                            EventBus.emit('update-comments-from-popular-notes-activity', { activity: this.post.activity, reaction: resp.data.reaction });
                        }
                        this.$emit('comment-posted', { activity: this.post.activity, reaction: resp.data.reaction });
                        try {
                            EventBus.emit('track-comment', { 'post': this.post, 'reaction': resp.data.reaction});
                        } catch (err) {
                            return;
                        }
                    }
                    else {
                        if( resp.data.message == 'Not Member'){
                            this.$toast.add({ severity: 'error', summary: `You must be a member of the group "${this.post.activity.group.name}" to comment.`, detail: '', life: 3000, group: 'center' });
                        } 
                        else if( resp.data.message == 'Not Authorized') {
                            this.$toast.add({ severity: 'error', summary: 'Not Authorized', life: 3000, group: 'center' });
                        }
                        else if( resp.data.message == 'Comments disabled'){
                            this.$toast.add({ severity: 'error', summary: 'Comments are currently disabled for this group feed.', life: 3000, group: 'center' });
                        }
                        else {
                            this.$toast.add({ severity: 'error', summary: 'There was an error posting your comment.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                        }
                       
                    }
                });
            }
        },

        addEventListenersToContentElement(contentElement){
            if (contentElement) {
                Array.from(contentElement.getElementsByTagName('IMG')).forEach((img) => {
                    img.addEventListener('click', () => {
                        this.$refs.imageDialog.open(img.src, img.height > img.width);
                    })
                })

                Array.from(contentElement.getElementsByTagName('A')).forEach((link) => {
                    if (link.hasAttribute('data-mention-type')) {
                        link.addEventListener('click', () => {
                            this.close();
                        })
                    }
                    else {
                        const href = link.getAttribute('href');
                        
                        link.addEventListener('click', () => {
                            if (href.startsWith(SHARE_URL)) {
                                this.close();
                            }

                            EventBus.emit('track-post-link-clicked', { postId: this.post.activity.foreign_id, href:link.getAttribute('href')});
                        })
                    }
                })
            }
        },

        onShown() {
            this.resetReadTimer();
            if( !this.postUtils.isOwnPost(this.post.activity.actor.id)){
                FeedService.recordLook(this.post.activity);
            }

            if(!this.postUtils.isAlreadyRead(this.post.activity)) {
                FeedService.recordRead(this.post.activity).then(resp => {
                    if (resp?.data?.status == 'success') {
                        this.$emit('post-read', { activity: this.post.activity, reaction: resp?.data?.reaction });
                    }
                });
            }

            if (this.post.activity.verb.startsWith('motion')) {
                this.startPollingPrices();
            }

            this.likeClicked = false;
            this.comments = [];
            this.noMoreResults = false;
            this.loading = false;
           
            
            this.loadMoreComments();
            this.$nextTick(() => {

                const postActualContent = document.getElementById(`postActualContent-${this.post.activity.id}`);
                this.addEventListenersToContentElement(postActualContent);
                
                if( this.startInEditMode ){
                    this.enterEditMode();
                }else {
                    this.mode = 'read';
                    EventBus.emit('track-view-post', this.post );
                    this.startReadTimer();
                }
                
            })

            
            
        },

        startReadTimer() {
            this.readingStartTime = new Date();
            this.readingTimer = setTimeout(() => {
                this.trackReadingTime();
            }, 2*60*1000);
        },

        resetReadTimer() {
            clearTimeout(this.readingTimer);
            this.readingStartTime = null;
            this.readingStopTime = null;
        },

        stopReadTimer() {
           clearTimeout(this.readingTimer);
           this.readingStopTime = new Date();
            
        },

        trackReadingTime() {
            if(this.readingStartTime){
                this.stopReadTimer();
                EventBus.emit('track-read-post', {post: this.post, readTimeSeconds: (this.readingStopTime.getTime() - this.readingStartTime.getTime())/1000 });
            } else {
                this.resetReadTimer();
            }
        },

        async onCommentDeleted() {
            
            this.likeClicked = false;
            this.comments = [];
            this.noMoreResults = false;
            this.loading = false;
           
            
            await this.loadMoreComments();
            
        },

        hasMoreComments() {
            return this.comments.length > 0 && !this.noMoreResults;
        },

        async loadData() {
            FeedService.getCommentLikes(this.post.activity.foreign_id).then(resp=> {
                if( resp.data.status == 'success') {
                    this.commentLikes = resp.data.commentLikes
                }
                else {
                    console.log("error getting comment likes ", resp.data.message);
                }
                this.loadMoreComments();
                

            });
        },

        async loadMoreComments() {
            if (!this.loading) {
                this.loading = true;

                if (this.hasMoreComments()) {
                    this.loadActivityComments(this.post.activity.id, this.comments[this.comments.length - 1].id);
                } else {
                    if (!this.noMoreResults) {
                        this.loadActivityComments(this.post.activity.id, 0);
                    } else {
                        this.loading = false;
                    }
                }
            }
        },

        async loadActivityComments(activityId, lastCommentId ){
          await FeedService.getActivityComments(activityId, lastCommentId).then((resp) => {
              this.loading = false;
             
              if (resp.results.length > 0) {
                  //console.log("comments: "+ JSON.stringify(resp.results))
                  let arr = this.comments.concat(resp.results);

                  this.comments = arr;
                  this.noMoreResults = false;
              } else {
                  this.noMoreResults = true;
              }
          });
        },

        openReactionMenu(e) {
            e.stopPropagation();
            if( this.$refs.reactionMenu ) {
                this.$refs.reactionMenu.toggle(e);
            }
            e.preventDefault();
        },
        addOrRemoveReaction(activity, reaction) {//eslint-disable-line
            //console.log("adding or removing reaction for message "+ JSON.stringify(this.data));
            if(!this.postUtils.isOwnPost(activity.actor.id)){

                const reactions = activity.own_reactions[reaction];
 
                if (reactions !== undefined) {
                    for(let i = 0; i < reactions.length; i++) {
                        const r = reactions[i];

                        const userId = r.userID || r.user_id;

                        if (this.userUtils.isUser(userId)) {
                            if( r.kind == reaction){

                                FeedService.removeReaction(r.id).then(() => {
                                    this.$emit('post-unreacted', activity, reaction);
                                });

                                break;
                            }
                        }
                    }
                } else {
                    FeedService.reactToPost(activity, reaction).then((resp) => {
                        if (resp.data.status === 'success') {
                            this.$emit('post-reacted', activity, resp.data.reaction);
                        } else if(resp.data.message == 'Not Member'){
                            this.$toast.add({ severity: 'error', summary: `You must be a member of the group "${activity.group.name}" to react to this post.`, detail: '', life: 3000, group: 'center' });
                        } else if( resp.data.message == 'Likes disabled'){
                            this.$toast.add({ severity: 'error', summary: 'Likes and reactions are currently disabled for this group feed.', life: 3000, group: 'center' });
                        }
                    })
                    .catch((err) => {
                        this.$toast.add({ severity: 'error', summary: err, detail: '', life: 3000, group: 'center' });
                        console.error(err);
                    })
                }
            }
        },
        formatReactionCount(key) {
            let reactionNum = this.reactionCounts[key];

            return {
                icon: FeedService.reactionMap[key],
                number: reactionNum
            };
        },

        alreadyReacted(activity, key) {
            if (activity.own_reactions[key] === undefined) {
                return false;
            } else {
                return activity.own_reactions[key].length > 0;
            }
        },

        getGroupPortfolioMemberBreakdown() {
            PortfolioService.getPublicGroupPortfolioMemberBreakdown(this.post.activity.motion.groupPortfolioId, this.post.activity.motion.investmentAccountId).then(allocationData=> {
                if (allocationData.status === 200) {
                    this.getDataFromPortfolioAllocation(allocationData.data);
                    // console.log("getGroupPortfolioMemberBreakdown", this.selectedAccountData, allocationData.data);
                }
                this.allocationLoaded = true;
            });
        },

        getDataFromPortfolioAllocation(allocationData) {
            this.sectorLabels = [];
            this.sectorValues = [];
            this.assetLabels = [];
            this.assetValues = [];

            if (allocationData.status === 'error') {
                return;
            }

            const sectorData = allocationData.sectorMapping.dataPoints.filter((data) => {
                return data.value > 0;
            });
            const assetData = allocationData.classMapping.dataPoints.filter((data) => {
                return data.value > 0;
            });

            const labelValueGenerator = (data, sectorOrAsset) => {
                let formattedCategory = '';
                const splitCategory = data.category.split(' ');
                const formattedWords = splitCategory.map((word) => {
                    return word.replace(word[0], word[0].toUpperCase());
                });

                if (formattedWords.length > 1) {
                    formattedCategory = formattedWords.join(' ');
                } else {
                    formattedCategory = formattedWords[0];
                }

                if (sectorOrAsset === 'sector') {
                    this.sectorLabels.push(formattedCategory);
                    this.sectorValues.push((data.value * 100).toFixed(2));
                } else if (sectorOrAsset === 'asset') {
                    this.assetLabels.push(formattedCategory);
                    this.assetValues.push((data.value * 100).toFixed(2));
                }
            };

            sectorData.forEach((data) => {
                labelValueGenerator(data, 'sector');
            });

            assetData.forEach((data) => {
                labelValueGenerator(data, 'asset');
            });
        },

        getGroupPortfolioMemberHoldings() {
            PortfolioService.getPublicGroupPortfolioMemberHoldings(this.post.activity.motion.groupPortfolioId, this.post.activity.motion.investmentAccountId).then(assetPositionData=>{
                if (assetPositionData.status === 200) {
                    this.getDataFromPortfolioHoldings(assetPositionData.data);
                }
                this.holdingsLoaded = true;
            });
        },

        getDataFromPortfolioHoldings(assetPositionData) {
            if (assetPositionData.status === 'error') {
                return;
            }

            const equityPositions = assetPositionData.holdings.equityPositions;
            const fundPositions = assetPositionData.holdings.fundPositions;
            const derivativePositions = assetPositionData.holdings.derivativePositions;
            const fixedIncomePositions = assetPositionData.holdings.fixedIncomePositions;
            const otherPositions = assetPositionData.holdings.otherAssetPositions;

            const publicHoldingsArray = [];

            if (equityPositions.length > 0) {
                equityPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (fundPositions.length > 0) {
                fundPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (derivativePositions.length > 0) {
                derivativePositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (fixedIncomePositions.length > 0) {
                fixedIncomePositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (otherPositions.length > 0) {
                otherPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            const orderedHoldingsArray = publicHoldingsArray.sort((a, b) => {
                return b.weight - a.weight;
            });

            this.holdings = orderedHoldingsArray;

            this.holdingLabels = [];
            this.holdingValues = [];

            let cumulativeWeight = 0;

            this.holdings.forEach(holding => {
                const weight = holding.weight * 100;
                this.holdingValues.push(weight);
                cumulativeWeight += weight;
                
                const ticker = holding.otherAsset?.proxySymbol || holding.otherAsset?.symbol || holding.derivative?.symbol || holding.securityListing?.symbol || holding.fund?.symbol;
                this.holdingLabels.push(ticker);
            });

            this.holdingValues.push(100 - cumulativeWeight);
            this.holdingLabels.push('Other');
        },
    },

    name: 'PostDetailsDialog',
};
</script>

<style>



.post-details-modal {
    max-height: 100vh !important;
    width: 700px;
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

.post-details-modal .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
    border-bottom: 1px solid #F2F2F2;
    padding: 16px 16px;
    max-height: 80px;
}

.post-details-modal.editing-modal .p-dialog-content {
    overflow-y: auto;
    overflow-x: hidden;
}
.post-details-modal.editing-modal .p-dialog-content::-webkit-scrollbar {
    width: 10px;
} 
.post-details-modal.editing-modal .p-dialog-content::-webkit-scrollbar-track {
    background-color: #F2F2F2;
    opacity: 1;
    transition: background-color .2s;
} 
.post-details-modal.editing-modal .p-dialog-content::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #BFBFBF;    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
}
.post-details-modal .p-dialog-content {
    overflow: hidden;
    padding: 0px;
}

.post-details-modal .p-dialog-footer {
    border-top: 1px solid #F2F2F2;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
    padding: 16px;
}

.post-details-modal .p-dialog-header .p-dialog-header-icon {
    display: none;
}

.post-actual-content > p {
        padding: 0 ;
        margin:0;
    }

@media (max-width: 760px) {
    /* .comment-footer {
        width: auto !important;
    } */
    .comment-footer .p-avatar {
        align-self: flex-start;
        margin-top: 0.5rem;
        /* width: 36px;
        height: 36px; */
        margin-right: 3px;
    }
    .post-details-modal {
        width: 100% !important;
        height: 100% !important; /*80vh;*/
        border-radius: 0px !important;
    }
    .post-details-modal .p-dialog-header {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }    
    .post-details-modal .p-dialog-footer {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 16px;
        width: 100% !important;
    }
    .post-details-modal .p-dialog-header .p-dialog-header-icon {
        display: block;
    }  
    
    .post-actual-content > figure {
        margin-inline-start: 0 !important;
    }

    

}

    .post-actual-content ol li:before {
        content: counter(list-0,decimal) ". ";
    }
    .post-actual-content ol li.ql-indent-1:before {
        content: counter(list-1,lower-alpha) ". ";
    }

    .post-actual-content ol li.ql-indent-2:before {
        content: counter(list-2,lower-roman) ". ";
    }
    .post-actual-content ol li.ql-indent-3:before {
        content: counter(list-3,decimal) ". ";
    }
    .post-actual-content ol li.ql-indent-4:before {
        content: counter(list-4,lower-alpha) ". ";
    }
    .post-actual-content ol li.ql-indent-5:before {
        content: counter(list-5,lower-roman) ". ";
    }
    .post-actual-content ol li.ql-indent-6:before {
        content: counter(list-6,decimal) ". ";
    }
    .post-actual-content ol li.ql-indent-7:before {
        content: counter(list-7,lower-alpha) ". ";
    }
    .post-actual-content ol li.ql-indent-8:before {
        content: counter(list-8,lower-roman) ". ";
    }

 .post-actual-content li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}
.post-actual-content li:not(.ql-direction-rtl):before {
    margin-left: -1.5em;
    margin-right: .3em;
    text-align: right;
}

.post-actual-content ul>li:before {
    content: "\2022";
}

.post-actual-content li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}

  .post-actual-content ol>li, .post-actual-content ul>li {
    list-style-type: none;
} 

.post-actual-content ol, .post-actual-content ul{
    
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    list-style-position: inside;
}

 .post-actual-content ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0
}

.post-actual-content ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-1;
} 

/* .post-actual-content ol li.ql-indent-1 {
    counter-increment: list-1;
} */

.post-actual-content ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.post-actual-content ol li.ql-indent-2 {
    counter-increment: list-2;
}

.post-actual-content ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.post-actual-content ol li.ql-indent-3 {
    counter-increment: list-3;
}

.post-actual-content ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.post-actual-content ol li.ql-indent-4 {
    counter-increment: list-4;
}

.post-actual-content ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
}

.post-actual-content ol li.ql-indent-5 {
    counter-increment: list-5;
}

.post-actual-content ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
}

.post-actual-content ol li.ql-indent-6 {
    counter-increment: list-6;
}

.post-actual-content ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
}

.post-actual-content ol li.ql-indent-7 {
    counter-increment: list-7;
}

.post-actual-content ol li.ql-indent-8 {
    counter-reset: list-9;
}

.post-actual-content ol li.ql-indent-8 {
    counter-increment: list-8;
}


</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
::v-deep(*:not(.pi, .material-symbols-outlined)), ::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}



::v-deep(.post-actual-content ol li:not(.ql-direction-rtl), .post-actual-content ul li:not(.ql-direction-rtl) ){
    padding-left: 1.5em;
}

::v-deep(.post-actual-content li.ql-indent-1:not(.ql-direction-rtl) ){
    padding-left: 4.5em;
}
::v-deep(.post-actual-content li.ql-indent-2:not(.ql-direction-rtl) ){
    padding-left: 7.5em;
}
::v-deep(.post-actual-content li.ql-indent-3:not(.ql-direction-rtl) ){
    padding-left: 10.5em;
}
::v-deep(.post-actual-content li.ql-indent-4:not(.ql-direction-rtl) ){
    padding-left: 13.5em;
}
::v-deep(.post-actual-content li.ql-indent-5:not(.ql-direction-rtl) ){
    padding-left: 16.5em;
}
::v-deep(.post-actual-content li.ql-indent-6:not(.ql-direction-rtl) ){
    padding-left: 19.5em;
}
::v-deep(.post-actual-content li.ql-indent-7:not(.ql-direction-rtl) ){
    padding-left: 22.5em;
}
::v-deep(.post-actual-content li.ql-indent-8:not(.ql-direction-rtl) ){
    padding-left: 25.5em;
}

::v-deep(.post-actual-content .ql-indent-1:not(.ql-direction-rtl)){
    padding-left: 3em;
}
::v-deep(.post-actual-content .ql-indent-2:not(.ql-direction-rtl)){
    padding-left: 6em;
}
::v-deep(.post-actual-content .ql-indent-3:not(.ql-direction-rtl)){
    padding-left: 9em;
}
::v-deep(.post-actual-content .ql-indent-4:not(.ql-direction-rtl)){
    padding-left: 12em;
}
::v-deep(.post-actual-content .ql-indent-5:not(.ql-direction-rtl)){
    padding-left: 15em;
}
::v-deep(.post-actual-content .ql-indent-6:not(.ql-direction-rtl)){
    padding-left: 18em;
}
::v-deep(.post-actual-content .ql-indent-7:not(.ql-direction-rtl)){
    padding-left: 21em;
}
::v-deep(.post-actual-content .ql-indent-8:not(.ql-direction-rtl)){
    padding-left: 24em;
}

/* ::v-deep(.post-actual-content ol>li, .post-actual-content ul>li) {
    list-style-type: none !important;
} */

::v-deep(.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus) {

  box-shadow: none;
}

::v-deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
  font-weight: normal;
}


[class*='image-preview'] {
    margin-top: 8px;
    border: 1px solid black;
    border-radius: 8px;
    height: 200px;
    text-align: center;
    width: fit-content;
}
[class*='image-preview'] img {
    /* width: 100%; */
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
}
.image-preview-thumbnail {
    /* width: 250px; */
    margin-left: auto;
    margin-right: auto;
}
.image-preview-thumbnail.wide-photo {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}

.image-preview-thumbnails {
    /* width: 100%; */
    margin-left:auto;
    margin-right:auto;
}
.image-preview-thumbnails > img {
    width: 50%;
}
.image-preview-thumbnails img:first-of-type {
    padding-right: 8px;
}

.edit-form-container .title-input {
    width: 100%;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.edit-form-container .title-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.view-polling-results-section {
    margin: 0 8px;
}

.question-post {
    color: #32364E; /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}
.poll {
    font-size:18px;
     left: 12px;
    top: -4px;
}

.motion-post {
    color: #32364E; /*#33CC99;*/
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 26px;
}

::v-deep(figure.table > table) {
    width: 100%;
}

.looks-container{
    margin-left: auto;
}

::v-deep(.embedly-card) {
    max-height: 680px;
    transform-origin: top center;
    -webkit-transform:scale(0.9);
    -moz-transform-scale:scale(0.9);
    }


 ::v-deep(.insta-iframe) {
     height:760px;
     
 }


 ::v-deep(.insta-blockquote){
     max-height: 500px;
     /* height: 630px; */
     transform-origin: top center;
     -webkit-transform:scale(0.7);
    -moz-transform-scale:scale(0.7);
 }

::v-deep(.ck.ck-media-form.ck-responsive-form){
    position: fixed;
    background: white;
    box-shadow: var(--ck-drop-shadow),0 0;
}

.edit-form-container {
    padding: 1rem;
}

.post-details-modal .p-dialog-header .status-post-owner-info {
    display: flex;
    align-items: center;
    width: 100%;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container {
    margin-left: 8px;
    display: block;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .full-name {
    display: block;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .full-name:hover {
    cursor: pointer;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .username {
    display: block;
    color: #a6a6a6;
    font-size: 12px;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .username span:hover {
    cursor: pointer;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .posted-in-span {
    color: #33CC99;
    font-size: 13px;
    cursor: pointer;
}
.post-details-modal .p-dialog-header .status-post-owner-info .more-options {
    margin-left: auto;
}

.post-details-modal .p-dialog-content .post-body-content {
    padding: 16px;
}
.post-details-modal .p-dialog-content .post-body-content .post-content-title {
    font-weight: bold;
    font-size: 24px;
    color: #32364e;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
}
.post-details-modal .p-dialog-header .post-educational-title {
    font-weight: bold;
    font-size: 24px;
    color: #32364e;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 16px 0;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar  [class*='container'] {
    display: flex;
    align-items: center;
    color: #32364e;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar  [class*='container'] i {
    font-size: 24px;
    margin-right: 8px;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar .likes-container {
    margin-right: 16px;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar .likes-container .likes-amount:hover,
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar .likes-container i.liked,
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar .likes-container i:not(.own-post):hover {
    cursor: pointer;
    color: #33CC99;
}
.post-details-modal .p-dialog-content .post-body-content .activity-stats-bar .comments-container {
    margin-right: 16px;
}

.post-details-modal .p-dialog-content .p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    overflow: auto;
    width: 100%;
    height: 100%;
}
.post-details-modal .p-dialog-content .p-scrollpanel ::v-deep(.p-scrollpanel-content::-webkit-scrollbar) {
    display: none;
}

.post-details-modal .p-dialog-footer .comment-footer ::v-deep(.post-comment-button) {
    margin: 0px;
}

.post-details-modal .p-dialog-content .comments-section {
    border-top: 2px solid #BFBFBF;
    padding: 16px;
    margin-top:10px;
}
.post-details-modal .p-dialog-footer .comment-footer {
    display: flex;
    align-items: center;
    /* width: 100%; */
}

.post-actual-content {
    word-wrap: break-word;
    font-size: 16px;
}
::v-deep(.post-actual-content p) {
    color: black;
}
::v-deep(.post-actual-content .image) {
    margin: 0 auto !important;
}

::v-deep(.post-actual-content .image.image-style-side) {
    float: right;
}
::v-deep(.post-actual-content .image img) {
    max-width: 100%;
    max-height: 100%;
    width: unset;
    height: unset;
}
::v-deep(.post-actual-content img:hover) {
    cursor: pointer;
}
::v-deep(.post-actual-content img) {         
    max-width: 100%;
    padding: 8px 0px;
}
::v-deep(a) {
    font-weight: bold;
}
::v-deep(.analyst-mention) {
    color: #693BF5;
}
::v-deep(.security-mention) {
    color: #33CC99;
}

::v-deep(.tag-mention) {
    color: #33CC99;
}
  

.more-options {
    float: right;
}

.p-avatar:hover {
    cursor: pointer;
}

.trade-details-section {
    margin-bottom: 24px;
}
.trade-details-section .trade-details-header {
    font-weight:bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}
.trade-details-section .trade-details-content ::v-deep(b),
.trade-details-section .trade-details-content b {
    color: black;
}
.trade-details-section .trade-details-content .trade-details-portfolio-name {
    color: #33CC99;
    cursor: pointer;
}
.trade-details-section .allocation-chart-section {
    display: flex;
}
.trade-details-section .allocation-chart-section ::v-deep(.allocation-card) {
    width: 50%;
    margin-left: 24px;
}

.motion-details-header {
    font-weight:bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}

.voting-deadline-section {
    margin-bottom: 16px;
}
.voting-deadline-section .voting-deadline-header {
    font-weight:bold;
    color: #32364e;
}
.voting-deadline-section ::v-deep(b) {
    color: black;
}

.summary-section {
    margin-bottom: 20px;
    font-size: 14px;
}

.summary-section-header {
    font-weight: bold;
    color: #32364E;
    font-size: 14px;
    margin-bottom: 10px;
}
.summary-section ul {
    word-wrap: break-word;
    padding-inline-start: 40px;
}

.rationale-header {
    font-weight: bold;
    color: #32364E;
    margin-bottom: 10px;
}

.motion-section {
    border-top: 1px solid #BFBFBF;
    margin: 24px 0;
    padding-top: 12px;
}

.motion-status-text {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: black;
}
.voted-text {
    color: black;
    font-size: 24px;
    font-weight: bold;
}
.voted-text ::v-deep(.yes) {
    color: #33CC99;
}
.voted-text ::v-deep(.no) {
    color: #E63E3E;
}

.motion-security-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}
.motion-security-section .security-exchange-text {
    font-weight: bold;
    margin-bottom: 12px;
}
.motion-security-section ::v-deep(.p-grid) {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
}
.motion-security-section ::v-deep(.p-grid > div:first-child) {
    padding-left: 8px;
}
.motion-security-section .security-table .label-container {
    text-align: left;
}
.motion-security-section .security-table .value-container {
    font-weight: bold;
}

.voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.voting-container button {
    display: flex;
    align-items: center;
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 0px 8px;
    background: none;
}
.voting-container button:hover {
    transform:rotate(0.2deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
    animation-iteration-count: infinite;
    background: none;
    border-color: none;
    box-shadow: none;
}
.voting-container .yes-vote-button {
    margin-right: 12px;

}
.voting-container .yes-vote-button:hover {
    border-color: #33CC99;
}
.voting-container .no-vote-button:hover {
    border-color: #E63E3E;
}
.voting-container .vote-label {
    font-weight: bold;
    color: #32364e;
    font-size: 14px;
}
.voting-container .yes-vote-button .vote-icon {
    color: #33CC99;
}
.voting-container .no-vote-button .vote-icon {
    color: #E63E3E;
}
.voting-container .vote-icon {
    font-size: 24px;
}
/* .like--float {
    opacity: 0;
}
.up {
    margin-left: -5px;
    -webkit-animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    99% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
} */
.cancel-post-button {
    /* font-size: 0.8rem; */
    /* padding: 5px; */
    padding: 0.4rem 0.8rem;
   margin-left: 5px;
    font-size: 12px;
     color: #32364e;
    background: #FFFFFF;
    border: 1px solid #32364e;
    border-radius: 500px;
    /* padding: 8px 64px; */
}

.update-post-button {
    
    /* font-size: 0.8rem; */
    /* padding: 5px; */
    padding: 0.4rem 0.8rem;
     margin-left: auto;
    font-size: 12px;
     color: #FFFFFF;
    background: #33CC99;
    border: 1px solid #33CC99;
    border-radius: 500px;
    /* padding: 8px 64px; */
    
}





.cancel-post-button:hover {
    color: #FFFFFF;
    background: #32364e;
    border-color: #32364e;
}
.cancel-post-button:focus {
    box-shadow: none;
    border-color: #32364e;
}


.update-post-button:hover {
    background: #00C083;
    border-color: #00C083;
}
.update-post-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}



.more-options-edit-mode {
    margin-left: 5px;
}

.status-container.input-error .ck-editor__editable_inline.ck-focused,
.status-container.input-error .ck.ck-content,
.status-container.input-error .ck.ck-toolbar {
  border-color: #E63E3E !important;
}

.actions-container {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.actions-container > * {
    margin-right: 8px;
}
.actions-container > *:last-child {
    margin-right: 0px;
}

.following-badge {
    font-size: 14px;
    font-weight: normal;
}

.reaction-bar-container {
    display: flex;
    margin-bottom: 8px;
}
.reaction-icon {
    color: #32364e;
}
.reaction-icon:hover {
    cursor: pointer;
    transform:rotate(0.4deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
}
.reaction-container {
    margin-right: 4px;
}
.reaction-container:last-child {
    margin-right: 0;
}
.reaction-container > span:hover {
    cursor: pointer;
}
.reaction-container.own-post > span:hover {
    cursor: default;
    pointer-events: none;
}
.reaction-container.reacted .num {
    color: #33CC99;
}
/* Shaking animation */
@keyframes shakeError {
  0% {
    transform: translateX(0); }
  15% {
    transform: translateX(0.375rem); }
  30% {
    transform: translateX(-0.375rem); }
  45% {
    transform: translateX(0.375rem); }
  60% {
    transform: translateX(-0.375rem); }
  75% {
    transform: translateX(0.375rem); }
  90% {
    transform: translateX(-0.375rem); }
  100% {
    transform: translateX(0); } }

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
} 



.post-details-modal > :not(.edit-form-container) > *:not(.p-dropdown, .p-inputnumber) > ::v-deep(.p-inputtext) {
    border-radius: 10px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    margin-right: 16px;
    padding: 8px 0px 8px 16px;
    flex: 1;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
.post-details-modal > :not(.edit-form-container) > *:not(.p-dropdown, .p-inputnumber) > ::v-deep(.p-inputtext:hover) {
    border-color: #bfbfbf;
}
.post-details-modal > :not(.edit-form-container) > *:not(.p-dropdown, .p-inputnumber) > ::v-deep(.p-inputtext:focus) {
    box-shadow: none;
    border-color: #bfbfbf;
}
.post-details-modal > :not(.edit-form-container) > *:not(.p-dropdown, .p-inputnumber) > ::v-deep(.p-inputtext::placeholder) {
    color: #bfbfbf;
    font-size: 16px;
}

/* QUESTION DETAILS DIALOG STYLING */
.question-post-details-modal .post-content-title {
    position: relative;
    left: -15px;
}

.question-post-details-modal .title-icon {
    /* width: 75px; */
    margin-left: 10px;
    margin-right: 10px;
}

.choice-text-input {
  width: 65%;
}
.question-label{
  text-align:left;
  margin-bottom: 1rem;
}

.choices-label{
  text-align:left;
  margin-bottom: 1rem;
  margin-top:1rem;
}
.gap-2 {
    gap: 1rem!important;
    margin-left: 1rem;
}

.gap-3 {
    gap: 1rem!important;
    margin-bottom: 25px;
    margin-left: 1rem;
    
}

.align-items-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap!important;
}
.flex {
    display: flex!important;
}
.ml-2{
    margin-left: 0.5rem!important;
}

.choices-section{
  text-align: left;
  margin-top: 5px;
  display: flex;
  flex-direction: column; 
}

.choices-section .p-dropdown {
    width: 13rem;
}

.contest-dropdown .p-dropdown {
    width: unset !important;
    min-width: 13rem !important;
}

.choices-labels{
  display: flex;
  justify-content: space-between;
}

.input-section {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 5px 0px 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.choices-item-container {
  flex-grow: 1;
}
.required {
  margin-left: 2px;
}

.reward-currency-select-button {
    margin-left: auto;
    width: fit-content;
}
::v-deep(.reward-currency-select-button .p-button) {
    font-size: 0.9rem !important;
    padding: 4px 6px 4px 6px;
}
::v-deep(.reward-currency-select-button .p-button:focus) {
    box-shadow: none;
}

/* MOTION DETAILS DIALOG STYLING */

.motion-post-details-modal .title-icon {
    width: 30px;
    margin-right: 15px;
}

@media (max-width: 760px) {
    ::v-deep(.scroll-to-top-button) {
        bottom: 50px;
    }

    .trade-details-section .allocation-chart-section {
        flex-direction: column;
    }
    .trade-details-section .allocation-chart-section ::v-deep(.allocation-card) {
        width: 100%;
        margin-left: 0;
    }

    .motion-status-text {
        font-size: 16px;
    }
    .voted-text {
        font-size: 16px;
    }

    .voting-container button {
        padding: 6px;
    }
    .voting-container .vote-label {
        font-size: 14px;
    }
    .voting-container .vote-icon {
        font-size: 22px;
    }
    
    .motion-security-section ::v-deep(.p-grid > div:first-child) {
        padding-right: 0;
    }
    .motion-security-section ::v-deep(.p-grid > div:last-child > .quote-data-table) {
        margin-left: 8px;
    }
    .security-exchange-text {
        font-size: 12px;
    }
    .p-grid ::v-deep(.quote-data-table .value-container > span),
    .p-grid ::v-deep(.quote-data-table .label-container > span) {
        font-size: 12px;
    }
}

@media (max-width: 592px) {

    .question-post-details-modal .title-icon {
        /* width: 60px;
        height: 40px; */
    }

    .motion-post-details-modal .post-content-title {
        position: relative;
        left: 10px;
    }
    .motion-post-details-modal .title-icon {
        width: 20px;
    }
}

</style>
