<template>
    <Menu ref="menu" :model="[{label: 'Reactions'}]" class="reaction-menu" :popup="true">
            <template #item>
            <div @mouseleave="onMouseLeave" style="border: none;outline:none;" >
                <span :title="reaction.text" v-for="reaction of reactions" :key="reaction" class='reaction-menu-item' @click="updateReaction(reaction.text)">{{reaction.icon}}</span>   
            </div>
        </template>
    </Menu>
</template>

<script>
export default {
    name: "ReactionMenu",
    props: {
        reactions: {
            type: Array,
            required: true
        }
    },
    emits: ['reaction-selected'],

    methods: {
        onMouseLeave(e) {//eslint-disable-line
            //console.log("on blur fired");
           this.$refs.menu.hide(e);
        },
        show(e) {
            this.$refs.menu.show(e);
           
            //this.$refs.menu.toggle(e)
        },
        toggle(e) {
            this.$refs.menu.toggle(e)
        },

        hide(){
            this.$refs.menu.hide();
        },

        updateReaction(reaction) {
            this.$emit('reaction-selected', reaction);
        }
    }
}
</script>
<style >
.reaction-menu.p-menu{
    width: 275px;
    padding: 8px 0 8px 8px;
}
.reaction-menu.p-menu:focus{
    
    border: none;
    outline: none;
}

.reaction-menu.p-menu:focus-visible{
    
    border: none;
    outline: none;
}
.reaction-menu.p-menu > ul:focus-visible{
    
    border: none;
    outline: none;
}
</style>
<style  scoped>
.reaction-menu-item {
    cursor: pointer;
    padding: 3px;
    margin: 2px;
}
.reaction-menu-item:hover {
    background-color: #f2f4fa;
}
</style>>

