<template>
    <div :class="activityCardClass" :data-log-id="activityContentDivId" :data-is-note="isNote()" :data-feed-post-id="getFeedPostId()" :data-is-own-post="isOwnPost()">
        <!-- POSTS/NOTES -->
        <template v-if="activity.verb == 'post'">
            <div v-if="template === 'Popular Notes'" class="popular-notes-card" @click="onClick">
                <div class="activity-block">
                    <template v-if="images.length === 1">
                        <div class="activity-content-row">
                            <div class="activity-content-column">
                                <div class="activity-title" v-if="activity.title">{{ activity.title }}</div>
                                <div :id="activityContentDivId" class="activity-content" v-html="getPostPreviewContent(activity)"></div>
                                <div class="image-preview-thumbnail">
                                    <img :src="images[0].url" />
                                </div>
                                <div class="activity-stats-bar">
                                    <UserAvatar :imageSrc="activity.actor.data.avatar" size="sm" class="p-d-md-block p-d-none" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)" />
                                    <span class="full-name"
                                        ><span @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)">{{ fullName }}</span> | {{ timeUtils.getTimeFromNow(activity.time) }}</span
                                    >
                                    <span class="likes-container">
                                        <i :class="{ 'pi pi-thumbs-up': true, liked: postUtils.isAlreadyLiked(activity), 'own-post': postUtils.isOwnPost(activity.actor.id) }" @click="onLikeClicked" />
                                        <span class="likes-amount" v-tooltip.bottom="'View Likes'" @click="openPostLikesListDialog($event)">{{ hasLikeCounts() ? '' + activity.reaction_counts.like : '' }}</span>
                                    </span>
                                    <span class="comments-container">
                                        <i class="pi pi-comment" />
                                        <span class="comments-amount">{{ getCommentCount() }}</span>
                                    </span>
                                    <div class="reaction-container" v-if="!postUtils.isOwnPost(activity.actor.id)">
                                        <div class="reaction-icon-container" @click="openReactionMenu">
                                            <span class="material-symbols-outlined reaction-icon">sentiment_satisfied</span>
                                        </div>
                                        <ReactionMenu :reactions="postReactions" @reaction-selected="addOrRemoveReaction(activity, $event)" ref="reactionMenu" />
                                    </div>
                                    <span class="looks-container" v-if="hasLookCounts()" v-tooltip.bottom="activity.reaction_counts.look + ' views'"> 👀 {{ activity.reaction_counts.look }} </span>
                                </div>
                                <div class="reaction-bar-container">
                                    <template v-for="key in Object.keys(reactionCounts)" :key="key">
                                        <div
                                            :class="{ 'reaction-container': true, 'own-post': postUtils.isOwnPost(activity.actor.id), reacted: alreadyReacted(activity, key) }"
                                            v-if="!['comment', 'like', 'look', 'read'].includes(key) && formatReactionCount(key)['number'] > 0"
                                            @click.stop="addOrRemoveReaction(activity, key)"
                                        >
                                            <span class="num">{{ formatReactionCount(key)['number'] }}</span>
                                            <span class="icon">{{ formatReactionCount(key)['icon'] }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="activity-title" v-if="activity.title">{{ activity.title }}</div>
                        <div :id="activityContentDivId" class="activity-content" v-html="getPostPreviewContent(activity)" @click="onClick"></div>
                        <a class="view-more-text" v-if="images.length > 0 && textLength < 200">View more</a>

                        <template v-if="images.length > 0">
                            <div :class="thumbnailClass(images[0].url)" v-if="images.length == 1">
                                <img :src="images[0].url" />
                            </div>
                            <div class="image-preview-thumbnails" v-else-if="images.length > 1">
                                <template v-for="image in images" :key="image">
                                    <img :src="image.url" />
                                </template>
                            </div>
                        </template>

                        <div class="activity-stats-bar">
                            <UserAvatar :imageSrc="activity.actor.data.avatar" size="sm" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)" />
                            <span class="full-name"
                                ><span @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)">{{ fullName }}</span> | {{ timeUtils.getTimeFromNow(activity.time) }}</span
                            >
                            <span class="likes-container">
                                <i class="pi pi-thumbs-up" />
                                <span class="likes-amount" v-tooltip.bottom="'View Likes'" @click="openPostLikesListDialog($event)">{{ hasLikeCounts() ? '' + activity.reaction_counts.like : '' }}</span>
                            </span>
                            <span class="comments-container">
                                <i class="pi pi-comment" />
                                <span class="comments-amount">{{ getCommentCount() }}</span>
                            </span>
                            <div class="reaction-container" v-if="!postUtils.isOwnPost(activity.actor.id)">
                                <div class="reaction-icon-container" @click="openReactionMenu">
                                    <span class="material-symbols-outlined reaction-icon">sentiment_satisfied</span>
                                </div>
                                <ReactionMenu @reaction-selected="addOrRemoveReaction(activity, $event)" :reactions="postReactions" ref="reactionMenu" />
                            </div>
                            <span class="looks-container" v-if="hasLookCounts()" v-tooltip.bottom="activity.reaction_counts.look + ' views'"> 👀 {{ activity.reaction_counts.look }} </span>
                        </div>
                        <div class="reaction-bar-container">
                            <template v-for="key in Object.keys(reactionCounts)" :key="key">
                                <div
                                    :class="{ 'reaction-container': true, 'own-post': postUtils.isOwnPost(activity.actor.id), reacted: alreadyReacted(activity, key) }"
                                    v-if="!['comment', 'like', 'look', 'read'].includes(key) && formatReactionCount(key)['number'] > 0"
                                    @click.stop="addOrRemoveReaction(activity, key)"
                                >
                                    <span class="num">{{ formatReactionCount(key)['number'] }}</span>
                                    <span class="icon">{{ formatReactionCount(key)['icon'] }}</span>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
            <div v-else @click="onClick">
                <div class="activity-block">
                    <template v-if="!isEducationalPost()">
                        <template v-if="isAd">
                            <div class="status-post-owner-info" @click="doNothingOnClick($event)">
                                <UserAvatar :imageSrc="'/invrs-icon-white1.png'" customStyle="min-width: 40px; min-height: 40px;" @click="doNothingOnClick($event)" />
                                <div class="names-container" @click="doNothingOnClick($event)">
                                    <span v-if="!activity.adLink.targetUrl" class="full-name">Partners We Love</span>
                                    <span v-else class="full-name">INVRS</span>
                                    <span class="username" v-if="userUtils.userIsAdvertising() || userUtils.userIsAdmin()">
                                        Posted {{ timeUtils.getTimeFromNow(activity.time) }}{{ activity.revision_date ? ' | Edited ' + timeUtils.getTimeFromNow(activity.revision_date) : '' }}
                                    </span>
                                </div>

                                <div class="actions-container" @click="doNothingOnClick($event)">
                                    <Badge class="ad-flag" value="Ad" />
                                    <template v-if="userUtils.userIsAdvertising() || userUtils.userIsAdmin()">
                                        <MoreOptionsMenu class="more-options" @click.stop="toggleMenu" />
                                        <Menu ref="menu" :model="postMenuItems" :popup="true" />
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="status-post-owner-info">
                                <UserAvatar :imageSrc="activity.actor.data.avatar" customStyle="min-width: 40px; min-height: 40px;" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)" />
                                <div class="names-container">
                                    <span class="full-name" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)">{{ fullName }}</span>
                                    <span class="username">
                                        <span @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)">@{{ activity.actor.data.name }}</span>
                                        | Posted {{ timeUtils.getTimeFromNow(activity.time) }}{{ activity.revision_date ? ' | Edited ' + timeUtils.getTimeFromNow(activity.revision_date) : '' }}
                                        {{ activity.group ? '|' : '' }}
                                        <span v-if="!isEducationalPost()" class="posted-in-span" @click.stop="goToGroupProfile(activity.group, activity.groupFeed)">
                                            {{ activity.group ? ' Posted in ' + activity.group.name : '' }}
                                            {{ activity.groupFeed ? '- ' + activity.groupFeed.name : '' }}
                                        </span>
                                    </span>
                                </div>

                                <div class="actions-container">
                                    <FollowButton :followTarget="userObjectForFollowButton" :confirmUnfollow="false" v-if="!userUtils.isUser(activity.actor.id) && !postUtils.alreadyFollowsUser(activity.actor.id) && !isEducationalPost()" />

                                    <template v-if="postUtils.isOwnPost(activity.actor.id) || userUtils.userIsAdmin()">
                                        <MoreOptionsMenu class="more-options" @click.stop="toggleMenu" />
                                        <Menu ref="menu" :model="postMenuItems" :popup="true" />
                                    </template>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="actions-container" style="float: right">
                            <MoreOptionsMenu class="more-options" @click.stop="toggleMenu" v-if="postMenuItems && postMenuItems.length > 0" />
                            <Menu ref="menu" :model="postMenuItems" :popup="true" />
                        </div>
                    </template>

                    <template v-if="isEducationalPost()">
                        <div class="activity-title" v-if="activity.title">
                            <template v-if="activity.task == true">
                                <img src="/assets/images/task.png" class="title-icon" style="margin-right: 5px; scale: 0.8" />
                            </template>
                            <template v-else>
                                <img src="/assets/images/lesson.png" class="title-icon" style="scale: 0.7" />
                            </template>
                            {{ activity.title }}
                        </div>
                        <div v-else>
                            <img src="/assets/images/lesson.png" class="title-icon" style="scale: 0.7" />
                        </div>
                    </template>
                    <template v-else>
                        <div class="activity-title" v-if="activity.title">
                            {{ activity.title }}
                        </div>
                    </template>

                    <div :id="activityContentDivId" :class="activityContentClass" v-html="getPostPreviewContent(activity)"></div>
                    <a class="view-more-text" v-if="images.length > 0 && textLength < 200 && !shouldShowFullContent()">View more</a>

                    <template v-if="images.length > 0 && !shouldShowFullContent()">
                        <div :class="thumbnailClass(images[0].url)" v-if="images.length == 1">
                            <img :src="images[0].url" />
                        </div>
                        <div class="image-preview-thumbnails" v-else-if="images.length > 1">
                            <template v-for="image in images" :key="image">
                                <img :src="image.url" />
                            </template>
                        </div>
                    </template>

                    <div v-if="activity.mentioned_tags" style="margin-top: 10px">
                        <template v-for="feedTag in activity.mentioned_tags" :key="feedTag">
                            <a class="mention tag-mention" data-mention-type="tag" @click="onTagClick($event)" :href="'/index.html#/tag/profile/' + feedTag.tagId">{{ feedTag.tag.startsWith('#') ? feedTag.tag : '#' + feedTag.tag }}</a>
                            &nbsp;
                        </template>
                    </div>
                    <div class="activity-stats-bar">
                        <span class="likes-container" v-if="allowsLikes()">
                            <i :class="{ 'pi pi-thumbs-up': true, liked: postUtils.isAlreadyLiked(activity), 'own-post': postUtils.isOwnPost(activity.actor.id) }" @click="onLikeClicked" />
                            <span class="likes-amount" v-tooltip.bottom="'View Likes'" @click="openPostLikesListDialog($event)">{{ hasLikeCounts() ? '' + activity.reaction_counts.like : '' }}</span>
                        </span>
                        <span class="comments-container" v-if="!isAd && allowsComments()">
                            <i class="pi pi-comment" @click="onClick" />
                            {{ getCommentCount() }}
                        </span>
                        <div class="reaction-container" v-if="!postUtils.isOwnPost(activity.actor.id) && allowsLikes()">
                            <div class="reaction-icon-container" @click="openReactionMenu">
                                <span class="material-symbols-outlined reaction-icon">sentiment_satisfied</span>
                            </div>
                            <ReactionMenu :reactions="postReactions" @reaction-selected="addOrRemoveReaction(activity, $event)" ref="reactionMenu" />
                        </div>
                        <span class="looks-container" v-if="hasLookCounts()" v-tooltip.bottom="activity.reaction_counts.look + ' views'"> 👀 {{ activity.reaction_counts.look }} </span>
                    </div>
                    <div class="reaction-bar-container">
                        <template v-for="key in Object.keys(reactionCounts)" :key="key">
                            <div
                                :class="{ 'reaction-container': true, 'own-post': postUtils.isOwnPost(activity.actor.id), reacted: alreadyReacted(activity, key) }"
                                v-if="!['comment', 'like', 'look', 'read'].includes(key) && formatReactionCount(key)['number'] > 0"
                                @click.stop="addOrRemoveReaction(activity, key)"
                            >
                                <span class="num">{{ formatReactionCount(key)['number'] }}</span>
                                <span class="icon">{{ formatReactionCount(key)['icon'] }}</span>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="recent-comment-block" data-role="recent-comment" v-if="hasComments() && !isMobile()">
                    <div class="recent-comment-row">
                        <UserAvatar :imageSrc="activity.latest_reactions.comment[0].user.data.avatar" size="sm" customStyle="flex: 1 0 auto;" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)" />
                        <div class="comment-section">
                            <CommentCard :origActivity="activity" :comment="activity.latest_reactions.comment[0]" :summary="true" />
                            <span class="view-more-text" v-if="hasCommentCounts() && activity.num_comments > 1">{{ viewMoreCommentsText }}</span>
                        </div>
                    </div>
                </div>
                <div class="no-comment-block" v-else-if="!isMobile() && !isAd && allowsComments()">
                    <UserAvatar :imageSrc="users.user.analyst.avatar" size="sm" />
                    <InputText type="text" placeholder="Add a comment..." @click="onClick" />
                </div>
            </div>
        </template>

        <!-- REPOSTS -->
        <template v-else-if="activity.verb == 'repost'">
            <div @click="onClick">
                <div class="repost-info">
                    <span class="username" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)"
                        ><i class="pi pi-replay" />&nbsp;&nbsp;Reposted by {{ getReposter() }}
                        <span v-if="activity.object.group" @click.stop="goToGroupProfile(activity.object.group)">{{ '| Originally posted in ' + activity.object.group.name }}</span>
                    </span>
                    <div class="actions-container">
                        <FollowButton :followTarget="userObjectForFollowButton" :confirmUnfollow="false" v-if="!userUtils.isUser(activity.actor.id) && !postUtils.alreadyFollowsUser(activity.actor.id)" />
                        <!-- <Badge class='following-badge' value="Following" v-else-if="!userUtils.isUser(activity.actor.id) && postUtils.alreadyFollowsUser(activity.actor.id)"></Badge> -->
                        <MoreOptionsMenu class="more-options" @click.stop="toggleMenu" />
                        <Menu ref="menu" :model="postMenuItems" :popup="true" />
                    </div>
                </div>
                <!-- <div>Reposted by {{getReposter()}}.</div> -->
                <template v-if="activity.object && activity.object.actor && activity.object.actor.data">
                    <div class="activity-block" style="padding-top: 8px">
                        <div class="status-post-owner-info">
                            <UserAvatar :imageSrc="activity.object.actor.data.avatar" customStyle="width: 40px; height: 40px;" @click="postUtils.goToAnalystProfile(activity.object.actor.data.name, $event)" />
                            <div class="names-container">
                                <span class="full-name" @click="postUtils.goToAnalystProfile(activity.object.actor.data.name, $event)">{{ originalPosterFullName }}</span>
                                <span class="username">
                                    <span @click="postUtils.goToAnalystProfile(activity.object.actor.data.name, $event)">@{{ activity.object.actor.data.name }}</span>
                                    | {{ timeUtils.getTimeFromNow(activity.object.time) }}{{ activity.object.revision_date ? ' | Edited ' + timeUtils.getTimeFromNow(activity.object.revision_date) : '' }}
                                    <span @click.stop="goToGroupProfile(activity.group, $event)">{{ activity.group ? '| Reposted in ' + activity.group.name : '' }}</span>
                                </span>
                            </div>
                            <!-- <MoreOptionsMenu class="more-options" @click.stop="toggleMenu" />
                                <Menu ref="menu" :model="postMenuItems" :popup="true" /> -->
                        </div>

                        <div class="activity-title" v-if="activity.object.title">{{ activity.object.title }}</div>
                        <div :id="activityContentDivId" class="activity-content" v-html="getPostPreviewContent(activity.object)"></div>
                        <a class="view-more-text" v-if="images.length > 0 && textLength < 200">View more</a>

                        <template v-if="images.length > 0">
                            <div :class="thumbnailClass(images[0].url)" v-if="images.length == 1">
                                <img :src="images[0].url" />
                            </div>
                            <div class="image-preview-thumbnails" v-else-if="images.length > 1">
                                <template v-for="image in images" :key="image">
                                    <img :src="image.url" />
                                </template>
                            </div>
                        </template>
                        <div v-if="activity.object.mentioned_tags" style="margin-top: 10px">
                            <template v-for="feedTag in activity.object.mentioned_tags" :key="feedTag">
                                <a class="mention tag-mention" :href="'/index.html#/tag/profile/' + feedTag.tagId">{{ feedTag.tag.startsWith('#') ? feedTag.tag : '#' + feedTag.tag }}</a>
                                &nbsp;
                            </template>
                        </div>
                        <div class="activity-stats-bar">
                            <span class="likes-container" v-if="allowsLikes()">
                                <i :class="{ 'pi pi-thumbs-up': true, liked: postUtils.isAlreadyLiked(activity.object), 'own-post': postUtils.isOwnPost(activity.object.actor.id) }" @click="onLikeClicked" />
                                <span class="likes-amount" v-tooltip.bottom="'View Likes'" @click="openPostLikesListDialog($event)">{{ hasLikeCounts() ? '' + activity.object.reaction_counts.like : '' }}</span>
                            </span>
                            <span class="comments-container" v-if="allowsComments()">
                                <i class="pi pi-comment" @click="onClick" />
                                {{ getRepostCommentCount() }}
                            </span>
                            <div class="reaction-container" v-if="!postUtils.isOwnPost(activity.actor.id) && allowsLikes()">
                                <div class="reaction-icon-container" @click="openReactionMenu">
                                    <span class="material-symbols-outlined reaction-icon">sentiment_satisfied</span>
                                </div>
                                <ReactionMenu :reactions="postReactions" @reaction-selected="addOrRemoveReaction(activity.object, $event)" ref="reactionMenu" />
                            </div>
                            <span class="looks-container" v-if="hasLookCounts()" v-tooltip.bottom="activity.object.reaction_counts.look + ' views'"> 👀 {{ activity.object.reaction_counts.look }} </span>
                        </div>
                    </div>
                    <div class="reaction-bar-container">
                        <template v-for="key in Object.keys(reactionCounts)" :key="key">
                            <div
                                :class="{ 'reaction-container': true, 'own-post': postUtils.isOwnPost(activity.object.actor.id), reacted: alreadyReacted(activity.object, key) }"
                                v-if="!['comment', 'like', 'look', 'read'].includes(key) && formatReactionCount(key)['number'] > 0"
                                @click.stop="addOrRemoveReaction(activity.object, key)"
                            >
                                <span class="num">{{ formatReactionCount(key)['number'] }}</span>
                                <span class="icon">{{ formatReactionCount(key)['icon'] }}</span>
                            </div>
                        </template>
                    </div>
                </template>

                <template v-else>
                    <div class="activity-block">Original post is not available</div>
                </template>
                <div class="recent-comment-block" data-role="recent-comment" v-if="hasComments() && !isMobile()">
                    <div class="recent-comment-row">
                        <UserAvatar :imageSrc="activity.object.latest_reactions.comment[0].user.data.avatar" size="sm" customStyle="flex: 1 0 auto;" @click="postUtils.goToAnalystProfile(activity.object.actor.data.name, $event)" />
                        <div class="comment-section">
                            <CommentCard :origActivity="activity" :comment="activity.object.latest_reactions.comment[0]" :summary="true" />
                            <span class="view-more-text" v-if="hasCommentCounts() && activity.object.num_comments > 1">{{ viewMoreCommentsText }}</span>
                        </div>
                    </div>
                </div>
                <div class="no-comment-block" v-else-if="!isMobile() && allowsComments()">
                    <UserAvatar :imageSrc="users.user.analyst.avatar" size="sm" />
                    <InputText type="text" placeholder="Add a comment..." @click="onClick" />
                </div>
            </div>
        </template>

        <!-- FOLLOWINGS -->
        <template v-else-if="activity.verb == 'follow'">
            <div class="follow-card">
                <UserAvatar :imageSrc="activity.actor.data.avatar" customStyle="min-width: 40px; min-height: 40px;" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)" />
                <!-- <Avatar :image="activity.actor.data.avatar" class="p-avatar p-component" shape="circle" @click='postUtils.goToAnalystProfile(activity.actor.data.name, $event)'/> -->

                <span class="user-handle">
                    <a :href="'/index.html#/analysts/profile/' + activity.actor.data.name">@{{ activity.actor.data.name }}</a>
                </span>
                <span style="color: black">&nbsp; now follows </span>
                <div class="followee-container" v-if="activity.object.data">
                    <UserAvatar :imageSrc="activity.object.data.avatar" customStyle="min-width: 40px; min-height: 40px;" @click="postUtils.goToAnalystProfile(activity.object.data.name, $event)" />
                    <!-- <Avatar :image="activity.object.data.avatar" class="p-avatar p-component" shape="circle" @click='postUtils.goToAnalystProfile(activity.object.data.name, $event)'/> -->
                    <span class="user-handle">
                        <a :href="'/index.html#/analysts/profile/' + activity.object.data.name">@{{ activity.object.data.name }}</a>
                    </span>
                </div>
                <template v-else> someone secret. </template>
            </div>
        </template>

        <!-- MOTIONS -->

        <template v-else-if="activity.verb.startsWith('motion')">
            <div class="activity-block motion-activity-block" @click="onClick">
                <div class="status-post-owner-info">
                    <UserAvatar :imageSrc="activity.actor.data.avatar" customStyle="min-width: 40px; min-height: 40px;" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)" />
                    <div class="names-container">
                        <span class="full-name" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)">{{ fullName }}</span>
                        <span class="username">
                            <span @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)">@{{ activity.actor.data.name }}</span>
                            | Posted {{ timeUtils.getTimeFromNow(activity.time) }}
                            {{ activity.group ? '|' : '' }}
                            <span class="posted-in-span" @click.stop="goToGroupProfile(activity.group, activity.groupFeed)">
                                {{ activity.group ? ' Posted in ' + activity.group.name : '' }}
                                {{ activity.groupFeed ? '- ' + activity.groupFeed.name : '' }}
                            </span>
                        </span>
                    </div>
                    <div class="actions-container">
                        <FollowButton :followTarget="userObjectForFollowButton" :confirmUnfollow="false" v-if="!userUtils.isUser(activity.actor.id) && !postUtils.alreadyFollowsUser(activity.actor.id)" />
                        <!-- <Badge class='following-badge' value="Following" v-else-if="!userUtils.isUser(activity.actor.id) && postUtils.alreadyFollowsUser(activity.actor.id)"></Badge> -->
                        <MoreOptionsMenu class="more-options" @click.stop="toggleMenu" />
                        <Menu ref="menu" :model="postMenuItems" :popup="true" />
                    </div>
                </div>

                <div class="activity-title">
                    <img class="title-icon" src="/assets/images/motion_icon.png" />
                    {{ activity.motion.title }}
                </div>
                <div :id="activityContentDivId" class="activity-content" v-html="activity.motion.summary"></div>
                <a class="view-more-text" style="font-weight: bold; margin-left: 12px" href="#">...View more</a>

                <div v-if="activity.mentioned_tags" style="margin-top: 10px">
                    <template v-for="feedTag in activity.mentioned_tags" :key="feedTag">
                        <a class="mention tag-mention" data-mention-type="tag" @click="onTagClick($event)" :href="'/index.html#/tag/profile/' + feedTag.tagId">{{ feedTag.tag.startsWith('#') ? feedTag.tag : '#' + feedTag.tag }}</a>
                        &nbsp;
                    </template>
                    <template v-for="motionTradeOrder in activity.motion.motionTradeOrders" :key="motionTradeOrder">
                        <template v-if="motionTradeOrder.tradeOrder && motionTradeOrder.tradeOrder.assetType == 'equity'">
                            <a
                                data-mention-type="security"
                                :data-mention="'$' + motionTradeOrder.tradeOrder.symbol"
                                class="mention security-mention"
                                onclick="window.CKEditorPluginService.onMentionClicked(event)"
                                href="#"
                                data-sec-type="equity"
                                :data-exchange="motionTradeOrder.tradeOrder.exchangeSymbol"
                                >${{ motionTradeOrder.tradeOrder.symbol }}</a
                            >
                        </template>
                        <template v-else-if="motionTradeOrder.tradeOrder">
                            <a
                                data-mention-type="security"
                                :data-mention="'$' + motionTradeOrder.tradeOrder.symbol"
                                class="mention security-mention"
                                onclick="window.CKEditorPluginService.onMentionClicked(event)"
                                href="#"
                                data-sec-type="fund"
                                :data-exchange="motionTradeOrder.tradeOrder.exchangeSymbol"
                                >${{ motionTradeOrder.tradeOrder.symbol }}</a
                            >
                        </template>
                    </template>
                </div>
                <div class="activity-stats-bar">
                    <span class="likes-container" v-if="allowsLikes()">
                        <i :class="{ 'pi pi-thumbs-up': true, liked: postUtils.isAlreadyLiked(activity), 'own-post': postUtils.isOwnPost(activity.actor.id) }" @click="onLikeClicked" v-touch:tap="onLikeClicked" />
                        <span class="likes-amount" v-tooltip.bottom="'View Likes'" @click="openPostLikesListDialog($event)">{{ hasLikeCounts() ? '' + activity.reaction_counts.like : '' }}</span>
                    </span>
                    <span class="comments-container" v-if="allowsComments()">
                        <i class="pi pi-comment" @click="onClick" v-touch:tap="onClick" />
                        {{ getCommentCount() }}
                    </span>
                    <div class="reaction-container" v-if="!postUtils.isOwnPost(activity.actor.id) && allowsLikes()">
                        <div class="reaction-icon-container" @click="openReactionMenu">
                            <span class="material-symbols-outlined reaction-icon">sentiment_satisfied</span>
                        </div>
                        <ReactionMenu :reactions="postReactions" @reaction-selected="addOrRemoveReaction(activity, $event)" ref="reactionMenu" />
                    </div>
                    <div class="motion-status-text" v-html="motionStatusText" v-if="[PASSED_MOTION_STATUS, VETOED_MOTION_STATUS, WITHDRAWN_MOTION_STATUS, FAILED_MOTION_STATUS].includes(activity.motion.motionStatus)"></div>
                    <div class="motion-status-text" v-else>
                        <span class="deadline-passed" v-if="!alreadyReacted(activity, 'yesVote') && !alreadyReacted(activity, 'noVote') && deadlineHasPassed">Deadline has passed, voting is closed.</span>
                        <div class="voting-container" v-else-if="!alreadyReacted(activity, 'yesVote') && !alreadyReacted(activity, 'noVote') && !deadlineHasPassed">
                            <div class="other">Motion is open for voting.</div>
                            <Button class="vote-button" label="Vote" />
                        </div>
                        <template v-else-if="alreadyReacted(activity, 'yesVote') || alreadyReacted(activity, 'noVote')">
                            <span class="voted" v-if="alreadyReacted(activity, 'yesVote')">You voted Yes to this Motion.</span>
                            <span class="voted" v-else-if="alreadyReacted(activity, 'noVote')">You voted No to this Motion.</span>
                        </template>
                        <span class="motion-consensus" v-if="showMotionConsensus(activity)">Current Consensus {{ getMotionConsensus(activity) }}</span>
                    </div>
                    <span class="looks-container" v-if="hasLookCounts()" v-tooltip.bottom="activity.reaction_counts.look + ' views'"> 👀 {{ activity.reaction_counts.look }} </span>
                </div>
                <div class="reaction-bar-container">
                    <template v-for="key in Object.keys(reactionCounts)" :key="key">
                        <div
                            :class="{ 'reaction-container': true, 'own-post': postUtils.isOwnPost(activity.actor.id), reacted: alreadyReacted(activity, key) }"
                            v-if="!['comment', 'like', 'look', 'yesVote', 'noVote', 'read'].includes(key) && formatReactionCount(key)['number'] > 0"
                            @click.stop="addOrRemoveReaction(activity, key)"
                        >
                            <span class="num">{{ formatReactionCount(key)['number'] }}</span>
                            <span class="icon">{{ formatReactionCount(key)['icon'] }}</span>
                        </div>
                    </template>
                </div>
            </div>

            <div class="recent-comment-block" data-role="recent-comment" v-if="hasComments() && !isMobile()">
                <div class="recent-comment-row">
                    <UserAvatar :imageSrc="activity.latest_reactions.comment[0].user.data.avatar" size="sm" customStyle="flex: 1 0 auto;" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)" />
                    <div class="comment-section">
                        <CommentCard :origActivity="activity" :comment="activity.latest_reactions.comment[0]" :summary="true" />
                        <span class="view-more-text" v-if="hasCommentCounts() && activity.num_comments > 1">{{ viewMoreCommentsText }}</span>
                    </div>
                </div>
            </div>
            <div class="no-comment-block" v-else-if="!isMobile() && allowsComments()">
                <UserAvatar :imageSrc="users.user.analyst.avatar" size="sm" />
                <InputText type="text" placeholder="Add a comment..." @click="onClick" />
            </div>
        </template>

        <!-- QUESTIONS -->
        <template v-else-if="activity.verb.startsWith('question')">
            <div class="activity-block question-activity-block" @click="onClick">
                <template v-if="!isEducationalPost()">
                    <div class="status-post-owner-info">
                        <UserAvatar :imageSrc="activity.actor.data.avatar" customStyle="min-width: 40px; min-height: 40px;" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)" />
                        <div class="names-container">
                            <span class="full-name" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)">{{ fullName }}</span>
                            <span class="username">
                                <span @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)">@{{ activity.actor.data.name }}</span>
                                | Posted {{ timeUtils.getTimeFromNow(activity.time) }}
                                {{ activity.group ? '|' : '' }}
                                <span class="posted-in-span" @click.stop="goToGroupProfile(activity.group, activity.groupFeed)">
                                    {{ activity.group ? ' Posted in ' + activity.group.name : '' }}
                                    {{ activity.groupFeed ? '- ' + activity.groupFeed.name : '' }}
                                </span>
                            </span>
                        </div>

                        <div class="actions-container">
                            <FollowButton :followTarget="userObjectForFollowButton" :confirmUnfollow="false" v-if="!userUtils.isUser(activity.actor.id) && !postUtils.alreadyFollowsUser(activity.actor.id) && !isEducationalPost()" />
                            <!-- <Badge class='following-badge' value="Following" v-else-if="!userUtils.isUser(activity.actor.id) && postUtils.alreadyFollowsUser(activity.actor.id)"></Badge> -->
                            <MoreOptionsMenu class="more-options" @click.stop="toggleMenu" v-if="postMenuItems && postMenuItems.length > 0" />
                            <Menu ref="menu" :model="postMenuItems" :popup="true" />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="actions-container" style="float: right">
                        <MoreOptionsMenu class="more-options" @click.stop="toggleMenu" v-if="postMenuItems && postMenuItems.length > 0" />
                        <Menu ref="menu" :model="postMenuItems" :popup="true" />
                    </div>
                </template>
                <div class="activity-title">
                    <template v-if="activity.poll?.pollTypeId == MULTIPLE_CHOICE_POLL.pollTypeId || activity.poll?.pollTypeId == MULTIPLE_SELECT_POLL.pollTypeId">
                        <img src="/assets/images/mc.png" class="title-icon" />
                    </template>
                    <template v-else-if="activity.poll?.pollTypeId == YESNO_POLL.pollTypeId">
                        <img src="/assets/images/poll.png" class="title-icon" />
                    </template>
                    <template v-else>
                        <img src="/assets/images/gq.png" class="title-icon" />
                    </template>

                    {{ activity.title }}
                </div>

                <div :id="activityContentDivId" class="activity-content" v-html="getPostPreviewContent(activity)"></div>
                <a class="view-more-text" v-if="images.length > 0 && textLength < 200 && !shouldShowFullContent()">View more</a>

                <QuestionVoting
                    :activity="activity"
                    @post-reacted="(a, r) => this.$emit('post-reacted', a, r)"
                    @open-polling-results-modal="viewPollingResults()"
                    @on-view-answer-details="onClick($event)"
                    :showAnswerDetailsLink="isEducationalPost() && activity?.poll?.showAnswerOnSubmit"
                />

                <div v-if="activity.mentioned_tags" style="margin-top: 30px">
                    <template v-for="feedTag in activity.mentioned_tags" :key="feedTag">
                        <a class="mention tag-mention" data-mention-type="tag" @click="onTagClick($event)" :href="'/index.html#/tag/profile/' + feedTag.tagId">{{ feedTag.tag.startsWith('#') ? feedTag.tag : '#' + feedTag.tag }}</a>
                        &nbsp;
                    </template>
                </div>
                <div class="activity-stats-bar">
                    <span class="likes-container" v-if="allowsLikes()">
                        <i :class="{ 'pi pi-thumbs-up': true, liked: postUtils.isAlreadyLiked(activity), 'own-post': postUtils.isOwnPost(activity.actor.id) }" @click="onLikeClicked" v-touch:tap="onLikeClicked" />
                        <span class="likes-amount" v-tooltip.bottom="'View Likes'" @click="openPostLikesListDialog($event)">{{ hasLikeCounts() ? '' + activity.reaction_counts.like : '' }}</span>
                    </span>
                    <span class="comments-container" v-if="allowsComments()">
                        <i class="pi pi-comment" @click="onClick" v-touch:tap="onClick" />
                        {{ getCommentCount() }}
                    </span>
                    <div class="reaction-container" v-if="!postUtils.isOwnPost(activity.actor.id) && allowsLikes()">
                        <div class="reaction-icon-container" @click="openReactionMenu">
                            <span class="material-symbols-outlined reaction-icon">sentiment_satisfied</span>
                        </div>
                        <ReactionMenu :reactions="postReactions" @reaction-selected="addOrRemoveReaction(activity, $event)" ref="reactionMenu" />
                    </div>

                    <span class="looks-container" v-if="hasLookCounts()" v-tooltip.bottom="activity.reaction_counts.look + ' views'"> 👀 {{ activity.reaction_counts.look }} </span>
                </div>
                <div class="reaction-bar-container">
                    <template v-for="key in Object.keys(reactionCounts)" :key="key">
                        <div
                            :class="{ 'reaction-container': true, 'own-post': postUtils.isOwnPost(activity.actor.id), reacted: alreadyReacted(activity, key) }"
                            v-if="!['comment', 'like', 'look', 'yesVote', 'noVote', 'A', 'B', 'C', 'D', 'E', 'read'].includes(key) && formatReactionCount(key)['number'] > 0"
                            @click.stop="addOrRemoveReaction(activity, key)"
                        >
                            <span class="num">{{ formatReactionCount(key)['number'] }}</span>
                            <span class="icon">{{ formatReactionCount(key)['icon'] }}</span>
                        </div>
                    </template>
                </div>
            </div>

            <div class="recent-comment-block" data-role="recent-comment" v-if="hasComments() && !isMobile()">
                <div class="recent-comment-row">
                    <UserAvatar :imageSrc="activity.latest_reactions.comment[0].user.data.avatar" size="sm" customStyle="flex: 1 0 auto;" @click="postUtils.goToAnalystProfile(activity.actor.data.name, $event)" />
                    <div class="comment-section">
                        <CommentCard :origActivity="activity" :comment="activity.latest_reactions.comment[0]" :summary="true" />
                        <span class="view-more-text" v-if="hasCommentCounts() && activity.num_comments > 1">{{ viewMoreCommentsText }}</span>
                    </div>
                </div>
            </div>
            <div class="no-comment-block" v-else-if="!isMobile() && allowsComments()">
                <UserAvatar :imageSrc="users.user.analyst.avatar" size="sm" />
                <InputText type="text" placeholder="Add a comment..." @click="onClick" />
            </div>
        </template>
        <template v-else>
            <span>unknown activity type</span>
        </template>
    </div>
    <ConfirmModal headerText="Delete this post?" confirmButtonLabel="Delete" @confirmed="deletePostConfirmed" @cancelled="deletePostCancelled" ref="confirmDeleteModal" />
    <PromotePostModal ref="promotePostModal" />
    <SharePostModal ref="sharePostModal" />
    <PostLikesListDialog ref="postLikesListModal" />
    <PostLocationsDialog ref="postLocationsDialog" :isRepost="true" @post-to-locations="doRepost" />
    <ViewPollingResultsModal ref="pollingResultsModal" />
    <MovePostDialog ref="movePostDialog" />
</template>

<script>
import { PASSED_MOTION_STATUS, FAILED_MOTION_STATUS, WITHDRAWN_MOTION_STATUS, VETOED_MOTION_STATUS, YESNO_POLL, MULTIPLE_CHOICE_POLL, MULTIPLE_SELECT_POLL, CANADIAN_CURRENCY_TYPE } from '../../common/constants';

import Badge from 'primevue/badge';

import { SHARE_URL } from '../../common/config';
import BrowserUtils from '../../utilities/BrowserUtils';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import ReactionMenu from '../menu/ReactionMenu.vue';
import FeedService from '../../service/FeedService';
import AdService from '../../service/AdService';
import PollingService from '../../service/PollingService';
import CommentCard from './CommentCard.vue';
import UserAvatar from '../profile/UserAvatar';
import ConfirmModal from '../modal/ConfirmModal.vue';
import PromotePostModal from '../modal/PromotePostModal.vue';
import FollowButton from '../button/FollowButton.vue';
import QuestionVoting from './QuestionVoting.vue';
import MovePostDialog from './MovePostDialog.vue';

import TimeUtils from '../../utilities/TimeUtils';
import PostUtils from '../../utilities/PostUtils';
import UserUtils from '../../utilities/UserUtils';

import { alreadyAnsweredPoll } from './index.js';

import { mapState } from 'vuex';
import EventBus from '../../event-bus';
import clip from 'text-clipper';

import SharePostModal from '../modal/SharePostModal.vue';
import PostLikesListDialog from './PostLikesListDialog.vue';
import PostLocationsDialog from './PostLocationsDialog.vue';

import ViewPollingResultsModal from './ViewPollingResultsModal.vue';

export default {
    emits: ['on-view-details', 'post-liked', 'post-unliked', 'post-deleted', 'on-edit-details', 'post-reacted', 'post-unreacted', 'poll-opened', 'poll-closed'],
    props: {
        selectedFeedType: {
            type: String,
            required: false,
        },
        selectedGroupFeed: {
            type: Object,
            required: false,
        },
        activity: {
            type: Object,
            required: false,
        },
        component: {
            type: String,
            required: false,
        },
        context: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            // CONSTANTS
            WITHDRAWN_MOTION_STATUS,
            VETOED_MOTION_STATUS,
            PASSED_MOTION_STATUS,
            FAILED_MOTION_STATUS,
            YESNO_POLL,
            MULTIPLE_CHOICE_POLL,
            MULTIPLE_SELECT_POLL,
            CANADIAN_CURRENCY_TYPE,

            likedClicked: false,
            images: [],
            template: this.component,
            postUtils: null,
            timeUtils: null,
            userUtils: UserUtils,
            textLength: 0,
            deletingAsSpam: false,
            postReactions: [],
            reactionCounts: {},
            castingYesVote: false,
            castingNoVote: false,
            selectedOption: null,
            selectedOptions: [],
            castingPoll: false,
        };
    },

    computed: {
        ...mapState(['users', 'selectedGroup']),
        isPollClosed() {
            return this.activity.poll?.closedDate != null;
        },

        userObjectForFollowButton() {
            return {
                analystId: Number(this.activity.actor.id),
                name: this.activity.actor.data.name,
            };
        },

        originalPosterFullName() {
            const firstName = this.activity.object.actor.data.firstName ? this.activity.object.actor.data.firstName : '';
            const middleName = this.activity.object.actor.data.middleName ? this.activity.object.actor.data.middleName : '';
            const lastName = this.activity.object.actor.data.lastName ? this.activity.object.actor.data.lastName : '';

            return `${firstName} ${middleName} ${lastName}`;
        },
        activityCardClass() {
            const displayFormatViaComponentMap = {
                ['Popular Notes']: 'row',
            };

            return (displayFormatViaComponentMap[this.template] ? displayFormatViaComponentMap[this.template] : 'card') + ' activity-card-container';
        },

        activityContentClass() {
            return {
                'activity-content': true,
                'ad-activity-content': this.isAd,
            };
        },

        activityContentDivId() {
            let prefix = '';
            if (this.template === 'Popular Notes') {
                prefix = 'pop-notes';
            }
            if (this.activity.verb == 'repost') {
                return prefix + `${this.activity.actor.id}-reposted-activityContent-${this.activity.object.id}`;
            } else {
                if (this.isAd) {
                    return prefix + `ad-activityContent-${this.activity.id}`;
                } else {
                    return prefix + `activityContent-${this.activity.id}`;
                }
            }
        },

        viewMoreCommentsText() {
            if (this.activity.verb == 'repost') {
                const text = this.activity.object.num_comments == 2 ? 'comment' : 'comments';

                return `View ${this.activity.object.num_comments - 1} more ${text}`;
            } else {
                const text = this.activity.num_comments == 2 ? 'comment' : 'comments';

                return `View ${this.activity.num_comments - 1} more ${text}`;
            }
        },
        fullName() {
            // console.log("fullName", this.activity);
            const firstName = this.activity.actor.data.firstName ? this.activity.actor.data.firstName : '';
            const middleName = this.activity.actor.data.middleName ? this.activity.actor.data.middleName : '';
            const lastName = this.activity.actor.data.lastName ? this.activity.actor.data.lastName : '';

            return `${firstName} ${middleName} ${lastName}`;
        },

        postMenuItems() {
            return this.getPostMenuItems();
        },

        deadlineHasPassed() {
            return TimeUtils.isSameOrBeforeNow(this.activity.motion.deadlineDate);
        },

        pollClosed() {
            if (this.activity.poll?.closedDate) {
                return true;
            } else {
                //return TimeUtils.isSameOrBeforeNow(this.activity.poll?.closedDate);
                return false;
            }
        },

        deadlineDate() {
            return TimeUtils.getFormattedTime(this.activity.motion.deadlineDate, 'YYYY-MM-DD');
        },

        motionStatusText() {
            const MOTION_STATUS_MAP = {
                [PASSED_MOTION_STATUS]: "<span class='passed'>Motion Passed</span> <span class='material-symbols-outlined'>check_circle</span>",
                [WITHDRAWN_MOTION_STATUS]: "<span class='other'>Motion Withdrawn</span>",
                [FAILED_MOTION_STATUS]: "<span class='other'>Motion Failed</span> <span class='material-symbols-outlined'>cancel</span>",
                [VETOED_MOTION_STATUS]: "<span class='other'>Motion Vetoed</span>",
            };

            return MOTION_STATUS_MAP[this.activity.motion.motionStatus];
        },

        isQuestion() {
            return this.activity.verb.startsWith('question');
        },

        isYesNoQuestion() {
            return this.isQuestion && this.activity.poll.pollTypeId === YESNO_POLL.pollTypeId;
        },

        isPollQuestion() {
            return [MULTIPLE_CHOICE_POLL.pollTypeId, MULTIPLE_SELECT_POLL.pollTypeId].includes(this.activity.poll.pollTypeId);
        },

        hasBeenAnswered() {
            return (this.isYesNoQuestion || this.isPollQuestion) && (this.activity.reaction_counts?.yesVote > 0 || this.activity.reaction_counts?.noVote > 0);
        },

        isAd() {
            return this.activity?.adLink;
        },
    },

    name: 'ActivityCard',
    components: {
        Badge,

        MoreOptionsMenu,
        CommentCard,
        ConfirmModal,
        UserAvatar,
        PromotePostModal,
        SharePostModal,
        PostLikesListDialog,
        PostLocationsDialog,
        FollowButton,
        ReactionMenu,
        QuestionVoting,
        ViewPollingResultsModal,
        MovePostDialog,
    },

    created() {
        this.postUtils = PostUtils;
        this.timeUtils = TimeUtils;
    },

    watch: {
        activity: {
            // update thumbnails when activity updated
            handler(updatedActivity, oldActvity) {//eslint-disable-line
                
                if (this.activity.verb != 'repost') {
                    this.reactionCounts = this.activity.reaction_counts;

                    if (!this.activity.verb.startsWith('motion')) {
                        let postContent = document.getElementById(this.activityContentDivId);

                        this.$nextTick(() => {
                            this.images = this.grabActivityThumbnails(this.activity.thumbnails);

                            this.pruneContentNode(postContent);
                        });
                    }
                } else {
                    this.reactionCounts = this.activity.object.reaction_counts;
                }
            },
            deep: true,
        },
    },

    mounted() {
        this.reactionCounts = this.activity.reaction_counts;

        for (const key in FeedService.reactionMap) {
            this.postReactions.push({
                text: key,
                icon: FeedService.reactionMap[key],
            });
        }

        if (!this.activity.verb.startsWith('motion')) {
            let thumbnails = this.activity.verb == 'repost' ? this.activity.object.thumbnails : this.activity.thumbnails;

            this.images = this.grabActivityThumbnails(thumbnails);
        }
    },
    unmounted() {
        // if( this.impressionsDetector) {
        //     console.log("stopping impression detctor");
        //     this.impressionsDetector.stop();
        // }
    },

    methods: {
        doNothingOnClick(event) {
            event.stopPropagation();
        },

        isEducationalPost() {
            if ((this.activity?.group?.educational || this.activity?.group?.coursesEnabled) && this.selectedGroupFeed?.courseId) {
                return true;
            } else {
                return false;
            }
        },

        getCodeForOptionId(optionId) {
            let poll = this.activity.poll;
            for (var i = 0; i < poll.pollOptions.length; ++i) {
                if (poll.pollOptions[i].pollOptionId == optionId) {
                    return poll.pollOptions[i].code;
                }
            }
            return null;
        },

        isNote() {
            if (this.activity.verb == 'repost') {
                return this.activity.object.has_note == 1;
            } else {
                return this.activity.has_note == 1;
            }
        },
        isOwnPost() {
            if (this.activity.verb == 'repost') {
                return this.postUtils.isOwnPost(this.activity.object.actor.id);
            } else {
                return this.postUtils.isOwnPost(this.activity.actor.id);
            }
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        getPostPreviewContent(activity) {
            return activity.content ? this.pruneContent(activity.content) : this.pruneContent(activity.summary);
        },

        pruneContent(content) {
            if (content) {
                let virtualContentNode = new DOMParser().parseFromString(content, 'text/html');
                this.pruneContentNode(virtualContentNode.body);

                return virtualContentNode.body.innerHTML;
            } else {
                return content;
            }
        },

        isFeaturedGroupFeed() {
            if (!this.$route.path.startsWith('/groups') && this.activity.groupFeed && this.$store.state.featuredGroupFeed) {
                return this.selectedFeedType == 'featuredFeed' || (this.$store.state.featuredGroupFeed.groupId == this.activity.groupFeed.groupId && this.$store.state.featuredGroupFeed.groupFeedId == this.activity.groupFeed.groupFeedId);
            } else {
                return false;
            }
        },

        shouldShowFullContent() {
            return this.isFeaturedGroupFeed() || (this.selectedGroupFeed && this.selectedGroupFeed?.showFullContent) || this.isAd;
        },

        /* Does the removing of images from preview, the clamping of text if too long for previews, and
        preventing the dialog to popup when clicking on normal links */
        pruneContentNode(postContentNode) {
            if (postContentNode) {
                //console.log("this feed = "+ this.selectedFeedType);
                if (!this.shouldShowFullContent()) {
                    this.removeMostMediaFromPreview(postContentNode);
                    this.clampActivityPreview(postContentNode);
                }
            }
        },

        // Removes all images and removes all embeds except the first one to allow for video previews on feed.
        removeMostMediaFromPreview(postContent) {
            Array.from(postContent.getElementsByTagName('IMG')).forEach((img) => {
                img.remove();
            });

            Array.from(postContent.getElementsByTagName('FIGURE')).forEach((fig) => {
                if (fig.getAttribute('class') == 'image') {
                    fig.remove();
                }
            });

            let embedCount = 0;
            Array.from(postContent.querySelectorAll('div[data-oembed-url]')).forEach((embed) => {
                if (embedCount > 0) {
                    // leave one iframe for media previews
                    embed.remove();
                }
                ++embedCount;
            });

            embedCount = 0;
            Array.from(postContent.querySelectorAll('iframe')).forEach((embed) => {
                if (embedCount > 0) {
                    // leave one iframe for media previews
                    embed.remove();
                }
                ++embedCount;
            });
        },

        onTagClick(event) {
            //console.log("attempting to stop propagation")

            event.stopPropagation();
        },

        getReposter() {
            // let groupInfo = ""
            // if( this.activity.object.group ){
            //     groupInfo = " from "+this.activity.object.group.name;
            // }
            if (this.activity.actor.id == this.$store.state.users.user.analyst.analystId) {
                return 'You'; //+ groupInfo;
            } else {
                return this.fullName; // + groupInfo ;
            }
        },
        thumbnailClass(url) {
            let img = document.createElement('IMG');
            img.src = url;

            return {
                'image-preview-thumbnail': true,
                'wide-photo': img.width > img.height,
            };
        },
        goToGroupProfile(group, groupFeed) {
            this.loading = false;

            const latest = { groupId: group.groupId, groupFeedId: 0, name: 'Latest' };

            this.$store.commit('SET_GROUP_ACCESS_HIST', {
                groupId: group.groupId,
                conduit: {
                    groupId: group.groupId,
                    type: groupFeed && groupFeed?.courseId ? 'courseFeed' : 'feed',
                    obj: groupFeed && groupFeed?.groupFeedId ? groupFeed : latest,
                },
            });

            if (groupFeed && groupFeed?.groupFeedId && this.$route.params.groupId == group.groupId) {
                EventBus.emit('select-group-feed', groupFeed);
            }

            this.$router.push('/groups/profile/' + group.groupId);
        },

        allowsComments() {
            //console.log("context:"+this.context)
            if (this.context?.startsWith('group')) {
                if (this.selectedGroupFeed) {
                    return this.selectedGroupFeed?.allowMemberComments;
                } else if (this.selectedGroup) {
                    return this.selectedGroup?.allowMemberComments;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },

        allowsLikes() {
            if (this.context?.startsWith('group')) {
                if (this.selectedGroupFeed) {
                    return this.selectedGroupFeed?.allowMemberLikes;
                } else if (this.selectedGroup) {
                    return this.selectedGroup?.allowMemberLikes;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },

        hasComments() {
            if (this.activity.verb == 'repost') {
                return this.activity.object.latest_reactions && this.activity.object.latest_reactions.comment && this.activity.object.latest_reactions.comment.length > 0;
            } else {
                return this.activity.latest_reactions && this.activity.latest_reactions.comment && this.activity.latest_reactions.comment.length > 0;
            }
        },
        hasLikeCounts() {
            if (this.activity.verb == 'repost') {
                return this.activity.object.reaction_counts && this.activity.object.reaction_counts.like;
            } else {
                return this.activity.reaction_counts && this.activity.reaction_counts.like;
            }
        },

        hasLookCounts() {
            if (this.activity.verb == 'repost') {
                return this.activity.object.reaction_counts && this.activity.object.reaction_counts.look;
            } else {
                return this.activity.reaction_counts && this.activity.reaction_counts.look;
            }
        },

        getLookCounts() {},

        hasCommentCounts() {
            if (this.activity.verb == 'repost') {
                //console.log("repost: "+this.activity.object.num_comments)
                //return this.activity.object.reaction_counts && this.activity.object.reaction_counts.comment;
                return this.activity.object.num_comments;
            } else {
                //console.log("post: "+this.activity.num_comments)
                //return this.activity.reaction_counts && this.activity.reaction_counts.comment;
                return this.activity.num_comments;
            }
        },

        getCommentCount() {
            return this.hasCommentCounts() ? '' + this.activity.num_comments : '';
        },
        getRepostCommentCount() {
            return this.hasCommentCounts() ? '' + this.activity.object.num_comments : '';
        },

        onLikeClicked(e) {
            e.stopPropagation();
            if (!this.likedClicked) {
                this.likedClicked = true;
                if (this.activity.verb == 'repost') {
                    this.handleLikeClicked(this.activity.object);
                } else {
                    this.handleLikeClicked(this.activity);
                }
            }

            e.preventDefault();
        },

        handleLikeClicked(activity) {
            const isOwnPost = this.postUtils.isOwnPost(activity.actor.id);
            const isAlreadyLiked = this.postUtils.isAlreadyLiked(activity);
            if (!isOwnPost && !isAlreadyLiked) {
                FeedService.likePost(activity).then((resp) => {
                    if (resp.data.status == 'success') {
                        this.$emit('post-liked', { activity: activity, reaction: resp.data.reaction });
                        this.likedClicked = false;
                    } else if (resp.data.message == 'Not Member') {
                        this.$toast.add({ severity: 'error', summary: `You must be a member of the group "${activity.group.name}" to like this post.`, detail: '', life: 3000, group: 'center' });
                    } else if (resp.data.message == 'Likes disabled') {
                        this.$toast.add({ severity: 'error', summary: 'Likes and reactions are currently disabled for this group feed.', life: 3000, group: 'center' });
                    }
                });
            } else if (!isOwnPost && isAlreadyLiked) {
                // we are unliking here

                FeedService.unlikePost(activity.own_reactions.like[0].id).then((resp) => {
                    if (resp.data.status == 'success') {
                        this.$emit('post-unliked', activity);
                    } else {
                        console.log('error unliking ' + JSON.stringify(resp.data));
                    }
                    this.likedClicked = false;
                });
            }
        },

        onClick(e) {
            //console.log("did I even make it here?", e);
            if (!this.isInAppLink(e.target) && !this.isFeaturedGroupFeed(this.activity)) {
                if (this.activity.verb == 'repost') {
                    this.$emit('on-view-details', this.activity);
                } else if (this.isAd) {
                    if (!this.activity.adLink.targetUrl) {
                        // not an adLink object just a url for external partner
                        window.open(this.activity.adLink, '_blank');
                    } else {
                        // adLink object for an internal partner
                        AdService.trackInAppAdLink(this.activity.adLink);
                        this.$router.push(this.activity.adLink.targetUrl);
                    }
                } else {
                    this.$emit('on-view-details', this.activity);
                }
            }
        },
        isInAppLink(element) {
            let href = element.getAttribute('href');
            if (href && href.startsWith('/index.html#')) {
                return true;
            } else {
                return false;
            }
        },
        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openReactionMenu(e) {
            e.stopPropagation();
            if (this.$refs.reactionMenu) {
                this.$refs.reactionMenu.toggle(e);
            }
            e.preventDefault();
        },

        unfollow() {
            FeedService.unfollowUser(this.activity.actor.data.name).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'You are no longer following ' + this.activity.actor.data.name, detail: '', life: 2000, group: 'tc' });
                    delete this.$store.state.users.user.analyst.followed.followedAnalysts[this.activity.actor.id];

                    EventBus.emit('followed-changed');
                }
            });
        },

        follow() {
            FeedService.followUser(this.activity.actor.data.name).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'You are now following ' + this.activity.actor.data.name, detail: '', life: 2000, group: 'tc' });
                    this.$store.state.users.user.analyst.followed.followedAnalysts[this.activity.actor.id] = { analystId: this.activity.actor.id };

                    EventBus.emit('followed-changed');
                }
            });
        },

        deletePost() {
            this.$refs.confirmDeleteModal.open();
        },

        showMovePostModal() {
            this.$refs.movePostDialog.open(this.activity, this.onPostMoved);
        },

        onPostMoved() {
            let post = { activity: this.activity };
            this.$emit('post-deleted', post);
        },

        deletePostConfirmed() {
            let post = { activity: this.activity };
            if (this.activity.verb == 'repost') {
                FeedService.deleteRepost(post, this.deletingAsSpam).then((resp) => {
                    if (resp.data.status == 'success') {
                        this.$emit('post-deleted', post);

                        this.deletingAsSpam = false;
                    } else {
                        this.deletingAsSpam = false;
                    }
                });
            } else {
                FeedService.deletePost(post, this.deletingAsSpam).then((resp) => {
                    if (resp.data.status == 'success') {
                        if (this.isEducationalPost()) {
                            //this.$store.commit("SET_COURSE_MODULE_STALE", this.selectedGroupFeed?.groupFeedId);
                            EventBus.emit('refresh-current-module');
                        } else {
                            this.$emit('post-deleted', post);
                        }
                        this.deletingAsSpam = false;
                    } else {
                        this.deletingAsSpam = false;
                    }
                });
            }
        },
        report() {
            let postUrl = null;
            let postId = null;
            if (this.activity.verb == 'repost') {
                postUrl = SHARE_URL + '/' + this.activity.object.foreign_id + '.xhtml';
                postId = this.activity.object.foreign_id;
            } else {
                postUrl = SHARE_URL + '/' + this.activity.foreign_id + '.xhtml';
                postId = this.activity.foreign_id;
            }

            let type = 'Post';

            let report = {
                type: type,
                postUrl: postUrl,
                postId: postId,
            };
            EventBus.emit('post-reported', report);
        },

        deletePostCancelled() {
            this.deletingAsSpam = false;
        },

        isSharingEnabled() {
            return this.$store.state.featureSettings && this.$store.state.featureSettings.postSharingEnabled;
        },

        isMotion() {
            return this.activity.verb.startsWith('motion');
        },

        getPostMenuItems() {
            //console.log("getting follow menu item  and already follows = " + this.alreadyFollows)
            let menuItems = [];

            if (!this.isEducationalPost() && this.isPoll() && (alreadyAnsweredPoll(this.activity) || this.postUtils.isOwnPost(this.activity.actor.id))) {
                menuItems.push({
                    label: 'View Results',
                    icon: 'pi pi-eye',
                    command: () => {
                        this.viewPollingResults();
                    },
                });
            }

            if (this.isSharingEnabled()) {
                if (this.activity.group) {
                    if (!this.activity.group.isPrivate && !this.activity.group.billingPlanId) {
                        menuItems.push({
                            label: 'Share',
                            icon: 'pi pi-share-alt',
                            command: () => {
                                this.showShareLink();
                            },
                        });

                        if (this.activity.groupFeed) {
                            menuItems.push({
                                label: 'Get In-App Link',
                                icon: 'pi pi-link',
                                command: () => {
                                    this.showInAppShareLink(this.activity.foreign_id, this.activity.groupFeed);
                                },
                            });
                        }
                    }
                } else {
                    if (!this.isAd) {
                        menuItems.push({
                            label: 'Share',
                            icon: 'pi pi-share-alt',
                            command: () => {
                                this.showShareLink();
                            },
                        });
                    }
                }
            }

            if (UserUtils.userIsFeedModerator() || (this.isAd && (UserUtils.userIsAdmin() || UserUtils.userIsAdvertising()))) {
                if (this.activity.promoted == 0) {
                    menuItems.push({
                        label: 'Promote',
                        icon: 'pi pi-arrow-up',
                        command: () => {
                            this.promotePost();
                        },
                    });
                } else {
                    menuItems.push({
                        label: 'Promote/Demote',
                        icon: 'pi pi-arrows-v',
                        command: () => {
                            this.demotePost();
                        },
                    });
                }
               
                menuItems.push({
                        label: 'Edit',
                        icon: 'pi pi-pencil',
                        command: () => {
                            this.$emit('on-edit-details', this.activity);
                        },
                    }
                );
                 

                menuItems.push({
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.deletePost();
                    },
                });
                menuItems.push({
                    label: 'Delete As Spam',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.deletingAsSpam = true;
                        this.deletePost();
                    },
                });
            }

            // Can't repost your own post
            if (!this.postUtils.isOwnPost(this.activity.actor.id)) {
                if (this.activity.group) {
                    if (!this.activity.group.isPrivate && !this.isMotion()) {
                        // Can't repost a post that's in a private group
                        if (!this.activity.group.educational && !this.activity.group.billingPlanId) {
                            menuItems.push({
                                label: 'Repost',
                                icon: 'pi pi-replay',
                                command: () => {
                                    this.showRepostLocationsDialog();
                                },
                            });
                        }
                    }
                } else {
                    if (this.activity.verb != 'repost' && !this.isMotion()) {
                        // for now can't repost a repost gets too confusing
                        // can't repost your own repost or a repost of your own original post

                        menuItems.push({
                            label: 'Repost',
                            icon: 'pi pi-replay',
                            command: () => {
                                this.showRepostLocationsDialog();
                            },
                        });
                    }
                }

                if (!this.isEducationalPost()) {
                    if (this.postUtils.alreadyFollowsUser(this.activity.actor.id)) {
                        menuItems.push({
                            label: 'Unfollow',
                            icon: 'pi pi-users',
                            command: () => {
                                this.unfollow();
                            },
                        });
                    } else {
                        menuItems.push({
                            label: 'Follow',
                            icon: 'pi pi-users',
                            command: () => {
                                this.follow();
                            },
                        });
                    }

                    menuItems.push({
                        label: 'Report',
                        icon: 'pi pi-exclamation-triangle',
                        command: () => {
                            this.report();
                        },
                    });
                }
            } else {
                // can repost own post if its a group post
                if (this.activity.group && !this.activity.group.isPrivate && this.activity.verb != 'repost' && !this.isMotion()) {
                    // Can't repost a post that's in a private group
                    if (!this.activity.group.educational && !this.activity.group.billingPlanId) {
                        menuItems.push({
                            label: 'Repost',
                            icon: 'pi pi-replay',
                            command: () => {
                                this.showRepostLocationsDialog();
                            },
                        });
                    }
                }
                if (!this.isMotion()) {
                    menuItems.push({
                        label: 'Delete',
                        icon: 'pi pi-trash',
                        command: () => {
                            this.deletePost();
                        },
                    });
                }
                if (this.activity.verb != 'repost' && !this.isMotion()) {
                    if (!this.isQuestion || (this.isQuestion && !this.activity.poll) || ((this.isYesNoQuestion || this.isPollQuestion) && !this.hasBeenAnswered))
                        menuItems.push({
                            label: 'Edit',
                            icon: 'pi pi-pencil',
                            command: () => {
                                this.$emit('on-edit-details', this.activity);
                            },
                        });
                }

                if (this.isPoll()) {
                    if (!this.isPollClosed) {
                        menuItems.push({
                            label: 'Close Poll',
                            icon: 'pi pi-stop-circle',
                            command: () => {
                                this.closePoll();
                            },
                        });
                    } else {
                        menuItems.push({
                            label: 'Reopen Poll',
                            icon: 'pi pi-play-circle',
                            command: () => {
                                this.openPoll();
                            },
                        });
                    }
                }
            }

            if (UserUtils.userIsAdmin() || (this.activity.group && UserUtils.isGroupOwnerAdminOrMod(this.activity.group.groupOwnerId, this.$store.getters['users/groupRoleList']))) {
                menuItems.push({
                    label: 'Edit',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.$emit('on-edit-details', this.activity);
                    },
                });
                menuItems.push({
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.deletePost();
                    },
                });
            }

            if (this.activity.group && (UserUtils.isUser(this.activity.group.groupOwnerId) || UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList'])) && !this.activity.motion) {
                // exclude motions
                menuItems.push({
                    label: 'Move',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.showMovePostModal();
                    },
                });
            }

            // Removing duplicate items function
            menuItems = menuItems.filter((value, index, self) => {
                /* We use 'self' since it returns the array of the current element (value),
                    we then use that array to loop through each element and compare it to 'value'.
                    If they match, we return the index found in 'self' and compare it to 'index' to determine
                    it's duplicate
                */
                return (
                    index ===
                    self.findIndex((item) => {
                        return item.label === value.label;
                    })
                );
            });

            return menuItems;
        },

        isPoll() {
            return this.activity.poll;
        },

        closePoll() {
            PollingService.closePoll(this.activity.poll.pollId).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$emit('poll-closed', this.activity);
                } else {
                    this.$toast.add({ severity: 'error', summary: 'An error occurred trying to close the poll.', detail: '', life: 3000, group: 'tc' });
                }
            });
        },

        openPoll() {
            PollingService.openPoll(this.activity.poll.pollId).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$emit('poll-opened', this.activity);
                } else {
                    this.$toast.add({ severity: 'error', summary: 'An error occurred trying to open the poll.', detail: '', life: 3000, group: 'tc' });
                }
            });
        },

        viewPollingResults() {
            if (!this.activity.group?.educational) {
                this.$refs.pollingResultsModal.open(this.activity.poll.pollId);
            }
        },

        showShareLink() {
            if (this.activity.verb == 'repost') {
                this.$refs.sharePostModal.open(SHARE_URL + '/' + this.activity.object.foreign_id + '.xhtml');
            } else {
                this.$refs.sharePostModal.open(SHARE_URL + '/' + this.activity.foreign_id + '.xhtml');
            }
        },

        showInAppShareLink(postId, groupFeed) {
            let url = '/index.html#/groups/profile/' + groupFeed.groupId + '?postId=' + postId;
            this.$refs.sharePostModal.open(url);
        },

        getFeedPostId() {
            if (this.activity.verb == 'repost') {
                return this.activity.object.foreign_id;
            } else {
                return this.activity.foreign_id;
            }
        },

        demotePost() {
            this.$refs.promotePostModal.open(this.activity.promoted, this.doDemotion);
        },

        promotePost() {
            this.$refs.promotePostModal.open(this.activity.promoted, this.doPromotion);
        },

        doDemotion(score) {
            FeedService.promotePost(this.activity.foreign_id, score)
                .then((resp) => {
                    if (resp.data.status == 'success') {
                        this.$toast.add({ severity: 'success', summary: 'Post Demoted.', detail: '', life: 3000, group: 'tc' });
                        EventBus.emit('post-demoted');
                    } else {
                        console.error('error demoting post ' + resp.data.message);
                        this.$toast.add({ severity: 'error', summary: 'An error occurred trying to demote post.', detail: '', life: 3000, group: 'tc' });
                    }
                })
                .catch((resp) => {
                    console.error('error demoting post ' + resp);
                    this.$toast.add({ severity: 'error', summary: 'An error occurred trying to demote post.', detail: '', life: 3000, group: 'tc' });
                });
        },

        doPromotion(score) {
            FeedService.promotePost(this.activity.foreign_id, score)
                .then((resp) => {
                    if (resp.data.status == 'success') {
                        this.$toast.add({ severity: 'success', summary: 'Post Promoted.', detail: '', life: 3000, group: 'tc' });
                        EventBus.emit('post-promoted');
                    } else {
                        console.error('error promoting post ' + resp.data.message);
                        this.$toast.add({ severity: 'error', summary: 'An error occurred trying to promoted post.', detail: '', life: 3000, group: 'tc' });
                    }
                })
                .catch((resp) => {
                    console.error('error promoting post ' + resp);
                    this.$toast.add({ severity: 'error', summary: 'An error occurred trying to promoted post.', detail: '', life: 3000, group: 'tc' });
                });
        },

        showRepostLocationsDialog() {
            const activityGroupId = this.activity.verb == 'repost' ? this.activity?.object?.group?.groupId : this.activity?.group?.groupId;
            //console.log("repost", activityGroupId, this.activity?.object?.group?.groupId, this.activity?.group?.groupId);
            this.$refs.postLocationsDialog.open(activityGroupId);
        },

        doRepost(postToMainFeed, groupId, groupSubFeedId) {
            const activity = this.postUtils.isOwnPost(this.activity?.object?.actor?.id) ? this.activity.object : this.activity;
            //console.log("doRepost", activity);

            FeedService.repost(activity, null, postToMainFeed, groupId ? groupId : null, groupSubFeedId ? groupSubFeedId : null).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: "You've reposted " + activity.actor.data.name + "'s post.", detail: '', life: 3000, group: 'tc' });

                    EventBus.emit('reposted');
                } else {
                    if (resp.data.message == 'Not Authorized') {
                        this.$toast.add({ severity: 'error', summary: 'You must be a group member to repost.', detail: '', life: 3000, group: 'tc' });
                    }
                }
            });
        },

        grabActivityThumbnails(thumbnails) {
            let imageCounter = 0;
            let arr = [];

            if (thumbnails && thumbnails.length > 0) {
                thumbnails.forEach((thumb) => {
                    if (imageCounter < 2) {
                        // let img = document.createElement("IMG");
                        // img.src = thumb.url;
                        // img.crossOrigin = "anonymous";

                        // this.resizeImage(img);
                        arr.push(thumb);
                        ++imageCounter;
                    }
                });
            }

            return arr;
        },

        findNearestParent(node) {
            let parent = node.parentNode;
            const tagNames = ['STRONG', 'A', 'I'];

            /* This is to ensure that the view more element is not within the tags from bolding, italizing and of a mention - Just prevents any
            accidental sharing of styling or wierd clicking behaviour (If it's in a mention) */
            while (tagNames.includes(parent.tagName)) {
                parent = parent.parentNode;
            }

            return parent;
        },
        clampActivityPreview(element) {
            this.textLength = element.innerText.length;

            if (this.textLength >= (this.isMobile() ? 400 : 500)) {
                let parentNode = null;
                const id = this.activity.verb == 'repost' ? `${this.activity.object.id}-viewMoreText` : `${this.activity.id}-viewMoreText`;
                const indicatorString = `<a id="${id}-viewMoreText" class='view-more-text' href='#'>...View more</a>`;

                // 268 includes the character length of the view more link element
                element.innerHTML = clip(element.innerHTML, 468, { breakWords: true, html: true, indicator: indicatorString });

                this.$nextTick(() => {
                    const viewMoreTextEl = document.getElementById(`${id}-viewMoreText`);

                    // For some reason, if the clipping happens to be at the actual end of a string - It won't add the indicator value (The view more element). Most likely due to HTML structure
                    if (!viewMoreTextEl) {
                        // Manually add in the view more element at the end of element/string in the last child (Including nested) of the whole content
                        const newViewMoreTextEl = document.createElement('A');
                        newViewMoreTextEl.innerText = '...View More';
                        newViewMoreTextEl.id = `${id}-viewMoreText`;
                        newViewMoreTextEl.className = 'view-more-text';

                        let lastChild = element.lastChild;

                        while (lastChild) {
                            if (lastChild.lastChild) {
                                lastChild = lastChild.lastChild;
                            } else {
                                break;
                            }
                        }

                        parentNode = this.findNearestParent(lastChild);

                        // We find the most nearest parent that's not one of those types of elements, and append it as the last child (So that the link element is still beside it and inline)
                        parentNode.appendChild(newViewMoreTextEl);

                        // We replace the content within the activity content div with the new content that has the appended view more text
                        const activityContentDiv = document.getElementById(this.activityContentDivId);
                        if (activityContentDiv && element.firstChild) {
                            activityContentDiv.firstChild.replaceWith(element.firstChild);
                        }
                    }
                });
            }
        },

        openPostLikesListDialog(event) {
            event.stopPropagation();
            this.$refs.postLikesListModal.open(this.activity);
        },

        addOrRemoveReaction(activity, reaction) {
            //eslint-disable-line
            //console.log("adding or removing reaction for message "+ JSON.stringify(this.data));
            if (!this.postUtils.isOwnPost(activity.actor.id)) {
                const reactions = activity.own_reactions[reaction];

                if (reactions !== undefined) {
                    for (let i = 0; i < reactions.length; i++) {
                        const r = reactions[i];

                        const userId = r.userID || r.user_id;

                        if (this.userUtils.isUser(userId)) {
                            if (r.kind == reaction) {
                                FeedService.removeReaction(r.id).then(() => {
                                    this.$emit('post-unreacted', activity, reaction);
                                });

                                break;
                            }
                        }
                    }
                } else {
                    FeedService.reactToPost(activity, reaction)
                        .then((resp) => {
                            if (resp.data.status === 'success') {
                                //console.log(activity, resp.data.reaction);
                                this.$emit('post-reacted', activity, resp.data.reaction);
                            } else if (resp.data.message == 'Not Member') {
                                this.$toast.add({ severity: 'error', summary: `You must be a member of the group "${activity.group.name}" to react to this post.`, detail: '', life: 3000, group: 'center' });
                            } else if (resp.data.message == 'Likes disabled') {
                                this.$toast.add({ severity: 'error', summary: 'Likes and reactions are currently disabled for this group feed.', life: 3000, group: 'center' });
                            }
                        })
                        .catch((err) => {
                            this.$toast.add({ severity: 'error', summary: err, detail: '', life: 3000, group: 'center' });
                            console.error(err);
                        });
                }
            }
        },
        formatReactionCount(key) {
            let reactionNum = this.reactionCounts[key];

            return {
                icon: FeedService.reactionMap[key],
                number: reactionNum,
            };
        },

        showMotionConsensus() {
            let yesVote = this.reactionCounts['yesVote'] ?? 0;
            let noVote = this.reactionCounts['noVote'] ?? 0;

            return yesVote + noVote > 2;
        },

        getMotionConsensus() {
            let yesVote = this.reactionCounts['yesVote'] ?? 0;
            let noVote = this.reactionCounts['noVote'] ?? 0;
            let totalVotes = yesVote + noVote;

            const perc = Math.round((yesVote / totalVotes) * 100);
            if (yesVote >= noVote) {
                return perc + '% Yes ';
            } else {
                return Math.abs(perc - 100.0) + '% No';
            }
        },

        alreadyReacted(activity, key) {
            if (activity.own_reactions[key] === undefined) {
                return false;
            } else {
                return activity.own_reactions[key].length > 0;
            }
        },

        getPollAnswers(activity) {
            let answers = '';
            let poll = activity.poll;
            for (var i = 0; i < poll.pollOptions.length; ++i) {
                if (activity.own_reactions[poll.pollOptions[i].code]?.length > 0) {
                    answers = answers + poll.pollOptions[i].code + ' ';
                }
            }

            return answers;
        },
    },
};
</script>
<style scoped>
.poll-reward-label {
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 10px;
}

.poll-options {
    padding-bottom: 15px;
}
.poll-option {
    margin-bottom: 5px;
}

.polling-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 8px;
}
.voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 8px;
}
.voting-container button,
.polling-container button {
    display: flex;
    align-items: center;
    border: 2px solid #32364e;
    border-radius: 50px;
    padding: 0px 8px;
    background: none;
}
.voting-container button:hover,
.polling-container button:hover {
    transform: rotate(0.2deg);
    transition: transform 0.25s cubic-bezier(0.5, 400, 0.5, -400);
    animation-iteration-count: infinite;
    background: none;
    border-color: none;
    box-shadow: none;
}
.voting-container .yes-vote-button,
.polling-container .yes-vote-button {
    margin-right: 12px;
}
.voting-container .yes-vote-button:hover,
.polling-container .yes-vote-button:hover {
    border-color: #33cc99;
}
.voting-container .no-vote-button:hover {
    border-color: #e63e3e;
}
.voting-container .vote-label,
.polling-container .vote-label {
    font-weight: bold;
    color: #32364e;
    font-size: 14px;
}
.voting-container .yes-vote-button .vote-icon,
.polling-container .yes-vote-button .vote-icon {
    color: #33cc99;
}
.voting-container .no-vote-button .vote-icon {
    color: #e63e3e;
}
.voting-container .vote-icon,
.polling-container .vote-icon {
    font-size: 24px;
}

.motion-consensus {
    display: block;
    padding-top: 2px;
}
</style>
<style>
.p-component {
    font-family: 'Trebuchet MS', 'Verdana';
}

/* .p-menu {
    padding: 0px;
    border-radius: 8px;
}
.p-menu .p-menuitem-link {
    border-radius: 8px;
}
.p-menu .p-menuitem-link .p-menuitem-text,
.p-menu .p-menuitem-link .p-menuitem-icon {
    color: #32364e;    
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #32364e;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #FFFFFF;
}
.p-menu .p-menuitem-link:focus {
    box-shadow: none;
} */
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
::v-deep(*:not(.pi, .material-symbols-outlined)),
::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}
v::deep(.disabled-menu) {
    pointer-events: none;
}

::v-deep(.activity-content > p) {
    padding: 0;
    margin: 0;
}

::v-deep(.activity-content ol li:not(.ql-direction-rtl), .activity-content ul li:not(.ql-direction-rtl)) {
    padding-left: 1.5em;
}

::v-deep(.activity-content li.ql-indent-1:not(.ql-direction-rtl)) {
    padding-left: 4.5em;
}
::v-deep(.activity-content li.ql-indent-2:not(.ql-direction-rtl)) {
    padding-left: 7.5em;
}
::v-deep(.activity-content li.ql-indent-3:not(.ql-direction-rtl)) {
    padding-left: 10.5em;
}
::v-deep(.activity-content li.ql-indent-4:not(.ql-direction-rtl)) {
    padding-left: 13.5em;
}
::v-deep(.activity-content li.ql-indent-5:not(.ql-direction-rtl)) {
    padding-left: 16.5em;
}
::v-deep(.activity-content li.ql-indent-6:not(.ql-direction-rtl)) {
    padding-left: 19.5em;
}
::v-deep(.activity-content li.ql-indent-7:not(.ql-direction-rtl)) {
    padding-left: 22.5em;
}
::v-deep(.activity-content li.ql-indent-8:not(.ql-direction-rtl)) {
    padding-left: 25.5em;
}

::v-deep(.activity-content .ql-indent-1:not(.ql-direction-rtl)) {
    padding-left: 3em;
}
::v-deep(.activity-content .ql-indent-2:not(.ql-direction-rtl)) {
    padding-left: 6em;
}
::v-deep(.activity-content .ql-indent-3:not(.ql-direction-rtl)) {
    padding-left: 9em;
}
::v-deep(.activity-content .ql-indent-4:not(.ql-direction-rtl)) {
    padding-left: 12em;
}
::v-deep(.activity-content .ql-indent-5:not(.ql-direction-rtl)) {
    padding-left: 15em;
}
::v-deep(.activity-content .ql-indent-6:not(.ql-direction-rtl)) {
    padding-left: 18em;
}
::v-deep(.activity-content .ql-indent-7:not(.ql-direction-rtl)) {
    padding-left: 21em;
}
::v-deep(.activity-content .ql-indent-8:not(.ql-direction-rtl)) {
    padding-left: 24em;
}

::v-deep(.activity-content > iframe.ql-video) {
    width: 100%;
    height: 30rem;
    padding: 10px;
    max-width: 50rem;
}

.looks-container {
    margin-left: auto;
}

/* STYLING FOR QUESTION STYLE ACTIVITY CARDS */

.question-activity-block .activity-title {
    position: relative;
    left: -20px;
}

.question-activity-block .title-icon {
    /* width: 75px; */
    scale: 1;
    margin-left: 10px;
    margin-right: 5px;
}

/* STYLING FOR MOTION ACTIVITY CARDS */
.motion-activity-block .activity-title {
    position: relative;
    /* left: 10px; */
}

.motion-activity-block .title-icon {
    margin-right: 20px;
    /* width: 30px; */
    scale: 0.8;
}
.motion-status-text .view-results-text {
    color: #33cc99;
    margin-left: 4px;
}

.motion-status-text .view-results-text:hover {
    text-decoration: underline;
    color: #33cc99;
}
.motion-status-text {
    font-size: 11px;
}
.motion-status-text .passed {
    color: #33cc99;
    font-weight: bold;
}
.motion-status-text .failed {
    color: #e63e3e;
    font-weight: bold;
}
.motion-status-text .deadline-passed,
.motion-status-text .other {
    color: #32364e;
}
.motion-status-text .voted {
    color: #32364e;
}
.motion-status-text .material-symbols-outlined {
    color: #32364e;
    font-size: 16px;
}

.motion-status-text .voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.motion-status-text .voting-container .other {
    margin-right: 6px;
}
.motion-status-text .voting-container .vote-button {
    display: flex;
    align-items: center;
    border: 1px solid #32364e;
    border-radius: 50px;
    padding: 4px;
    background: none;
}
.motion-status-text .voting-container .vote-button:hover {
    transform: rotate(0.2deg);
    transition: transform 0.25s cubic-bezier(0.5, 400, 0.5, -400);
    animation-iteration-count: infinite;
    background: none;
}
.motion-status-text .voting-container .vote-button:focus {
    box-shadow: none;
}
.motion-status-text .voting-container .vote-button ::v-deep(.p-button-label) {
    font-weight: bold;
    color: #32364e;
    font-size: 12px;
}

.motion-activity-block .activity-stats-bar {
    align-items: center;
}
/* .motion-activity-block .looks-container {
    margin: 0;
} */
.motion-activity-block .motion-status-text {
    margin-left: auto;
    margin-right: auto;
}

.enabled-menu {
}

/* GENERAL STYLING FOR ALL ACTIVITY CARDS */

::v-deep(.not-clickable) {
    pointer-events: none;
}

.card {
    padding: 0px;
    border-radius: 16px;
    margin-bottom: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.card:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
}
.row {
    padding-bottom: 16px;
    border-radius: 0px;
    margin-bottom: 0px;
    box-shadow: none;
    border-bottom: 1px solid #bfbfbf;
}
.row:nth-child(6) {
    border-bottom: none;
}
.row:hover {
    cursor: pointer;
}

.activity-block {
    padding: 16px 16px 0px;
}
.activity-block:hover {
    cursor: pointer;
}

.status-post-owner-info {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.status-post-owner-info .names-container {
    display: block;
    padding-right: 15px;
}
.status-post-owner-info .names-container .full-name {
    display: block;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
.status-post-owner-info .names-container .username {
    display: block;
    color: #a6a6a6;
    font-size: 12px;
}
.status-post-owner-info .names-container .posted-in-span {
    color: #33cc99;
}

.actions-container {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.actions-container > * {
    margin-right: 8px;
}
.actions-container > *:last-child {
    margin-right: 0px;
}

::v-deep(.follow-button) {
    height: 25px !important;
    margin-right: 8px;
}

.ad-flag,
.following-badge {
    font-size: 14px;
    font-weight: normal;
    height: 25px !important;
    display: inline-flex;
    align-items: center;
}

.repost-info {
    display: flex;
    align-items: center;
    padding: 16px 16px 0px;
}
.repost-info .username {
    color: #a6a6a6;
    font-size: 12px;
}

.activity-title {
    font-size: 24px;
    color: #32364e;
    font-weight: bold;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.activity-content {
    word-break: break-word;
    color: black;
    font-size: 16px;
}
.activity-content ::v-deep(.image) {
    text-align: center;
    margin: 0 auto !important;
}
::v-deep(.activity-content img) {
    max-width: 50rem;
}

.activity-content ::v-deep(.image.image-style-side) {
    float: right;
}
.activity-content:hover {
    cursor: pointer;
}

::v-deep(.ad-activity-content img) {
    /* aspect-ratio: 20/9; */
    /* max-width: 60% !important;*/
}
@supports not (aspect-ratio: 20/9) {
    ::v-deep(.ad-activity-content img::before) {
        float: left;
        padding-top: 45%;
        content: '';
    }

    ::v-deep(.ad-activity-content img::after) {
        display: block;
        clear: both;
        content: '';
    }
}
::v-deep(.ad-activity-content .image) {
    width: 100% !important;
}
::v-deep(.ad-activity-content figure > img) {
    min-width: unset;
}

::v-deep(.view-more-text) {
    color: #33cc99;
    pointer-events: none;
}
::v-deep(.activity-content a) {
    font-weight: bold;
}
::v-deep(.activity-content .security-mention) {
    color: #33cc99;
}
::v-deep(.activity-content .analyst-mention) {
    color: #693bf5;
}

[class*='image-preview'] {
    margin-top: 8px;
    border: 1px solid black;
    border-radius: 8px;
    height: 200px;
    text-align: center;
    width: fit-content;
}
[class*='image-preview'] img {
    /* width: 100%; */
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
}
.image-preview-thumbnail {
    /* width: 250px; */
    margin-left: auto;
    margin-right: auto;
}
.image-preview-thumbnail.wide-photo {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}

.image-preview-thumbnails {
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
}
.image-preview-thumbnails > img {
    width: 50%;
}
.image-preview-thumbnails img:first-of-type {
    padding-right: 8px;
}

.activity-stats-bar {
    display: flex;
    margin: 16px 0px;
}
.activity-stats-bar > span {
    color: #32364e;
    display: flex;
    align-items: center;
}
.activity-stats-bar > span i {
    margin-right: 4px;
    font-size: 20px;
}
.activity-stats-bar i.own-post:hover {
    cursor: default;
}
.activity-stats-bar > span i:not(.own-post):hover {
    color: #33cc99;
}
.activity-stats-bar .likes-container {
    margin-right: 16px;
}
.activity-stats-bar .likes-container .pi-thumbs-up.liked {
    color: #33cc99;
}
.activity-stats-bar .likes-container .likes-amount:hover {
    color: #33cc99;
}

.activity-stats-bar ::v-deep(.comments-container) {
    margin-right: 16px;
}

.reaction-bar-container {
    display: flex;
    margin-bottom: 8px;
}
.reaction-icon {
    color: #32364e;
    vertical-align: middle;
}
.reaction-icon:hover {
    cursor: pointer;
    transform: rotate(0.4deg);
    transition: transform 0.25s cubic-bezier(0.5, 400, 0.5, -400);
}
.reaction-container {
    margin-right: 4px;
}
.reaction-container:last-child {
    margin-right: 0;
}
.reaction-container.own-post > span:hover {
    cursor: default;
    pointer-events: none;
}
.reaction-container.reacted .num {
    color: #33cc99;
}

[class*='-comment-block'] {
    border-top: 1px solid rgb(226, 226, 226);
    padding: 16px 16px;
}
.recent-comment-block .recent-comment-row {
    display: flex;
}
.recent-comment-block .recent-comment-row .comment-section {
    width: 100%;
    flex: 0 1 auto;
}
.recent-comment-block .recent-comment-row .comment-section .view-more-text {
    color: #a6a6a6;
    padding-top: 2px;
}
.recent-comment-block .recent-comment-row .p-avatar:hover {
    cursor: pointer;
}
.no-comment-block {
    display: flex;
    align-items: center;
}
.no-comment-block .p-avatar:hover {
    cursor: pointer;
}
::v-deep(.no-comment-block .p-inputtext) {
    color: transparent;
    border-radius: 10px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    height: 32px;
    padding: 8px 0px 8px 20px;
    flex: 1;
}
::v-deep(.no-comment-block .p-inputtext:hover) {
    border-color: #bfbfbf;
    cursor: pointer;
}
::v-deep(.no-comment-block .p-inputtext:focus) {
    box-shadow: none;
    border-color: #bfbfbf;
}
::v-deep(.no-comment-block .p-inputtext::placeholder) {
    color: #bfbfbf;
    font-size: 14px;
}

.p-avatar {
    margin-right: 8px;
}
/*.p-avatar.p-avatar-xl {
    width: 40px;
    height: 40px;
} */

/*FOLLOW CARD STYLING */

.follow-card {
    display: flex;
    padding: 16px;
    align-items: center;
}
.follow-card:hover {
    cursor: default;
}

.follow-card .followee-container {
    display: flex;
    margin-left: 8px;
    align-items: center;
}

.follow-card .p-avatar:hover {
    cursor: pointer;
}

.follow-card span {
    font-size: 16px;
}

.follow-card .user-handle a {
    font-weight: bold;
    color: #693bf5;
}

/* SPECIFIC STYLING FOR POPULAR NOTES COMPONENT */
.popular-notes-card .activity-title {
    font-size: 16px;
    margin-bottom: 16px;
}

.popular-notes-card .activity-content-row {
    display: flex;
    align-items: center;
}
.popular-notes-card .activity-content-row .activity-content-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.popular-notes-card .activity-content-row .image-preview-thumbnail {
    align-self: center;
    flex: 0 1 80px;
    height: 60px;
    margin-top: 0px;
}
.popular-notes-card .image-preview-thumbnail img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.popular-notes-card .activity-content {
    font-size: 14px;
}

.popular-notes-card .activity-stats-bar {
    margin-bottom: 0px;
}
.popular-notes-card .activity-stats-bar .full-name {
    font-size: 12px;
    font-weight: bold;
    color: #bfbfbf;
    margin-right: 24px;
}
.popular-notes-card .activity-stats-bar .likes-container {
    margin-right: 16px;
}

.popular-notes-card .activity-stats-bar > span i {
    font-size: 15px;
}
.popular-notes-card .activity-stats-bar .reaction-container {
    display: flex;
    align-items: center;
}
.popular-notes-card .activity-stats-bar .reaction-container .reaction-icon {
    color: #32364e;
    font-size: 20px;
}
.popular-notes-card .activity-stats-bar [class*='container'] [class*='amount'] {
    font-size: 12px;
}
.popular-notes-card .reaction-bar-container {
    margin-top: 8px;
}

.margin-bottom-container {
    margin-bottom: 10vh;
}

@media (max-width: 760px) {
    ::v-deep(.ad-activity-content img) {
        /* aspect-ratio: 20/9; */
        /* max-width: 80% !important; */
    }
    /* .activity-block {
        padding-left: 15px;
        padding-right: 15px;
    } */
    .card {
        /* padding-left: 10px;
        padding-right: 10px; */
        border-radius: 0;
        margin-bottom: 8px;
        box-shadow: none;
    }
    .card:hover {
        box-shadow: none;
    }

    ::v-deep(.activity-content > figure) {
        margin-inline-start: 0 !important;
    }

    .status-post-owner-info .names-container {
        flex: 1;
    }
}

@media (max-width: 592px) {
    .question-activity-block .title-icon {
        margin-left: 5px;
        margin-right: 5px;
        scale: 0.8;
    }

    /* .motion-activity-block .title-icon {
        width: 20px;
    } */
}
::v-deep(.embedly-card) {
    max-height: 680px;
    transform-origin: top center;
    -webkit-transform: scale(0.8);
    -moz-transform-scale: scale(0.8);
}
::v-deep(.embedly-card-hug) {
    max-height: 400px;
}

::v-deep(.insta-iframe) {
    transform-origin: top center;
    -webkit-transform: scale(0.5);
    -moz-transform-scale: scale(0.5);
    height: 780px;
}

::v-deep(.insta-blockquote) {
    max-height: 400px;
}
</style>