<template>
<ScrollPanel :style="scrollStyle" ref="scroller" >
  <div :style="scrollContentStyle" ref="scrollRef">
    <slot></slot>
    <!-- <div v-if="loading" style="text-align: center">
      <div class="spinner"></div>
    </div> -->
  </div>
  <ScrollTop class='scroll-to-top-button' target="parent" v-tooltip.top="{value: 'Back to top'}" v-if='autoNavigate'/>
</ScrollPanel>
</template>

<script>
import { onMounted, onUnmounted, toRefs, ref, computed,onErrorCaptured } from "vue";
import infiniteScroll from "./composables/infiniteScroll";
import errorHandler from "./composables/errorHandler";
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';

export default {
  props: {
    noResult: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "Failed to load data."
    },
    style: {
      // type: String,
      default: "width: 100%; height: 450px;max-height: 450px !important;"
    },
    contentStyle: {
      type: String,
      default: ""
    },
    direction: {
      type: String,
      default: 'down'
    },
    autoNavigate: {
      type: Boolean,
      default: false
    }
  },
   components: { ScrollPanel, ScrollTop},

  setup(props, { emit }) {
    const { noResult, message, direction } = toRefs(props);
    const scroller = ref(null);
    onErrorCaptured(((e) => {console.warn("infinite scroll capturing error : ",e); return false;}));
    const {
      handleScroll,
      scrollRef,
      loading,
    
    } = infiniteScroll(noResult, emit, scroller, direction)
    const scrollStyle = computed(() => props?.style);
    const scrollContentStyle = computed(() => props?.contentStyle);

    errorHandler(noResult, loading, message);

   
    onMounted(() => {
      try {
        scroller.value.$el.getElementsByClassName('p-scrollpanel-content')[0].addEventListener("scroll", handleScroll);
      }catch(error){
        console.log("error :" + error);
      }
		})
		onUnmounted(() => {
      // commenting this out for now, not sure why you'd need to do this.
      // try {
      //   if( scroller.value ){
      //     scroller.value.$el.getElementsByClassName('p-scrollpanel-content')[0].addEventListener("scroll", handleScroll);
      //   }
      // }catch(error){
      //   console.log("error :" + error);
      // }
		})

    return {
      scrollRef,
      loading,
      scroller,
      scrollStyle,
      scrollContentStyle
    }
  },
}
</script>

<style>
  .p-scrollpanel .p-scrollpanel-bar {
    background: none;
  }

  .p-scrolltop {
    border-radius: 16px;
    padding: 6px 16px;
    border-style: solid;
    border-width: 1px;
    margin-right: 8px;
    background: #FFFFFF;
    border-color: #32364E;
  }
  .p-scrolltop:hover {
    background: #32364E;
    color: #FFFFFF;
  }
  .p-link:focus {
    box-shadow: none;
    border-color: #32364E;
  }

</style>
<style scoped>

  .p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    -ms-overflow-style: none;
    scrollbar-width: none;

    width: auto;
    height: 100%;
  }
  .p-scrollpanel ::v-deep(.p-scrollpanel-content::-webkit-scrollbar) {
    display: none;
  }

  .spinner {
    display: inline-block; /* Setting display to inline-block prevents the custom event from being fired multiple times at once */
    margin: 5px auto;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 2px dashed black;
    position: relative;
    animation: spin 2s ease infinite;
  }

  .spinner::before {
    position: absolute;
    content: "";
    top: 0;
    left: -7px;
    width: 40%;
    height: 70%;
    background: white;
  }

  ::v-deep(.p-scrollpanel-content) {
    padding: 0px;
  }


  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
