import { format, add, endOfMonth, isAfter, isBefore, endOfWeek  } from "date-fns";
import { CANADIAN_CURRENCY_TYPE, US_CURRENCY_TYPE } from '../../common/constants';

export const AWARDS = [
	{ awardId: 1, defaultReason:'First Place', name:'First Place Award', icon:'/assets/images/medal_gold.png'},
	{ awardId: 2, defaultReason:'Second Place', name:'Second Place Award', icon:'/assets/images/medal_silver.png'},
	{ awardId: 3, defaultReason:'Third Place', name:'Third Place Award', icon:'/assets/images/medal_bronze.png'},
	{ awardId: 4, defaultReason:'Top 10', name:'Top 10 Award', icon:'/assets/images/ribbon_pr.png'},
	{ awardId: 5, defaultReason:'Positive Returns', name:'Postive Returns Award', icon:'/assets/images/ribbon_gr.png'},
    { awardId: 6, defaultReason:'Participation', name:'Participation Badge', icon:'/assets/images/part_badge.png'}
]

export function getCurrencyText(currencyId) {
    if (currencyId === CANADIAN_CURRENCY_TYPE.ID) {
        return CANADIAN_CURRENCY_TYPE.NAME;
    } else {
        return US_CURRENCY_TYPE.NAME;
    }
}

export function getContestCycles(contestStartDate, showCurrent) {
    let cycleOptions = [];

    const currentDate = new Date();

    let startDateOfContestCycle;
    let endDateOfContestCycle;
    let cycleName;
    let isFirstCycle = true;

    do {
        startDateOfContestCycle = isFirstCycle ? new Date(contestStartDate) : add(endDateOfContestCycle, { days: 1 });
        isFirstCycle = false;
        endDateOfContestCycle = endOfMonth(startDateOfContestCycle);

        cycleName = format(startDateOfContestCycle, "MMM d") + ' - ' + format(endDateOfContestCycle, "MMM d yyyy");
        if (showCurrent && isBefore(currentDate, endDateOfContestCycle)) {
            cycleName += " (Current)";
        }
        cycleOptions.push({
            label: cycleName,
            value: { id: 1, name: cycleName, startDate: format(startDateOfContestCycle, "yyyyMMdd"), endDate: format(endDateOfContestCycle, "yyyyMMdd") },
        });
    } while (isAfter(currentDate, endDateOfContestCycle));
    if( cycleOptions.length > 1) {
        const tomorrow = add(currentDate, { days: 1 });
        cycleOptions.unshift({
            label: "All Time",
            value: { id: 1, name: "All Time", startDate: format(new Date(contestStartDate), "yyyyMMdd"), endDate: format(tomorrow, "yyyyMMdd") },
        })
    }
    return cycleOptions;
}

export function getWeeklyContestCycles(contestStartDate, showCurrent) {
    let cycleOptions = [];

    const currentDate = new Date();

    let startDateOfContestCycle;
    let endDateOfContestCycle;
    let cycleName;
    let isFirstCycle = true;

    do {
        startDateOfContestCycle = isFirstCycle ? new Date(contestStartDate) : add(endDateOfContestCycle, { days: 1 });
        isFirstCycle = false;
        endDateOfContestCycle = endOfWeek(startDateOfContestCycle, { weekStartsOn: 1 });

        cycleName = format(startDateOfContestCycle, "MMM d") + ' - ' + format(endDateOfContestCycle, "MMM d yyyy");
        if (showCurrent && isBefore(currentDate, endDateOfContestCycle)) {
            cycleName += " (Current)";
        }

        const historical = isAfter(currentDate, endDateOfContestCycle);

        cycleOptions.push({
            label: cycleName,
            value: { id: 1, name: cycleName, startDate: format(startDateOfContestCycle, "yyyyMMdd"), endDate: format(endDateOfContestCycle, "yyyyMMdd"), historical:historical },
        });
    } while (isAfter(currentDate, endDateOfContestCycle));
    return cycleOptions;
}

export function getCompetitionDateFormatted(date) {
    return format(new Date(date), "yyyy-MM-dd");
}

export function getCompetitionRegistrationType(item) {
    if (item.inviteOnly) {
        return "Invite Only";
    } else if (item.membersOnly) {
        return "Members Only";
    } else {
        return "Open";
    }
}

export function getCompetitionType(competitionTypeId) {
    switch (competitionTypeId) {
        case 1:
            return "Open to Teams";
        case 2:
            return "Open to Individuals";
        case 3:
            return "Open to Teams and Individuals";
    }
}

export function getCompetitionSkillLevel(skillLevelId) {
    switch (skillLevelId) {
        case 1:
            return "Beginner";
        case 2:
            return "Intermediate";
        case 3:
            return "Advanced";
        case 4:
            return "Beginner to Intermediate";
        case 5:
            return "Intermediate to Advanced";
        case 6:
            return "All Skill Levels";
    }
}

export function getCompetitionCategory(categoryId) {
    switch (categoryId) {
        case 1:
            return "Portfolio";
        case 2:
            return "Research";
        case 3:
            return "Quiz";
    }
}


