<template>
	<Dialog header="Promote/Demote Post" v-model:visible="display" :modal="true" :closable="false" class='promote-modal'>
		<span class='body-text'>Enter a promotion score between 0 and 100(The higher the score the higher the post is ranked).</span>
		<template #footer>
			<!-- <span class='p-float-label'> -->
			<div class="p-field p-col-12 p-md-12" style="text-align: center;">
				<label for="promotion-score" style="margin-right: 5px;">Current Score</label>
				<InputNumber id='promotion-score'  v-model="score" :min="0" :max="100" mode="decimal" showButtons />
            </div> 
               
            <!-- </span> -->
			
			
			<div class='button-container'>
			<Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close()'/>
			<Button label="Update Score" class='accept p-button-raised p-button-rounded' @click='closeAndCallback(score)'/>
			</div>
		</template>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';

export default {
    name: 'ConfirmModal',
	
    data() {
		return {
			
			display: false,
			score: 0,
			callback: null,
		}
    },

	components: {
		Dialog,InputNumber,
	},

	

	methods: {
		open( currentScore, callback) {
			this.callback = callback;
			this.score = currentScore;
			this.display = true;
		},
		close() {
			this.display = false;
			this.callback = null;
			this.score = 0;
		},

		closeAndCallback(score) {
			
			this.display = false
			if( this.callback) {
				this.callback(score);
			}
			this.callback = null;
		}
	}

}
</script>

<style>
.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.promote-modal {
	font-family: "Trebuchet MS", "Verdana";
	box-shadow:  0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.promote-modal .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.promote-modal .p-dialog-header .p-dialog-title {
	font-weight: bold;
    font-size: 24px;
}

.promote-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 10px 25px;
}

.promote-modal .body-text {
    font-size: 16px;
    color: #a6a6a6;
}

.promote-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.promote-modal .button-container {
    display: flex;
    justify-content: center;
}
</style>
<style scoped>
 .promote-modal .p-dialog-footer .p-button {
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	font-weight: 600;
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .promote-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 1rem;
 }

 .promote-modal .p-dialog-footer .accept {
	border-color: #33CC99;
 }

 .promote-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33CC99;
	border: 2px solid #33CC99;
 }

 .promote-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
	border: 2px solid #32364E;
 } 

/* .promote-modal .score-input {
    width: 20px;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
}
.promote-modal .score-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
} */
.p-float-label {
    margin: 15px 0px 25px;
}

.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}
</style>
