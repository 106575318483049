import APIService from '@/common/APIService'
import QMAPIService from '@/common/QMAPIService'
import { GIPHY_KEY, GIPHY_URL } from "@/common/config";


class DataServiceImpl {

  searchTrendingGiphy(limit, offset) {//eslint-disable-line

  }

  searchGiphy(query, limit, offset) {
    return APIService.getExternal(GIPHY_URL+GIPHY_KEY+'&q='+query+'&limit='+limit+'&offset='+offset+'&rating=r&lang=en')
  }

  getEquityHistory(securityId) {
    return APIService.get('/data/equity/'+securityId+"/history");
  }

  getFundHistory(fundId) {
    return APIService.get('/data/fund/'+fundId+"/history");
  }

  getIndexHistory(indexId) {
    return APIService.get('/data/marketIndex/'+indexId+"/history");
  }
  /*
  getPriceHistory(type, id) {
    if( type == 'EQUITY') {
      return this.getEquityHistory(id);
    }
    else if( type == 'FUND') {
      return this.getFundHistory(id);
    }
    else {
      return this.getIndexHistory(id);
    }
  }*/

  getLatestPriceSince(symbol, startDate, startDateTime) {//eslint-disable-line
    let params = '?symbol='+symbol+'&zeroTradeDays=false&marketSession=mkt&adjusted=true&adjType=SO&unadjusted=false&startDate='+startDate;
    params = params + '&interval=5&freq=day'//'&interval=1';//+interval;//'&datatype=int&freq=minute'
      
    return QMAPIService.get('getEnhancedChartData.json'+params);
    
  }

  getPriceHistory(symbol, interval, intervalType, startDate, endDate) {//eslint-disable-line
    let params = '?symbol='+symbol+'&zeroTradeDays=false&marketSession=mkt&adjusted=true&adjType=SO&unadjusted=false&start='+startDate;
   
      if(intervalType != 'D' && ( intervalType != 'M' || (intervalType == 'M' && interval > 1)) )
      {
        params = params +'&freq=day&datatype=eod';
      }
      else {
        params = params + '&interval=5&freq=day'//'&interval=1';//+interval;//'&datatype=int&freq=minute'
      }
    
    
    return QMAPIService.get('getEnhancedChartData.json'+params);
    
  }

  getSnapQuotes(symbols) {
    return QMAPIService.get('getQuotes.json?symbols='+symbols);
  }

  getSectorSecurities(exchangeCode, sectorIndustryCode, statCode, limit) {//eslint-disable-line
    

    
    // if( countryCode ) {
    //   return QMAPIService.get('getMarketStats.json?statCountry='+countryCode+'&stat='+statCode+'&statTop='+limit+'&sector='+sectorIndustryCode);
    // }
    // else {
      return QMAPIService.get('getMarketStats.json?statExchange='+exchangeCode+'&stat='+statCode+'&statTop='+limit+'&sector='+sectorIndustryCode);
    //}

  }

  getFinancials(securityId, stmtType, periodType) {
    return APIService.get("/data/equity/"+securityId+"/financials?stmtType="+stmtType+ (periodType? "&periodType="+periodType: ""));
  }

  getMetrics(securityId, periodType) {
    return APIService.get("/data/equity/"+securityId+"/metrics"+ (periodType? "?periodType="+periodType: ""));
  }

  searchMetrics(securityId, periodType, query, limit, offset) {
    return APIService.get("/data/equity/"+securityId+"/metrics/search?periodType="+(periodType? periodType : "")+"&query="+query+"&limit="+limit+"&offset="+offset);
  }

  getMeasureDescription(measureId){
    return APIService.get(`/data/equity/metric/${measureId}/description`);
  }

  getLineItemDescription(dataItemId){
    return APIService.get(`/data/equity/financial/${dataItemId}/description`);
  }

  saveUserMetric(measureId){
    return APIService.post("/data/equity/user/metric/"+measureId, {});
  }

  saveCompareMetric(measureId){
    return APIService.post("/data/equity/user/metric/compare/"+measureId, {});
  }

  removeUserMetric(measureId){
    return APIService.delete("/data/equity/user/metric/"+measureId);
  }

  getComparisonMetrics(securityId) {
    return APIService.get("/data/equity/"+securityId+"/comp/metrics");
  }
  
  getPeers(securityId){
    return APIService.get("/data/equity/"+securityId+"/peers");
  }

  getMetricData(periodTypeId, measureId, securityId){
    return APIService.get("/data/equity/"+securityId+"/measure/"+ measureId+"?periodTypeId="+periodTypeId);
  }

  getTopMentionedEquities(limit, startDate, endDate) {
    return APIService.get("/data/equity/top/mentioned?limit="+limit+"&startDate="+startDate+"&endDate="+endDate);
  }

  getTopMentionedGroupEquities(groupId, limit, startDate, endDate) {
    return APIService.get("/data/equity/top/mentioned/group?limit="+limit+"&startDate="+startDate+"&endDate="+endDate+(groupId ? "&groupId="+groupId : ""));
  }

  getTopMentionedFunds(limit, startDate, endDate) {
    return APIService.get("/data/fund/top/mentioned?limit="+limit+"&startDate="+startDate+"&endDate="+endDate);
  }

  getTopMentionedGroupFunds(groupId, limit, startDate, endDate) {
    return APIService.get("/data/fund/top/mentioned/group?limit="+limit+"&startDate="+startDate+"&endDate="+endDate+(groupId ? "&groupId="+groupId : ""));
  }

  getTopMentionedTags(limit, startDate, endDate) {
    return APIService.get("/data/tag/top/mentioned?limit="+limit+"&startDate="+startDate+"&endDate="+endDate);
  }

  getTopMentionedGroupTags(groupId, limit, startDate, endDate) {
    return APIService.get("/data/tag/top/mentioned/group?limit="+limit+"&startDate="+startDate+"&endDate="+endDate+(groupId ? "&groupId="+groupId : ""));
  }


  
}

const DataService = new DataServiceImpl();
export default DataService;