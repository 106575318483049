import { utcToZonedTime } from 'date-fns-tz';
import moment from "moment";
import router from '../router';
import store from '../store/index';
class PostUtilsImpl {
    gatherMentionsAndMedia(node, mentions, mediaUrls) {
        console.log("mention search on ", node.name)
        if (node.hasAttribute('mention')) {
            mentions.push(node.getAttribute('mention'));
        } else if( node.name == 'imageInline' || node.name == 'imageBlock' || node.name == 'img'){
            let src = node.getAttribute('src');
            
            mediaUrls.push({type: 'image', url: src });
        
        }else if( node.name == 'media' || node.name=='iframe'){    
           
            let url = node.getAttribute('url');
            
            mediaUrls.push( {type: 'video', url:url});

        } else if (node.children) {
            for (let child of node.children) {
                this.gatherMentionsAndMedia(child, mentions, mediaUrls);
            }
        }
      }
  
    getPostedDate(time) {
        const nyTimeZone = 'America/New_York';
        let d = new Date(time + 'Z');

        return moment(utcToZonedTime(d, nyTimeZone)).fromNow();
    }

    getRevisionDate(revision_date) {
        const nyTimeZone = 'America/New_York';
        let d = new Date(revision_date);

        return moment(utcToZonedTime(d, nyTimeZone)).fromNow();
    }

    goToAnalystProfile(username, event) {
        event.stopPropagation();
        router.push('/analysts/profile/' + username);
    }

    isAlreadyLiked(activity) {
        if (activity.own_reactions === undefined) {
            return false;
        }

        return activity.own_reactions.like && activity.own_reactions.like.length > 0;
    }

    isAlreadyRead(activity) {
        if (activity.own_reactions === undefined) {
            return false;
        }

        return activity.own_reactions.read && activity.own_reactions.read.length > 0;
    }

    isOwnPost(user_id) {
        return Number(user_id) === store.state.users.user.analyst.analystId;
    }

    alreadyFollowsUser(user_id) {
        return store.state.users?.user?.analyst.followed.followedAnalysts[Number(user_id)] !== undefined;
    }

    hasLikeCount(reaction_counts) {
        return reaction_counts && reaction_counts.like;
    }

    hasLookCount(reaction_counts) {
        return reaction_counts && reaction_counts.look;
    }

    hasCommentCount(reaction_counts) {
        return reaction_counts && reaction_counts.comment;
    }
}

const PostUtils = new PostUtilsImpl();
export default PostUtils;