<template>
  <Dialog header="Share Post" class='share-post-modal' v-model:visible="display" :modal="true" >
		
		<span class='body-text'> Copy the url below to share this post. </span>

		<div class='share-link-bar'>
            <InputText type="text" v-model="sharedUrl" id="sharedInput" ref="sharedInput" class="url-input"/>
            <Button label="Copy link" class="copy-link-button" @click="handleCopy" />
		</div>
	
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
export default {
    name: "SharePostModal",
    data() {
		return {
			display: false,
            
            sharedUrl: null
		}
	},
	components: {
		Dialog, InputText
	},
    methods: {
        async handleCopy() {
			
			
			try {
				if (navigator.clipboard) {
					await navigator.clipboard.writeText(this.sharedUrl);
				} else {
					let input = document.getElementById("sharedInput");
					input.select();
					document.execCommand('copy');

				}
				this.close();
				this.$toast.add({ severity: 'success', summary: 'Linked copied!', life: 2000, group: 'center' });
			} catch (err) {
				console.debug('Oops, unable to copy'+err);
			}
			

		},

        open(sharedUrl) {
            this.sharedUrl = sharedUrl;
            this.display = true;
        },
		close() {
			this.display = false;
            this.sharedUrl = null;

		}
    }
}
</script>
<style>
.share-post-modal {
	font-family: "Trebuchet MS", "Verdana";
	box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    width: 500px;
    height: auto;
	border-radius: 16px;
}

.share-post-modal .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	padding: 16px;
}

.share-post-modal .p-dialog-header .p-dialog-title {
	font-weight: bold;
	font-size: 24px;
	color: #32364e;
}

.share-post-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	padding: 16px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}


</style>
<style scoped>
*:not(.pi), .p-component {
    font-family: 'Trebuchet MS', 'Verdana';
}

 @media (max-width: 760px) {
    .share-post-modal {
        width: 100%;
       
    }
 }

.share-post-modal .body-text {
    font-size: 16px;
    color: #BFBFBF;
}

 .share-post-modal .share-link-bar {
	display: flex;
	align-items: center;
	margin-top: 16px;
 }

::v-deep(.p-inputtext) {
	pointer-events: none;
	width: 75%;
	font-size: 16px;
    color: #BFBFBF;
    border-radius: 8px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    padding: 8px 0px 8px 16px;
	margin-right: 8px;
}

.share-post-modal .copy-link-button {
	color: #FFFFFF;
	background: #33CC99;
	border: 1px solid #33CC99;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	flex: 1;
}

.share-post-modal .copy-link-button:hover {
    background: #00C083;
    border-color: #00C083;
}
.share-post-modal .copy-link-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}
</style>